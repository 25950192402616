import React from 'react';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import styles from './OAuth.css';


export default function OAuth(){
const codestyle={backgroundColor: 'lightgray'};
return (
<div className="container">
<Card className="card-background"> <Card.Body>
<blockquote className="blockquote mb-0">
<h1 className="text-header">OAuth</h1>
<p>IOX`s APIs involve consumer-mediated exchange of data.  Conduent follows the OAuth 2.0 protocols for authentication.  When accessing IOX`s sandbox APIs, you will need to follow the instructions below to receive an Authorization Code and an Access Token for a sandbox user. Please note the Authorization Code is only valid for a single use.</p>
</blockquote> </Card.Body>
</Card>
<br/>
<hr className="horizontal-rule"></hr>
<br/>
<Card className="card-background"> <Card.Body>
<blockquote className="blockquote mb-0">
<h1 className="text-header">Getting an Authorization Code</h1>
<p> Copy the link below into a browser window to initiate the member authentication and authorization flow.</p>
</blockquote> </Card.Body>
<blockquote className="OAuth-code" >{process.env.REACT_APP_EDUCATIONAL_MATERIAL_URL}Authorize</blockquote>
</Card>
<Card className="card-background">
{/*<Card.Body>
<p>Use the following guidance to login as a sandbox user:</p>
<p>There are currently 20 sandbox users that exist. The username and password pattern is as follows:</p>
<Table striped bordered hover>
<thead> <tr>
<th>UserName</th>
<th>HUsername##</th> </tr>
</thead> <tbody>
<tr> <td>PASSWORD</td>
<td>password###</td>
</tr>
</tbody>
</Table>
<p>where '#####' is any '0' padded digit ranging from 00001 up to the number of sandbox users. For Example:</p>
<Table striped bordered hover>
<thead> <tr>
<th>UserName</th>
<th>HUsername009</th> </tr>
</thead> <tbody>
<tr> <td>PASSWORD</td>
<td>pw78980!</td>
</tr>
</tbody>
</Table>
<p>Once the page has loaded, the authorization code will be appended to the URL following the "=".</p>
</Card.Body>*/}
</Card>

<br/>
<hr className="horizontal-rule"></hr>
<br/>
<Card className="card-background"> <Card.Body>
<h1 className="text-header">Getting an Access Token</h1>
<p> Now that you have your application credentials and authorization code, perform a post request against the Access Token endpoint using the URL, headers, and body content below.</p>
</Card.Body>
<Card.Body>
<h3 className="accesstoken-heading">URL</h3>
<codeblock className="accesstoken-code"> POST</codeblock>
<span>&nbsp;</span><span>&nbsp;</span>
<codeblock className="accesstoken-code"> {process.env.REACT_APP_EDUCATIONAL_MATERIAL_URL}iox/route/userInfo/getToken</codeblock>
<br/>
<br/>
<Table striped bordered hover>
<tbody>
<tr>
<td>Auth URL</td>
<td>{process.env.REACT_APP_EDUCATIONAL_MATERIAL_URL}Authorize</td>
</tr>
<tr>
<td>Access Token URL</td>
<td>{process.env.REACT_APP_EDUCATIONAL_MATERIAL_URL}iox/route/userInfo/getToken</td>
</tr>
<tr>
<td>Client ID</td>
<td>e8ht67-nh-7890</td>
</tr>
<tr>
<td>Client Secret</td>
<td>e8ht67-nh-7890</td>
</tr>
</tbody>
</Table>

<h4 className="accesstoken-heading">Headers</h4>
<Table striped bordered hover>
<tbody>
<tr>
<td>content-type</td>
<td>application/x-www-form-urlencoded</td>
</tr>
<tr>
<td>accept</td>
<td>application/json</td>
</tr>
<tr> <td>authorization</td>
<td>Basic base64</td>
</tr>
</tbody>
</Table>

<p>* Not shown in the examples, but we do support the content-type of application/json as well.</p>
<h4 className="accesstoken-heading">Body</h4>
<Table striped bordered hover>
<tbody>
<tr>
<td>code</td>
<td>'oauth code'</td>
</tr>
<tr>
<td>grant_type</td>
<td>"authorization_code"</td>
</tr>
<tr> <td>redirect_uri</td>
<td>'your_redirect_uri'</td>
</tr>
</tbody>
</Table>

<h4 className="accesstoken-heading">Sample Request</h4>

<div style={codestyle} >'curl --location --request POST https://iam.iox.dev.sddss.apps.conduent.com/am/oauth2/iox/access_token' \<br/>
                                    '--header Authorization: Basic base64(client_id:client_secret)'\<br/>
                                     '--header Content-Type: application/x-www-form-urlencoded' \<br/>
                                     '--data-urlencode code=your authorization code' \<br/>
                                     '--data-urlencode grant_type=authorization_code' \<br/>
                                     '--data-urlencode redirect_uri=redirect_uri'</div>

<h4 className="accesstoken-heading">Sample Response</h4>

<div style={codestyle} >"access_token":"dX6lk9mYmBFj2OnlUaMF" <br/>
                        "tokenId":"Lx0qfebWAXgS3H0Y-xu44QYd95o.*DIAAlNLABxvZ21TelJZdlk5dDJpeGlZRzIvSldFWnhtRGc9AAR0eXBlAANDVFMAAlMxAAIwMQ..*"<br/>
                        "scope":"openid profile" <br/>
                        "id_token":"eyJ0eXAiOiJKV1QiLCJraWQiOiJLeG41Z0Uxa1BNa3RLNUd4QjhEcng0bHM0UTg9IiwiYWxn" <br/>
                        "realm":"/iox" <br/>
                        "token_type":"Bearer" <br/>
                         "expires_in":"7775999"</div>
                                                  
</Card.Body>
</Card>

</div>
);
}