/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';

export default function DisplaySuccessMessages (props) {
  return <div className="w-100">{props.successMessages.length > 0 ? <ul className="alert alert-success custom-alert" role="alert" >
    {props.successMessages.map((message, index) => <li key ={index}>{message}</li>)
    }
  </ul> : null
  }</div>;
}

DisplaySuccessMessages.propTypes = {
  successMessages: PropTypes.array.isRequired
};
