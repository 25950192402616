import * as actionTypes from '../Actions/FeedBackActionTypes';

const axiosPayLoad = {
  payload: null
};
const reducer = (state = axiosPayLoad, action) => {
  switch (action.type) {
    case actionTypes.FEEDBACK_MONTHS:
      return { ...state, feedbackMonths: action.feedbackMonths };
    case actionTypes.FEEDBACK_REPORT:
      return { ...state, feedbackReport: action.feedbackReport };
    case actionTypes.RESET_FEEDBACK_DATA:
      return { ...state, [action.key]: '' };
    default: return state;
  }
};

export default reducer;
