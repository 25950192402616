import React,{useState,useEffect,useRef} from 'react';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ErrorMessages from '../../../SharedModule/MessageHandlers/ErrorMessages';
import SuccessMessage from '../../../SharedModule/MessageHandlers/SuccessMessage';
import Spinner from '../../../SharedModule/Spinner/Spinner';
import * as RegistrationConstants from './RegistrationConstants';
import * as AppConstants from '../../../SharedModule/Utils/AppConstants';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import InputLabel from "@material-ui/core/InputLabel";
import { Button } from 'react-bootstrap';
import {useDispatch,useSelector} from 'react-redux';
import {registerPayer,payerSearch,payerExistingSearch} from '../Store/Actions/PayerRegistrationActions';
import MenuItem from '@material-ui/core/MenuItem';
import { AppConfigDropdownActions } from '../../../SharedModule/Store/Actions/AppActions';
import InputMask from 'react-input-mask';
import AutosuggestInp from '../../../SharedModule/AutoSuggestion/AutoSuggestions';

const PayerRegistration=(props)=>{
    const [errorMessages, setErrorMessages] = useState([]);
    const [successMessages, setSuccessMessages] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [spinnerLoader, setSpinnerLoader] = useState(false);
    const [dialogType, setDialogType] = React.useState('');
    const registrationRef = useRef(null); 
    const [stateList, setStateList] = React.useState([]);
    const [termsCheckBox,setTermsCheckBox] = useState(false);
    const defaultVale = {
        "payerRegistrationId": "",
        "payerName": "",
        "emailId": "",
        "phoneNumber": "",
        "devName": "",
        "devPhoneNumber": "",
        "addressLine1": "",
        "addressLine2": "",
        "city": "",
        "state": "-1",
        "zip": "",
        "status": "",
        "statusReason": "",
        "redirectUrl": "",
        "privacyPolicyUrl": "",
        "termsOfServiceUrl": "",
        "addedAuditTimeStamp": "",
        "addedAuditUserID": "",
        "auditTimeStamp": "",
        "homePageUrl":"",
        "auditUserID": "",
        "clientId": "",
        "clientSecret": "",
        "registrationAccessToken": "",
        "permission":false
    }
    const [values, setValues] = useState(defaultVale);
    const [payerList,setPayerList]=useState([]);
    const [{
        payerIDError,
        payerNameError,
        privacyPolicyUrlError,
        termsOfServiceUrlError,
        redirectUrlError,
        addressLine1Error,
        cityError,
        stateError,
        zipError,
        zipInvalid,
        phoneNumberError,
        phoneInvalid,
        emailIdError,
        emailIdInvalidError,
        termsCheckBoxError,
        privacyPolicyIndError,
        privacyPolicyDetailIndError,
    }, setShowError] = useState(false);
    const dispatch=useDispatch();
    const validatePayerRegistrationDispatch=(data)=>dispatch(registerPayer(data));
    const payerSearchFun = searchCriteria => dispatch(payerExistingSearch(searchCriteria));
    const dropDownDispatch = (configKeyList) => dispatch(AppConfigDropdownActions(configKeyList));
    const dropdownData = useSelector(state => state.sharedState.appConfigDropdown);
    const validateRegistrationData = useSelector(state => state.payerRegState.payerRegistrationData);
    const payerSearchData = useSelector(state => state.payerRegState.existingPayerList);
 
    useEffect(() => {
        const configKeyList = ["STATE_LIST","APP_TYPE"];
        dropDownDispatch(configKeyList);
        payerSearchFun();
    }, []);

    useEffect(()=>{
        if (payerSearchData && payerSearchData.data) {
            setPayerList(payerSearchData);
        }
    },[payerSearchData])

    useEffect(() => {
        if (dropdownData && dropdownData.STATE_LIST && dropdownData.STATE_LIST.configDetail) {
            setStateList(dropdownData.STATE_LIST.configDetail);
        }
    }, [dropdownData]);

    useEffect(() => {
        /* if (validateRegistrationData) {
            setSpinnerLoader(false);
            if (validateRegistrationData.success) {                
                registrationRef.current.scrollIntoView({
                    behavior: "smooth"
                });
                cancelAction();
                setSuccessMessages(['Thank you for submitting your payer for review. You will receive an email with our decision after your payer has been reviewed.']);
                document.getElementById("root").scrollTop=0;

            } else {
                setErrorMessages(['Unable to process your payer registration now. Please try later.']);
                registrationRef.current.scrollIntoView({
                    behavior: "smooth"
                });
                document.getElementById("root").scrollTop=0;
            }
        } */
        if (validateRegistrationData) {
            setSpinnerLoader(false);
            let errorMessagesArray = [];
            errorMessagesArray = AppConstants.handleAPIErrors(validateRegistrationData, errorMessagesArray, RegistrationConstants.REGISTRATION_TECHNICAL_ERROR);
            if (errorMessagesArray && errorMessagesArray.length > 0 ) {
                setErrorMessages(errorMessagesArray);
                /* document.getElementById("error-scroll").scrollIntoView({
                    behavior: "smooth",
                    block: "start"
                }); */
                registrationRef.current.scrollIntoView({
                    behavior: "smooth"
                });
                document.getElementById("root").scrollTop=0;
            }else{
                registrationRef.current.scrollIntoView({
                    behavior: "smooth"
                });
                cancelAction();
                if(validateRegistrationData.success)
                setSuccessMessages(['Thank you for submitting your payer for review. You will receive an email with our decision after your payer has been reviewed.']); 
                else
                setErrorMessages([validateRegistrationData?.message]);

                document.getElementById("root").scrollTop=0;
            }
        }
    }, [validateRegistrationData]);

    // Method to validate Registration Form
    const validateRegisterForm = () => {
        let errorMessagesArray = [];
        setShowError({
            payerIDError: values.payerRegistrationId ? false : (() => { errorMessagesArray.push(RegistrationConstants.PAYER_ID_REQUIRED); return true; })(),
            payerNameError: values.payerName ? false : (() => { errorMessagesArray.push(RegistrationConstants.PAYER_NAME_REQUIRED); return true; })(),
            privacyPolicyUrlError: values.privacyPolicyUrl ? false : (() => { errorMessagesArray.push(RegistrationConstants.PRIVACY_URL_REQUIRED); return true; })(),
            termsOfServiceUrlError: values.termsOfServiceUrl ? false : (() => { errorMessagesArray.push(RegistrationConstants.TERMS_URL_REQUIRED); return true; })(),
            redirectUrlError: values.redirectUrl ? false : (() => { errorMessagesArray.push(RegistrationConstants.REDIRECT_URL_REQUIRED); return true; })(),
            addressLine1Error: values.addressLine1 || values.addressLine2 ? false : (() => { errorMessagesArray.push(RegistrationConstants.ADDRESS_REQUIRED); return true; })(),
            cityError: !values.city ? (() => { errorMessagesArray.push(RegistrationConstants.CITY_REQUIRED); return true; })() : false,
            stateError: values.state === '-1' ? (() => { errorMessagesArray.push(RegistrationConstants.STATE_REQUIRED); return true; })() : false,
            zipError: (!values.zip) ? (() => { errorMessagesArray.push(RegistrationConstants.POSTAL_REQUIRED); return true; })() : false,
            /* zipInvalid:(()=>{
                if (values.zip && (!/^[0-9]+$/.test(values.zip))) {
                    errorMessagesArray.push(RegistrationConstants.ZIP_INVALID);
                    return true;
                }
                if (values.zip && (/^[0-9]+$/.test(values.zip))) {
                    let zipStriing=values.zip;
                    if(zipStriing.length!==5){
                        errorMessagesArray.push(RegistrationConstants.ZIP_INVALID);
                        return true;
                    }            
                }
            })(), */
            phoneNumberError: values.phoneNumber ? false : (() => { errorMessagesArray.push(RegistrationConstants.CONTACT_NUMBER_REQUIRED); return true; })(),
            phoneInvalid:(()=>{
                var unmaskedPhone = values.phoneNumber?.replace(AppConstants.PHONE_NUMBER_UNMASK_REGEX, '');
                if ( unmaskedPhone && unmaskedPhone?.length < 10) {
                    errorMessagesArray.push( RegistrationConstants.PHONE_NUMBER_FORMAT);
                    return true;
                }
                /* let phoneStringAll=values.phoneNumber+values.phoneNumber1+values.phoneNumber2;
                if(phoneStringAll.length > 0 && phoneStringAll.length < 10){
                    errorMessagesArray.push(RegistrationConstants.PHONE_INVALID);
                    return true
                }
                if (values.phoneNumber && values.phoneNumber1 && values.phoneNumber2 && (!/^[0-9]+$/.test(values.phoneNumber) || !/^[0-9]+$/.test(values.phoneNumber1) || !/^[0-9]+$/.test(values.phoneNumber2))) {
                    errorMessagesArray.push(RegistrationConstants.PHONE_INVALID);
                    return true
                }
                if (values.phoneNumber && values.phoneNumber1 && values.phoneNumber2 && (/^[0-9]+$/.test(values.phoneNumber) || /^[0-9]+$/.test(values.phoneNumber1) || /^[0-9]+$/.test(values.phoneNumber2))) {
                    let phoneString=values.phoneNumber+values.phoneNumber1+values.phoneNumber2;
                    if(phoneString.length!==10){
                        errorMessagesArray.push(RegistrationConstants.PHONE_INVALID);
                        return true
                    }            
                } */
            })(), 
            emailIdError: values.emailId ? false : (() => { errorMessagesArray.push(RegistrationConstants.EMAIL_ID_REQUIRED); return true; })(),
            emailIdInvalidError: !values.emailId ? false : (() => {
                if (!AppConstants.EMAIL_ADDRESS_REGEX.test(values.emailId)) {
                    errorMessagesArray.push(RegistrationConstants.EMAIL_INVALID);
                    return true;
                }

            }
            )(),
            termsCheckBoxError: termsCheckBox ? false : (() => { errorMessagesArray.push(RegistrationConstants.TERMS_CONDITION_REQUIRED); return true; })(),
        });   
     

        setErrorMessages(errorMessagesArray);

        if (errorMessagesArray && errorMessagesArray.length === 0) {
            setDialogType("submit");
            setDialogOpen(true);
            return true;
        } else {
            registrationRef.current.scrollIntoView({
                behavior: "smooth"
            });
            document.getElementById("root").scrollTop=0;
            return false;
        }
    };
    const cancelDialog = () =>{
        setDialogOpen(true);
        setDialogType('cancel')
    }

    const cancelAction = () =>{
        setDialogOpen(false)
        clearMessages();
        setValues(defaultVale)
    }

    const redirectToHomePage = () =>{
        props.history.push({
            pathname: "/",
          });
    }

    // Method to submit Registration Form
    const submitRegistrationForm = () => {
        clearMessages();
        setDialogOpen(false);       
        setSpinnerLoader(true);
        validatePayerRegistrationDispatch({...values,phoneNumber:values.phoneNumber?.replace(AppConstants.PHONE_NUMBER_UNMASK_REGEX, '')});
    };

    const clearMessages = () => {
        setErrorMessages([]);
        setSuccessMessages([]);
        setShowError(false);
    } 

    const handleChanges = name => event => {   
        if (name === 'phoneNumber') {
            setValues({ ...values, [name]: event.target.value.replace(AppConstants.PHONE_NUMBER_ONLY_REGEX, '') });
            /* var unmaskedPhone = event.target.value.replace(AppConstants.PHONE_NUMBER_UNMASK_REGEX, '');
            setPhoneNumber(unmaskedPhone); */
        } else if (name === 'memberID' || name === 'zip' || name === 'memberSSN') {
            setValues({ ...values, [name]: event.target.value.replace(AppConstants.NUMBER_ONLY_REGEX, '') });
            event.target.value = event.target.value.replace(AppConstants.NUMBER_ONLY_REGEX, '');
        }else{
            setValues({ ...values, [name]: event.target.value });
        }    
    }

    // Method to set Terms Check Box
    const handleChangeTermCheckBox = () => {
        setTermsCheckBox(!termsCheckBox);
    };

    const setPayerName=(val,index)=>{
        setValues({ ...values, payerName: val });
    }
    const setPayerId=(val,index)=>{
        // setValues([...values, {...values[index],payerReferenceId:val }]);
    }
    const checkPayerStatus=(val,index)=>{
        // setValues([...values, {...values[index],payerReferenceId:val }]);
    }

    return (
        <div className="content-wrapper block-elm mt-3" ref={registrationRef}>
        {/* <div className="block-elm mt-2">
            <ul className="alert alert-success custom-alert mb-1" role="alert">
                <li className="ml-2">Long Description is required.</li>
            </ul>
        </div> */}
        {spinnerLoader ? <Spinner /> : null}

        <Dialog
            open={dialogOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="custom-alert-box"
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    { dialogType === 'cancel' ? 'Are you sure you want to Cancel?' : ' Are you sure you want to Submit?'}

                    
                </DialogContentText>
            </DialogContent>
            <DialogActions>
               
                    {dialogType === 'submit' && (
                        <Button onClick={() => { submitRegistrationForm() }} color="primary" className="btn btn-success">
                            Ok
                        </Button>
                    )
                    }

                    {dialogType === 'cancel' && (
                        <Button onClick={() => { redirectToHomePage() }} color="primary" className="btn btn-success">
                            Ok
                        </Button>
                    )
                    }
                <Button onClick={() => { setDialogOpen(false); }} color="primary" autoFocus>
                    Cancel
        </Button>
            </DialogActions>
        </Dialog>
        
            {errorMessages && errorMessages.length > 0 ?
            <div className="block-elm mt-3"><ErrorMessages errorMessages={errorMessages} /></div> : null}
            {errorMessages && successMessages && errorMessages.length === 0 && successMessages.length > 0 ?
            <div className="block-elm mt-3"><SuccessMessage successMessages={successMessages} /></div> : null}
        
        <div className="page-header" >
            <h1 className="page-heading float-left">
                Payer Registration
            </h1>
            <div className="float-right"><a href="/Help2" target="_blank" className="dib fw-600 py-2 mt-1"> Help </a></div> 
            <div className="clearfix" />
        </div>
        <div className="tab-holder">
        <div className="tab-body container-space">
            {/* <!-- Payer Details --> */}
            <div className="tab-header">
                <h2 className="tab-heading float-left">
                    Payer Details  </h2>
                <div className="clearfix" />
            </div>
            <div className="tab-body-bordered mb-2">
                <div className="form-wrapper-iox">
                        <div className="mui-custom-form with-select input-md">
                            <TextField
                                label="Payer ID"
                                value={values.payerRegistrationId}
                                inputProps={{ maxLength: 15 }}
                                InputLabelProps={{
                                    shrink: true,
                                    for: 'Payer-ID-lbl'
                                }}
                                InputProps={{
                                    id: 'Payer-ID-lbl'
                                }}
                                onChange={handleChanges('payerRegistrationId')}
                                required
                                error={payerIDError}
                                helperText={payerIDError ? RegistrationConstants.PAYER_ID_REQUIRED : null}
                            />
                        </div>
                        <div className="mui-custom-form with-select input-md">
                            {/* <TextField
                                label="Payer Name"
                                value={values.payerName}
                                onChange={handleChanges('payerName')}
                                inputProps={{ maxLength: 35 }}
                                InputLabelProps={{
                                    shrink: true,
                                    for: 'Payer-Name-lbl'
                                }}
                                InputProps={{
                                    id: 'Payer-Name-lbl'
                                }}
                            /> */}
                            {/* <AutosuggestInp inpValue={values?.payerName} change={setPayerName} error={null}
                            helperText={null} setPayerId={setPayerId} checkPayerStatus={checkPayerStatus} payerList={payerList} index={0} required={false}/> */}
                            <AutosuggestInp inpValue={values?.payerName} change={setPayerName} error={payerNameError}
                            helperText={payerNameError ? RegistrationConstants.PAYER_NAME_REQUIRED : null} setPayerId={setPayerId} checkPayerStatus={checkPayerStatus} payerList={payerList} index={0} required={true}/>
                        </div>
                        <div className="mui-custom-form with-select input-md">
                            <TextField
                                label="Email ID"
                                value={values.emailId}
                                inputProps={{ maxLength: 35 }}
                                InputLabelProps={{
                                    shrink: true,
                                    for: 'Payer-Email-ID'
                                }}
                                InputProps={{
                                    id: 'Payer-Email-ID'
                                }}
                                required
                                onChange={handleChanges('emailId')}
                                error={emailIdError ? RegistrationConstants.EMAIL_ID_REQUIRED : emailIdInvalidError ? RegistrationConstants.EMAIL_INVALID : null}
                                helperText={emailIdError ? RegistrationConstants.EMAIL_ID_REQUIRED : emailIdInvalidError ? RegistrationConstants.EMAIL_INVALID : null}
                            />
                        </div>
                        <div className="mui-custom-form with-select input-md">
                            {/* <TextField
                                label="Phone Number"
                                value={values.phoneNumber}
                                inputProps={{ maxLength: 35 }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={handleChanges('phoneNumber')}
                                required
                                error={phoneNumberError || phoneInvalid}
                                helperText={phoneNumberError ? RegistrationConstants.CONTACT_NUMBER_REQUIRED : phoneInvalid ? RegistrationConstants.PHONE_NUMBER_FORMAT : null}
                            /> */}
                            <InputMask
                                mask="(999) 999-9999"
                                disabled={false}
                                maskChar=" "
                                value={values.phoneNumber}
                                onChange={handleChanges('phoneNumber')}
                                inputProps={{ maxLength: 10 }}
                            >
                                {() => <TextField
                                    required
                                    id="standard-phone"
                                    label="Phone Number"
                                    InputLabelProps={{
                                        shrink: true,
                                        for: 'Phone-Number-lbl'
                                    }}
                                    InputProps={{
                                        id: 'Phone-Number-lbl'
                                    }}
                                    error={phoneNumberError || phoneInvalid}
                                    helperText={phoneNumberError ? RegistrationConstants.CONTACT_NUMBER_REQUIRED : phoneInvalid ? RegistrationConstants.PHONE_NUMBER_FORMAT : null}
                                />}
                            </InputMask>
                        </div>
                        <div className="mui-custom-form with-select input-md">
                            <TextField
                                label="Developer Name"
                                value={values.devName}
                                inputProps={{ maxLength: 35 }}
                                InputLabelProps={{
                                    shrink: true,
                                    for: 'Dev-Name-lbl'
                                }}
                                InputProps={{
                                    id: 'Dev-Name-lbl'
                                }}
                                onChange={handleChanges('devName')}
                            />
                        </div>
                        <div className="mui-custom-form with-select input-md">
                            <TextField
                                label="Developer Contact"
                                value={values.devPhoneNumber}
                                inputProps={{ maxLength: 35 }}
                                InputLabelProps={{
                                    shrink: true,
                                    for: 'Dev-Contact-lbl'
                                }}
                                InputProps={{
                                    id: 'Dev-Contact-lbl'
                                }}
                                onChange={handleChanges('devPhoneNumber')}
                            />
                        </div>
                        <div className="mui-custom-form with-select input-md">
                            <TextField
                                label="Homepage URL"
                                value={values.homePageUrl}
                                inputProps={{ maxLength: 50 }}
                                InputLabelProps={{
                                    shrink: true,
                                    for: 'Homepage-URL-lbl'
                                }}
                                InputProps={{
                                    id: 'Homepage-URL-lbl'
                                }}
                                onChange={handleChanges('homePageUrl')}
                            />
                        </div>
                        
                        <div className="mui-custom-form with-select input-md">
                            <TextField
                                label="Privacy Policy URL"
                                value={values.privacyPolicyUrl}
                                inputProps={{  maxLength: 50 }}
                                InputLabelProps={{
                                    shrink: true,
                                    for: 'pp-URL-lbl'
                                }}
                                InputProps={{
                                    id: 'pp-URL-lbl'
                                }}
                                onChange={handleChanges('privacyPolicyUrl')}
                                required
                                error={privacyPolicyUrlError}
                                helperText={privacyPolicyUrlError ? RegistrationConstants.PRIVACY_URL_REQUIRED : null}
                            />
                        </div>

                        <div className="mui-custom-form with-select input-md">
                            <TextField
                                label="Terms of Service URL"
                                value={values.termsOfServiceUrl}
                                inputProps={{maxLength: 50 }}
                                InputLabelProps={{
                                    shrink: true,
                                    for: 'TOS-URL-lbl'
                                }}
                                InputProps={{
                                    id: 'TOS-URL-lbl'
                                }}
                                onChange={handleChanges('termsOfServiceUrl')}
                                required
                                error={termsOfServiceUrlError}
                                helperText={termsOfServiceUrlError ? RegistrationConstants.TERMS_URL_REQUIRED : null}
                            />
                        </div>
                        <div className="mui-custom-form with-select input-md">
                            <TextField
                                label="Redirect URL"
                                value={values.redirectUrl}
                                inputProps={{ maxLength: 50 }}
                                InputLabelProps={{
                                    shrink: true,
                                    for: 'Redirect-URL-lbl'
                                }}
                                InputProps={{
                                    id: 'Redirect-URL-lbl'
                                }}
                                onChange={handleChanges('redirectUrl')}
                                required
                                error={redirectUrlError}
                                helperText={redirectUrlError ? RegistrationConstants.REDIRECT_URL_REQUIRED : null}
                            />
                        </div>

                </div>
            </div>
            {/* <!-- ** --> */}

            {/* <!-- Address Details --> */}
            <div className="tab-header">
                <h2 className="tab-heading float-left">
                    Address Details  </h2>
                <div className="clearfix" />
            </div>
            <div className="tab-body-bordered mb-2">
                <div className="form-wrapper-iox">
                    <div className="mui-custom-form with-select input-md field-md">
                        <TextField
                            label="Address"
                            value={values.addressLine1}
                            inputProps={{ maxLength: 50 }}
                            InputLabelProps={{
                                shrink: true,
                                for: 'Address-lbl'
                            }}
                            InputProps={{
                                id: 'Address-lbl'
                            }}
                            onChange={handleChanges('addressLine1')}
                            required
                            placeholder="Line 1"
                            error={addressLine1Error}
                            helperText={addressLine1Error ? RegistrationConstants.ADDRESS_REQUIRED : null}
                        />
                    </div>
                    <div className="mui-custom-form with-select input-md field-md">
                        <TextField
                            label="Address-2"
                            value={values.addressLine2}
                            inputProps={{ maxLength: 50 }}
                            InputLabelProps={{
                                shrink: true,
                                for: 'Address2-lbl',
                                className: 'hidden-lbl'
                            }}
                            InputProps={{
                                id: 'Address2-lbl'
                            }}
                            onChange={handleChanges('addressLine2')}
                            placeholder="Line 2"
                            error={addressLine1Error}
                            helperText={addressLine1Error ? RegistrationConstants.ADDRESS_REQUIRED : null}
                        />
                    </div>
                    <div className="mui-custom-form with-select input-md">
                        <TextField
                            label="City"
                            value={values.city}
                            inputProps={{ maxLength: 30 }}
                            InputLabelProps={{
                                shrink: true,
                                for: 'City-label'
                            }}
                            InputProps={{
                                id: 'City-label'
                            }}
                            onChange={handleChanges('city')}
                            required
                            error={cityError}
                            helperText={cityError ? RegistrationConstants.CITY_REQUIRED : null}
                        />
                    </div>
                    <div className="mui-custom-form with-select input-md">
                        <TextField
                            select
                            required={true}
                            label="State"
                            value={values.state}
                            onChange={handleChanges('state')}
                            inputProps={{ id: 'State', maxLength: 2 }}
                            InputLabelProps={{
                                shrink: true,
                                for: 'State'
                            }}
                            error={stateError}
                            helperText={stateError ? RegistrationConstants.STATE_REQUIRED : null}
                        >
                            <MenuItem selected key="-1" value="-1">
                                Please Select One
                                </MenuItem>
                            {stateList ? stateList.map(option => (
                                <MenuItem selected key={option.key} value={option.key}>
                                    {option.value}
                                </MenuItem>
                            )) : null}
                        </TextField>
                    </div>
                    <div className="mui-custom-form with-select input-md">
                        <TextField
                            label="Postal Code"
                            value={values.zip}
                            inputProps={{ maxLength: 5 }}
                            InputLabelProps={{
                                shrink: true,
                                for: 'Postal-Code-lbl'
                            }}
                            InputProps={{
                                id: 'Postal-Code-lbl'
                            }}
                            onChange={handleChanges('zip')}
                            required
                            error={zipError}
                            helperText={zipError ? RegistrationConstants.POSTAL_REQUIRED : null}
                        />
                    </div>
                </div>
            </div>

            <div className="block-elm mt-2">
                <div className="sub-radio float-left mt-0">
                    <FormControlLabel for="termsConditionscheckbox"
                        className="sub-radio hide-spn"
                        control={
                            <Checkbox
                                id="termsConditionscheckbox"
                                color="primary"
                                error={termsCheckBoxError}
                                onChange={handleChangeTermCheckBox}
                                checked={termsCheckBox}
                                value={termsCheckBox}
                            />
                        }
                        label="TandC"

                    />
                </div>
                <div for="termsConditions" className="radio-label pt-1 float-left">
                    I agree to the <a href={process.env.REACT_APP_TERMS_CONDITION_URL} target="_blank">Terms and Conditions</a> and <a href={process.env.REACT_APP_PRIVACY_POLICY_URL} target="_blank">Privacy policy</a>
                </div>
                {termsCheckBoxError ? <p className="attest-error mx-3">{termsCheckBoxError ? RegistrationConstants.TERMS_CONDITION_REQUIRED : null}</p> : null}

            </div>
            <div className="tab-footer text-center mt-3">
                <button className="btn btn-ic btn-save" disabled={!termsCheckBox} onClick={validateRegisterForm} > Submit </button>
                <button className="btn btn-cancel ml-3" onClick={cancelDialog} > Cancel </button>
            </div>
        </div>
        </div>
        </div>
    )
}
export default PayerRegistration;