import * as actionTypes from '../Actions/LoginActionTypes';

const axiosPayLoad = {
  payload: null,
  adminUserInfo:null
};
const reducer = (state = axiosPayLoad, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_USER:
      return { ...state, loginUser: action.loginUser };
    case actionTypes.RESET_LOGIN_DATA:
      return { ...state, [action.key]: '' };
    case actionTypes.ADMIN_USER_INFO:
      return { ...state, adminUserInfo: action.payload };
    default: return state;
  }
};

export default reducer;
