import React,{useState,useEffect} from 'react';
import TableComponent from '../../../SharedModule/Table/Table';
import $ from 'jquery';
import datepickerFactory from 'jquery-datepicker';
datepickerFactory($);
require('jquery-datepicker');
require('bootstrap-datepicker');
require('bootstrap-datepicker/dist/css/bootstrap-datepicker.css');
window.jquery=$;
window.jQuery=$;


const QuarterView=props=>{
  const {disabled,value,setQuartelyFilter}=props;
  const [quarterinputVal,setQuarterinputVal]=useState(null);
  let currRef=this;

  useEffect(()=>{
      setQuarterinputVal(value);
      document.getElementById("example1").value=value;
  },[value])

    useEffect(()=>{
         $.fn.datepicker.dates['qtrs'] = {
            days: ["Sunday", "Moonday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
            daysShort: ["Sun", "Moon", "Tue", "Wed", "Thu", "Fri", "Sat"],
            daysMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
            months: ["January-March", "April-June", "July-September", "October-December", "", "", "", "", "", "", "", ""],
            monthsShort: ["Jan&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Feb&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mar", "Apr&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;May&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Jun", "Jul&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Aug&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sep", "Oct&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Nov&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dec", "", "", "", "", "", "", "", ""],
            today: "Today",
            clear: "Clear",
            format: "mm/dd/yyyy",
            titleFormat: "MM yyyy",
            weekStart: 0
          }; 
          
          $('#example1').datepicker({
            format: "MM yyyy",
            minViewMode: 1,
            autoclose: true,
            language: "qtrs",
            forceParse: false
          }).on("show", function(event) {
          
            $(".month").each(function(index, element) {
              if (index > 3) $(element).hide();
            });
            
          }).on('changeDate', function(e) {
                   setValue(e.target.value);
             });
        
    },[])

    const setValue=(val)=>{
        setQuartelyFilter(val);
    }
    return (
        <>
        <div className="input-group date Qdatepicker"  >
        <label for="example1" className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink Mui-disabled Mui-disabled " id="quarterlyID-label">
                             Quarterly </label>
  <input type="text" id="example1" className="form-control Qdateinput" value={quarterinputVal} disabled={disabled} readOnly placeholder="Duration"/>
  <span className="input-group-addon">
            <i className="glyphicon glyphicon-calendar"></i>
        </span>
</div>
        </>
    )
}
export default QuarterView;