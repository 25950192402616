import React,{useState,useEffect} from 'react';
import { Chart, Series, CommonSeriesSettings, Label, Format, Legend, Export, ValueAxis, Size,TickInterval,Margin } from 'devextreme-react/chart';
import {getAppstatusCount} from './utility';
import PropTypes from 'prop-types';
GraphView.propTypes = {
  apiType: PropTypes.string, 
};

export default function GraphView(props) {

  const { reportType, reportData, scrollBottomOfPage, graphType, trendReportData, showTrendGraph, trenGraphTitle } = props;
  const [graphData, setGraphData] = useState([]);
  const [patientGraphData, setPatientGraphData] = useState([]);
  const [providerGraphData, setProviderGraphData] = useState([]);
  const [drugListGraphData, setDrugListGraphData] = useState([]);
  const { apiType } = props;

  const [trendData, setTrendData] = useState([]);
  let reportNameList = {
    App: "Third Party Apps Registration",
    Member: "Member Registration",
    API: "Third Party API Access",
    MemberData: "Member API Access",
    AppAPI: "App API Access",
    ProviderData: "Provider Directory API",
  };

  useEffect(() => {
    if (reportData)
      setGraphData(getGraphData(reportData.data, ""));
      setPatientGraphData(getGraphData(reportData.data, "patient"));
      setProviderGraphData(getGraphData(reportData.data, "provider"));
      setDrugListGraphData(getGraphData(reportData.data, "drug"));

  }, [reportData]);

  useEffect(() => {
    if (trendReportData)
      setTrendData(getTrendData(trendReportData.data));
  }, [trendReportData]);

  const getGraphData = (data, apiType) => {
    if (reportType === "App") {
      return [{ label: "Submitted", count: Number(data["submitted"]) }, { label: "Approved", count: Number(data["approved"]) }, { label: "Rejected", count: Number(data["rejected"]) }, { label: "Revoked", count: Number(data["revoked"]) }];
    } else if (reportType === "Member") {
      return [{label: "Registrations",count : Number(data["registrationCount"])},{label: "0 to <18",count : Number(data["age0toBelow18Count"])},{label: "18 to <30",count : Number(data["age18toBelow30Count"])},{label: "30 to <50",count : Number(data["age30toBelow50Count"])},{label: "50 to <70",count : Number(data["age50toBelow70Count"])},{label: "Above >70",count : Number(data["ageAbove70Count"])}];
    } else if (reportType === "MemberData") {
      return [{ label: "Unique member access data once", count: Number(data["accessDistinct"]) }, { label: "Unique member access data more than once", count: Number(data["accessMTOnce"]) }];
    } else if (reportType === "API") {
      return [{ label: "Patient Access API", count: Number(data["patientCount"]) }, { label: "Provider Directory API", count: Number(data["providerCount"]) }, { label: "Covered Drug List API", count: Number(data["drugCount"]) }];
    } else if (reportType === "ProviderData"){
        return [{label:"Practitioner",count:Number(data["practitionerCount"])},{label:"Organization",count:Number(data["organizationCount"])}];
	    }else if (reportType === "AppAPI" && apiType === "patient") {
      let patientArray = [];
      for (var i = 0; i < data.patientAppsInfo.length; i++) {
        patientArray.push({label:"appName" , count:Number(10)});
      }
      return patientArray;
    }  else if (reportType === "AppAPI" && apiType === "provider") {
      let providerArray = [];
      for (var i = 0; i < data.providerAppsInfo.length; i++) {
        providerArray.push([data.providerAppsInfo[i].appName, parseInt(data.providerAppsInfo[i].count)]);
      }
      return providerArray;
    } else if (reportType === "AppAPI" && apiType === "drug") {
        let drugListArray = [];
        for (var i = 0; i < data.drugListAppsInfo.length; i++) {
            drugListArray.push([data.drugListAppsInfo[i].appName, parseInt(data.drugListAppsInfo[i].count)]);
        }
    }
      else {
      return [];
    }
  };

    const getTrendData = (data) => {
      let tempArr=[];
      data.map(e=>{
        tempArr.push({label:e.xvalue,count:Number(e.yvalue)});
      })
      return tempArr;
    };

  return (
    <>
      {showTrendGraph ?
        <>
          <div>
            {reportType != "AppAPI" ?(
              <Chart id="chart" dataSource={graphData}>
                <Size
                  height={600}
                  width={800}
                />
                <Margin
                  top={60}
                  bottom={60}
                  left={30}
                  right={30}
                />
                <CommonSeriesSettings

                  barPadding={0.5}
                />
                <ValueAxis
                  title={`Count of ${reportNameList[reportType]}`}
                  allowDecimals={false}
                />
                <Label visible={true}>
                  <Format type="fixedPoint" precision={1} />
                </Label>
                <Series
                  valueField="count"
                  argumentField="label"
                  name=""
                  type="bar"
                  series={false}
                  color="rgb(0,71,186)" />
                <Legend visible={false}></Legend>
              </Chart>
            ) :reportType === "AppAPI" && apiType === "patient" ? (
              <Chart id="chart" dataSource={patientGraphData}>
                <Size
                  height={60}
                  width={800}
                />
                <Margin
                  top={60}
                  bottom={60}
                  left={30}
                  right={30}
                />
                <CommonSeriesSettings

                  barPadding={0.5}
                />
                <ValueAxis
                  title={`Count of Patient Access API`}
                  allowDecimals={false}
                />
                <Label visible={true}>
                  <Format type="fixedPoint" precision={1} />
                </Label>
                <Series
                  valueField="count"
                  argumentField="label"
                  name=""
                  type="bar"
                  series={false}
                  color="rgb(0,71,186)" />
                <Legend visible={true}></Legend>
              </Chart>
            ) : reportType === "AppAPI" && apiType === "provider" ? (
              <Chart id="chart" dataSource={providerGraphData}>
                <Size
                  height={60}
                  width={800}
                />
                <Margin
                  top={60}
                  bottom={60}
                  left={30}
                  right={30}
                />
                <CommonSeriesSettings

                  barPadding={0.5}
                />
                <ValueAxis
                  title={`Count of Provider API Access`}
                  allowDecimals={false}
                />
                <Label visible={true}>
                  <Format type="fixedPoint" precision={1} />
                </Label>
                <Series
                  valueField="count"
                  argumentField="label"
                  name=""
                  type="bar"
                  series={false}
                  color="rgb(0,71,186)" />
                <Legend visible={true}></Legend>
              </Chart>
            ) : reportType === "AppAPI" && apiType === "drug"? (
              <Chart id="chart" dataSource={drugListGraphData}>
                <Size
                  height={60}
                  width={800}
                />
                <Margin
                  top={60}
                  bottom={60}
                  left={30}
                  right={30}
                />
                <CommonSeriesSettings

                  barPadding={0.5}
                />
                <ValueAxis
                  title={`Count of Drug List API Access`}
                  allowDecimals={false}
                />
                <Label visible={true}>
                  <Format type="fixedPoint" precision={1} />
                </Label>
                <Series
                  valueField="count"
                  argumentField="label"
                  name=""
                  type="bar"
                  series={false}
                  color="rgb(100,71,186)" />
                <Legend visible={true}></Legend>
              </Chart>

            ) : null }

          </div>

          <div>
            <div className="tab-holder">
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>




              <div className="tab-header mb-2">
                <h2 className="tab-heading float-left">
                  {" "}
                  {`Trend Graph for ${trenGraphTitle}`}{" "}
                </h2>
                <div className="clearfix" />
              </div>
            </div>
            <Chart id="chart1" dataSource={trendData}>
              <Size
                height={600}
                width={800}
              />
              <Margin
                top={10}
                bottom={60}
                left={30}
                right={30}
              />
              <CommonSeriesSettings

                barPadding={0.5}
                allowDecimals={false}
              />
              <ValueAxis
                allowDecimals={false}
              />
              <Series
                valueField="count"
                argumentField="label"
                name={`Count of ${reportNameList[reportType]}`}
                type="line"
                series={false}
                color="rgb(0,71,186)" />
              <Legend visible={false}></Legend>
            </Chart>
          </div>
        
        </> :reportType != "AppAPI"?<Chart id="chart" dataSource={graphData}>
          <Size
            height={400}
            width={800}
          />
          <Margin
            top={20}
            left={30}
            right={30}
          />
          <CommonSeriesSettings

            barPadding={0.5}
          />
          <ValueAxis
            title={`Count of ${reportNameList[reportType]}`}
            allowDecimals={false}
          />
          <Label visible={true}>
            <Format type="fixedPoint" precision={1} />
          </Label>
          <Series
            valueField="count"
            argumentField="label"
            name=""
            type="bar"
            series={false}
            color="rgb(0,71,186)" />
          <Legend visible={false}></Legend>
        </Chart>:reportType === "AppAPI" ?<Chart id="chart" dataSource={patientGraphData}>
          <Size
            height={400}
            width={800}
          />
          <Margin
            top={20}
            left={30}
            right={30}
          />
          <CommonSeriesSettings

            barPadding={0.5}
          />
        <Series
          valueField="count"
          argumentField="label"
          name={`Count of ${reportNameList[reportType]}`}
          type="line"
          series={false}
          color="rgb(0,71,186)" />
          <Legend visible={false}></Legend>
        </Chart>:<Chart id="chart" dataSource={providerGraphData}>
          <Size
            height={400}
            width={800}
          />
          <Margin
            top={20}
            left={30}
            right={30}
          />
          <CommonSeriesSettings

            barPadding={0.5}
          />
          <ValueAxis
            title={`Count of ${reportNameList[reportType]}`}
            allowDecimals={false}
          />
          <Label visible={true}>
            <Format type="fixedPoint" precision={1} />
          </Label>
          <Series
            valueField="count"
            argumentField="label"
            name=""
            type="bar"
            series={false}
            color="rgb(0,71,186)" />
          <Legend visible={false}></Legend>
        </Chart>/* :reportType === "AppAPI"?<Chart id="chart" dataSource={drugListGraphData}>
          <Size
            height={400}
            width={800}
          />
          <Margin
            top={20}
            left={30}
            right={30}
          />
          <CommonSeriesSettings

            barPadding={0.5}
          />
          <ValueAxis
            title={`line 1Count of ${reportNameList[reportType]}`}
            allowDecimals={false}
          />
          <Label visible={true}>
            <Format type="fixedPoint" precision={1} />
          </Label>
          <Series
            valueField="count"
            argumentField="label"
            name=""
            type="bar"
            series={false}
            color="rgb(0,71,186)" />
          <Legend visible={false}></Legend>
        </Chart> *///:[]
}
    </>

  );
}
