/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import { Button } from 'react-bootstrap';
import MenuItem from '@material-ui/core/MenuItem';
import * as RegistrationConstants from './RegistrationConstants';
import ErrorMessages from '../../../SharedModule/MessageHandlers/ErrorMessages';
import SuccessMessage from '../../../SharedModule/MessageHandlers/SuccessMessage';
import Spinner from '../../../SharedModule/Spinner/Spinner';
import { AppConfigDropdownActions } from '../../../SharedModule/Store/Actions/AppActions';
import { appStatusUpdateAction, downloadFileAction,downloadFileResetAction } from '../Store/Actions/RegistrationActions';
import moment from 'moment';
import Radio from "@material-ui/core/Radio";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import TableComponent from '../../../SharedModule/Table/Table';
import IdleSessionLogout from '../../../SharedModule/SessionManagement/IdleSessionLogout';

const headCells = [
    {
        id: 'fileName', numeric: false, disablePadding: true, label: 'File Name', enableHyperLink: true, fontSize: 12, width: '30%'
    },
    {
        id: 'description', numeric: false, disablePadding: false, label: 'Description', enableHyperLink: false, fontSize: 12
    }
];
function rand() {
    return Math.round(Math.random() * 20) - 10;
}
function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}
const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 600,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        borderRadius: '10px'
    },
}));

export default function AppApproveReject(props) {   
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };  
    const dispatch = useDispatch();
    const registrationRef = useRef(null);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogType, setDialogType] = React.useState('');
    const disabled=true;
    const anchorRef = React.useRef();
    const [errorMessages, setErrorMessages] = React.useState([]);
    const [successMessages, setSuccessMessages] = React.useState([]);
    const [stateList, setStateList] = React.useState([]);
    const [appTypeList, setAppTypeList] = React.useState([]);
    const [spinnerLoader, setSpinnerLoader] = React.useState(false);
    const [showAppButton, setShowAppButton] = React.useState(true);    
    const [selectedFile, setSelectedFile] = React.useState(null)
    const [{
        rejectErr,
        revokeErr
    }, setShowError] = React.useState(false);       
    const defaultVale = {
        "appName": "",
        "appType": "-1",
        "devFirstName": "",
        "devLastName": "",
        "orgName": "",
        "homePageUrl": "",
        "privacyPolicyUrl": "",
        "termsOfServiceUrl": "",
        "redirectUrl": "",
        "addressLine1": "",
        "addressLine2": "",
        "city": "",
        "state": "-1",
        "zip": "",
        "zip1": "",
        "phoneNumber": "",
        "phoneNumber1": "",
        "phoneNumber2": "",
        "emailId": "",
        "privacyPolicyInd": '',
        "privacyPolicyDetailInd": "",
        "bestPracticesInd": ""
    }
    const [values, setValues] = React.useState(defaultVale);
    const [appValues,setAppValues] =React.useState({
        status:'',
        reason:''
    })
    const dropDownDispatch = (configKeyList) => dispatch(AppConfigDropdownActions(configKeyList));
    const appStatusUpdateDispatch = (registrationCriteria) => dispatch(appStatusUpdateAction(registrationCriteria));
    const dropdownData = useSelector(state => state.sharedState.appConfigDropdown);
    const appStatusDetails = useSelector(state => state.AppRegistrationState.appStatusDetails);
    const appDetails = useSelector(state => state.AppRegistrationState.appDetails);
    const downloadFile = useSelector(state => state.AppRegistrationState.downloadeFile);
    const clearMessages = () => {
        setErrorMessages([]);
        setSuccessMessages([]);
    }
    useEffect(() => {
        const configKeyList = ["STATE_LIST","APP_TYPE"];
        dropDownDispatch(configKeyList);
        if(appDetails && appDetails.data){
            let appDetailsData={...appDetails.data};
            let phoneNumber=appDetailsData.phoneNumber;
            let zip=appDetailsData.zip;
            appDetailsData.phoneNumber = phoneNumber.substring(0, 3);
            appDetailsData.phoneNumber1 = phoneNumber.substring(3, 6);
            appDetailsData.phoneNumber2 = phoneNumber.substring(6, 10);
            appDetailsData.zip = zip.substring(0, 5);
            appDetailsData.zip1 = zip.substring(5, 10);
            appDetailsData.bestPracticesInd= appDetails.data.bestPracticesInd === true ? 'true' : 'false';
            appDetailsData.privacyPolicyDetailInd= appDetails.data.privacyPolicyDetailInd === true ? 'true' : 'false';
            appDetailsData.privacyPolicyInd= appDetails.data.privacyPolicyInd === true ? 'true' : 'false';
            setValues(appDetailsData);
        }
    }, []);

    useEffect(() => {
        if (dropdownData && dropdownData.STATE_LIST && dropdownData.STATE_LIST.configDetail) {
            setStateList(dropdownData.STATE_LIST.configDetail);          
        }
        if (dropdownData && dropdownData.APP_TYPE && dropdownData.APP_TYPE.configDetail) {
            setAppTypeList(dropdownData.APP_TYPE.configDetail);
        }
    }, [dropdownData]);
    useEffect(() => {
        if (appStatusDetails) {
            setSpinnerLoader(false);
            if (appStatusDetails.success) {
                if(appStatusDetails.data.status==='APPROVED'){
                setSuccessMessages([RegistrationConstants.APPROVED_SUCCESS]);
                }
                if(appStatusDetails.data.status==='REJECTED'){
                    setSuccessMessages([RegistrationConstants.REJECTED_SUCCESS]);
                }
                if(appStatusDetails.data.status==='REVOKED'){
                    setSuccessMessages([RegistrationConstants.REVOKED_SUCCESS]);
                }              
                /* document.getElementById("error-scroll").scrollIntoView({
                    behavior: "smooth"
                }); */
                registrationRef.current.scrollIntoView({
                    behavior: "smooth"
                });
                document.getElementById("root").scrollTop=0;
                setShowAppButton(false);
              
            } else {
                setErrorMessages(['Unable to process your request. Please try later.']);
                /* document.getElementById("error-scroll").scrollIntoView({
                    behavior: "smooth"
                }); */
                registrationRef.current.scrollIntoView({
                    behavior: "smooth"
                });
                document.getElementById("root").scrollTop=0;
            }
        }
    }, [appStatusDetails]);    
    useEffect(() => {
        if (downloadFile && !downloadFile.systemFailue) {
            anchorRef.current.href = downloadFile;
            anchorRef.current.download = selectedFile;
            anchorRef.current.click();
            dispatch(downloadFileResetAction());
        }
    }, [downloadFile]);   
    const validateUpdateForm = () => {
        let errorMessagesArray = [];   
        setShowError({
            rejectErr: appValues.status==='REJECTED' && !appValues.reason ? (() => { errorMessagesArray.push(RegistrationConstants.REJECT_NOTE_REQUIRED); return true; })() : false,  
            revokeErr: appValues.status==='REVOKED' && !appValues.reason ? (() => { errorMessagesArray.push(RegistrationConstants.REVOKE_NOTE_REQUIRED); return true; })() : false,           
        });
        if (errorMessagesArray && errorMessagesArray.length === 0) {
            setDialogOpen(true);
            setDialogType('submit')
            return true;
        }
    };
    const statusUpdate = () => {
        setDialogOpen(false);
        clearMessages();
        handleClose();
        let appDetailsData     
        if(appDetails && appDetails.data){
             appDetailsData={...appDetails.data};
            appDetailsData.status=appValues.status;
            appDetailsData.statusReason=appValues.reason;
            appDetailsData.clientId=appDetails.data.clientId ? appDetails.data.clientId : '';
            if(appDetailsData.homePageUrl){
                if(appDetailsData.homePageUrl.includes('http://') || appDetailsData.homePageUrl.includes('https://')){
                   // appDetailsData.homePageUrl=appDetailsData.homePageUrl;
                }else{
                    appDetailsData.homePageUrl='http://'+appDetailsData.homePageUrl;
                }
            } 
            if(appDetailsData.privacyPolicyUrl){
                if(appDetailsData.privacyPolicyUrl.includes('http://') || appDetailsData.privacyPolicyUrl.includes('https://')){
                  //  appDetailsData.privacyPolicyUrl=appDetailsData.privacyPolicyUrl;
                }else{
                    appDetailsData.privacyPolicyUrl='http://'+appDetailsData.privacyPolicyUrl;
                }
            } 
            if(appDetailsData.redirectUrl){
                if(appDetailsData.redirectUrl.includes('http://') || appDetailsData.redirectUrl.includes('https://')){
                   // appDetailsData.redirectUrl=appDetailsData.redirectUrl;
                }else{
                    appDetailsData.redirectUrl='http://'+appDetailsData.redirectUrl;
                }
            } 
            if(appDetailsData.termsOfServiceUrl){
                if(appDetailsData.termsOfServiceUrl.includes('http://') || appDetailsData.termsOfServiceUrl.includes('https://')){
                   // appDetailsData.termsOfServiceUrl=appDetailsData.termsOfServiceUrl;
                }else{
                    appDetailsData.termsOfServiceUrl='http://'+appDetailsData.termsOfServiceUrl;
                }
            }     
            const userId = sessionStorage.getItem('isLoggedIn');
            appDetailsData.auditUserID = userId ? userId : 'MMIS_USER';               
        }      
       setSpinnerLoader(true);
      appStatusUpdateDispatch(appDetailsData);     
    };

    const handleChanges = name => event => {
        setShowError(false);
        setAppValues({ ...appValues, reason: '' }); 
        if(name==='approve'){
            let newval={reason: '',status: 'APPROVED'}
            setAppValues({ ...appValues, ...newval}); 
        }
        else if(name==='reject'){
            let newval={reason: '',status: 'REJECTED'}
            setAppValues({ ...appValues, ...newval }); 
        }
        else if(name==='revoke'){
            let newval={reason: '',status: 'REVOKED'}
            setAppValues({ ...appValues, ...newval }); 
        }else{
            setAppValues({ ...appValues,[name]: event.target.value });
        }
        handleOpen()  
    }
    const cancelAction =()=>{
        props.history.push({
            pathname: '/appPayerManagement',
            state: { type: "app" }
          });
    }
    const editRow = row => (event) => {      
        event.stopPropagation();        
        setSelectedFile(row.fileName);
        dispatch(downloadFileAction(row.attachmentPageID, row.fileName));
    }
    return (
        <div className="block-elm" >
             <a ref={anchorRef}> </a>
            {spinnerLoader ? <Spinner /> : null}
            <IdleSessionLogout/>

            <Dialog
                open={dialogOpen}
                // aria-labelledby="alert-dialog-title"
                // aria-describedby="alert-dialog-description"
                className="custom-alert-box"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {appValues.status==='REVOKED' ? 'Are you sure you want to revoke the application access?' : appValues.status==='APPROVED' ? 'Are you sure you want to approve the application?' : 'Are you sure you want to reject the application?'}

                        
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    
                    {dialogType === 'submit' && (
                        <Button onClick={() => { statusUpdate() }} color="primary" className="btn btn-success">
                            Ok
                        </Button>
                    )
                    }


                    <Button onClick={() => { setDialogOpen(false); }} color="primary" autoFocus>
                        Cancel
            </Button>
                </DialogActions>
            </Dialog>

            {/* <!-- main content start --> */}
            <div className="content-wrapper">


                <div className="frm-app-registration mt-1">
                    <div className="tabs-container">
                        <div className="page-header mob-main-head" ref={registrationRef}>
                            <h1 className="page-heading float-left">{values.status==='APPROVED' ? 'App Revoke' : 'App Approve/Reject'}</h1>
                            {/* <div className="float-right"><a href="/Help2" onClick={()=>{localStorage.setItem("ioxHelpDoc","admin")}} target="_blank" className="dib fw-600 py-2 mt-1"> Help </a></div> */}
                            <div className="clearfix" />
                        </div>


                        <div className="form-wrapper col-sm-12 px-0" >
                            {errorMessages && errorMessages.length > 0 ?
                                <ErrorMessages errorMessages={errorMessages} /> : null}
                            {errorMessages && successMessages && errorMessages.length === 0 && successMessages.length > 0 ?
                                <SuccessMessage successMessages={successMessages} /> : null}
                        </div>

                        <div className="tab-body mt-2 pb-3">
                            {/* <!-- section start --> */}
                            <div className="tabs-inner-container m-3">
                                <div className="tab-header">
                                    <h2 className="tab-heading float-left frm-label">App Details</h2>
                                    <div className="clearfix" />
                                </div>

                                <div className="tab-body-bordered pb-3 pt-3 mt-0">
                                    <div className="form-wrapper col-sm-12 px-2 my-1">
                                        <div className="mui-custom-form col-sm-6 col-md-3 m-0 mb-3">
                                            <TextField
                                                required
                                                id="app-name"
                                                label="App Name"
                                                value={values.appName}
                                                inputProps={{ maxLength: 35 }}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                disabled={disabled}                                             
                                            />
                                        </div>
                                        <div className="mui-custom-form col-sm-6 col-md-3 m-0 mb-3">
                                            <TextField
                                                select
                                                required={true}
                                                id="app-type"
                                                label="App Type"
                                                value={values.appType}
                                                inputProps={{ id: 'appType', maxLength: 2 }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    for: 'appType'
                                                }}
                                                disabled={disabled} 
                                            >
                                                <MenuItem selected key="-1" value="-1">
                                                    Please Select One
                                        </MenuItem>
                                        {appTypeList ? appTypeList.map(option => (
                                                    <MenuItem selected key={option.key} value={option.key}>
                                                        {option.value}
                                                    </MenuItem>
                                                )) : null}
                                            </TextField>
                                        </div>
                                        {values.appType==='Others' && (
                                            <div className="mui-custom-form col-sm-6 col-md-3 m-0 mb-3">
                                                <TextField
                                                    required
                                                    id="app-description"
                                                    label="App Type Description"
                                                    disabled={disabled} 
                                                    value={values.appTypeODesc}
                                                   // onChange={handleChanges('appTypeODesc')}
                                                    inputProps={{ maxLength: 15 }}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                // error={appNameError}
                                                // helperText={appNameError ? RegistrationConstants.APP_NMAE_REQUIRED : null}

                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className="form-wrapper col-sm-12 px-2 my-1">
                                        <div className="mui-custom-form col-sm-6 col-md-3 m-0 mb-3">
                                            <TextField
                                                required
                                                id="developer-fname"
                                                label="Developer First Name"
                                                value={values.devFirstName}
                                                disabled={disabled} 
                                                inputProps={{ maxLength: 35 }}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </div>
                                        <div className="mui-custom-form col-sm-6 col-md-3 m-0 mb-3">
                                            <TextField
                                                required
                                                id="developer-lname"
                                                label="Developer Last Name"
                                                value={values.devLastName}
                                                disabled={disabled} 
                                                inputProps={{ maxLength: 35 }}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </div>
                                        <div className="mui-custom-form col-sm-6 col-md-3 m-0 mb-3">
                                            <TextField
                                                required
                                                id="organization-name"
                                                label="Organization Name"
                                                value={values.orgName}
                                                disabled={disabled} 
                                                inputProps={{ maxLength: 35 }}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-wrapper col-sm-12 px-2 my-1">
                                        <div className="mui-custom-form col-sm-6 col-md-3 m-0 mb-3">
                                            <TextField                                               
                                                id="home-page-url"
                                                label="Home Page URL"
                                                value={values.homePageUrl}
                                                disabled={disabled} 
                                                inputProps={{ maxLength: 50 }}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </div>
                                        <div className="mui-custom-form col-sm-6 col-md-3 m-0 mb-3">
                                            <TextField
                                                required
                                                id="privacy-policy-url"
                                                label="Privacy Policy URL"
                                                value={values.privacyPolicyUrl}
                                                disabled={disabled} 
                                                inputProps={{ maxLength: 50 }}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            
                                            />
                                        </div>
                                        <div className="mui-custom-form col-sm-6 col-md-3 m-0 mb-3">
                                            <TextField
                                                required
                                                id="terms-of-service-url"
                                                label="Terms of Services URL"
                                                value={values.termsOfServiceUrl}
                                                disabled={disabled} 
                                                inputProps={{ maxLength: 50 }}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </div>
                                        <div className="mui-custom-form col-sm-6 col-md-3 m-0 mb-3">
                                            <TextField
                                                required
                                                id="redirect-url"
                                                label="Redirect URL"
                                                value={values.redirectUrl}
                                                disabled={disabled} 
                                                inputProps={{ maxLength: 50 }}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- section end --> */}

                            {/* <!-- section start --> */}
                            <div className="tabs-inner-container m-3">
                                <div className="tab-header">
                                    <h2 className="tab-heading float-left frm-label">Address</h2>
                                    <div className="clearfix" />
                                </div>

                                <div className="tab-body-bordered pb-3 pt-3 mt-0">
                                    <div className="form-wrapper col-sm-12 px-2 my-1">
                                        <div className="mui-custom-form col-sm-6 col-md-6 m-0 mb-3">
                                            <TextField
                                                required
                                                id="address1"
                                                label="Address"
                                                value={values.addressLine1}
                                                disabled={disabled} 
                                                inputProps={{ maxLength: 50 }}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}

                                            />
                                        </div>
                                        <div className="mui-custom-form col-sm-6 col-md-6 m-0 mb-3">
                                        <label className="visuallyhidden" for="address2">Address Extension</label>
                                            <TextField
                                                required
                                                id="address1"
                                                className="pt-4 add2-block"
                                                value={values.addressLine2}
                                                disabled={disabled} 
                                                inputProps={{ maxLength: 50 }}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </div>
                                        <div className="mui-custom-form col-sm-6 col-md-3 m-0 mb-3">
                                            <TextField
                                                required
                                                id="city"
                                                label="City"
                                                value={values.city}
                                                disabled={disabled} 
                                                inputProps={{ maxLength: 50 }}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </div>
                                        <div className="mui-custom-form col-sm-6 col-md-3 m-0 mb-3">
                                            <TextField
                                                select
                                                required={true}
                                                id="State"
                                                label="State"
                                                value={values.state}
                                                disabled={disabled} 
                                                inputProps={{ id: 'state', maxLength: 2 }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    for: 'state'
                                                }}
                                            
                                            >
                                                <MenuItem selected key="" value="-1">
                                                    Please Select One
                                                </MenuItem>
                                                {stateList ? stateList.map(option => (
                                                    <MenuItem selected key={option.key} value={option.key}>
                                                        {option.value}
                                                    </MenuItem>
                                                )) : null}
                                            </TextField>
                                        </div>
                                        <div className="mui-custom-form col-sm-6 col-md-3 m-0 mb-3">
                                            <div className="custom-mui-label"><span className="MuiFormLabel-asterisk">*</span> Zip and Extension</div>
                                            <div className="cndt-row">
                                                <div className="cndt-col-8">
                                                <label className="visuallyhidden" for="zip-ext1">Zip number</label>
                                                    <TextField
                                                        id="zip-ext1"
                                                        value={values.zip}
                                                        disabled={disabled} 
                                                        inputProps={{ maxLength: 50 }}
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                    />
                                                </div>
                                                <div className="cndt-col-4">
                                                <label className="visuallyhidden" for="zip-ext2">Extension</label>
                                                    <TextField
                                                        id="zip-ext2"
                                                        value={values.zip1}
                                                        disabled={disabled} 
                                                        inputProps={{ maxLength: 50 }}
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                    
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                        <div className="mui-custom-form col-sm-6 col-md-3 m-0 mb-3">
                                            <div className="custom-mui-label"><span className="MuiFormLabel-asterisk">*</span> Phone Number</div>
                                            <div className="cndt-row">
                                                <div className="cndt-col-3">
                                                <label className="visuallyhidden" for="phone-num1">Phone number</label>
                                                    <TextField
                                                        id="phone-num1"
                                                        value={values.phoneNumber}
                                                        disabled={disabled} 
                                                        inputProps={{ maxLength: 3 }}
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}

                                                    />
                                                </div>
                                                <div className="cndt-col-3">
                                                <label className="visuallyhidden" for="phone-num2">Phone number extension</label>
                                                    <TextField
                                                        id="phone-num2" 
                                                        value={values.phoneNumber1}
                                                        disabled={disabled} 
                                                        inputProps={{ maxLength: 3 }}
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                    />
                                                </div>
                                                <div className="cndt-col-6">
                                                <label className="visuallyhidden" for="phone-num3">Phone number extension next</label>
                                                    <TextField
                                                        id="phone-num3"                                                        
                                                        value={values.phoneNumber2}
                                                        disabled={disabled} 
                                                        inputProps={{ maxLength: 4 }}
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                        <div className="mui-custom-form col-sm-6 col-md-6 m-0 mb-3">
                                            <TextField
                                                required
                                                id="email-id"
                                                label="Email Id"
                                                value={values.emailId}
                                                disabled={disabled} 
                                                inputProps={{ maxLength: 64 }}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </div>

                                    </div>

                                </div>
                            </div>
                            {/* <!-- section end --> */}

                            {/* <!-- section start --> */}
                            <div className="tabs-inner-container m-3">
                                <div className="tab-header">
                                    <h2 className="tab-heading float-left frm-label">Privacy Policy Questionnaire</h2>
                                    <div className="clearfix" />
                                </div>
                                {/* border block start */}
                                <div className="tab-body-bordered pb-3 pt-3 mt-0 mb-3">
                                    <div className="questionnaire-area ml-3">
                                        <div className="txt-quest"><span className="MuiFormLabel-asterisk">*</span> Does the app have a publicly available privacy policy, written in plain language, that has been affirmatively shared with the patient prior to the patient authorizing app access to their  health information?</div>


                                        <div className="form-wrapper col-sm-12 m-0 p-0">
                                            <div className="mui-custom-form col-sm-6 col-md-6 m-0 pl-3">
                                                <div className="sub-radio">
                                                    <Radio
                                                        type="radio"
                                                        value={'true'}
                                                        id="ShortStay-TransExclYes1"
                                                        checked={values.privacyPolicyInd === 'true'}
                                                        disabled={disabled} 
                                                    />
                                                    <label for="ShortStay-TransExclYes1" className="radio-label">
                                                        Yes
                                                </label>
                                                    <Radio
                                                        type="radio"
                                                        value={'false'}
                                                        id="ShortStay-TransExclNo1"
                                                        className="ml-2"
                                                        checked={values.privacyPolicyInd === 'false'}
                                                        disabled={disabled} 
                                                    />
                                                    <label for="ShortStay-TransExclNo1" className="radio-label">
                                                        No
                                                 </label>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {/* border block end */}
                                {/* border block start */}
                                <div className="tab-body-bordered pb-3 pt-3 mt-0 mb-3">
                                    <div className="questionnaire-area ml-3">
                                        <div className="txt-quest"><span className="MuiFormLabel-asterisk">*</span> Does the app’s privacy policy include important information, such as, at a minimum:</div>
                                        <div className="txt-desc">
                                            <ul>
                                                <li>How a patient’s health information may be accessed, exchanged, or used by any person or other entity, including whether the patient’s health information may be shared or
sold at any time (including in the future);</li>
                                                <li>A requirement for express consent from a patient before the patient’s health information is accessed, exchanged, or used, including receiving express consent before a patient’s health information is shared or sold (other than disclosures required by law or disclosures necessary in connection with the sale of the application or a similar transaction)</li>
                                                <li>If the app will access any other information from a patient’s device; or</li>
                                                <li>How a patient can discontinue app access to their data, and what the app’s policy and process is for disposing of a patient’s data once the patient has withdrawn consent?
</li>
                                            </ul>
                                        </div>

                                        <div className="form-wrapper col-sm-12 m-0 p-0">
                                            <div className="mui-custom-form col-sm-6 col-md-6 m-0 pl-3">
                                                <div className="sub-radio">
                                                    <Radio
                                                        type="radio"
                                                        value={'true'}
                                                        id="ShortStay-TransExclYes2"
                                                        checked={values.privacyPolicyDetailInd === 'true'}
                                                        disabled={disabled} 
                                                    />
                                                    <label for="ShortStay-TransExclYes2" className="radio-label">
                                                        Yes
                                                </label>
                                                    <Radio
                                                        type="radio"
                                                        value={'false'}
                                                        id="ShortStay-TransExclNo2"
                                                        className="ml-2"
                                                        checked={values.privacyPolicyDetailInd === 'false'}
                                                        disabled={disabled} 
                                                    />
                                                    <label for="ShortStay-TransExclNo2" className="radio-label">
                                                        No
                                                 </label>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {/* border block end */}
                                {/* border block start */}
                                <div className="tab-body-bordered pb-3 pt-3 mt-0">
                                    <div className="questionnaire-area ml-3">
                                        <div className="txt-quest"><span className="MuiFormLabel-asterisk">*</span> Does the app follow industry best practices when developing a privacy policy and consult relevant resources, such as:</div>
                                        <div className="txt-desc">
                                            <ul>
                                                <li>CARIN Alliance Code of Conduct: <a title="CARIN Alliance Code of Conduct" href="https://www.carinalliance.com/code-of-conduct" target="_blank" rel="noopener noreferrer">https://www.carinalliance.com/code-of-conduct</a></li>
                                                <li>ONC Model Privacy Notice: <a title="ONC Model Privacy Notice" href="https://www.healthit.gov/topic/privacy-security-and-hipaa/model-privacy-notice-mpn" target="_blank" rel="noopener noreferrer">https://www.healthit.gov/topic/privacy-security-and-hipaa/model-privacy-notice-mpn</a></li>
                                            </ul>
                                        </div>

                                        <div className="form-wrapper col-sm-12 m-0 p-0">
                                            <div className="mui-custom-form col-sm-6 col-md-6 m-0 pl-3">
                                                <div className="sub-radio">
                                                    <Radio
                                                        type="radio"
                                                        value={'true'}
                                                        id="ShortStay-TransExclYes3"
                                                        checked={values.bestPracticesInd === 'true'}
                                                        disabled={disabled} 
                                                    />
                                                    <label for="ShortStay-TransExclYes3" className="radio-label">
                                                        Yes
                                                </label>
                                                    <Radio
                                                        type="radio"
                                                        value={'false'}
                                                        id="ShortStay-TransExclNo3"
                                                        className="ml-2"
                                                        checked={values.bestPracticesInd === 'false'}
                                                        disabled={disabled} 
                                                    />
                                                    <label for="ShortStay-TransExclNo3" className="radio-label">
                                                        No
                                                 </label>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {/* border block end */}
                            </div>
                            {/* <!-- section end --> */}

                            {/* <!-- section start --> */}
                            <div className="tabs-inner-container m-3">
                                <div className="tab-header">
                                    <h2 className="tab-heading float-left frm-label">Attachments</h2>
                                    <div className="clearfix" />
                                </div>

                                <div className="tab-body-bordered pb-3 mt-0">
                                    <div className="tabs-inner-container mx-3">                                      

                                        <div className="tab-holder mt-3">
                                            <TableComponent headCells={headCells}  tableData={values.attachments && values.attachments.length ? values.attachments : []}
                                                onTableRowClick={editRow} defaultSortColumn="diagnosisCode" />
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <!-- section end --> */}

                            {/* <!-- section start --> */}
                            <div className="date-area p-3 my-3">
                                <div className="block">
                                    <span className="inline-text">Submitted Date: </span>{values.addedAuditTimeStamp!==null ?  moment(values.addedAuditTimeStamp).format('MM/DD/YYYY') : ''}
                                </div>
                                {values.status==='APPROVED' &&(
                                    <div className="block">
                                    <span className="inline-text">Approved Date: </span>{values.auditTimeStamp!==null ?moment(values.auditTimeStamp).format('MM/DD/YYYY') : ''}
                                </div>
                                )}
                                 {values.status==='REVOKED' &&(
                                    <div className="block">
                                    <span className="inline-text">Revoked Date: </span>{values.auditTimeStamp!==null ?moment(values.auditTimeStamp).format('MM/DD/YYYY') : ''}
                                </div>
                                )}
                                {values.status==='REJECTED' &&(
                                    <div className="block">
                                    <span className="inline-text">Rejected Date: </span>{values.auditTimeStamp!==null ?moment(values.auditTimeStamp).format('MM/DD/YYYY') : ''}
                                </div>
                                )}
                            </div>
                            {/* <!-- section start --> */}

                            {/* button area start */}
                            <div className="float-center th-btnGroup py-3">
                            
                                {/* Modal start */}
                                <Modal
                                    open={open}
                                    onClose={handleClose}
                                    // aria-labelledby="simple-modal-title"
                                    // aria-describedby="simple-modal-description"
                                >

                                    <form autoComplete="off">
                                        <div id="frmModal" style={modalStyle} className={classes.paper}>
                                            <div className="custom-panel">
                                                <div className="panel-header">
                                                {appValues.status==='APPROVED' ? 'APPROVE APPLICATION' : appValues.status==='REVOKED' ? 'REVOKE APPLICATION' : 'REJECT APPLICATION'}
                                                    </div>
                                                <div className="close-modal-bx" onClick={handleClose}><i class="fa fa-times"></i></div>
                                            </div>
                                            <div className="tab-body p-3">
                                                <div className="form-wrapper mt-2">
                                                    <div className="mui-custom-form field-md col-sm-12 m-0 mb-3">
                                                        <div className="txt-confirm">
                                                        {appValues.status==='APPROVED' ? 'Are you sure you want to approve the application?' : appValues.status==='REVOKED' ? 'Are you sure you want to revoke the application?' : 'Are you sure you want to reject the application?'}
                                                            </div>
                                                    </div>
                                                    <div className="mui-custom-form col-sm-12 m-0 pt-0 mb-3">
                                                        <TextField
                                                            required={appValues.status==='REVOKED' || appValues.status==='REJECTED' ? true : false}
                                                            id="standard-firstname"
                                                            label={appValues.status==='APPROVED' ? 'Please enter approval notes' : appValues.status==='REVOKED' ? 'Please enter revoke reason' : 'Please enter Reject Reason'}
                                                            value={appValues.reason}
                                                            onChange={handleChanges('reason')}
                                                            inputProps={{ maxLength: 100 }}
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                            error={rejectErr ? RegistrationConstants.REJECT_NOTE_REQUIRED : revokeErr ? RegistrationConstants.REVOKE_NOTE_REQUIRED : null}
                                                            helperText={rejectErr ? RegistrationConstants.REJECT_NOTE_REQUIRED : revokeErr ? RegistrationConstants.REVOKE_NOTE_REQUIRED : null}
                                                            className="mt-0 pt-0"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="tab-footer p-3">
                                                    <div className="float-right th-btnGroup">
                                                        <Button
                                                            title={appValues.status==='APPROVED' ? 'Approve' : appValues.status==='REVOKED' ? 'Revoke' : 'Reject'}
                                                            variant="outlined" 
                                                            color="primary"
                                                            className={appValues.status==='APPROVED' ? "btn btn-ic btn-approve" : appValues.status==='REJECTED' ? "btn btn-ic btn-reject" : appValues.status==='REVOKED' ? "btn btn-ic btn-revoke" : null }
                                                            onClick={validateUpdateForm}
                                                        >
                                                            {appValues.status==='APPROVED' ? 'Approve' : appValues.status==='REVOKED' ? 'Revoke' : 'Reject'}
                                                            
                        </Button>
                                                        <Button
                                                            title="Cancel"
                                                            variant="outlined"
                                                            color="primary"
                                                            className="btn btn-cancel"
                                                            onClick={handleClose}
                                                        >
                                                            Cancel
                        </Button>

                                                    </div>
                                                    <div className="clearfix" />
                                                </div>
                                            </div>

                                        </div>
                                    </form>

                                </Modal>
                                {/* Modal end */}
                          
                            {values.status==='SUBMITTED' && showAppButton &&(
                                <>
                                          <Button
                                          title="Approve"
                                          id="approve"
                                          variant="outlined"
                                          color="primary"
                                          className="btn btn-ic btn-approve"
                                          onClick={handleChanges('approve')}
                                      >
                                          Approve
                                                      </Button>
                                      <Button
                                          title="Reject"
                                          variant="outlined"
                                          color="primary"
                                          className="btn btn-ic btn-reject"
                                          onClick={handleChanges('reject')}
                                      >
                                          Reject
                                </Button>
                                </>
                            )}
                            

                            {values.status==='APPROVED' && showAppButton &&(
                                          <Button
                                          title="Revoke"
                                          variant="outlined"
                                          color="primary"
                                          className="btn btn-ic btn-revoke"
                                          onClick={handleChanges('revoke')}
                                      >
                                          Revoke
                              </Button>
                            )}
                     
                               
                                <Button
                                    title="Cancel"
                                    variant="outlined"
                                    color="primary"
                                    className="btn btn-cancel"
                                    onClick={cancelAction}
                                >
                                    Cancel
                                                </Button>
                                <div className="clearfix" />
                            </div>
                            {/* button area end */}
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- main content end --> */}



        </div >
    )
}
