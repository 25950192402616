import React from 'react';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import { Button } from 'react-bootstrap';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { LogoutActions } from '../Store/Actions/AppActions';

export default function (ComposedClass) {
  class AutoLogout extends React.Component {
    constructor(props) {
      super(props);
      this.userID = props.location.state && props.location.state.userID ? props.location.state.userID : '';
      this.state = {
        warningTime: 1000 * 60 * process.env.REACT_APP_WARNING_TIME,
        signoutTime: 1000 * 60 * process.env.REACT_APP_SIGNOUT_TIME,
        warnTimer: false
      };
    }

    componentDidMount() {
      this.events = [
        'load',
        'mousemove',
        'mousedown',
        'click',
        'scroll',
        'keypress'
      ];
      for (var i in this.events) {
        window.addEventListener(this.events[i], this.resetTimeout);
      }

      this.setTimeout();
    }

    clearTimeoutFunc = () => {
      if (this.warnTimeout) clearTimeout(this.warnTimeout);
      if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
    };

    setTimeout = () => {
      this.warnTimeout = setTimeout(this.warn, this.state.warningTime);
      this.logoutTimeout = setTimeout(this.logout, this.state.signoutTime);
    };

    resetTimeout = () => {
      this.clearTimeoutFunc();
      this.setTimeout();
    };

    warn = () => {
      this.setState({ warnTimer: true });
    };

    logout = () => {
      // Send a logout request to the API
      this.setState({ warnTimer: false });
      this.destroy();
    };

    clearWarn = () => {
      // Send a logout request to the API
      this.setState({ warnTimer: false });
      this.clearTimeoutFunc();
    };

    destroy = () => {
      //clear the session
      const logoutRequest = {
        logoutType: "sessionTimeout",
        userId: this.userID
      }
      this.props.dispatch(LogoutActions(logoutRequest));
    };

    render() {
      return (
        <div>
          <Dialog className='alert-dialog' open={this.state.warnTimer}>
            <div className="row">
              <ErrorOutlineIcon className="alert-font" />
              <p className="col-md-11 col-sm-11 col-10 px-1 py-3 font-weight-bold">Your session is about to expire. Do you want to continue with this session?</p>
            </div>
            <div className="flex-row">
              <Button className="btn btn-primary btn-alert mr-2" onClick={this.clearWarn}>YES</Button>
            </div>
          </Dialog>
          <ComposedClass {...this.props} warnTimer={this.state.warnTimer} />
        </div>
      );
    }
  }
  const mapStateToProps = state => ({
    logoutData: state.sharedState.logout
  });
  const Logout = connect(mapStateToProps, null)(
    AutoLogout
  );
  return Logout;

}


