import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";

const GraphView = (props) => {
  const { reportType, reportData,scrollBottomOfPage,toGetTrenGraphChartData } = props;
  const [graphData, setGraphData] = useState([]);
  const [patientGraphData, setPatientGraphData] = useState([]);
  const [providerGraphData, setProviderGraphData] = useState([]);
  const [drugListGraphData, setDrugListGraphData] = useState([]);
  console.log("graphData",graphData);

  let reportNameList = {
    App: "Third Party Apps Registration",
    Member: "Member Registration",
    API: "Third Party API Access",
    MemberData: "Member API Access",
    AppAPI : "App API Access",
    ProviderData: "Provider Directory API",
  };

  useEffect(() => {
    setTimeout(() => {
      if(document.querySelector('div[aria-label="A tabular representation of the data in the chart."]'))
    document.querySelector('div[aria-label="A tabular representation of the data in the chart."]').remove();  
    }, 5000);
    
  });
  
  useEffect(() => {
    async function getFormattedData() {
      if(reportType != "AppAPI"){
      let response = await getStatusCount(reportData.data);
      setGraphData(response);
      }else{
      let patientresponse = await getStatusCount(reportData.data,"patient");
      setPatientGraphData(patientresponse);
      let providerresponse = await getStatusCount(reportData.data,"provider");
      setProviderGraphData(providerresponse);
      let drugresponse = await getStatusCount(reportData.data,"drug");
      setDrugListGraphData(drugresponse);
    }}
    getFormattedData();
  },[reportData]);

  const getStatusCount = (data,apiType) => {
      if(reportType === "App"){
        return [["Submitted",Number(data["submitted"])],["Approved",Number(data["approved"])],["Rejected",Number(data["rejected"])],["Revoked",Number(data["revoked"])]];
      }else if(reportType === "Member"){
      return [["Registrations",Number(data["registrationCount"])],["0 to <18",Number(data["age0toBelow18Count"])],["18 to <30",Number(data["age18toBelow30Count"])],["30 to <50",Number(data["age30toBelow50Count"])],["50 to <70",Number(data["age50toBelow70Count"])],["Above >70",Number(data["ageAbove70Count"])]];
      }else if(reportType === "MemberData"){
        return [["Unique member access data once",Number(data["accessDistinct"])],["Unique member access data more than once",Number(data["accessMTOnce"])]];
      }else if (reportType === "API"){
        return [["Patient Access API",Number(data["patientCount"])],["Provider Directory API",Number(data["providerCount"])],["Covered Drug List API",Number(data["drugCount"])]];
      }else if(reportType === "AppAPI" && apiType === "patient"){
           let patientArray = [];
      if (data && data.patientAppsInfo && data.patientAppsInfo.length>0 ){
          for (var i = 0; i < data.patientAppsInfo.length; i++) {
            patientArray.push([data.patientAppsInfo[i].appName,parseInt(data.patientAppsInfo[i].count)]);
          }   
         return patientArray;
      }else{
            return [];
          }
         
      }else if(reportType === "AppAPI" && apiType === "provider"){
          let providerArray = [];
      if (data && data.providerAppsInfo && data.providerAppsInfo.length>0 ){
          for (var i = 0; i < data.providerAppsInfo.length; i++) {
            providerArray.push([data.providerAppsInfo[i].appName,parseInt(data.providerAppsInfo[i].count)]);
          }
          return providerArray;
      }else{
          return [];
        }
      }else if(reportType === "AppAPI" && apiType === "drug"){
          let drugListArray = [];
      if (data && data.drugListAppsInfo && data.drugListAppsInfo.length>=0 ){
          for (var i = 0; i < data.drugListAppsInfo.length; i++) {
            drugListArray.push([data.drugListAppsInfo[i].appName,parseInt(data.drugListAppsInfo[i].count)]);
          }
          return drugListArray;
      }else{
          return [];
      }}else if (reportType === "ProviderData"){
        return [["Practitioner",Number(data["practitionerCount"])],["Organization",Number(data["organizationCount"])]];
        }else{
        return [];
      }
    };
       
     if(reportType != "AppAPI" && reportType==="Member"){      
        return (
      <div className="row">
      <div className="col-3"></div>
      <div className="col-6" id="reactgooglegraph">
        <Chart
          height="400px"
          width="400px"
          chartType="ColumnChart"
          loader={<div>Loading Chart</div>}
          data={[["", ""], ...graphData]}
          options={{
            hAxis: { title: "" },
            vAxis: { title: `Count of ${reportNameList[reportType]}` ,minValue: 0,titleTextStyle: {italic: false},format:'0'},
            legend: "none",
            bar: {groupWidth: "40%"},
            trendlines: { 0: {} },
          }}
          chartEvents={[
            {
              eventName: "ready",
              callback: ({ chartWrapper, google }) => {
                const chart = chartWrapper.getChart();
                var handler = function(e) {
                  google.visualization.events.removeListener();
                  let selection=chart.getSelection();
                 if((selection && selection.length >0) && reportType !== "ProviderData" ){
                    toGetTrenGraphChartData(graphData[selection[0]?.row][0]);
                  }
                };
                function mouseHandlerPointer(e) {
                   document.getElementById('reactgooglegraph').style.cursor = 'pointer';
                }  
               
               function mouseHandlerDefault(e) {
                  document.getElementById('reactgooglegraph').style.cursor = 'default';
                }
                google.visualization.events.addListener(
                  chartWrapper.getChart(),
                  "select",
                  handler
                );
                google.visualization.events.addListener(chart, 'onmouseover', mouseHandlerPointer);                 
                google.visualization.events.addListener(chart, 'onmouseout', mouseHandlerDefault);
              }
            }
          ]}
          rootProps={{ "data-testid": "2" }}
        />
      </div>
      <div className="col-3"></div>
      </div>
    );

   }else if(reportType === "AppAPI"){

  return (
    <div className="row">
    <div className="col-3"></div>
    <div className="col-6" id="reactgooglegraph">
      <Chart id="pat"
        height="300px"
        width="800px"
        chartType="ColumnChart"
        loader={<div>Loading Chart</div>}
        data={[["", ""], ...patientGraphData]}
        options={{
          hAxis: { title: "" },
          vAxis: { title: `Count of Patient API Access` ,minValue: 0,titleTextStyle: {italic: false},format:'0'},
          legend: "none",
          bar: {groupWidth: "40%"},
          trendlines: { 0: {} },
        }}
        chartEvents={[
          {
            eventName: "ready",
            callback: ({ chartWrapper, google }) => {
              const chart = chartWrapper.getChart();
              var handler = function(e) {
                google.visualization.events.removeListener();
                let selection=chart.getSelection();
                if(selection && reportType != "AppAPI"){
                  toGetTrenGraphChartData(graphData[selection[0]?.row][0]);
                }
              };
              function mouseHandlerPointer(e) {
                 document.getElementById('reactgooglegraph').style.cursor = 'pointer';
              }  
             
             function mouseHandlerDefault(e) {
                document.getElementById('reactgooglegraph').style.cursor = 'default';
              }
              google.visualization.events.addListener(
                chartWrapper.getChart(),
                "select",
                handler
              );
              google.visualization.events.addListener(chart, 'onmouseover', mouseHandlerPointer);                 
              google.visualization.events.addListener(chart, 'onmouseout', mouseHandlerDefault);
            }
          }
        ]}
        rootProps={{ "data-testid": "2" }}
      />
      {providerGraphData.length > 0 && (
      <Chart id="prov"
        height="300px"
        width="800px"
        chartType="ColumnChart"
        loader={<div>Loading Chart</div>}
        data={[["", ""], ...providerGraphData]}
        options={{
          hAxis: { title: "" },
          vAxis: { title: `Count of Provider API Access` ,minValue: 0,titleTextStyle: {italic: false},format:'0'},
          legend: "none",
          bar: {groupWidth: "40%"},
          trendlines: { 0: {} },
        }}
        chartEvents={[
          {
            eventName: "ready",
            callback: ({ chartWrapper, google }) => {
              const chart = chartWrapper.getChart();
              var handler = function(e) {
                google.visualization.events.removeListener();
                let selection=chart.getSelection();
                if(selection && reportType != "AppAPI"){
                  toGetTrenGraphChartData(graphData[selection[0]?.row][0]);
                }
              };
              function mouseHandlerPointer(e) {
                 document.getElementById('reactgooglegraph').style.cursor = 'pointer';
              }  
             
             function mouseHandlerDefault(e) {
                document.getElementById('reactgooglegraph').style.cursor = 'default';
              }
              google.visualization.events.addListener(
                chartWrapper.getChart(),
                "select",
                handler
              );
              google.visualization.events.addListener(chart, 'onmouseover', mouseHandlerPointer);                 
              google.visualization.events.addListener(chart, 'onmouseout', mouseHandlerDefault);
            }
          }
        ]}
        rootProps={{ "data-testid": "2" }}
      />
      )}
      {drugListGraphData.length > 0 && (
      <Chart
        height="300px"
        width="800px"
        chartType="ColumnChart"
        loader={<div>Loading Chart</div>}
        data={[["", ""], ...drugListGraphData]}
        options={{
          hAxis: { title: "" },
          vAxis: { title: `Count of Drug List API Access` ,minValue: 0,titleTextStyle: {italic: false},format:'0'},
          legend: "none",
          bar: {groupWidth: "40%"},
          trendlines: { 0: {} },
        }}
        chartEvents={[
          {
            eventName: "ready",
            callback: ({ chartWrapper, google }) => {
              const chart = chartWrapper.getChart();
              var handler = function(e) {
                google.visualization.events.removeListener();
                let selection=chart.getSelection();
                if(selection && reportType != "AppAPI"){
                  toGetTrenGraphChartData(graphData[selection[0]?.row][0]);
                }
              };
              function mouseHandlerPointer(e) {
                 document.getElementById('reactgooglegraph').style.cursor = 'pointer';
              }  
             
             function mouseHandlerDefault(e) {
                document.getElementById('reactgooglegraph').style.cursor = 'default';
              }
              google.visualization.events.addListener(
                chartWrapper.getChart(),
                "select",
                handler
              );
              google.visualization.events.addListener(chart, 'onmouseover', mouseHandlerPointer);                 
              google.visualization.events.addListener(chart, 'onmouseout', mouseHandlerDefault);
            }
          }
        ]}
        rootProps={{ "data-testid": "2" }}
      />
      )}
    </div>
    <div className="col-3"></div>
    </div>
  );
  }else{
  
  return (
    <div className="row">
    <div className="col-3"></div>
    <div className="col-6" id="reactgooglegraph">
      <Chart
        height="400px"
        chartType="ColumnChart"
        loader={<div>Loading Chart</div>}
        data={[["", ""], ...graphData]}
        options={{
          hAxis: { title: "" },
          vAxis: { title: `Count of ${reportNameList[reportType]}` ,minValue: 0,titleTextStyle: {italic: false},format:'0'},
          legend: "none",
          bar: {groupWidth: "40%"},
          trendlines: { 0: {} },
        }}
        chartEvents={[
          {
            eventName: "ready",
            callback: ({ chartWrapper, google }) => {
              const chart = chartWrapper.getChart();
              var handler = function(e) {
                google.visualization.events.removeListener();
                let selection=chart.getSelection();
                if((selection && selection.length >0) && reportType !== "ProviderData" ){
                  toGetTrenGraphChartData(graphData[selection[0]?.row][0]);
                }
              };
              function mouseHandlerPointer(e) {
                 document.getElementById('reactgooglegraph').style.cursor = 'pointer';
              }  
             
             function mouseHandlerDefault(e) {
                document.getElementById('reactgooglegraph').style.cursor = 'default';
              }
              google.visualization.events.addListener(
                chartWrapper.getChart(),
                "select",
                handler
              );
              google.visualization.events.addListener(chart, 'onmouseover', mouseHandlerPointer);                 
              google.visualization.events.addListener(chart, 'onmouseout', mouseHandlerDefault);
            }
          }
        ]}
        rootProps={{ "data-testid": "2" }}
      />
  
    </div>
    <div className="col-3"></div>
    </div>
  );
      }
};

export default GraphView;
