/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import { Button } from 'react-bootstrap';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import * as AppConstants from '../../../SharedModule/Utils/AppConstants';
import * as ClaimsConstants from './ClaimsConstants';
import { CreateFeedbackActions, UpdateFeedbackActions } from '../Store/Actions/ClaimsActions';

export default function ClaimsDialog(props) {
    const dispatch = useDispatch();
    let errorMessagesArray = [];
    const { feedbackQsList, row, values, userID, open, setOpen, setSpinnerLoader, setErrorMessages,
    setIsClaimsOpen, showError, setShowError, showErrorText, setShowErrorText, selectedMenu, onSelect, onClickRow,
    tempRowIndex, setTempRowIndex, tempRowData, setTempRowData, selectedKey, setSelectedKey } = props;
    const [openSuccess, setOpenSuccess] = React.useState(false);

    // Dispatch Actions
    const createFeedbackDispatch = (createFeedbackCriteria) => dispatch(CreateFeedbackActions(createFeedbackCriteria));
    const updateFeedbackDispatch = (updateFeedbackCriteria) => dispatch(UpdateFeedbackActions(updateFeedbackCriteria));

    const createFeedbackData = useSelector(state => state.claimsState.createFeedback);
    const updateFeedbackData = useSelector(state => state.claimsState.updateFeedback);

    useEffect(() => {
        if (createFeedbackData) {
            setErrorMessages([]);
            setSpinnerLoader(false);
            errorMessagesArray = [];
            errorMessagesArray = AppConstants.handleAPIErrors(createFeedbackData, errorMessagesArray, '');
            if (errorMessagesArray && errorMessagesArray.length > 0) {
                setErrorMessages(errorMessagesArray);
            } else {
                saveSuccess(createFeedbackData);
            }
        }
    }, [createFeedbackData]);

    useEffect(() => {
        if (updateFeedbackData) {
            setErrorMessages([]);
            setSpinnerLoader(false);
            errorMessagesArray = [];
            errorMessagesArray = AppConstants.handleAPIErrors(updateFeedbackData, errorMessagesArray, '');
            if (errorMessagesArray && errorMessagesArray.length > 0) {
                setErrorMessages(errorMessagesArray);
            } else {
                saveSuccess(updateFeedbackData);
            }
        }
    }, [updateFeedbackData]);

    // Method to set rules on Successful Save
    const saveSuccess = (feedbackData) => {
        row.claimsFeedbackDetails = feedbackData;
        setIsClaimsOpen(false);
        setOpenSuccess(true);
    }

    // Method to validate Feedback form on confirm
    const validateFeedbackForm = () => {
        let errorObj = showError;
        let errorTextObj = showErrorText;
        var feedbackError = 'feedbackError';
        var feedbackErrorText = 'feedbackErrorText';
        for (var x = 0; x < feedbackQsList.length; x++) {
            setShowError(false);
            setShowErrorText('');
            errorObj = { ...errorObj, [feedbackError + x]: false };
            errorTextObj = { ...errorTextObj, [feedbackErrorText + x]: '' };
            if (values['feedbackQs' + x] === 'O' &&
                (values['feedbackText' + x] === '' || (values['feedbackText' + x] && values['feedbackText' + x].trim() === ''))) {
                errorObj = { ...errorObj, [feedbackError + x]: true };
                errorTextObj = { ...errorTextObj, [feedbackErrorText + x]: ClaimsConstants.OTHER_TEXT_ERROR };
                errorMessagesArray.push(ClaimsConstants.OTHER_TEXT_ERROR);
            }
        }
        setShowError(errorObj);
        setShowErrorText(errorTextObj);

        if (errorMessagesArray && errorMessagesArray.length === 0) {
            return true;
        } else {
            return false;
        }
    }

    // Method to save feedback
    const saveFeedback = () => {
        const feedbackRequest = [];
        if (validateFeedbackForm()) {
            if (row.claimsFeedbackDetails && row.claimsFeedbackDetails.length > 0) {
                for (var x = 0; x < feedbackQsList.length; x++) {
                    const feedback = {
                    createdBy: userID,
                    createdOn: row.claimsFeedbackDetails[x].createdOn,
                    feedbackQuestionId: values['feedbackQsId' + x],
                    id: row.claimsFeedbackDetails[x].id,
                    memberOid: "",
                    memberSystemId: row.memberSystemId,
                    responseOption: values['feedbackQs' + x],
                    responseText: values['feedbackText' + x],
                    tcn: row.tcn,
                    updatedBy: userID,
                    updatedOn: new Date()
                };
                feedbackRequest.push(feedback);
            }
                updateFeedbackDispatch(feedbackRequest);
            } else {
                for (var y = 0; y < feedbackQsList.length; y++) {
                const feedback = {
                    createdBy: userID,
                    createdOn: new Date(),
                    feedbackQuestionId: values['feedbackQsId' + y],
                    id: "",
                    memberOid: "",
                    memberSystemId: row.memberSystemId,
                    responseOption: values['feedbackQs' + y],
                    responseText: values['feedbackText' + y],
                    tcn: row.tcn,
                    updatedBy: userID,
                    updatedOn: new Date()
                };
                feedbackRequest.push(feedback);
            }
                createFeedbackDispatch(feedbackRequest);
            }
            setOpen(false);
            setSpinnerLoader(true);
        } else {
            setOpen(false);
        }
    }

    // Method to handle OK
    const handleOK = () => {
        setOpenSuccess(false);
        if (tempRowData && tempRowIndex !== -1 && tempRowData.tcn !== row.tcn) {
            onClickRow(tempRowData, tempRowIndex);
        }
        if (selectedKey !== 0 && selectedKey !== selectedMenu) {
            onSelect(selectedKey);
        }
        setTempRowIndex(-1);
        setTempRowData([]);
        setSelectedKey(0);
    }

    return (
        <>
            <Dialog className='alert-dialog' open={open}>
                <div className="row">
                    <ErrorOutlineIcon className="alert-font" />
                    <p className="col-md-11 col-sm-11 col-10 px-1 py-3 font-weight-bold">{ClaimsConstants.CONFIRMATION_MESSAGE}</p>
                </div>
                <div className="flex-row">
                    <Button id="claimsConfirm" className="btn btn-primary btn-alert mr-2" onClick={saveFeedback}>CONFIRM</Button>
                    <Button id="claimsCancel" className="btn btn-transparent btn-alert" onClick={() => setOpen(false)}>CANCEL</Button>
                </div>
            </Dialog>

            <Dialog className='alert-dialog' open={openSuccess}>
                <div className="row">
                    <ErrorOutlineIcon className="alert-font" />
                    <p className="col-md-11 col-sm-11 col-10 px-1 py-3 font-weight-bold">{ClaimsConstants.SUCCESS_MESSAGE}</p>
                </div>
                <div className="flex-row">
                    <Button id="claimsOk" className="btn btn-primary btn-alert mr-2" onClick={handleOK}>OK</Button>
                </div>
            </Dialog>
        </>
    );
}