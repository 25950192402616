import React from 'react';
import Card from 'react-bootstrap/Card';

import Table from 'react-bootstrap/Table';
import styles from './EOB.css';


export default function EOB(){
let EOBresponse={
    "resourceType": "Bundle",
    "id": "e04c7a79-ee0d-40f5-831b-02ee56765cdc",
    "meta": {
        "lastUpdated": "2024-02-12T11:00:24.433+00:00"
    },
    "type": "searchset",
    "total": 0,
    "link": [
        {
            "relation": "self",
            "url": "https://iox.dev.sddss.apps.conduent.com/patientAccess/api/R4/ExplanationOfBenefit?_id=200000405"
        }
    ]
};
const codestyle={backgroundColor: 'lightgray'};
return (
<div className="container">
<Card className="card-background"> <Card.Body>
<blockquote className="blockquote mb-0">
<h1 className="text-header">Explanation Of Benefits</h1>
<p> The Explanation of Benefit (EOB) API enables IOX members to share written explanation of benefits with third party applications. Explanation of benefits include the payments IOX has made on behalf of a member and a description of the healthcare services provided to the member. This API conforms to the R4 FHIR standard and the CARIN Blue Button FHIR STU1 Implementation Guide.</p>
</blockquote> </Card.Body>
</Card>
<br/>
<hr className="horizontal-rule"></hr>
<br/>

<Card className="card-background">
<Card.Body>
<h4 className="EOB-heading">URL</h4>
<div>
<codeblock className="accesstoken-code"> URL</codeblock>
<span>&nbsp;</span><span>&nbsp;</span>
<codeblock className="accesstoken-code">{process.env.REACT_APP_EDUCATIONAL_MATERIAL_URL}patientAccess/api/R4/ExplanationOfBenefit</codeblock>
</div>
</Card.Body>
<Card.Body>
<h4 className="EOB-heading">Headers</h4>
<Table striped bordered hover>
<tbody>

<tr>
<td>Accept</td>
<td>application/json</td>
</tr>
<tr> <td>Authorization</td>
<td>Bearer 'ACCESS_TOKEN'</td>
</tr>
</tbody>
</Table>
</Card.Body>
<Card.Body>
<p>Note:Third party app will have access token after member has given consent to app (Refer Member consent section)
</p>
<h4 className="EOB-heading">Sample Request</h4>

<div style={codestyle}>'curl --location 'https://iox.dev.sddss.apps.conduent.com/patientAccess/api/R4/ExplanationOfBenefit?_id=20214520652089810''\<br/>
                                    '--header 'Authorization: Bearer7Sp6WPj9AcmSLJMD_6u-bVZHp-4' '\<br/>
                                     '--header 'Cookie: JSESSIONID=bp4uCPJH3YbKA9eGuXDDh380zbq1oFPOuE9NsWNi' '\
</div>

<h4 className="EOB-heading">Sample Response</h4>

<pre style={codestyle}>
<code class="prettyprint">
{JSON.stringify(EOBresponse,null,'  ')}
</code>
</pre>
</Card.Body>

</Card>
</div>
);
}