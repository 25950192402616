/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef,useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Spinner from '../../../SharedModule/Spinner/Spinner';
import InputAdornment from '@material-ui/core/InputAdornment';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import AppSearch from '../Components/AppSearch';
import PayerSearch from '../../PayerRegistration/Components/PayerSearch';
import * as appActions from '../../AppRegistration/Store/Actions/RegistrationActions'
import * as payerActions from '../../PayerRegistration/Store/Actions/PayerRegistrationActions'
import IdleSessionLogout from "../../../SharedModule/SessionManagement/IdleSessionLogout";


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));
export default function AppSearchCom(props) {
    const [spinnerLoader, setSpinnerLoader] = React.useState(false);
    const [values, setValues] = React.useState(defaultVale);
    const [unApprovedAppCount,setUnApprovedAppCount]=useState(0);
    /* disabled payer management for SD */
    /* const [unApprovedPayerCount,setUnApprovedPayerCount]=useState(0);*/
    const defaultVale = {
        "appSearch": "",
        "appStatus": "SUBMITTED"
    }

    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const dispatch=useDispatch();
    const appSearchFun =()=> dispatch(appActions.getUnapprovedApps('SUBMITTED'));
    /* disabled payer management for SD*/
    /*const payerSearchFun =()=> dispatch(payerActions.getUnapprovedPayers('SUBMITTED')); */
    const unapprovedApps = useSelector(state => state.AppRegistrationState.unApprovedApps);
    /*const unapprovedPayers = useSelector(state => state.payerRegState.unApprovedPayers);*/

    useEffect(()=>{
        setSpinnerLoader(true);
        appSearchFun();
        /* disabled payer management for SD*/
        /*payerSearchFun();*/
        props?.location?.state?.type == "payer" ? setValue(1) : setValue(0);
        document.getElementById("root").scrollTop=0; 
    },[])

    useEffect(()=>{
        setTimeout(() => {
            setSpinnerLoader(false);
        }, 1000);
        setUnApprovedAppCount(unapprovedApps?.data?.length ? unapprovedApps?.data?.length : 0 )
         /* disabled payer management for SD */
        /*setUnApprovedPayerCount(unapprovedPayers?.data?.length ? unapprovedPayers?.data?.length : 0)*/
    },[unapprovedApps/*,unapprovedPayers*/])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <div className="content-wrapper block-elm" >
            {spinnerLoader ? <Spinner /> : null}
            <IdleSessionLogout/>
            {/* <!-- main content start --> */}
            <div className="tabs-container mt-4">
                {value == 0 && <div className="alert alert-success custom-alert" role="alert">
                     <i className="fa fa-info-circle mr-2" /> { unApprovedAppCount == 0 ?"No application is pending for approval":"You have unapproved apps which are submitted, awaiting for approval."}
                </div>}
                {/*disabled payermanagement for SD*/}
                {/* {value == 1 && <div className="alert alert-success custom-alert" role="alert">
                     <i className="fa fa-info-circle mr-2" />{ unApprovedPayerCount == 0 ?"No payer registration is pending for approval":"You have unapproved payer registration which are submitted, awaiting for approval."}
                </div>} */}
                {/* <div className="block-elm text-right">
                    <a href="/Help2" onClick={()=>{localStorage.setItem("ioxHelpDoc","admin")}} target="_blank" className="dib fw-600 pr-1"> Help </a>
                </div> */}
                <div className="tab-body">
                    <div className='tab-holder custom-tabber mt-3 pb-3'>
                        <AppBar position='static' color="default">
                            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                                <Tab label={`App Management (${unApprovedAppCount})`} {...a11yProps(0)} />
                                {/*disabled payermanagement for SD*/}
                                {/* <Tab label={`Payer Management (${unApprovedPayerCount})`} {...a11yProps(1)} /> */}
                            </Tabs>
                        </AppBar>
                        <TabPanel value={value} index={0}>
                           <AppSearch {...props}/>
                        </TabPanel>
                        {/*disabled payermanagement for SD*/}
                        {/* <TabPanel value={value} index={1}>
                            <PayerSearch {...props}/>
                        </TabPanel> */}
                    </div>
                </div>



            </div>

            {/* <!-- main content end --> */}

        </div>
    )
}

