export const VALIDATE_REGISTRATION = 'VALIDATE_REGISTRATION';
export const REGISTRATION_OPTIONS = 'REGISTRATION_OPTIONS';
export const VALIDATE_REGISTRATION_OPTIONS = 'VALIDATE_REGISTRATION_OPTIONS';
export const VERIFY_VERIFICATION_CODE = 'VERIFY_VERIFICATION_CODE';
export const RESEND_VERIFICATION_CODE = 'RESEND_VERIFICATION_CODE';
export const RESEND_EMAIL = 'RESEND_EMAIL';
export const RESET_REGISTRATION_DATA = 'RESET_REGISTRATION_DATA';
export const PAYER_TO_PAYER_SEARCH = 'PAYER_TO_PAYER_SEARCH';
export const SAVE_PAYER_CONSENT_DATA_EXCHANGE = 'SAVE_PAYER_CONSENT_DATA_EXCHANGE';
export const REGISTRATION_VALUES = 'REGISTRATION_VALUES';
export const SAVE_NEW_PAYER_DETAILS = 'SAVE_NEW_PAYER_DETAILS';
export const MEMBER_SEARCH = 'MEMBER_SEARCH';
export const MEMBER_CONSENT_GET = 'MEMBER_CONSENT_GET';
export const SAVE_MEMBER_CONSENT_DATA_EXCHANGE = 'SAVE_MEMBER_CONSENT_DATA_EXCHANGE';