import React from 'react';

export default function PrivacyPolicyForm(props) {
    return (
        <div className="tabs-container container">
            <div className="contactUs-container pl-0 row">
                <div className="contactUs-subContainer">                   
                    <div className="terms-of-use-head p-4">
                        <b className="p-2 dib"> PRIVACY POLICY </b>
                    </div>
                    <div className="container-body p-4 mt-2">
                        <section className="block-elm px-2 mb-3">
                            <p> Welcome to the Interoperability Exchange Solution, where members can receive health information on a Third Party application of their choice. Like all online resources, we recognize that visitors to this web site are concerned about their privacy. </p> 


                            <p> The Interoperability Exchange Solution discloses personally identifiable information only to authorized members or their representatives, authorized agency staff, and third party applications with the approval and at the direction of a member. </p> 

                            <p> The State recognizes that personally identifiable information collected by the State should be used only for the purpose for which it is collected. Except as authorized by law, such information will not be disclosed to other persons or organizations. Therefore, it is the policy that all personally identifiable information collected by state government Web sites shall be subject to the following principles: </p> 

                        </section>

                        <section className="block-elm px-2 mb-3">
                            <p> <b> Electronic information - </b> information collected and held electronically shall be afforded the same protection as non-electronic information; </p>
                            <p> <b> Collection of personal information - </b> the collection of personal information shall be limited to and consistent with the requirements of the government program or activity; </p>
                            <p> <b> Use of personal information - </b> individuals shall be informed as to why information is being collected and how it will be used; </p>
                            <p> <b> Disclosure of personal information - </b> disclosure of information shall be limited to the purpose for which it was collected unless otherwise authorized by law; when practical and provided by law, information may be obtained from other government entities; </p>
                            <p> <b> Access to personal information - </b> individuals shall be allowed a reasonable opportunity to obtain access to their personal information and to ensure that it is correct; and </p>
                            <p> <b> Security precautions - </b> reasonable precautions shall be taken to ensure the security of personal information. 
                            </p>
                        </section>

                        <section className="block-elm px-2 mb-3">

                            <p>
                            <b>Personally Identifiable Information.</b> The term "personally identifiable information" includes health information and any other information that could reasonably be used to identify you, including your name, address, telephone number, e-mail address, Social Security number, Medicaid ID number, Provider ID number, birth date or any combination of information that could be used to identify you. </p>

                            <p>
                            <b>Information Voluntarily Provided By You.</b> This web site collects voluntary information from you in three different ways: through a registration feature and an authentication feature. </p>

                            <p>
                            <b>Registration.</b> In order to engage in certain online transactions and access restricted online information, members and third party application developers will be required to register with the portal by submitting your SSN and Medicaid ID as well as some additional demographic information about yourself. </p>

                            <p>
                            <b>Authentication.</b> The authentication method used for granting authorized users access to secure content requires authorized Members to use their User ID and Password. </p>

                            <p>
                            This website does not use "cookies." However, the site uses temporary session cookies to allow users to interact with the portal and to use online features. Session cookies do not allow us to personally identify a user. They are stored only in memory and are deleted when the user's browser is shut down. </p>

                            <p>
                            This site does collect and indefinitely store your Internet Protocol (IP) address, (which does not identify you as an individual), as well as information about the date and time of your visit, whether a file you have requested exists, and how many bytes of information were transmitted to you over the Web from this site. We use your IP address to assess the frequency of visits to this site and the popularity of its various pages and functions. We will not attempt to match any personally identifiable information that you provide to us with your IP address, unless required to do so in conjunction with a criminal or civil investigation. </p>

                            <p>
                            <b>Security.</b> The managers of this web site have taken reasonable steps to safeguard the integrity of the telecommunications and computing infrastructure, including but not limited to authentication, monitoring, auditing, and encryption. These steps should not be construed in any way as giving business, legal or other advice, or warranting as fail proof, the security of information provided via this web site. </p>

                            <p>
                            <b>Links Disclaimer.</b> In order to provide visitors with certain information, this website provides links to local, other-state, and federal government agencies, and web sites of other organizations. A link does not constitute an endorsement of the content, viewpoint, accuracy, opinions, policies, products, services or accessibility of that web site. Once you link to another web site from this portal, you are subject to the terms and conditions of that web site, including, but not limited to, its privacy policy and this Privacy Notice no longer applies. </p>

                        </section>

                      
                        <footer className="block-elm px-2">
                            <div><b>Contact Information:</b> for questions regarding this privacy notice, please contact: </div>
                            <div>South Dakota Interoperability and Patient Access HelpDesk at 1-877-350-2171.</div>
                            {/* <div>Conduent Privacy Officer</div> */}
                            {/* <div>P.O. Box 240808</div><br/> */}
                            {/* <div>Anchorage, AK 99524-0808 </div> */}
                        </footer>
                    </div>
                </div>
            </div>
        </div>
    )
}
