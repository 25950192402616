/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Spinner from '../../../SharedModule/Spinner/Spinner';
import { AppConfigDropdownActions } from '../../../SharedModule/Store/Actions/AppActions';
import { AppSearch, appDetailsAction,resetAppStatus } from '../Store/Actions/RegistrationActions';
import moment from 'moment';
import InputAdornment from '@material-ui/core/InputAdornment';


import TableComponent from '../../../SharedModule/Table/Table';

const appTypeDropdown = [{
    key: 'SUBMITTED',
    value: 'Submitted'
},
{
    key: 'APPROVED',
    value: 'Approved'
},
{
    key: 'REJECTED',
    value: 'Rejected'
},
{
    key: 'REVOKED',
    value: 'Revoked'
},
{
    key: 'ALL',
    value: 'All'
}
]

export default function AppSearchCom(props) {

    // Page Constants
    const dispatch = useDispatch();
    const registrationRef = useRef(null);
    const [redirect, setRedirect] = React.useState(false);
 
    const [showRecords, setShowRecords] = React.useState([]);  
    const [spinnerLoader, setSpinnerLoader] = React.useState(false);

       // Field bindings
    const defaultVale = {
        "appSearch": "",
        "appStatus": "SUBMITTED",
        }
    const [values, setValues] = React.useState(defaultVale);
    const toTitleCase=name=>{
        return name.replace(
            /\w\S*/g,
            function(txt) {
              return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
          );
    }
    const appHeadCells = [
        {
            id: 'appName', numeric: false, disablePadding: true, label: 'App Name', enableHyperLink: true, fontSize: 12, width: '15%'
        },
        {
            id: 'appType', numeric: false, disablePadding: false, label: 'App Type', enableHyperLink: false, fontSize: 12, width: '15%'
        },
        {
            id: 'submittedby', numeric: false, disablePadding: false, label: toTitleCase(`${values.appStatus == "ALL" ? "" : values.appStatus} By`), enableHyperLink: false, fontSize: 12, width: '20%'
        },
        {
            id: 'auditTimeStamp', numeric: false, disablePadding: false, label: toTitleCase(`${values.appStatus == "ALL" ? "" : values.appStatus} Date`), enableHyperLink: false, fontSize: 12, width: '20%'
        },
        {
            id: 'orgName', numeric: false, disablePadding: false, label: 'Organization Name', enableHyperLink: false, fontSize: 12, width: '20%'
        },
        {
            id: 'status', numeric: false, disablePadding: false, label: 'Status', enableHyperLink: false, fontSize: 12, width: '10%'
        }
    ];
    

    // Dispatch Actions
    const dropDownDispatch = (configKeyList) => dispatch(AppConfigDropdownActions(configKeyList));
    const appSearchFun = searchCriteria => dispatch(AppSearch(searchCriteria));

    const dropdownData = useSelector(state => state.sharedState.appConfigDropdown);
    const payload = useSelector(state => state.AppRegistrationState.searchResult);
    const onSearchView = searchvalues => dispatch(appDetailsAction(searchvalues));
    const appDetails = useSelector(state => state.AppRegistrationState.appDetails);
    const appDetailsTime = useSelector(state => state.AppRegistrationState.appDetailsTime);

    // Method to make API calls on Load
    useEffect(() => {
        const configKeyList = ["STATE_LIST"];
        dropDownDispatch(configKeyList);
        const appStatus = localStorage.getItem('appStatus');
        setValues({ ...values, 'appStatus': appStatus ?  appStatus : 'SUBMITTED'}); 
        if(appStatus==='ALL'){
            appSearchFun(''); 
        }
        else{
            appSearchFun(appStatus ? appStatus : 'SUBMITTED'); 
        } 
         
    }, []);

     
    useEffect(() => {
        if (dropdownData && dropdownData.STATE_LIST && dropdownData.STATE_LIST.configDetail) {
          //  setStateList(dropdownData.STATE_LIST.configDetail);
        }
    }, [dropdownData]);

    useEffect(() => {
        setShowRecords([]);
        if (payload && payload.data) {
            setShowRecords(payload.data);
        }
    }, [payload]);

    
    const onSearch = name => event => {
        setValues({ ...values, [name]: event.target.value });
        let queryString=event.target.value.toLowerCase();
        if(queryString.length===0){
            return setShowRecords(payload.data);
        }
        if(showRecords.length > 0){
           let filterData= payload.data.filter(item =>
             item.appName.toLowerCase().indexOf(queryString)===0 ||
             item.appType.toLowerCase().indexOf(queryString)===0 ||
             item.devFirstName.toLowerCase().indexOf(queryString)===0 ||
             moment(item.addedAuditTimeStamp).format('MM/DD/YYYY').toLowerCase().indexOf(queryString)===0 || 
             item.orgName.toLowerCase().indexOf(queryString)===0 || item.status.toLowerCase().indexOf(queryString)===0 ||
             (item.auditUserID && item.auditUserID.toLowerCase().indexOf(queryString)===0) || 
             (item.auditTimeStamp && moment(item.auditTimeStamp).format('MM/DD/YYYY').toLowerCase().indexOf(queryString)===0)
             )        
           setShowRecords(filterData);
        }
       
              
    };

    const handleChangeStatus = name => event => {
        if(event.target.value==='ALL'){
            appSearchFun(''); 
        }
        else{
            appSearchFun(event.target.value); 
        }
        localStorage.setItem('appStatus', event.target.value);
        setValues({ ...values, [name]: event.target.value });       
    };

    const getTablegetTableData = (data) => {
        if (data && data.length) {
            let tData = JSON.stringify(data);
            tData = JSON.parse(tData);
            tData.map((each, index) => {
                each.index = index;               
                each.addedAuditTimeStampDate = each.addedAuditTimeStamp ? moment(each.addedAuditTimeStamp).format('MM/DD/YYYY') : '';         
                each.auditTimeStamp = each.auditTimeStamp ? moment(each.auditTimeStamp).format('MM/DD/YYYY') : ''; 
                //each.auditTimeStamp = each.auditTimeStamp ? moment(each.auditTimeStamp).utc().format('MM/DD/YYYY hh:mm:ss') : ''; 
                each.status=each.status ? each.status.charAt(0).toUpperCase() + each.status.slice(1).toLowerCase(): '';             
                // each.submittedby=each.devFirstName+' '+each.devLastName;   
                each.submittedby= each.status?.toUpperCase() == "SUBMITTED" ? (each.devFirstName+' '+each.devLastName) : each.auditUserID;            
                // each.edmsDocType= each.edmsDocType;                
            });
            
            return tData;
        } else {
            return [];
        }
    }

    useEffect(() => {
        if (appDetails != null && redirect) {
            setSpinnerLoader(false);
            setRedirect(false);
          if (appDetails.success && (appDetails.errorCode === null || appDetails.errorCode === undefined)) {
              if(appDetails && appDetails.data ){
                props.history.push({
                    pathname: '/appApproveReject',
                  });
              }
                        
          } else {
            //errorMessagesArray.push(ErrMsgConstants.ERROR_OCCURED_DURING_TRANSACTION);
           // props.tableErrorFunction(errorMessagesArray)
          }
        }
      }, [appDetailsTime]);

    const editRow = row => (event) => {             
        onSearchView(row.id);
          setSpinnerLoader(true);
           setRedirect(true);
           dispatch(resetAppStatus());
    }
    
    return (
        <div className="content-wrapper block-elm" >
            {spinnerLoader ? <Spinner /> : null}

            {/* <!-- main content start --> */}


                <div className="frm-app-registration mt-1">
                    <div className="tabs-container" ref={registrationRef}>
                        {/* <div className="page-header mob-main-head" ref={registrationRef}>
                            <h1 className="page-heading float-left">App Search</h1>
                            <div className="float-right"><a href="/Help2" onClick={()=>{localStorage.setItem("ioxHelpDoc","admin")}} target="_blank" className="dib fw-600 py-2 mt-1"> Help </a></div>
                            <div className="clearfix" />
                        </div> */}


                        {/* <div className="form-wrapper col-sm-12 px-0" >
                            {errorMessages && errorMessages.length > 0 ?
                                <ErrorMessages errorMessages={errorMessages} /> : null}
                            {errorMessages && successMessages && errorMessages.length === 0 && successMessages.length > 0 ?
                                <SuccessMessage successMessages={successMessages} /> : null}
                        </div> */}

                        <div className="tab-body mt-2 pb-3">
                            {/* <!-- section start --> */}
                            <div className="tabs-inner-container mx-3">
                                <div className="tab-header">
                                    <h1 className="tab-heading float-left frm-label">Filter by Keyword/Status</h1>
                                    <div className="clearfix" />
                                </div>

                                <div className="tab-body-bordered p-3 mt-0 plr-none">
                                    <div className="form-wrapper col-sm-12 px-0 my-1" id="frm-app-search">
                                        <div className="mui-custom-form col-sm-12 col-md-10 txt-search m-0">
                                        <label className="visuallyhidden" for="app-search">App Search</label>
                                            <TextField
                                                id="app-search"
                                                value={values.appSearch}
                                                onInput={onSearch('appSearch')}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                placeholder="Filter by keyword.."
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"><div className="icon-filter"></div></InputAdornment>,
                                                    id: 'app-search'
                                                }}
                                            />
                                        </div>
                                        <div className="mui-custom-form col-sm-12 col-md-2 txt-select m-0">
                                        <label className="visuallyhidden" for="app-status-id">App Status</label>
                                            <TextField
                                                select
                                                required={true}
                                                id="app-status"
                                                value={values.appStatus}
                                                onChange={handleChangeStatus('appStatus')}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    for: 'appType'
                                                }}
                                                InputProps={{
                                                    id: 'app-status-id'
                                                }}

                                            >                                           
                                                {appTypeDropdown ? appTypeDropdown.map(option => (
                                                    <MenuItem selected key={option.key} value={option.key}>
                                                        {option.value}
                                                    </MenuItem>
                                                )) : null}
                                            </TextField>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <!-- section end --> */}

                            {/* <!-- section start --> */}
                            <div className="tab-holder mx-3 mt-3">
                            <TableComponent headCells={ appHeadCells} tableData={getTablegetTableData(showRecords ? showRecords : [])} onTableRowClick={editRow} defaultSortColumn="addedAuditTimeStamp" sortOrder="asc" />
                             
                            </div>
                            {/* <!-- section end --> */}


                        </div>
                    </div>
                </div>
            {/* <!-- main content end --> */}

        </div>
    )
}

