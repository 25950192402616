import React, { useEffect, useState } from 'react';
import Routes from "../../Routes";
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Dropdown } from 'react-bootstrap';
import Header from "./header";
import Footer from "./footer";
import $ from 'jquery';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import * as serviceEndPoint from '../../SharedModule/Service/service';
const drawerWidth = 285;


const useStyles = makeStyles(theme => ({

  root: {
    display: 'flex',
    paddingTop: '0px',
  },
  hide: {
    display: 'none'
  },
  containerFluid: {
    padding: 0
  }
}));
export default function Layout(props) {
  const isLoggedIn = sessionStorage.getItem('isLoggedIn');
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [open, setOpen] = React.useState(true);
  const [isAdmin, setisAdmin] = React.useState(true);
  const [activeClass,setActiveClass]=useState("app");
  const cnRole = localStorage.getItem('cnRole');


  useEffect(()=>{
    setActiveClass(location.pathname);
  },[location.pathname])

  const onSideMenuClick = (pathname) => {
    history.push(pathname);
  }

  useEffect(() => {
    
    if (cnRole == "Low"){
      setActiveClass("/memberManagement");
      setisAdmin(false);
    }

  }, [cnRole]);
  React.useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 1024) {
        $('body').addClass('leftNavOff');
      }else{
        $("body").removeClass("leftNavOff");
      }
    }
    window.addEventListener('resize', handleResize);
    handleResize();
  },[]);

  const toggleSideBar = () => {

    if ($('body').hasClass('leftNavOff')) {
      $('body').removeClass('leftNavOff');
    } else {
      $('body').addClass('leftNavOff');
    }
  }
  
    const invalidateToken = () => {
        let isAdmin = localStorage.getItem('isAdmin') === 'true';
        let isConsentManagement = localStorage.getItem('clientId') === 'ioxconsentmanagement';
        const reqObj = {
            token: sessionStorage.getItem("token"),
            client_id: isAdmin ?
                process.env.REACT_APP_ADMIN_CLIENTID : (
                    isConsentManagement ?
                        process.env.REACT_APP_CONSENT_MANAGEMENT_CLIENTID :
                        process.env.REACT_APP_TP_MEMBER_CLIENTID),
            client_secret: isAdmin ?
                process.env.REACT_APP_ADMIN_CLIENT_SECRETE : (
                    isConsentManagement ?
                        process.env.REACT_APP_CONSENT_MANAGEMENT_CLIENT_SECRET :
                        process.env.REACT_APP_TP_MEMBER_CLIENT_SECRET),
        };
        axios
            .post(
                isAdmin ? serviceEndPoint.INVALIDATE_TOKEN_FOR_ADMIN :
                    serviceEndPoint.INVALIDATE_TOKEN_FOR_MEMBER,
                reqObj
            )
            .then((res) => {
                logout(isAdmin);
            });
    };
  
  let token = sessionStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common['Authorization'] = `${token}`;
  } else {
      axios.defaults.headers.common['Authorization'] = null;
  }
  const logout = () => {
    const isAdmin = localStorage.getItem('isAdmin') === 'true'
    const userIdentifier = sessionStorage.getItem('userIdentifier');
    let logoutUrl;
     sessionStorage.clear();
     localStorage.clear();
    if (isAdmin) {
       logoutUrl = `${process.env.REACT_APP_ADMIN_OAUTH_LOGOUT_URI}&logout_hint=${userIdentifier}`;
  } else {
      logoutUrl = process.env.REACT_APP_MEMBER_OAUTH_LOGOUT_URI;
  }
  window.open(logoutUrl, "_self");
  };
  return (
    <>
          <div className='main-container' >
            <Drawer
              className={clsx(open ? classes.drawer : '', 'side-drawer')}
              variant="persistent"
              anchor="left"
              open={open}
              classes={{
                paper: classes.drawerPaper
              }}
            >

                <div className="nav-list-container" id="sideNav">
                    <div className="top-brand-border" />
                      {localStorage.getItem('isAdmin') === 'true' && isAdmin == true && location.pathname.toLowerCase().indexOf('/consentviewer') == -1 && (
                          <List className="side-menu-list muiMainNav">
                              <ListItem title="App Management" className={activeClass == "/appPayerManagement" ? "sideMenu-active-Li" : ""} button onClick={() => { onSideMenuClick("/appPayerManagement"); }}>

                                  <div className='side-menu-icon'><i className="fa fa-clipboard-list" /></div>
                                  <ListItemText> App Management </ListItemText>
                              </ListItem>
                          </List>)
                      }

                      {localStorage.getItem('isAdmin') === 'true' && location.pathname.toLowerCase().indexOf('/consentviewer') == -1 && (
                          <List className="side-menu-list muiMainNav">
                              <ListItem title="Member Restriction" className={activeClass == "/memberManagement" ? "sideMenu-active-Li" : ""} button onClick={() => { onSideMenuClick("/memberManagement"); setActiveClass("/memberManagement") }}>
                                  <div className='side-menu-icon'><i className="fa fa-clipboard-list" /></div>
                                  <ListItemText> Member Restriction </ListItemText>
                              </ListItem>
                          </List>)
                      }

                      {location.pathname.toLowerCase().indexOf('/consentviewer') == -1 && localStorage.getItem('isAdmin') === 'true' && isAdmin == true && (
                          <List className="side-menu-list muiMainNav">
                              <ListItem title="Reports" button className={activeClass == "/reports" ? "sideMenu-active-Li" : ""} onClick={() => { onSideMenuClick("/reports"); }} >

                                  <div className='side-menu-icon'><i className="fa fa-sitemap fa-20" /></div>
                                  <ListItemText> Reports </ListItemText>
                              </ListItem>
                          </List>)
                      }
                      {localStorage.getItem('isAdmin') === 'false' && (
                          <List className="side-menu-list muiMainNav">
                              <ListItem title="Member Consent Management" button className={activeClass == "consentManagement" ? "sideMenu-active-Li" : ""} onClick={() => { onSideMenuClick("/consentsManagement"); setActiveClass("consentManagement") }} >
                                  <div className='side-menu-icon'><i className="fa fa-clipboard-list" /></div>
                                  <ListItemText> Member Consent Management </ListItemText>
                              </ListItem>
                          </List>)
                      }                   
        {location.pathname.toLowerCase().indexOf('/consentviewer') == -1 && (
                          <List className="side-menu-list muiMainNav">
                              <ListItem title="Signout" className={activeClass == "signout" ? "sideMenu-active-Li" : ""} button onClick={() => { logout(); setActiveClass("signout") }}>
                                  <div className='side-menu-icon'><i className="fa fa-sign-out fa-20 mt-1" /></div>
                                  <ListItemText> Logout </ListItemText>
                              </ListItem>
                          </List>)
                      }

              </div>
            </Drawer>
            <div className="main-content block-elm">
              <div className="page-container">
                <Header print={false} />
                {props.children}
              </div>
              <noscript>Your browser does not support JavaScript!</noscript>
              <Footer />
            </div>
          </div>
        </>
  );
}
