import React from 'react';
import Card from 'react-bootstrap/Card';

import Table from 'react-bootstrap/Table';
import styles from './List.css';


export default function List(){
let Listresponse={
    "resourceType": "Bundle",
    "id": "5f521074-6797-4c68-b71c-586777f506f1",
    "meta": {
        "lastUpdated": "2024-01-30T06:12:44.668+00:00"
    },
    "type": "searchset",
    "total": 0,
    "link": [
        {
            "relation": "self",
            "url": "https://iox.dev.sddss.apps.conduent.com/drugFormulary/api/R4/List?status=Active"
        }
    ]
}
const codestyle={backgroundColor: 'lightgray'};
return (
<div className="container">
<Card className="card-background"> <Card.Body>
<blockquote className="blockquote mb-0">
    
<h1 className="text-header">List</h1>
<p>The List resource is a flat, possibly ordered collection of records. List resources are used in many places, including allergies,
 medications, alerts, family history, medical history, etc. List resources can be used to support patient-specific clinical lists,
as well as lists that manage workflows such as tracking patients, managing teaching cases, etc. Resources supported by the List resource can be homogeneous – consisting of only one type of resource (e.g. allergy lists) as well as heterogeneous – containing a variety of resources (e.g. a problem list including Conditions, AllergyIntolerances, recent Procedures, etc.).</p>
</blockquote> </Card.Body>
</Card>
<br/>
<hr className="horizontal-rule"></hr>
<br/>

<Card className="card-background">
<Card.Body>
<h4 className="List-heading">URL</h4>
<div>
<codeblock className="accesstoken-code"> URL</codeblock>
<span>&nbsp;</span><span>&nbsp;</span>
<codeblock className="accesstoken-code">{process.env.REACT_APP_EDUCATIONAL_MATERIAL_URL}drugFormulary/api/R4/List</codeblock>
</div>
</Card.Body>
<Card.Body>
<h4 className="List-heading">Headers</h4>
<Table striped bordered hover>
<tbody>

<tr>
<td>Accept</td>
<td>application/json</td>
</tr>
<tr> <td>Authorization</td>
<td>Bearer 'ACCESS_TOKEN'</td>
</tr>
</tbody>
</Table>
</Card.Body>
<Card.Body>
<h4 className="List-heading">Sample Request</h4>

<div style={codestyle}>'curl --location 'https://iox.dev.sddss.apps.conduent.com/drugFormulary/api/R4/List?status=Active''\<br/>
</div>

<h4 className="List-heading">Sample Response</h4>
<pre style={codestyle}>
<code class="prettyprint">
{JSON.stringify(Listresponse,null,'  ')}

</code>
</pre>

</Card.Body>
</Card>
</div>

);
}