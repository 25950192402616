export const REQUIRED = ' is required.';
export const INVALID = ' is invalid.';
export const FIRST_NAME = 'First Name';
export const LAST_NAME = 'Last Name';
export const DOB = 'Date of Birth';
export const PHONE_NUMBER = 'Phone Number';
export const EMAIL_ADDRESS = 'Email Address';
export const USER_ID = 'User ID';
export const MEMBER_ID = 'Member ID';
export const MEMBER_SSN = 'Member SSN';
export const ADDRESS = 'Address';
export const CITY = 'City';
export const STATE = 'State';
export const POSTAL_CODE = 'Postal Code';
export const SECURITY_QUESTION_1 = 'Security Question 1';
export const SECURITY_QUESTION_2 = 'Security Question 2';
export const SECURITY_QUESTION_3 = 'Security Question 3';
export const ANSWER = 'Answer';
export const ANSWER_1 = 'Answer 1';
export const ANSWER_2 = 'Answer 2';
export const ANSWER_3 = 'Answer 3';
export const VERIFICATION_CODE = 'Verification Code';
export const PHONE_NUMBER_FORMAT = 'Phone Number must be 10 digits.';
export const SSN_NUMBER_FORMAT = 'Member SSN must be 9 digits.';
export const DUPLICATE_SECURITY_QUESTIONS = 'Security questions must be unique. Please select three different security questions.';
export const UNDER_18_ERROR = 'Attestation is required for child under the age of eighteen.';
export const REGISTRATION_AUTHENTICATION_ERROR = 'Failed authentication -The registration information you submitted was invalid. Please contact your Service Representative at 1-844-ONE-CNDT.';
export const VERIFICATION_CODE_ERROR = 'The verification code you entered is incorrect.  Click Resend Code to generate a new code.';
export const VERIFICATION_EMAIL_SUCCESS = 'New Verification Code has been sent successfully to your registered Email Address.';
export const RESEND_EMAIL_SUCCESS = 'One-time Password has been sent successfully to your registered Email Address.';
export const NO_REGISTRATION_OPTION_ERROR = 'Please select your Recent Visit.';
export const REGISTRATION_TECHNICAL_ERROR = 'Due to technical problems, the system was unable to complete the request. Please try again. If you continue to have issues, please contact at 1-877-350-2171';
export const OLD_PAYER_DATA_CONSENT='Please select data Consent.';
export const PLAN_NAME='Plan Name is required.';
export const START_DATE='Start Date is required.';
export const START_DATE_INVALID='Start Date is Invalid.';
export const END_DATE_INVALID='End Date is Invalid.';
export const END_DATE='End Date is required.';
export const PAYER_NAME='Payer Name is required.';
export const COVERAGE_ID='Coverage ID is required.';
export const TERMS_CONDITION_REQUIRED='Terms & Conditions,Privacy Policy is required.';

export const MEMBER_REGISTRATION_SUCCESS='Member has been successfully registered.'
export const STARTDT_GREATER_THAN_ENDDT="The start date must be less than or equal to the end date."
export const CONFIDENTIALITY_STATUS = 'These credentials are not eligible to login for IOX. If you continue to have issues, please contact at 1-877-350-2171.';




