import React from 'react';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

function CustomScrollMenu(props) {

  // Selected prop will be passed
  const MenuItem = ({ text, selectedMenu }) => {
    return (
      <div className={`menu-item ${selectedMenu === text ? 'active' : ''}`}>
        {text}
      </div>
    );
  };
  
  // Important! add unique key
  const Menu = (list) => props.claimRollingMonths.map(element => {
    return (
      <MenuItem
        text={element.toString().toUpperCase()}
        key={element}
        selectedMenu={props.selectedMenu}
      />
    );
  });
  
  
  const Arrow = ({ text, className }) => {
    return (
      <div className={className}>{text}</div>
    );
  };
  
  const ArrowLeft = Arrow({ text: <ArrowBackIosIcon/> });
  const ArrowRight = Arrow({ text: <ArrowForwardIosIcon/> });

    // Create Menu from items
    const menu = Menu(props.claimRollingMonths, props.selectedMenu);

    return(
        <ScrollMenu
          data={menu}
          arrowLeft={ArrowLeft}
          arrowRight={ArrowRight}
          selected={props.selectedMenu}
          onSelect={props.onSelect}
        />
    );
}
export default CustomScrollMenu;