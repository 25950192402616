/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import { Button } from 'react-bootstrap';
import * as ResetProfileConstants from './ResetProfileConstants';
import * as AppConstants from '../../../SharedModule/Utils/AppConstants';
import ErrorMessages from '../../../SharedModule/MessageHandlers/ErrorMessages';
import Spinner from '../../../SharedModule/Spinner/Spinner';
import { ForgotUserIdActions } from '../Store/Actions/ResetProfileActions';
import AlertDialog from '../../../SharedModule/Layout/AlertDialog';

export default function ForgotUserNameForm(props) {

    // Page Constants
    const dispatch = useDispatch();
    const forgotUserRef = useRef(null);
    let errorMessagesArray = [];
    const [errorMessages, setErrorMessages] = React.useState([]);
    const [spinnerLoader, setSpinnerLoader] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [{
        emailAddressError,
        memberIDError
    }, setShowError] = React.useState(false);

    const [{
        emailAddressErrorText,
        memberIDErrorText
    }, setShowErrorText] = React.useState('');

    // Field bindings
    const [values, setValues] = React.useState({
        emailAddress: '',
        memberID: ''
    });

    // Dispatch Actions
    const forgotUserIdDispatch = (forgotUserIdCriteria) => dispatch(ForgotUserIdActions(forgotUserIdCriteria));

    const forgotUserIdData = useSelector(state => state.resetProfileState.forgotUserId);

    useEffect(() => {
        if (forgotUserIdData) {
            setSpinnerLoader(false);
            errorMessagesArray = [];
            errorMessagesArray = AppConstants.handleAPIErrors(forgotUserIdData, errorMessagesArray, ResetProfileConstants.FORGOT_USERID_TECHNICAL_ERROR);
            if (errorMessagesArray && errorMessagesArray.length > 0) {
                setErrorMessages(errorMessagesArray);
                forgotUserRef.current.scrollIntoView({
                    behavior: "smooth"
                });
            } else {
                setOpen(true);
            }
        }
    }, [forgotUserIdData]);

    // Method to set field values on change
    const handleChanges = name => event => {
        if (name === 'memberID') {
            setValues({ ...values, [name]: event.target.value.replace(AppConstants.NUMBER_ONLY_REGEX, '') });
        }
        else {
            setValues({ ...values, [name]: event.target.value });
        }
    };

    // Method to validate User Id Form
    const validateUserIDForm = () => {
        errorMessagesArray = [];
        setErrorMessages([]);
        var emailAddressError; var memberIDError = false;
        var emailAddressErrorText; var memberIDErrorText = '';

        if (values.emailAddress === '' || (values.emailAddress && values.emailAddress.trim() === '')) {
            emailAddressError = true;
            emailAddressErrorText = ResetProfileConstants.EMAIL_ADDRESS + ResetProfileConstants.REQUIRED;
            errorMessagesArray.push(emailAddressErrorText);
        }
        if (!emailAddressError && !AppConstants.EMAIL_ADDRESS_REGEX.test(values.emailAddress)) {
            emailAddressError = true;
            emailAddressErrorText = AppConstants.EMAIL_INVALID;
            errorMessagesArray.push(emailAddressErrorText);
        }
        if (values.memberID === '' || (values.memberID && values.memberID.trim() === '')) {
            memberIDError = true;
            memberIDErrorText = ResetProfileConstants.MEMBER_ID + ResetProfileConstants.REQUIRED;
            errorMessagesArray.push(memberIDErrorText);
        }

        setErrorMessages(errorMessagesArray);
        setShowError({
            emailAddressError: emailAddressError,
            memberIDError: memberIDError
        });

        setShowErrorText({
            emailAddressErrorText: emailAddressErrorText,
            memberIDErrorText: memberIDErrorText
        });

        if (errorMessagesArray && errorMessagesArray.length > 0) {
            forgotUserRef.current.scrollIntoView({
                behavior: "smooth"
            });
            return true
        } else {
            return false;
        }
    };

    // Method to submit Registration Form
    const submitForgotUserIDForm = () => {
        if (!validateUserIDForm()) {
            const forgotUserIdRequest = {
                email: values.emailAddress,
                memberId: values.memberID
            }
            setSpinnerLoader(true);
            forgotUserIdDispatch(forgotUserIdRequest);
        }
    };

    return (
        <div className="tabs-container container">
            <div className="text-float pb-1" ref={forgotUserRef}></div>
            {spinnerLoader ? <Spinner /> : null}
            {errorMessages && errorMessages.length > 0 ?
                <div className="forgot-username-container mb-0 p-0 row">
                    <div className="form-wrapper col-sm-12 p-0">
                        <ErrorMessages errorMessages={errorMessages} />
                    </div>
                </div> : null}
            <div className="forgot-username-container bg-white pl-0 mt-0 row">
                <div className="col-12 text-float px-0 py-3 font-weight-600">
                    <a href="/contactUs" target="_blank">Contact Us</a>
                    <span className="blue-font px-1">|</span>
                    <a href="/Help2" onClick={()=>{localStorage.setItem("ioxHelpDoc","member")}} target="_blank">Help</a>
                </div>
                <div className="col forgot-username-text-container d-inline-block">
                    <div className="col p-0">
                        <ol className="custom-counter">
                            <li className="mb-4">Please enter your registered Email Address.</li>
                            <li className="">User ID must be sent to your registered Email Address.</li>
                        </ol>
                    </div>
                </div>
                <div className="col-12 col-lg-6 d-inline-block p-4 pl-lg-0">
                    <h5 className="mt-1 mb-4">FORGOT USER ID</h5>
                    <form autoComplete="off" className="form-styles">
                        <div className="col px-0">
                            <div className="mui-custom-form m-0 mb-4">
                                <TextField
                                    required
                                    // className="field-padding field-family-font pr-2"
                                    id="standard-email-address"
                                    label={ResetProfileConstants.EMAIL_ADDRESS}
                                    value={values.emailAddress}
                                    onChange={handleChanges('emailAddress')}
                                    inputProps={{ maxLength: 64 }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={emailAddressError}
                                    helperText={emailAddressError ? emailAddressErrorText : null}
                                />
                            </div>
                            <div className="mui-custom-form m-0 mb-4">
                                <TextField
                                    required
                                    //className="field-padding field-family-font pr-2"
                                    id="standard-member-ID"
                                    label={ResetProfileConstants.MEMBER_ID}
                                    value={values.memberID}
                                    onChange={handleChanges('memberID')}
                                    inputProps={{ maxLength: 15 }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={memberIDError}
                                    helperText={memberIDError ? memberIDErrorText : null}
                                />
                            </div>
                        </div>
                        <div className="form-wrapper mb-0">
                            <Button className='col-sm btn-primary mr-sm-3 w-100' onClick={submitForgotUserIDForm}>SUBMIT</Button>
                            <Button className='col-sm btn-transparent w-100' onClick={() => AppConstants.backToLogin(props)}>CANCEL</Button>
                        </div>
                    </form>
                </div>
                <AlertDialog
                    open={open}
                    setOpen={setOpen}
                    page="forgotUserId"
                    props={props}
                />
            </div>
        </div>
    )
}
