import React from 'react';
import Card from 'react-bootstrap/Card';

import Table from 'react-bootstrap/Table';
import styles from './CareTeam.css';


export default function CareTeam(){
let CareTeamResponse={
    "resourceType": "CareTeam",
    "id": "99999",
    "meta": {
        "profile": [
            "http://hl7.org/fhir/us/core/StructureDefinition/us-core-careteam"
        ]
    },
    "text": {
        "status": "generated",
        "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p><b>Generated Narrative</b></p></div>"
    },
    "identifier": [
        {
            "value": "99999"
        }
    ],
    "status": "active",
    "name": "CareTeam for the treatment of Amy V Shaw",
    "subject": {
        "reference": "Patient/200000400",
        "display": "Amy V. Shaw"
    },
    "period": {
        "start": "2020-06-26",
        "end": "2020-07-27"
    },
    "participant": [
        {
            "role": [
                {
                    "coding": [
                        {
                            "system": "http://nucc.org/provider-taxonomy",
                            "code": "207RC0000X",
                            "display": "Cardiovascular Disease"
                        }
                    ]
                }
            ],
            "member": {
                "reference": "Practitioner/practitioner-1",
                "display": "Ronald Bone, MD"
            }
        },
        {
            "role": [
                {
                    "coding": [
                        {
                            "system": "http://nucc.org/provider-taxonomy",
                            "code": "208D00000X",
                            "display": "General Practice"
                        }
                    ]
                }
            ],
            "member": {
                "reference": "Practitioner/practitioner-2",
                "display": "Kathy Fielding, MD"
            }
        },
        {
            "role": [
                {
                    "coding": [
                        {
                            "system": "http://snomed.info/sct",
                            "code": "116154003",
                            "display": "Patient (person)"
                        }
                    ]
                }
            ],
            "member": {
                "reference": "Patient/example",
                "display": "Amy V. Shaw"
            }
        }
    ],
    "reasonCode": [
        {
            "coding": [
                {
                    "system": "http://snomed.info/sct",
                    "code": "134006",
                    "display": "Decreased hair growth"
                }
            ]
        }
    ],
    "note": [
        {
            "text": "Any notes which is not captured here"
        }
    ]
}
const codestyle={backgroundColor: 'lightgray'};
return (
<div className="container">
<Card className="card-background"> <Card.Body>
<blockquote className="blockquote mb-0">
<h1 className="text-header">Care Team</h1>
<p>The CareTeam includes all the people and organizations who plan to participate in the coordination and delivery of care and is assigned to:

<br></br>•	a single patient, or
<br></br>•	a group (such as a married couple in therapy or a support group), or
<br></br>•	an event, prior to a subject being identified (such as a code blue team or emergency response team)
<br></br>Care Team is not limited to practitioners, but may include other caregivers such as family members, guardians, the patient themself, or others. The Care Team, depending on where used, may include care team members specific to a particular care plan, an episode, an encounter, or may reflect all known team members across these perspectives. An individual's CareTeam can be dynamic over time, such that there can be transience of team members, such as a rehabilitation team.
</p>
</blockquote> </Card.Body>
</Card>
<br/>
<hr className="horizontal-rule"></hr>
<br/>

<Card className="card-background">
<Card.Body>
<h4 className="CareTeam-heading">URL</h4>
<div>
<codeblock className="accesstoken-code"> URL</codeblock>
<span>&nbsp;</span><span>&nbsp;</span>
<codeblock className="accesstoken-code">{process.env.REACT_APP_EDUCATIONAL_MATERIAL_URL}clinicalData/api/R4/CareTeam</codeblock>
</div>
</Card.Body>
<Card.Body>
<h4 className="CareTeam-heading">Headers</h4>
<Table striped bordered hover>
<tbody>

<tr>
<td>Accept</td>
<td>application/json</td>
</tr>
<tr> <td>Authorization</td>
<td>Bearer 'ACCESS_TOKEN'</td>
</tr>
</tbody>
</Table>
</Card.Body>
<p>Note:Third party app will have access token after member has given consent to app (Refer Member consent section)
</p>
<Card.Body>
<h4 className="CareTeam-heading">Sample Request</h4>

<div style={codestyle}>'curl --location 'https://iox.dev.sddss.apps.conduent.com/clincalData/api/R4/CareTeam/1004''\<br/>
                                    '--header 'Authorization: Bearer XYPDpdP2QTyZW3kpqLqSaYclmlU' '\
</div>

<h4 className="Coverage-heading">Sample Response</h4>
<pre style={codestyle}>
<code class="prettyprint">
{JSON.stringify(CareTeamResponse,null,'  ')}

</code>
</pre>
</Card.Body>
</Card>
</div>
);
}