import React,{useEffect} from 'react';

const PayerConsentCpy=()=>{
    useEffect(()=>{
        window.close();
    },[])

    return <></>
}

export default PayerConsentCpy;