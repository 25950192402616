import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Link, Route } from 'react-router-dom';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import { Dropdown } from 'react-bootstrap';

const useStyles = makeStyles(theme => ({

    root: {
        display: 'flex',
        paddingTop: '0px',
    },
    hide: {
        display: 'none'
    }
}));

export default function Header(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [AP, setOpenAPList] = React.useState(false);
    const [mainClass, setMainClass] = React.useState('');


    const handleDrawerOpen = () => {
        setOpen(true);
        setMainClass('');
    };
    const handleDrawerClose = () => {
        setOpen(false);
        setMainClass('full-menu');
    }; 

    return (
        <div className={props.print ? "hide-on-screen header-space" : "hide-on-print"}>
            <header className="top-navbarr">
            <a href="/home">
			<div className="top-logo-bar float-left">
				<a href="/home" title="Conduent Medicaid Suite">
					{/* <img src="logo2.png" alt="Conduent Medicaid Suite" /> */}
					<img src={`${window.location.origin}/logo2.png`} alt="Conduent Medicaid Suite" />
					{/* <img src={`${window.location.origin}/logo2.JPG`} alt="Conduent Medicaid Suite" /> */}
					{/* <img src="logo2.JPG" alt="Conduent Medicaid Suite" /> */}



				</a>
				<span className="logo-text"> </span>
			</div>
            </a>
            {props.print ? null : <div className="float-right user-actions">

                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            className={clsx(classes.menuButton, open && classes.hide, 'menu-close-button')}>
                            <ChevronRightIcon />
                        </IconButton>
                        <Dropdown className="user-action-btn absolute ml-auto nav-user-dropdown">
                            <Dropdown.Toggle variant="secondary" className="float-right">
                                <AccountCircleIcon /> IOX User
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item>
                                    <Link to='#' title="User Profile">
                                        <i className="fa fa-user" aria-hidden="true" />
                                        &nbsp; User Profile
                  </Link>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <Link to='#' title="Change Password">
                                        <i className="fa fa-cog" aria-hidden="true" />
                                        &nbsp; Settings
                  </Link>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <Link to='#' title="Logout">
                                        <i className="fa fa-sign-out" aria-hidden="true" />
                                        &nbsp; Logout</Link></Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Toolbar>

            </div>}
		</header>


            {/* <div className={props.print ? "header-container hide-on-screen" : "header-container hide-on-print"}>
            <a href="/login"><img src="stateLogo.jpg" alt="State Logo" ></img></a>
        </div> */}

        </div>
    )
}
