/* eslint-disable no-useless-escape */
export const NUMBER_ONLY_REGEX = /[a-zA-Z!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~ ]/;
export const PHONE_NUMBER_ONLY_REGEX = /[a-zA-Z!@#$%^&*()_\=[\]{};':"\\|,.<>/?~]/;
export const PHONE_NUMBER_UNMASK_REGEX = /[^a-zA-Z0-9]/g;
export const EMAIL_ADDRESS_REGEX = /^([A-Za-z0-9_\-\.]){1,}\@([A-Za-z0-9_\-\.]){1,}\.([A-Za-z]){2,4}$/;
// export const USER_ID_REGEX = /^[a-zA-Z0-9]+([_\-@.]?[a-zA-Z0-9_\-@.])*$/;
export const USER_ID_REGEX = /^[a-zA-Z0-9_\-@.]*$/;
export const NEW_PASSWORD_REGEX = /(?=.{8,100})((?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[!$#%])|(?=.*[a-z])(?=.*[A-Z])(?=.*[!$#%])).*/;
export const INVALID_SPECIAL_CHARS = /[@^&*`~,.<>;':"\/\[\]\|{}()=_+-]/;
export const SELECT = 'Select';
export const SELECT_QUESTION = 'Select a Question';
export const STATE_LIST = 'STATE_LIST';
export const NEW_PAYER = 'NEW_PAYER';
export const MEMBER_SECURITY_QUESTIONS = 'MEMBER_SECURITY_QUESTIONS';
export const CLAIM_FEEDBACK_QUESTIONS = "CLAIM_FEEDBACK_QUESTIONS";
export const CLAIM_ROLLING_MONTHS = "CLAIM_ROLLING_MONTHS";
export const NUMBER_OF_DAYS_FOR_PASSWORD_EXPIRY = "NUMBER_OF_DAYS_FOR_PASSWORD_EXPIRY";
export const NUMBER_OF_DAYS_FOR_PASSWORD_EXPIRY_REMAINDER = "NUMBER_OF_DAYS_FOR_PASSWORD_EXPIRY_REMAINDER";
export const SUCCESS = 'Success';
export const FAILED = 'Failed';
export const TECH_ERROR = 'Technical Error';
export const LOCKED = 'locked';
export const EMAIL_INVALID = 'Email Address must be in xxx@yyy.zzz format and only allowed characters are (A-Z)(a-z)(0-9)(@._-).';
export const USER_ID_INVALID = 'User ID - Only allowed characters are(A-Z)(a-z)(0-9)(@._-).';
export const SYSTEM_ERROR = 'Due to system error we are unable to process your request at this time. Please try later. If the problem persists please contact Customer Service at (907) 644-6800 or toll-free in Alaska at (800) 770-5650.';
export const LOGOUT_ERROR = 'Due to technical errors the system was unable to log off. Please close the browser.';
export const MEMBER_REGISTRATION_SUPPORT_CONTACT = 'MEMBER_REGISTRATION_SUPPORT_CONTACT';
export const APP_REGISTRATION_SUPPORT_CONTACT = 'APP_REGISTRATION_SUPPORT_CONTACT';
// Method to redirect back to Login
export const backToLogin = (props) => {
    sessionStorage.removeItem('authToken');
    props.history.push({
        pathname: '/login'
    });
}

export const backToMemberRegistration = (props) => {
    props.history.push({
        pathname: '/registration'
    });
}

export const backToHome = (props) => {
    sessionStorage.removeItem('authToken');
    props.history.push({
        pathname: '/'
    });
}

// Method to redirect back to Admin Login
export const backToAdminLogin = (props) => {
    sessionStorage.removeItem('authToken');
    props.history.push({
        pathname: '/adminLogin'
    });
}

// Method to redirect back to Admin Login
export const backToClaims = (props) => {
    props.history.push({
        pathname: '/claims',
        state: {userID: props.location.state && props.location.state.userID ? props.location.state.userID : '',
        loginTime: props.location.state && props.location.state.loginTime ? props.location.state.loginTime : '' ,
        userName: props.location.state && props.location.state.userName ? props.location.state.userName : '' }
    });
}

// Method to handle API Errors
export const handleAPIErrors = (apiResponseData, errorMessagesArray, TECHNICAL_ERROR) => {
    if (apiResponseData) {
        if (apiResponseData.systemFailure || (apiResponseData.status && (apiResponseData.status === 404 || 
            apiResponseData.status === TECH_ERROR))){
            errorMessagesArray.push(TECHNICAL_ERROR ? TECHNICAL_ERROR : SYSTEM_ERROR);
        } else if (apiResponseData.status && apiResponseData.status === FAILED) {
            if (apiResponseData.message) {
                errorMessagesArray.push(apiResponseData.message);
            } else {
                errorMessagesArray.push(SYSTEM_ERROR);
            }
        } else {
            errorMessagesArray = [];
        }
    }
    return errorMessagesArray;
}

export const jsonHelper = {
    jsonToStringify: (j) => {
        try {
            return JSON.stringify(j);
        }
        catch {
            console.error("Expected JSON object")
        }
    },

    stringToJson: (j) => {
        try {
            return JSON.parse(j);
        }
        catch {
            console.error("Expected Object as String")
        }
    }
}