import * as actionTypes from './RegistrationActionTypes';
import * as serviceEndPoint from '../../../../SharedModule/Service/service';
import axios from 'axios';

export const ValidateRegistration = (validateRegistration) => {
  return ({
    type: actionTypes.VALIDATE_REGISTRATION,
    validateRegistration: validateRegistration
  });
};

export const appSearchDispatch = (payload) => {
  return ({
    type: actionTypes.APP_SEARCH,
    payload: payload
  });
};

export const dispatchAppDetailsAction = (response) => ({
  type: actionTypes.APP_SEARCH_VIEW,
  appSearchData: response
});

export const setDownloadFile = (data) => {
  return {
    type: actionTypes.DOWNLOAD_FILE_ACTION,
    downloadeFile: data
  };
};
export const downloadFileResetAction = (data) => {
  return {
    type: actionTypes.DOWNLOAD_RESET,
    downloadeFile: null
  };
};

export const dispatchAppStatusUpdateAction = (response) => ({
  type: actionTypes.APP_STATUS_UPDATE,
  appDetailsData: response
});

export const resetAppStatus = () => ({
  type: actionTypes.RESET_APP_STATUS,
  appDetailsData: null
});



export const ValidateRegistrationActions = (values, files) => dispatch => {
  const formInfo = new FormData();
  if (files !== null) {
   let i;
   for(i=0; i<files.length; i++){
    formInfo.append('file', files[i]);
  }
}
  formInfo.append('appRegistrationVO', JSON.stringify(values));
    return axios.post(`${serviceEndPoint.APP_REGISTRATTION}`, formInfo)
    
    .then(response => {
      dispatch(ValidateRegistration(response.data));
  })
  .catch(error => {
    dispatch(ValidateRegistration({ systemFailure: true }));
}) 
};


export const AppSearch = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.APP_SEARCH + '?status='+value}`)
      .then(response => {
        dispatch(appSearchDispatch(response.data));
      })
      .catch(error => {
        dispatch(appSearchDispatch({ systemFailure: true }));
      });
  };
};
export const unapprovedAppDispatch = (payload) => {
  return ({
    type: actionTypes.UNAPPROVED_APPS,
    payload: payload
  });
};
export const getUnapprovedApps = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.APP_SEARCH + '?status='+value}`)
      .then(response => {
        dispatch(unapprovedAppDispatch(response.data));
      })
      .catch(error => {
        dispatch(unapprovedAppDispatch({ systemFailure: true }));
      });
  };
};

export const appDetailsAction = values => dispatch => {   
  return axios.get(serviceEndPoint.APP_SEARCH_VIEW + '?id='+values)
      .then(response => {
          dispatch(dispatchAppDetailsAction(response.data));
      })
      .catch(error => {
          dispatch(dispatchAppDetailsAction({ systemFailue: true }));
      })    
}

export const appPrivacyAction = values => dispatch => {
  return axios.get(serviceEndPoint.APP_PRIVACY_VIEW + '?id='+values)
      .then(response => {
          dispatch(dispatchAppDetailsAction(response.data));
      })
      .catch(error => {
          dispatch(dispatchAppDetailsAction({ systemFailue: true }));
      })
}

export const downloadFileAction = (folder, fileName) => {  
  return dispatch => {
    return axios.get(`${serviceEndPoint.DOWNLOAD_ACTION_LINK}/${folder}/${fileName}`,{responseType: "blob"})
      .then(response => {
        if (response.data === undefined) {
          dispatch(setDownloadFile({ systemFailue: true }));
        } else {
          var myURL = window.URL || window.webkitURL;
          const objectUrl = myURL.createObjectURL(new Blob([response.data]));
          dispatch(setDownloadFile(objectUrl));
        }
      })
      .catch(error => {
        dispatch(setDownloadFile({ systemFailue: true }));
      });
  };
};

export const appStatusUpdateAction = values => dispatch => {   
  return axios.post(serviceEndPoint.APP_STATUS_UPDATE,values)
      .then(response => {
          dispatch(dispatchAppStatusUpdateAction(response.data));
      })
      .catch(error => {
          dispatch(dispatchAppStatusUpdateAction({ systemFailue: true }));
      })    
}


