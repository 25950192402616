export const REQUIRED = ' is required.';
export const INVALID = ' is invalid.';
export const EMAIL_ADDRESS = 'Email Address';
export const MEMBER_ID = 'Medicaid ID';
export const USER_ID = 'User ID';
export const ANSWER = 'Answer';
export const CURRENT_PASSWORD = 'Current Password';
export const NEW_PASSWORD = 'New Password';
export const CONFIRM_NEW_PASSWORD = 'Confirm New Password';
export const CURRENT_PASSWORD_INVALID = 'The Current PINpassword you submitted is invalid. Please try again.';
export const CURRENT_PASSWORD_SUBMIT_INVALID = ' attempted to change the password with an invalid current password.'
export const NEW_PASSWORD_ERROR = 'New Password must be atleast 8 characters.';
export const NEW_PASSWORD_INVALID = 'The new passwords do not conform to the password policy listed on this page.'
export const CONFIRM_PASSWORD_ERROR = 'Your submitted New password and the Confirm New password do not match. Please try again.';
export const CURRENT_NEW_PASSWORD_ERROR = 'Your submitted Current password and your New password must not be the same. Please try again.';
export const CHANGE_PASSWORD_SUCCESS = 'Your password has been changed successfully.';
export const PASSWORD_RECOVERY_INVALID_CRED_ERROR = 'Your login credentials are invalid. Please try again';
export const PASSWORD_RECOVERY_INVALID_USER_ERROR = 'The User ID entered was not found. Verify your entry or click Forgot User ID on the Login screen to have it sent to the email address on file.';
export const PASSWORD_RECOVERY_TECHNICAL_ERROR = 'Due to technical problems, the system was unable to complete the request. Please contact your Service Representative at (907) 644-6800 or toll-free in Alaska at (800) 770-5650.';
export const CHANGE_PASSWORD_TECHNICAL_ERROR = 'Due to technical problems, the system was unable to change your password. Please try again later or contact Customer Service at (907) 644-6800 or toll-free in Alaska at (800) 770-5650.';
export const FORGOT_USERID_TECHNICAL_ERROR = 'Due to technical problems, the system was unable to complete the request. Please contact your Service Representative at (907) 644-6800 or toll-free in Alaska at (800) 770-5650.';




