import axios from "axios";
import { VIEW_CONSENT, VIEW_CONSENT_FAILED } from "./ConsentsManagementActionTypes";
import { MEMBER_CONSENT_DETAILS } from "../../../../SharedModule/Service/service";
/*
export const viewConsent = (userId) => ({
    type: VIEW_CONSENT,
    userId
});
*/

export const viewConsent = (userId) => {
    return (dispatch) => {
        return axios.post(`${MEMBER_CONSENT_DETAILS}`, {
            "userId": userId
        })
            .then(response => {
                const fetchedConsents = response.data;
                dispatch({
                    type: VIEW_CONSENT,
                    payload: fetchedConsents,
                });
            })
            .catch(error => {
                console.error(error);
                dispatch({
                    type: VIEW_CONSENT_FAILED,
                    payload: error,
                });
            });
    };
};