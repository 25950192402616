import React from 'react';
import { Chart, Series, CommonSeriesSettings, Label, Format, Legend, Export, ValueAxis, Size } from 'devextreme-react/chart';

export default function GraphView(props) {

  const { feedbackData, printLayout } = props;

  return (
    <Chart className="chart-container"
      dataSource={feedbackData}
    >
      <Size
          height={400}
          width={printLayout ? 900 : 995}
      />
      <CommonSeriesSettings
        argumentField="feedback"
        type="bar"
        hoverMode="allArgumentPoints"
        selectionMode="allArgumentPoints"
      >
        <Label visible={true}>
          <Format type="fixedPoint" precision={0} />
        </Label>
      </CommonSeriesSettings>
      <Series
        argumentField="feedback"
        color="rgb(0,131,123)"
        valueField="yesCount"
        name="Yes"
      />
      <Series
        color="rgb(0,71,186)"
        valueField="noCount"
        name="No"
      />
      <Series
        color="rgb(102,58,182)"
        valueField="otherCount"
        name="Other"
      />
      <ValueAxis
        position="left"
        // tickInterval={2}
        // max={50}
        showZero={true}
        valueMarginsEnabled={true} />
      <Legend verticalAlignment="bottom" horizontalAlignment="center"></Legend>
      <Export enabled={false} />
    </Chart>
  );
}
