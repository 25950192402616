import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

export default function Captcha(props) {

  const onChange = (value) => {
    props.handleChangeCaptcha(value ? value : ''); 
 }

 return (
   <>
  <label htmlFor="g-recaptcha-response" hidden aria-hidden="true">Captcha</label>
   <ReCAPTCHA className="" id="g-recaptcha"
     ref={props.captcha}
     sitekey={process.env.REACT_APP_SITE_KEY}
     onChange={onChange}
     theme="light"
   />
   </>
  )
 };

