import * as actionTypes from './ResetProfileActionTypes';
import * as serviceEndPoint from '../../../../SharedModule/Service/service';
import axios from 'axios';

export const ForgotUserId = (forgotUserId) => {
  return ({
    type: actionTypes.FORGOT_USERID,
    forgotUserId: forgotUserId
  });
};

export const ForgotUserIdActions = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.FORGOT_USERID}`, value)
      .then(response => {
        dispatch(ForgotUserId(response.data));
      })
      .catch(error => {
        dispatch(ForgotUserId({ systemFailure: true }));
      });
  };
};
export const GetSecurityQs = (getSecurityQs) => {
  return ({
    type: actionTypes.GET_SECURITY_QS,
    getSecurityQs: getSecurityQs
  });
};
export const GetSecurityQsActions = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.GET_SECURITY_QS}`, value)
      .then(response => {
        dispatch(GetSecurityQs(response.data));
      })
      .catch(error => {
        dispatch(GetSecurityQs({ systemFailure: true }));
      });
  };
};

export const PasswordRecovery = (passwordRecovery) => {
  return ({
    type: actionTypes.PASSWORD_RECOVERY,
    passwordRecovery: passwordRecovery
  });
};
export const PasswordRecoveryActions = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.PASSWORD_RECOVERY}`, value)
      .then(response => {
        dispatch(PasswordRecovery(response.data));
      })
      .catch(error => {
        dispatch(PasswordRecovery({ systemFailure: true }));
      });
  };
};
export const ChangePassword = (changePassword) => {
  return ({
    type: actionTypes.CHANGE_PASSWORD,
    changePassword: changePassword
  });
};
export const ChangePasswordActions = (value) => {
  const token = sessionStorage.getItem('authToken');
  return dispatch => {
    return axios.post(`${serviceEndPoint.CHANGE_PASSWORD}`, value, {
      headers: {
        'Authorization': `bearer ${token}`
      },
    })
      .then(response => {
        dispatch(ChangePassword(response.data));
      })
      .catch(error => {
        dispatch(ChangePassword({ systemFailure: true }));
      });
  };
};

export const resetData = (key) => {
  return {
    type: actionTypes.RESET_DATA,
    key: key
  };
};