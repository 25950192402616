/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { lighten, makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { withRouter } from "react-router";
import Moment from "react-moment";
import numeral from "numeral";
import EnhancedTableHead from "./EnhancedTableHead";
import TablePaginationActions from "./TablePagination";
import { desc, stableSort, getSorting } from "./TableSort";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Tooltip from "@material-ui/core/Tooltip";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import ReplayIcon from "@material-ui/icons/Replay";
import Spinner from "../Spinner/Spinner";
import Checkbox from "@material-ui/core/Checkbox";
import { Button } from "react-bootstrap";
import axios from "axios";
import { REVOKE_MEMBER_CONSENT } from "../Service/service";

function TableComponent(props) {
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState(props.defaultSortColumn);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    // const printLayout = useSelector((state) => state.appDropDowns.printLayout);

    useEffect(() => {
        setPage(0);
    }, [props.tableData]);

    useEffect(() => {
        if (props.sortOrder === "asc") {
            setOrder("asc");
        } else {
            setOrder("desc");
        }
    }, [props.sortOrder]);

    useEffect(() => {
        if (props.rowsPerPage) {
            setRowsPerPage(props.rowsPerPage)
        }
    }, [props.rowsPerPage]);

    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = props.tableData.map((n) => {
                return n;
            });
            props.setSelected(newSelecteds);
            return;
        }
        props.setSelected([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleClick = (event, row) => {
        event.stopPropagation();
        const selectedIndex = props.selected.findIndex((i) => i.index === row.index);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(props.selected, row);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(props.selected.slice(1));
        } else if (selectedIndex === props.selected.length - 1) {
            newSelected = newSelected.concat(props.selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                props.selected.slice(0, selectedIndex),
                props.selected.slice(selectedIndex + 1)
            );
        }

        props.setSelected(newSelected);
    };

    const ajaxify = (url, payload, responseHandler) => {
        axios.post(url, payload)
            .then(response => {
                responseHandler(response);
            })
            .catch(error => {
                alert(error);
            });
    }

    const formatTableCell = (row, name, index, labelId) => {
        let toolTipTitle;
        if (name.enableHyperLink) {
            let hyperLinkText = name.id.includes("-")
                ? row[name.id.split("-")[0]] + "-" + row[name.id.split("-")[1]]
                : row[name.id];
            if (name.isToolTip) {
                toolTipTitle = name.toolTip.includes("-")
                    ? `${row[name.toolTip.split("-")[0]]}-${row[name.toolTip.split("-")[1]]
                    }`
                    : row[name.toolTip];
            }
            return (
                <TableCell
                    style={{
                        minWidth: name.width,
                        maxWidth: name.width,
                        width: name.width,
                        fontSize: name.fontSize,
                    }}
                    component="th"
                    id={labelId}
                    title={name.isToolTip ? "" : row[name.id]}
                    scope="row"
                    padding="default"
                    className="has-link"
                    onClick={
                        props.onTableCellClick
                            ? props.onTableCellClick(name.id, index)
                            : null
                    }
                >
                    {name.isToolTip && toolTipTitle && (
                        <Tooltip title={toolTipTitle}>
                            <span>
                                <a url={name.id}>{hyperLinkText}</a>
                            </span>
                        </Tooltip>
                    )}

                    {!(name.isToolTip && toolTipTitle) && !name.isHref ? (
                        <a url={name.id}>{hyperLinkText}</a>
                    ) : (
                            <a target='_blank' href={hyperLinkText} title=''>{name.id}</a>
                        )}

                </TableCell>
            );
        }
        if (name.dateHyperLink) {
            return (
                <TableCell
                    style={{
                        minWidth: name.width,
                        maxWidth: name.width,
                        width: name.width,
                        fontSize: name.fontSize,
                    }}
                    component="th"
                    id={labelId}
                    scope="row"
                    padding="default"
                    className="has-link"
                >
                    {name.id.includes("-") ? (
                        <a>
                            <Moment format="MM/DD/YYYY" date={row[name.id.split("-")[0]]} /> -{" "}
                            <Moment format="MM/DD/YYYY" date={row[name.id.split("-")[1]]} />
                        </a>
                    ) : (
                            <a>
                                <Moment format="MM/DD/YYYY" date={row[name.id]} />
                            </a>
                        )}
                </TableCell>
            );
        }

        if (name.isBoolean) {
            return (
                <TableCell>
                    {row[name.id] === false ||
                        row[name.id] === "No" ||
                        row[name.id] === undefined
                        ? "N"
                        : "Y"}
                </TableCell>
            );
        }

        if (name.isDate && row[name.id] !== null && row[name.id] !== "") {
            return (
                <TableCell
                    style={{
                        minWidth: name.width,
                        maxWidth: name.width,
                        width: name.width,
                        fontSize: name.fontSize,
                    }}
                >
                    {name.id && name.id !== "" ? (
                        <>
                            {name.id.includes("-") ? (
                                <>
                                    <Moment
                                        format="MM/DD/YYYY"
                                        date={row[name.id.split("-")[0]]}
                                    />{" "}
                  -{" "}
                                    <Moment
                                        format="MM/DD/YYYY"
                                        date={row[name.id.split("-")[1]]}
                                    />
                                </>
                            ) : (
                                    <Moment format="MM/DD/YYYY" date={row[name.id]} />
                                )}
                        </>
                    ) : (
                            <></>
                        )}
                </TableCell>
            );
        }
        if (name.isBalance) {
            return (
                <TableCell
                    style={{
                        minWidth: name.width,
                        maxWidth: name.width,
                        width: name.width,
                        fontSize: name.fontSize,
                    }}
                >
                    {name.id.includes("-") ? (
                        <>
                            ${numeral(row[name.id.split("-")[0]]).format("0,0.00")} - $
                            {numeral(row[name.id.split("-")[1]]).format("0,0.00")}
                        </>
                    ) : (
                            <>${numeral(row[name.id]).format("0,0.00")}</>
                        )}
                </TableCell>
            );
        }
        if (name.isToolTip && !name.enableHyperLink) {
            let title = name.toolTip.includes("-")
                ? `${row[name.toolTip.split("-")[0]]}-${row[name.toolTip.split("-")[1]]
                }`
                : row[name.toolTip];
            return (
                <TableCell
                    style={{
                        minWidth: name.width,
                        maxWidth: name.width,
                        width: name.width,
                        fontSize: name.fontSize,
                    }}
                >
                    {name.id.includes("-") ? (
                        <Tooltip title={title}>
                            <span>
                                {row[name.id.split("-")[0]] || row[name.id.split("-")[1]]
                                    ? `${row[name.id.split("-")[0]]}-${row[name.id.split("-")[1]]
                                    }`
                                    : null}
                            </span>
                        </Tooltip>
                    ) : (
                            <Tooltip title={title}>
                                <span>{row[name.id]}</span>
                            </Tooltip>
                        )}
                </TableCell>
            );
        }
        if (name.isRadio) {
            return (
                <TableCell
                    style={{
                        minWidth: name.width,
                        maxWidth: name.width,
                        width: name.width,
                        fontSize: name.fontSize,
                    }}
                >
                    <RadioGroup
                        InputLabelProps={{
                            shrink: true,
                            required: true,
                        }}
                    >
                        <FormControlLabel
                            value={row[name.id]}
                            checked={row[name.id] === props.tableRowRadioSelVal}
                            disabled={name.isRadioEnabled ? false : true}
                            control={<Radio color="primary" />}
                        />
                    </RadioGroup>
                </TableCell>
            );
        }
        if (name.isIconShuffle) {
            return (
                <TableCell
                    style={{
                        minWidth: name.width,
                        maxWidth: name.width,
                        width: name.width,
                        fontSize: name.fontSize,
                    }}
                >
                    <div className="flex-grid-1" style={{ width: "80px" }}>
                        <span
                            className="inline-title"
                            style={{ "padding-right": "0px", "min-width": "50px" }}
                        >
                            {" "}
                            {row[name.id]}{" "}
                        </span>
                        <span>
                            <Button
                                title="Add P_SYS_ID"
                                variant="outlined"
                                color="primary"
                                onClick={props.handleShuffle(row)}
                                className="btn btn-ic-only-sm btn-icon-only-shuffle"
                            ></Button>
                        </span>
                    </div>
                </TableCell>
            );
        }
        if (name.isIcon) {
            return (
                <TableCell
                    style={{
                        minWidth: name.width,
                        maxWidth: name.width,
                        width: name.width,
                        fontSize: name.fontSize,
                    }}
                >
                    {row[name.id] && row[name.id] !== "false" ? (
                        <CheckIcon />
                    ) : name.id !== "voidIndicator" ? (
                        <span className="Mui-red">
                            <CloseIcon />
                        </span>
                    ) : null}
                </TableCell>
            );
        }
        if (name.action) {
            return (
                <TableCell
                    style={{
                        minWidth: name.width,
                        maxWidth: name.width,
                        width: name.width,
                        fontSize: name.fontSize,
                    }}
                >
                    <ReplayIcon />
                </TableCell>
            );
        }
        if (name.editaction) {
            return (
                <TableCell
                    style={{
                        minWidth: name.width,
                        maxWidth: name.width,
                        width: name.width,
                        fontSize: name.fontSize,
                    }}
                >
                    <div className="flex-grid-1" style={{ width: "80px" }}>
                        <span
                            className="inline-title"
                            style={{ "padding-right": "0px", "min-width": "50px" }}
                        >
                            {" "}
                            {row[name.id]}{" "}
                        </span>
                        <span
                            title="Edit"
                            type="button"
                            className="edit-icon-1"
                            onClick={props.editRow(row)}
                        >
                            <i className="fa fa-pencil"></i>
                        </span>
                    </div>
                </TableCell>
            );
        }
        if (name.enableAjax) {
            return (
                <TableCell
                    style={{
                        minWidth: name.width,
                        maxWidth: name.width,
                        width: name.width,
                        fontSize: name.fontSize,
                    }}
                >
                    <div className="" style={{ width: "100px" }}>
                        <div className="float-left" style={{ position: "relative", top: "17px" }}>
                            <i className="fa fa-check"></i>
                        </div>
                        <div className="float-left">
                            <Button
                                title='Revoke'
                                onClick={(event) => {
                                    ajaxify(REVOKE_MEMBER_CONSENT, row[name.id], (response) => {
                                        if (response.data.status === 'Success') {
                                            window.location.reload(false);
                                        }
                                    })
                                }
                                }
                                variant="primary"
                                color="primary"
                                className="inline-title btn btn-ic-only-sm btn-icon-only-shuffle"
                            >
                                Revoke
              </Button>
                        </div>
                    </div>
                </TableCell>
            );
        }
        if (name.auditUser) {
            let userInfo = row[name.id] ? row[name.id].toString().split(":") : "";
            return (
                <TableCell
                    style={{
                        minWidth: name.width,
                        maxWidth: name.width,
                        width: name.width,
                        fontSize: name.fontSize,
                    }}
                >
                    <>
                        <div className="d-flex">
                            <div>
                                <span className="nameInitialBadge">
                                    {userInfo && userInfo[0] ? userInfo[0][0].toUpperCase() : ""}
                                    {userInfo && userInfo[1] ? userInfo[1][0].toUpperCase() : ""}
                                </span>
                            </div>
                            <div>
                                <span className="text-uppercase d-block mt-1 font-weight-semi-bold">
                                    {" "}
                                    {userInfo[0] ? userInfo[0] : ""}
                                    {userInfo[0] && userInfo[1]
                                        ? userInfo[0].includes(",")
                                            ? " "
                                            : userInfo[1].includes(",")
                                                ? " "
                                                : ", "
                                        : ""}
                                    {userInfo[1] ? userInfo[1] : ""}
                                    {userInfo[0] || userInfo[1] ? "-" : ""}
                                </span>
                                <span className="grayTitle d-block txt-caps">
                                    {userInfo[0] ? userInfo[0] : ""}
                                    {userInfo[0] && userInfo[1]
                                        ? userInfo[0].includes(",")
                                            ? " "
                                            : userInfo[1].includes(",")
                                                ? " "
                                                : ", "
                                        : ""}
                                    {userInfo[1] ? userInfo[1] : ""}
                                </span>
                            </div>
                        </div>
                    </>
                </TableCell>
            );
        }
        if (name.auditTimestamp) {
            return (
                <TableCell
                    style={{
                        minWidth: name.width,
                        maxWidth: name.width,
                        width: name.width,
                        fontSize: name.fontSize,
                    }}
                >
                    <>
                        {" "}
                        <Moment format="MM/DD/YYYY">{row[name.id]}</Moment> |{" "}
                        <span className="grayTitle">
                            {" "}
                            <Moment format="hh:mm:ss">{row[name.id]}</Moment>
                        </span>
                    </>
                </TableCell>
            );
        }
        if (name.auditRowUser) {
            let userInfo = row[name.id] ? row[name.id].toString().split(":") : "";
            return (
                <TableCell
                    style={{
                        minWidth: name.width,
                        maxWidth: name.width,
                        width: name.width,
                        fontSize: name.fontSize,
                    }}
                >
                    <>
                        <div>
                            <span className="text-uppercase font-weight-semi-bold d-block mt-1">
                                {userInfo[0] ? userInfo[0] : ""}
                                {userInfo[0] && userInfo[1]
                                    ? userInfo[0].includes(",")
                                        ? " "
                                        : userInfo[1].includes(",")
                                            ? " "
                                            : ", "
                                    : ""}
                                {userInfo[1] ? userInfo[1] : ""}
                                {userInfo[0] || userInfo[1] ? "-" : ""}
                            </span>
                            <span className="grayTitle d-block txt-caps">
                                {userInfo[0] ? userInfo[0] : ""}
                                {userInfo[0] && userInfo[1]
                                    ? userInfo[0].includes(",")
                                        ? " "
                                        : userInfo[1].includes(",")
                                            ? " "
                                            : ", "
                                    : ""}
                                {userInfo[1] ? userInfo[1] : ""}
                            </span>
                        </div>
                    </>
                </TableCell>
            );
        }
        if (name.auditRowTimestamp) {
            return (
                <TableCell
                    style={{
                        minWidth: name.width,
                        maxWidth: name.width,
                        width: name.width,
                        fontSize: name.fontSize,
                    }}
                >
                    <>
                        <Moment format="MM/DD/YYYY">{row[name.id]}</Moment>{" "}
                        <span className="d-block">
                            {" "}
                            <Moment format="hh:mm:ss">{row[name.id]}</Moment>
                        </span>
                    </>
                </TableCell>
            );
        }
        return (
            <TableCell
                style={{
                    minWidth: name.width,
                    maxWidth: name.width,
                    width: name.width,
                    fontSize: name.fontSize,
                }}
            >
                {name.id && name.id.includes("-") ? (
                    <>
                        {row[name.id.split("-")[0]] || row[name.id.split("-")[1]]
                            ? `${row[name.id.split("-")[0]]}-${row[name.id.split("-")[1]]}`
                            : null}
                    </>
                ) : (
                        <>{row[name.id]}</>
                    )}
            </TableCell>
        );
    };
    const isSelected = (row) => {
        if (props.multiDelete) {
            return props.selected.findIndex((i) => i.index === row.index) !== -1;
        } else {
            return false;
        }
    };

    const emptyRows =
        rowsPerPage -
        Math.min(rowsPerPage, props.tableData.length - page * rowsPerPage);
    const { tableRows } = props;
    return (
        <div className="pos-relative">
            {props.spinnerLoader ? <Spinner /> : null}
            <div className="table-wrapper">
                <Table
                    className="customDataTable with-link"
                    aria-label="enhanced table"
                >
                    <EnhancedTableHead
                        numSelected={props.multiDelete ? props.selected.length : 0}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={props.tableData.length}
                        headCells={props.headCells}
                        multiDelete={props.multiDelete}
                    />
                    <TableBody>
                        {stableSort(props.tableData, getSorting(order, orderBy))
                            .slice(
                                ...([page * rowsPerPage, page * rowsPerPage + rowsPerPage])
                            )
                            .map((row, index) => {
                                const isItemSelected = isSelected(row);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        onClick={
                                            props.onTableRowClick
                                                ? props.onTableRowClick(row, index)
                                                : null
                                        }
                                        role="checkbox"
                                        aria-checked={props.multiDelete ? isItemSelected : false}
                                        tabIndex={-1}
                                        key={row.id}
                                        selected={props.multiDelete ? isItemSelected : false}
                                    >
                                        {props.multiDelete ? (
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={props.multiDelete ? isItemSelected : false}
                                                    onClick={(event) => handleClick(event, row)}
                                                    inputProps={{ "aria-label": "select all desserts", "id": "select all desserts" }}
                                                />
                                                <span className="hide-elm"> Select All desserts </span>
                                            </TableCell>
                                        ) : null}
                                        {props.headCells.map((name, index) =>
                                            formatTableCell(row, name, index, labelId)
                                        )}
                                    </TableRow>
                                );
                            })}
                        {!props.tableData.length ? (
                            <TableRow>
                                <TableCell
                                    colSpan={props.headCells.length + (props.multiDelete ? 1 : 0)}
                                    align="center"
                                >
                                    <caption> No Data </caption>
                                </TableCell>
                            </TableRow>
                        ) : null}
                    </TableBody>
                    <TableFooter className="hide-on-print">
                        <TableRow>
                            <TableCell className="table-pagination">
                                Page: <span>{page + 1}</span>
                            </TableCell>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                colSpan={props.headCells.length}
                                count={props.tableData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: { "aria-label": "rows per page" },
                                    native: true,
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </div>
        </div>
    );
}
export default withRouter(TableComponent);
