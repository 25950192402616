/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import { Button } from 'react-bootstrap';
import { Dropdown } from 'react-bootstrap';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { makeStyles } from '@material-ui/core/styles';
import * as AppConstants from '../../../SharedModule/Utils/AppConstants';
import * as ResetProfileConstants from './ResetProfileConstants';
import ErrorMessages from '../../../SharedModule/MessageHandlers/ErrorMessages';
import Spinner from '../../../SharedModule/Spinner/Spinner';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { ChangePasswordActions, resetData } from '../Store/Actions/ResetProfileActions';
import { EventsLogActions, LogoutActions, resetLogoutData } from '../../../SharedModule/Store/Actions/AppActions';
import AlertDialog from '../../../SharedModule/Layout/AlertDialog';
import IdleTimer from '../../../SharedModule/SessionManagement/IdleTimer';

function ChangePasswordForm(props) {
    const drawerWidth = 240;
    const useStyles = makeStyles(theme => ({
        wrapper: {
            display: 'flex',
            height: '100%',
            padding: '20px 0 0 0'
        },
        drawer: {
            boxSizing: 'border-box',
            width: drawerWidth,
            flexShrink: 0
        },
        drawerPaper: {
            position: 'relative',
            boxSizing: 'border-box',
            width: drawerWidth
        },
        drawerPaperShrink: {
            position: 'relative',
            boxSizing: 'border-box',
            width: 100
        },
        loginStatus: {
            display: 'flex',
            alignItems: 'center',
            float: 'right'
        },
        dropDownItem: {
            padding: '10px 15px',
            color: '#D5D5D5'
        },
        nestedFolder: {
            paddingRight: '16px!important'
        }
    }));

    // Page Constants
    const classes = useStyles();
    const dispatch = useDispatch();
    const changePwdRef = useRef(null);
    const currentPwdRef = useRef(null);
    const newPwdRef = useRef(null);
    const confirmPwdRef = useRef(null);
    const userID = props.location.state && props.location.state.userID ? props.location.state.userID : '';
    const source = props.location.state && props.location.state.source ? props.location.state.source : '';
    const isAuthorisedUser = sessionStorage.getItem('authToken');

    let errorMessagesArray = [];
    const [showCPPassword, setShowCPPassword] = React.useState(false);
    const [showNPPassword, setShowNPPassword] = React.useState(false);
    const [showCNPPassword, setShowCNPPassword] = React.useState(false);
    const [errorMessages, setErrorMessages] = React.useState([]);
    const [spinnerLoader, setSpinnerLoader] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [logout, setLogout] = React.useState(false);
    const [{
        currentPasswordError,
        newPasswordError,
        confirmNewPasswordError
    }, setShowError] = React.useState(false);

    const [{
        currentPasswordErrorText,
        newPasswordErrorText,
        confirmNewPasswordErrorText
    }, setShowErrorText] = React.useState('');

    // Field bindings
    const [values, setValues] = React.useState({
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: ''
    });

    // Dispatch Actions
    const changePasswordDispatch = (changePasswordCriteria) => dispatch(ChangePasswordActions(changePasswordCriteria));
    const logoutDispatch = (logoutCriteria) => dispatch(LogoutActions(logoutCriteria));

    const changePasswordData = useSelector(state => state.resetProfileState.changePassword);
    const logoutData = useSelector(state => state.sharedState.logout);

        // Method to handle Logout
        const handleLogout = () => {
            setLogout(true);
            dispatch(resetData('changePassword'));
            const logoutRequest = {
                logoutType: "logout",
                userId: userID
            }
            logoutDispatch(logoutRequest);
        }
    // Method to make API calls on Load
    useEffect(() => {
        if (!isAuthorisedUser) {
            AppConstants.backToLogin(props);
        } else {
            dispatch(resetData('changePassword'));
        }
    }, []);

    useEffect(() => {
        if (changePasswordData) {
            setSpinnerLoader(false);
            errorMessagesArray = [];
            errorMessagesArray = AppConstants.handleAPIErrors(changePasswordData, errorMessagesArray, ResetProfileConstants.CHANGE_PASSWORD_TECHNICAL_ERROR);
            if (errorMessagesArray && errorMessagesArray.length > 0) {
                if (errorMessagesArray[0].indexOf(AppConstants.LOCKED) > -1) {
                    setTimeout(handleLogout, 5000);
                }
                setErrorMessages(errorMessagesArray);
                changePwdRef.current.scrollIntoView({
                    behavior: "smooth"
                });
            } else {
                setOpen(true);
            }
            dispatch(resetData('changePassword'));
        }
    }, [changePasswordData]);

    useEffect(() => {
        if (logoutData) {
            errorMessagesArray = [];
            errorMessagesArray = AppConstants.handleAPIErrors(logoutData, errorMessagesArray, AppConstants.LOGOUT_ERROR);
            if (errorMessagesArray && errorMessagesArray.length > 0) {
                if (errorMessagesArray[0].indexOf(AppConstants.LOGOUT_ERROR) > -1) {
                    const eventLog = {
                        memberId: "",
                        eventSource: logout ? "Logout" : "Session Logout",
                        eventType: "Failed",
                        eventDescription: logout ? 'The system was unable to log off. Please terminate the browser window.' : `${userID} has failed to logout from the AK MMIS system ${new Date()}`
                    }
                    setLogout(false);
                    dispatch(EventsLogActions(eventLog));
                }
                setErrorMessages(errorMessagesArray);
                dispatch(resetLogoutData('logout'));
            } else {
                dispatch(resetLogoutData('logout'));
                AppConstants.backToLogin(props);
            }
        }
    }, [logoutData]);

    // Method to set field values on change
    const handleChanges = name => event => {
        setValues({ ...values, [name]: event.target.value });
    };

    // Moving cursor to the end of the text
    useEffect(() => {
        if (currentPwdRef && currentPwdRef.current) {
            currentPwdRef.current.selectionStart = currentPwdRef.current.value.length;
            currentPwdRef.current.selectionEnd = currentPwdRef.current.value.length;
        }
    }, [showCPPassword]);

    useEffect(() => {
        if (newPwdRef && newPwdRef.current) {
            newPwdRef.current.selectionStart = newPwdRef.current.value.length;
            newPwdRef.current.selectionEnd = newPwdRef.current.value.length;
        }
    }, [showNPPassword]);

    useEffect(() => {
        if (confirmPwdRef && confirmPwdRef.current) {
            confirmPwdRef.current.selectionStart = confirmPwdRef.current.value.length;
            confirmPwdRef.current.selectionEnd = confirmPwdRef.current.value.length;
        }
    }, [showCNPPassword]);

    // Method to handle Password visibility
    const handleClickShowPassword = (field) => {
        if (field === ResetProfileConstants.CURRENT_PASSWORD) {
            setShowCPPassword(!showCPPassword);
            currentPwdRef.current.focus();
        } else if (field === ResetProfileConstants.NEW_PASSWORD) {
            setShowNPPassword(!showNPPassword);
            newPwdRef.current.focus();
        } else if (field === ResetProfileConstants.CONFIRM_NEW_PASSWORD) {
            setShowCNPPassword(!showCNPPassword);
            confirmPwdRef.current.focus();
        }
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    // Method to validate User Id Form
    const validateChangePasswordForm = () => {
        errorMessagesArray = [];
        setErrorMessages([]);
        var currentPasswordError; var newPasswordError; var confirmNewPasswordError = false;
        var currentPasswordErrorText; var newPasswordErrorText; var confirmNewPasswordErrorText = '';

        if (values.currentPassword === '' || (values.currentPassword && values.currentPassword.trim() === '')) {
            currentPasswordError = true;
            currentPasswordErrorText = ResetProfileConstants.CURRENT_PASSWORD + ResetProfileConstants.REQUIRED;
            errorMessagesArray.push(currentPasswordErrorText);
        }
        if (values.newPassword === '' || (values.newPassword && values.newPassword.trim() === '')) {
            newPasswordError = true;
            newPasswordErrorText = ResetProfileConstants.NEW_PASSWORD + ResetProfileConstants.REQUIRED;
            errorMessagesArray.push(newPasswordErrorText);
        }
        if (!newPasswordError && values.newPassword && values.newPassword.length < 8) {
            newPasswordError = true;
            newPasswordErrorText = ResetProfileConstants.NEW_PASSWORD_INVALID;
            errorMessagesArray.push(newPasswordErrorText);
        }
        if (!newPasswordError && values.newPassword &&
            (!AppConstants.NEW_PASSWORD_REGEX.test(values.newPassword) || AppConstants.INVALID_SPECIAL_CHARS.test(values.newPassword))) {
            newPasswordError = true;
            newPasswordErrorText = ResetProfileConstants.NEW_PASSWORD_INVALID;
            errorMessagesArray.push(newPasswordErrorText);
        }
        if (!newPasswordError && values.currentPassword === values.newPassword) {
            newPasswordError = true;
            newPasswordErrorText = ResetProfileConstants.CURRENT_NEW_PASSWORD_ERROR;
            errorMessagesArray.push(newPasswordErrorText);
        }
        if (values.confirmNewPassword === '' || (values.confirmNewPassword && values.confirmNewPassword.trim() === '')) {
            confirmNewPasswordError = true;
            confirmNewPasswordErrorText = ResetProfileConstants.CONFIRM_NEW_PASSWORD + ResetProfileConstants.REQUIRED;
            errorMessagesArray.push(confirmNewPasswordErrorText);
        }
        if (!confirmNewPasswordError && values.newPassword !== values.confirmNewPassword) {
            confirmNewPasswordError = true;
            confirmNewPasswordErrorText = ResetProfileConstants.CONFIRM_PASSWORD_ERROR;
            errorMessagesArray.push(confirmNewPasswordErrorText);
        }
        setErrorMessages(errorMessagesArray);
        setShowError({
            currentPasswordError: currentPasswordError,
            newPasswordError: newPasswordError,
            confirmNewPasswordError: confirmNewPasswordError
        });

        setShowErrorText({
            currentPasswordErrorText: currentPasswordErrorText,
            newPasswordErrorText: newPasswordErrorText,
            confirmNewPasswordErrorText: confirmNewPasswordErrorText
        });

        if (errorMessagesArray && errorMessagesArray.length > 0) {
            changePwdRef.current.scrollIntoView({
                behavior: "smooth"
            });
            return true
        } else {
            return false;
        }
    };

    // Method to submit Registration Form
    const submitChangePasswordForm = () => {
        if (!validateChangePasswordForm()) {
            const changePasswordRequest = {
                userId: userID,
                password: values.currentPassword,
                newPassword: values.newPassword
            }
            setSpinnerLoader(true);
            changePasswordDispatch(changePasswordRequest);
        }
    };
    // Method to handle Logout
    const handleCancel = () => {
        if (source === 'Login') {
            AppConstants.backToLogin(props);
        } else {
            AppConstants.backToClaims(props);
        }
    }

    return (
        <>
            {spinnerLoader ? <Spinner /> : null}
            <div className="form-header-container" id="header" ref={changePwdRef}>
                <div className="form-heading cp-heading">Change Password</div>
                <div className="secondary-form-heading">
                    <Dropdown className="user-action-btn mx-0" alignRight>
                        <Dropdown.Toggle
                            variant="secondary"
                            id="dropdown-basic" className="name-text">
                            <AccountCircleIcon className="name-text mr-1" />
                            {userID}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="arrow-up">
                            <Dropdown.Item
                                onClick={handleLogout}
                                className={classes.dropDownItem}>
                                &nbsp; Logout
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <span className="px-1 px-sm-2 name-text">|</span>
                    <span className=""><a href="/contactUs" target="_blank" className="text-white name-text">Contact Us</a></span>
                </div>
            </div>
            <div className="tabs-container container">
                <div className="form-wrapper col-sm-12 px-0 forgot-username-container">
                    {errorMessages && errorMessages.length > 0 ?
                        <ErrorMessages errorMessages={errorMessages} /> : null}
                </div>
                <div className="forgot-username-container pl-0 row bg-white">
                    <div className="col forgot-username-text-container d-inline-block">
                        <div className="col-sm-12 p-0">
                            <ol className="custom-counter">
                                <li className="mb-4">Password must be a minimum of 8 characters.</li>
                                <li className="mb-4">Password must be different from your last 5 passwords.</li>
                                <li className="mb-3">Password must contain at least 3 of the 4 following types.</li>
                                <ul className="custom-list-style">
                                    <li className="mb-2">Uppercase letters</li>
                                    <li className="mb-2">Lowercase letters</li>
                                    <li className="mb-2">Numbers</li>
                                    <li className="">Non-Alphanumeric (!, $, # or %)</li>
                                </ul>
                            </ol>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 d-inline-block p-4 pl-lg-0">
                        <h5 className="mt-1 mb-4">CHANGE MY PASSWORD</h5>
                        <form autoComplete="off" className="form-styles field-family-font">
                            <div className="col px-0">
                                <div className="mui-custom-form row m-0 mb-4">
                                    <TextField
                                        required
                                        inputRef={currentPwdRef}
                                        id="standard_current-password"
                                        type={showCPPassword ? 'text' : 'password'}
                                        label={ResetProfileConstants.CURRENT_PASSWORD}
                                        value={values.currentPassword}
                                        onChange={handleChanges('currentPassword')}
                                        onBlur={() => setShowCPPassword(false)}
                                        inputProps={{ maxLength: 20 }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                <IconButton
                                                    id="currentPass"
                                                    aria-label="toggle password visibility"
                                                    onClick={() => handleClickShowPassword(ResetProfileConstants.CURRENT_PASSWORD)}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {values.currentPassword ? !showCPPassword ? <Visibility /> : <VisibilityOff /> : null}
                                                </IconButton>
                                            </InputAdornment>
                                        }}
                                        error={currentPasswordError}
                                        helperText={currentPasswordError ? currentPasswordErrorText : null}
                                    />
                                </div>
                                <div className="mui-custom-form row m-0 mb-4">
                                    <TextField
                                        required
                                        inputRef={newPwdRef}
                                        id="standard_new_password"
                                        type={showNPPassword ? 'text' : 'password'}
                                        label={ResetProfileConstants.NEW_PASSWORD}
                                        value={values.newPassword}
                                        onChange={handleChanges('newPassword')}
                                        onBlur={() => setShowNPPassword(false)}
                                        inputProps={{ maxLength: 20 }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                <IconButton
                                                    id="newPass"
                                                    aria-label="toggle password visibility"
                                                    onClick={() => handleClickShowPassword(ResetProfileConstants.NEW_PASSWORD)}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {values.newPassword ? !showNPPassword ? <Visibility /> : <VisibilityOff /> : null}
                                                </IconButton>
                                            </InputAdornment>
                                        }}
                                        onCopy={handleMouseDownPassword}
                                        onPaste={handleMouseDownPassword}
                                        error={newPasswordError}
                                        helperText={newPasswordError ? newPasswordErrorText : null}
                                    />
                                </div>
                                <div className="mui-custom-form row m-0 mb-4">
                                    <TextField
                                        required
                                        inputRef={confirmPwdRef}
                                        id="standard-confirm_password"
                                        type={showCNPPassword ? 'text' : 'password'}
                                        label={ResetProfileConstants.CONFIRM_NEW_PASSWORD}
                                        value={values.confirmNewPassword}
                                        onChange={handleChanges('confirmNewPassword')}
                                        onBlur={() => setShowCNPPassword(false)}
                                        inputProps={{ maxLength: 20 }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                <IconButton
                                                    id="confirmPass"
                                                    aria-label="toggle password visibility"
                                                    onClick={() => handleClickShowPassword(ResetProfileConstants.CONFIRM_NEW_PASSWORD)}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {values.confirmNewPassword ? !showCNPPassword ? <Visibility /> : <VisibilityOff /> : null}
                                                </IconButton>
                                            </InputAdornment>
                                        }}
                                        onCopy={handleMouseDownPassword}
                                        onPaste={handleMouseDownPassword}
                                        error={confirmNewPasswordError}
                                        helperText={confirmNewPasswordError ? confirmNewPasswordErrorText : null}
                                    />
                                </div>
                            </div>
                            <div className="form-wrapper mb-0">
                                <Button className='col-sm btn-primary mr-sm-3 w-100' onClick={submitChangePasswordForm}>CHANGE PASSWORD</Button>
                                <Button className='col-sm btn-transparent w-100' onClick={handleCancel}>CANCEL</Button>
                            </div>
                        </form>
                    </div>
                </div>
                <AlertDialog
                    open={open}
                    setOpen={setOpen}
                    page="changePassword"
                    props={props}
                />
            </div>
        </>
    )
}
export default IdleTimer(ChangePasswordForm);