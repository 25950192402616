import React from 'react';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import styles from './EOB.css';
import ConditionImage from './Assets/Condition.png';
import USCORE_Endpoint1 from './Assets/USCORE_Endpoint1.png';
import USCORE_Endpoint2 from './Assets/USCORE_Endpoint2.png';
import USCORE_Endpoint3 from './Assets/USCORE_Endpoint3.png';
export default function USCORE(){
return (
<div className="container">
<Card className="card-background"> <Card.Body>
<blockquote className="blockquote mb-0">
<h1 className="text-header">Clinical Data API – US Core</h1>
<p>The IOX Clinical Data API implementation uses the <a href="http://hl7.org/fhir/us/core/STU5.0.1/CapabilityStatement-us-core-server.html">US Core Patient profile.</a></p>
<p>• For more information, see the HL7 US Core Capability Statement.</p>
<h1>Querying Patient-related Resources in the IOX System</h1>
<p> The IOX system lets you search Clinical Data-related information using Fast Healthcare Interoperability Resources (FHIR) resource types to retrieve data.</p>
<p>For additional information on how searches work, see HL7 FHIR Specification sections <a href="https://www.hl7.org/fhir/http.html#search">3.1.0.9 search </a>   and  <a href="https://www.hl7.org/fhir/search.html#3.1.1">3.1.1 Search. </a></p>
<h1>Clinical Data API Query Strings </h1>
<p>The <a href="https://iox.dev.sddss.apps.conduent.com/doc#%5B%7B%22num%22%3A12%2C%22gen%22%3A0%7D%2C%7B%22name%22%3A%22XYZ%22%7D%2C69%2C296%2C0%5D">Clinical API – Query Details Table</a> below list available query strings and describe key parameters that make up the queries.</p>
<p>• For example, to retrieve patient details of '561234' for the AllergyIntolerance, you would use the query string in the image below.</p>
<p>•Enter the query by updating the value segment of the query string with the value you want to locate. </p>
<img class="img-responsive" src={ConditionImage} alt="any text"></img>
<p>Includes and Reverse Includes </p>
<p>Many query strings incorporate contain (_include) and reverse include (_revinclude) parameters to determine which resources a search draws upon.The HL7 specification states the following about includes and reverse includes: </p>
<p>The client can use the _include parameter to indicate that the subject resources be included in the results. An alternative scenario _revinclude is where the client wishes to fetch a resource and any resources that refer to it. For example, the client may wish to fetch a MedicationRequest and any provenance resources that refer to the prescription. This is known as a reverse include and is specified by providing a _revinclude parameter.</p>
<p><b>Source:</b> Section <a href="https://www.hl7.org/fhir/search.html#revinclude">3.1.1.5.4</a> of the HL7 FHIR Specification.</p>
<p>Related HL7 FHIR Specifications</p>
<p>• To learn more about how searches work, see HL7 FHIR Specification sections <a href="https://www.hl7.org/fhir/http.html#search">3.1.0.9 search </a> and <a href="https://www.hl7.org/fhir/search.html#3.1.1">3.1.1 Search.</a></p>
<p>• To find details about specific resources and parameters, see section <a href="https://www.hl7.org/fhir/searchparameter-registry.html">3.1.4</a> of the HL7 FHIR Specification.</p>
<h1>Clinical Data API – Query Details Table</h1>
<p>The following table lists details about query strings for the IOX Clinical Data API and the elements that make up the queries. </p>
<p>The information in this table derives from the <a href="https://www.hl7.org/fhir/index.html">HL7 specifications</a>, including the US Core Capability Statement and FHIR Specification Defined Search Parameters <a href="https://www.hl7.org/fhir/searchparameter-registry.html">(section 3.1.4).</a></p>
<img class="img-responsive" src={USCORE_Endpoint1} alt="any text"></img>
<img class="img-responsive" src={USCORE_Endpoint2} alt="any text"></img>
<img class="img-responsive" src={USCORE_Endpoint3} alt="any text"></img>

</blockquote> </Card.Body>
</Card>
<br/>
<hr className="horizontal-rule"></hr>
<br/>

{/*<Card className="card-background">
<Card.Body>
<h4 className="EOB-heading">URL</h4>
<div>
<codeblock className="accesstoken-code"> URL</codeblock>
<span>&nbsp;</span><span>&nbsp;</span>
<codeblock className="accesstoken-code"> https://fhir.IOX.com/sandbox/auth/token</codeblock>
</div>
</Card.Body>
<Card.Body>
<h4 className="accesstoken-heading">Sample Request</h4>

<blockquote className="OAuth-code" >'curl --location --request POST https://fhir.IOX.com/sandbox/auth/token' \<br/>
                                    '--header Authorization: Basic base64(client_id:client_secret)'\<br/>
                                     '--header Content-Type: application/x-www-form-urlencoded' \<br/>
                                     '--data-urlencode code=your authorization code' \<br/>
                                     '--data-urlencode grant_type=authorization_code' \<br/>
                                     '--data-urlencode redirect_uri=redirect_uri'</blockquote>

<h4 className="accesstoken-heading">Sample Response</h4>

<blockquote className="OAuth-code" >'curl --location --request POST https://fhir.IOX.com/sandbox/auth/token' \<br/>
                                                  '--header Authorization: Basic base64(client_id:client_secret)'\<br/>
                                                  '--header Content-Type: application/x-www-form-urlencoded' \<br/>
                                                  '--data-urlencode code=your authorization code' \<br/>
                                                  '--data-urlencode grant_type=authorization_code' \<br/>
                                                  '--data-urlencode redirect_uri=redirect_uri'</blockquote>
</Card.Body>



</Card>*/}
</div>

);
}