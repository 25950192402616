import * as actionTypes from '../Actions/ResetProfileActionTypes';

const axiosPayLoad = {
  payload: null
};
const reducer = (state = axiosPayLoad, action) => {
  switch (action.type) {
    case actionTypes.FORGOT_USERID:
      return { ...state, forgotUserId: action.forgotUserId };
    case actionTypes.GET_SECURITY_QS:
      return { ...state, getSecurityQs: action.getSecurityQs };
    case actionTypes.PASSWORD_RECOVERY:
      return { ...state, passwordRecovery: action.passwordRecovery };
    case actionTypes.CHANGE_PASSWORD:
      return { ...state, changePassword: action.changePassword };
    case actionTypes.RESET_DATA:
      return { ...state, [action.key]: '' };
    default: return state;
  }
};

export default reducer;
