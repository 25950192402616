/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CustomScrollMenu from '../../../SharedModule/Layout/CustomScrollMenu';
import ClaimsTable from './ClaimsTable';
import { Dropdown } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import * as AppConstants from '../../../SharedModule/Utils/AppConstants';
import { AppConfigDropdownActions, LogoutActions, EventsLogActions } from '../../../SharedModule/Store/Actions/AppActions';
import { GetClaimsRollingMonthsActions, GetClaimsMonthlyDataActions, resetClaimsData } from '../Store/Actions/ClaimsActions';
import Spinner from '../../../SharedModule/Spinner/Spinner';
import ErrorMessages from '../../../SharedModule/MessageHandlers/ErrorMessages';
import IdleTimer from '../../../SharedModule/SessionManagement/IdleTimer';
import { setPrintLayout, resetLogoutData } from '../../../SharedModule/Store/Actions/AppActions';

function Claims(props) {
  const drawerWidth = 240;
  const userID = props.location.state && props.location.state.userID ? props.location.state.userID : '';
  const userName = props.location.state && props.location.state.userName ? props.location.state.userName : '';
  const isAuthorisedUser = sessionStorage.getItem('authToken');

  const useStyles = makeStyles(theme => ({
    wrapper: {
      display: 'flex',
      height: '100%',
      padding: '20px 0 0 0'
    },
    drawer: {
      boxSizing: 'border-box',
      width: drawerWidth,
      flexShrink: 0
    },
    drawerPaper: {
      position: 'relative',
      boxSizing: 'border-box',
      width: drawerWidth
    },
    drawerPaperShrink: {
      position: 'relative',
      boxSizing: 'border-box',
      width: 100
    },
    loginStatus: {
      display: 'flex',
      alignItems: 'center',
      float: 'right'
    },
    dropDownItem: {
      padding: '10px 15px',
      color: '#D5D5D5'
    },
    nestedFolder: {
      paddingRight: '16px!important'
    }
  }));

  // Page Constants
  const dispatch = useDispatch();
  const classes = useStyles();
  let errorMessagesArray = [];
  const [selectedKey, setSelectedKey] = React.useState(0);
  const [selectedMenu, setSelectedMenu] = React.useState(0);
  const [claimRollingMonths, setClaimRollingMonths] = React.useState([]);
  const [feedbackQsList, setFeedbackQsList] = React.useState([]);
  const [claimsDataList, setClaimsDataList] = React.useState([]);
  const [isClaimsOpen, setIsClaimsOpen] = React.useState(false);
  const [showErrorView, setShowErrorView] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [logout, setLogout] = React.useState(false);
  const [spinnerLoader, setSpinnerLoader] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState([]);
  // eslint-disable-next-line no-unused-vars
  const [loginTime, setLoginTime] = React.useState(props.location.state && props.location.state.loginTime ? props.location.state.loginTime : '');

  // Dispatch Actions
  const dropDownDispatch = (configKeyList) => dispatch(AppConfigDropdownActions(configKeyList));
  const getClaimsRollingMonthsDispatch = () => dispatch(GetClaimsRollingMonthsActions());
  const getClaimsMonthlyDataDispatch = (claimsMonthlyCriteria) => dispatch(GetClaimsMonthlyDataActions(claimsMonthlyCriteria));
  const logoutDispatch = (logoutCriteria) => dispatch(LogoutActions(logoutCriteria));

  const dropdownData = useSelector(state => state.sharedState.appConfigDropdown);
  const claimsRollingMonthsData = useSelector(state => state.claimsState.claimsRollingMonths);
  const claimsMonthlyData = useSelector(state => state.claimsState.claimsMonthlyData);
  const logoutData = useSelector(state => state.sharedState.logout);


  // Method to make API calls on Load
  useEffect(() => {
    dispatch(resetClaimsData('claimsMonthlyData'));
    dispatch(resetClaimsData('createFeedback'));
    dispatch(resetClaimsData('updateFeedback'));
    if (!isAuthorisedUser) {
      AppConstants.backToLogin(props);
    } else {
      const configKeyList = ["CLAIM_FEEDBACK_QUESTIONS"];
      dropDownDispatch(configKeyList);
      getClaimsRollingMonthsDispatch();
    }
  }, []);

  useEffect(() => {
    if (dropdownData) {
      errorMessagesArray = [];
      errorMessagesArray = AppConstants.handleAPIErrors(dropdownData, errorMessagesArray, '');
      if (errorMessagesArray && errorMessagesArray.length > 0) {
        setErrorMessages(errorMessagesArray);
      } else {
        if (dropdownData.CLAIM_FEEDBACK_QUESTIONS && dropdownData.CLAIM_FEEDBACK_QUESTIONS.configKey === AppConstants.CLAIM_FEEDBACK_QUESTIONS) {
          setFeedbackQsList(dropdownData.CLAIM_FEEDBACK_QUESTIONS.configDetail);
        }
      }
    }
  }, [dropdownData]);

  useEffect(() => {
    if (claimsRollingMonthsData) {
      errorMessagesArray = [];
      errorMessagesArray = AppConstants.handleAPIErrors(claimsRollingMonthsData, errorMessagesArray, '');
      if (errorMessagesArray && errorMessagesArray.length > 0) {
        setErrorMessages(errorMessagesArray);
      } else {
        setClaimRollingMonths(claimsRollingMonthsData);
      }
    }
  }, [claimsRollingMonthsData]);

  useEffect(() => {
    if (claimsMonthlyData) {
      setShowErrorView(false);
      setSpinnerLoader(false);
      errorMessagesArray = [];
      errorMessagesArray = AppConstants.handleAPIErrors(claimsMonthlyData, errorMessagesArray, '');
      if (errorMessagesArray && errorMessagesArray.length > 0) {
        setErrorMessages(errorMessagesArray);
      } else {
        if (claimsMonthlyData.claims) {
          setClaimsDataList(claimsMonthlyData);
        } else {
          setShowErrorView(true);
        }
      }
    }
  }, [claimsMonthlyData]);

  useEffect(() => {
    if (logoutData) {
      errorMessagesArray = [];
      errorMessagesArray = AppConstants.handleAPIErrors(logoutData, errorMessagesArray, AppConstants.LOGOUT_ERROR);
      if (errorMessagesArray && errorMessagesArray.length > 0) {
        if (errorMessagesArray[0].indexOf(AppConstants.LOGOUT_ERROR) > -1) {
          const eventLog = {
            memberId: "",
            eventSource: logout ? "Logout" : "Session Logout",
            eventType: "Failed",
            eventDescription: logout ? 'The system was unable to log off. Please terminate the browser window.' :`${userID} has failed to logout from the AK MMIS system ${new Date()}`
          }
          setLogout(false);
          dispatch(EventsLogActions(eventLog));
        }
        setErrorMessages(errorMessagesArray);
        dispatch(resetLogoutData('logout'));
      } else {
        dispatch(resetClaimsData('claimsMonthlyData'));
        dispatch(resetLogoutData('logout'));
        AppConstants.backToLogin(props);
      }
    }
  }, [logoutData]);

  // Method to fetch claims data on Month selection
  const onSelect = key => {
    setSelectedKey(key);
    dispatch(resetClaimsData('createFeedback'));
    dispatch(resetClaimsData('updateFeedback'));
    dispatch(setPrintLayout(false));
    if (!isClaimsOpen) {
      setClaimsDataList([]);
      setSelectedMenu(key);
      const claimsMonthlyRequest = {
        userId: userID,
        month: key.replace(/ /g, '-')
      };
      getClaimsMonthlyDataDispatch(claimsMonthlyRequest);
      setSpinnerLoader(true);
    } else {
      if (selectedMenu !== key) {
        setOpen(true);
      }
      setSelectedMenu(selectedMenu);
    }
  }

  // Method to redirect back to ChangePassword
  const redirectToCP = () => {
    props.history.push({
      pathname: '/changePassword',
      state: { userID: userID, source: 'Claims', loginTime: loginTime, userName: userName }
    });
  }

  // Method to handle Logout
  const handleLogout = () => {
    setLogout(true);
    const logoutRequest = {
      logoutType: "logout",
      userId: userID
    }
    logoutDispatch(logoutRequest);
  }

  return (
    <>
      {spinnerLoader ? <Spinner /> : null}
      <div className="form-header-container col" id="header">
        <div className="form-heading heading-wrap float-left">Claims Details by Payment Month</div>
        <div className="secondary-form-heading">
          <Dropdown className="user-action-btn mx-0" alignRight>
            <Dropdown.Toggle
              variant="secondary"
              id="dropdown-basic" className="name-text">
              <AccountCircleIcon className="name-text mr-1" />
              {userID}
            </Dropdown.Toggle>
            <Dropdown.Menu className="arrow-up">
              <Dropdown.Item
                id="CP"
                onClick={redirectToCP}
                className={classes.dropDownItem}>
                &nbsp; Change Password
                      </Dropdown.Item>
              <Dropdown.Item
                id="logout"
                onClick={handleLogout}
                className={classes.dropDownItem}>
                &nbsp; Logout
                      </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <span className="px-1 px-sm-2 name-text">|</span>
          <span className="name-text"><a href="/contactUs" target="_blank" className="text-white">Contact Us</a></span>
        </div>
      </div>

      {errorMessages && errorMessages.length > 0 ?
        <div className="form-wrapper col-sm-12 px-0 mt-2">
          <ErrorMessages errorMessages={errorMessages} />
        </div> : null}
      {claimRollingMonths && claimRollingMonths.Months ?
        <div>
          <CustomScrollMenu
            onSelect={onSelect}
            selectedMenu={selectedMenu}
            claimRollingMonths={claimRollingMonths.Months}
          />
          {(!selectedMenu && !showErrorView) ?
            <div className="claims-alert">
              <img className="p-1" src="Alert_icon.svg" alt="Claims Details Unavailable" />
              <div className="p-1">Please click on any month to view the claims data</div>
            </div>
            : (selectedMenu && showErrorView) ?
              <div className="claims-alert text-danger">
                <img className="p-1" src="Alert_red.svg" alt="Claims Details Unavailable" />
                <div className="p-1">No Claims Found</div>
              </div>
              :
              claimsDataList && errorMessages && errorMessages.length === 0 ?
                <ClaimsTable
                  feedbackQsList={feedbackQsList}
                  claimsDataList={claimsDataList}
                  selectedMenu={selectedMenu}
                  isClaimsOpen={isClaimsOpen}
                  setIsClaimsOpen={setIsClaimsOpen}
                  open={open}
                  setOpen={setOpen}
                  userID={userID}
                  userName={userName}
                  setSpinnerLoader={setSpinnerLoader}
                  loginTime={loginTime}
                  onSelect={onSelect}
                  selectedKey={selectedKey}
                  setSelectedKey={setSelectedKey}
                /> : null
          }
        </div> : null}
    </>
  )
}

export default IdleTimer(Claims);
