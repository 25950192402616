import React, { useEffect,useState } from 'react';
import axios from 'axios';
import * as serviceEndPoint from '../../../SharedModule/Service/service';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../../SharedModule/Spinner/Spinner';
import ErrorMessages from '../../../SharedModule/MessageHandlers/ErrorMessages';
import { AppConfigDropdownActions, EventsLogActions } from '../../../SharedModule/Store/Actions/AppActions';
import * as AppConstants from '../../../SharedModule/Utils/AppConstants';
import * as RegistrationConstants from './RegistrationConstants';



export default function Home(props) {
  const dispatch=useDispatch();
  let [permission,setPermission]=useState("");
  let [payertargetName,setPayerTargetName]=useState("");
  let [payersourceName,setPayersourceName]=useState("");
  let [member_reg_support_contact,setMemberRegSupportContact]=useState("");
  const [spinnerLoader, setSpinnerLoader] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);


  const dropDownDispatch = (configKeyList) => dispatch(AppConfigDropdownActions(configKeyList));
  const dropdownData = useSelector(state => state.sharedState.appConfigDropdown);
  
  useEffect(() => {
    setSpinnerLoader(true);
    const configKeyList = ["MEMBER_REGISTRATION_SUPPORT_CONTACT","NEW_PAYER"];
    dropDownDispatch(configKeyList);

    setErrorMessages([]);
    const queryString = window.location.search;
    try {
      const urlParams = new URLSearchParams(queryString);
      if (urlParams.get("code") || urlParams.get("state") || urlParams.get("error")) {
        
        axios.post(serviceEndPoint.GET_ACCESS_TOKEN_FOR_P2P, {
          "state": urlParams.get("state"),
          "code": urlParams.get("code"),
          "error": urlParams.get("code") && urlParams.get("code")?.length > 0 ?  null : "Denied"
        }).then(async (res) => {
          setPermission(urlParams.get("code") && urlParams.get("code")?.length > 0 ?  "Allow" : "Denied");
          setSpinnerLoader(false);
          if(res && res.data){
           //  props.history.push('/');
           setPayersourceName(res?.data?.data);
          }else{
           //  props.history.push('/');
          }
          
        }
        ).catch((err) => {
          setSpinnerLoader(false);
          setErrorMessages(['Unable to process your payer registration now. Please try later.']);
          document.getElementById("root").scrollTop=0;
         // props.history.push('/');
        })
      } else {
        setSpinnerLoader(false);
         // props.history.push('/');
      }

    }
    catch {
      console.log("No code")
    }

  }, []) 

  useEffect(() => {
    if (dropdownData) {
        let errorMessagesArray = [];
        errorMessagesArray = AppConstants.handleAPIErrors(dropdownData, errorMessagesArray, RegistrationConstants.REGISTRATION_TECHNICAL_ERROR);
        if (errorMessagesArray && errorMessagesArray.length > 0) {
            setErrorMessages(errorMessagesArray);
        } else {
            if(dropdownData.MEMBER_REGISTRATION_SUPPORT_CONTACT && 
                dropdownData.MEMBER_REGISTRATION_SUPPORT_CONTACT.configKey === 
                AppConstants.MEMBER_REGISTRATION_SUPPORT_CONTACT) {
                  setMemberRegSupportContact(dropdownData.MEMBER_REGISTRATION_SUPPORT_CONTACT.configDetail[0].value);
                }

                if (dropdownData.NEW_PAYER && dropdownData.NEW_PAYER.configKey === AppConstants.NEW_PAYER) {
                  setPayerTargetName(dropdownData.NEW_PAYER.configDetail[0].value);
              }
        }
    }
}, [dropdownData]);

  return (
    <>
    <div className="container">
            {spinnerLoader ? <Spinner /> : null}
            <div className="success-alert-container">
                {errorMessages && errorMessages.length > 0 ?
                    <ErrorMessages errorMessages={errorMessages} /> : null}
            </div>
            <div className="success-container custom-success-container" style={{width:"100%"}}>
                <div className="">
                    {/* <img src="Successfully_submitted.svg" className="mx-auto d-block" alt="Successfully Submitted" />
                    <h6 className="text-center font-weight-bold my-3">Congratulations!</h6> */}
                    <div className="alert alert-success custom-alert">
                    {permission == "Allow" && <p className="fw-600 py-3 font-md px-3">
                    Thank you for initiating data exchange process. We will begin receiving your health data from <b>{payersourceName}</b> as soon as possible. If you have additional questions about the availability of the data, please contact <b>{payertargetName}</b> <b>{member_reg_support_contact}</b>.
                          {/* Your consent to share your data with <b>{payertargetName}</b> has been received. We will transfer the data as soon as <b>{payertargetName}</b>'s request is received. Please contact <b>{payertargetName}</b> for the status of the transfer. */}
                    </p>}
                    {permission == "Denied" && <p className="fw-600 py-3 font-md px-3">
                    <b>{payersourceName}</b> notified us that you do not want to share the data. Your old health records from <b>{payersourceName}</b> will not be available with us. If you change your mind, you may again click on the link received in your registered email id and modify your consent at <b>{payersourceName}</b>."
                        {/* Denial of your consent to exchange data with <b>{payertargetName}</b> has been recorded. None of your health records will be shared with <b>{payertargetName}</b>. If you would like to reinitiate this request, Please contact <b>{payertargetName}</b>. */}
                    </p>}
                    </div>
                </div>
            </div>
        </div>
           
    </>
  )
}