import React from 'react';
import Card from 'react-bootstrap/Card';
import styles from './ThirdPartyAppRegistration.css';

export default function ThirdPartyAppRegistration(){
return (
<div className="container">
<Card className="card-background"> <Card.Body>
<blockquote className="blockquote mb-0">
<h1 className="text-header">Third Party App Registration</h1>
<p> To register your application with the Interoperability Exchange (IOX) system, you must first submit the details of your App to the IOX administrator for review and approval.</p>
</blockquote> </Card.Body>
</Card>
<br/>
<hr className="horizontal-rule"></hr>
<br/>
<Card className="card-background"><Card.Body>
<p><b>STEP 1:</b> Click Register Your App Here from the landing page.</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; •    Provide the information in the App Details and Address sections.
</p>
<table border="1" class="center">
<thead class="text">
<tr><th>Field</th><th>Description</th></tr>
</thead>
<tbody>
<tr><td>App Name</td><td>Third Party App Name</td></tr>
<tr><td>App Type</td><td>Third Party App Type</td></tr>
<tr><td>Developer First Name</td><td>App Developer’s First Name</td></tr>
<tr><td>Developer Last Name</td><td>App Developer’s Last Name</td></tr>
<tr><td>Organization Name</td><td>App Developer’s Organization Name</td></tr>
<tr><td>Home Page URL</td><td>Third Party App Home Page URL</td></tr>
<tr><td>Privacy Policy URL</td><td>Third Party App Privacy Policy URL</td></tr>
<tr><td>Terms of Services URL</td><td>Third Party App Terms of Services URL</td></tr>
<tr><td>Redirect URL</td><td>Third Party App Redirect URL</td></tr>
<tr><td>Address (Line 1 and Line 2)</td><td>App Developer’s Mailing Address</td></tr>
<tr><td>City</td><td>App Developer’s City</td></tr>
<tr><td>State</td><td>App Developer’s State</td></tr>
<tr><td>Zip & Extension</td><td>App Developer’s Zip and Extension</td></tr>
<tr><td>Phone Number</td><td>App Developer’s Phone Number</td></tr>
<tr><td>Email ID</td><td>App Developer’s Email</td></tr>
</tbody>
</table>
<br/>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•	Review and answer the questions in the Privacy Policy Questionnaire section.<br/><br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•	Attach any documents supporting your third-party application registration request.<br/><br/>
<b>STEP 2: </b>Complete the Third Party App Registration form and click <b>SUBMIT.</b>  <br/><br/>
 
After submitting your details, once your details are reviewed and approved, you will
receive a decision regarding your registration. If your registration is approved, you will 
receive a Client ID and a Client Secret to call IOX’s FHIR APIs.

</p>
</Card.Body></Card>


</div>
);
}