import * as actionTypes from '../Actions/PayerRegistraionActionType';
const axiosPayload={
    payerRegistrationData:null,
    payerDetails:null
}

const reducer=(state=axiosPayload,action)=>{
    switch (action.type) {
        case actionTypes.PAYER_REGISTRATION:
            return {
                ...state,payerRegistrationData:action.payload
            }
         case actionTypes.PAYER_SEARCH:
             return { ...state, searchResult: action.payload };
        case actionTypes.UNAPPROVED_PAYERS:
            return { ...state, unApprovedPayers: action.payload };
        case actionTypes.PAYER_SEARCH_VIEW:
        return { ...state, payerDetails: action.appSearchData, appDetailsTime: new Date(), type: 'Edit'};
        case actionTypes.PAYER_STATUS_UPDATE:
            return { ...state, appStatusDetails: action.payerDetailsData };
        case actionTypes.RESET_PAYER_STATUS:
            return { ...state, appStatusDetails: action.payerDetailsData };    
        case actionTypes.EXISTING_PAYER_LIST:
            return { ...state, existingPayerList: action.payload };    
        default:
            return state
    }
}

export default reducer;