import React from 'react';
import Autosuggest from 'react-autosuggest';

  
  class App extends React.Component {
    constructor(props) {
      super();
      this.state = {
        value: props.inpValue ? props.inpValue : "",
        suggestions: [],
        error:false,
        errorText:null,
        payerList:props.payerList?.data,
        required:props.required
      };    
    }
  
    static getDerivedStateFromProps(props, current_state) {
        if (current_state.value !== props.inpValue || current_state.error !== props.error || current_state.errorText !== props.helperText || current_state.payerList !== props.payerList) {
          return {
            value: props.inpValue,
            error:props.error,
            errorText:props.helperText,
            payerList:props.payerList?.data
          }
        }
        return null
      }

      escapeRegexCharacters(str) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      }
      
     getSuggestions(value) {
        const escapedValue = this.escapeRegexCharacters(value?.trim());
        
        if (escapedValue === '') {
          return [];
        }
      
        const regex = new RegExp('^' + escapedValue, 'i');

       if(this.state.payerList)
        return this.state.payerList.filter(language => regex.test(language.payerName));
       else 
        return []; 
      }
      
    getSuggestionValue = (suggestion)=>{
       this.props.setPayerId(suggestion.id,this.props.index);
       this.props.checkPayerStatus(suggestion.payerStatus,this.props.index);
        return suggestion.payerName;
      }
      
     renderSuggestion(suggestion) {
        return (
          <span>{suggestion.payerName}</span>
        );
      }

    onChange = (event, { newValue, method }) => {
      this.setState({
        value: newValue
      });
      this.props.change(newValue,this.props.index);
    };
    
    onSuggestionsFetchRequested = ({ value }) => {
      this.setState({
        suggestions: this.getSuggestions(value)
      });
    };
  
    onSuggestionsClearRequested = () => {
      this.setState({
        suggestions: []
      });
    };
  
    render() {
      const { value, suggestions,error,errorText,required } = this.state;
      let classname= error ? "react-autosuggest__input_error" : "react-autosuggest__input";
      const inputProps = {
        placeholder: " ",
        value,
        onChange: this.onChange,
        error,
        className : classname,
        tabindex:-1,
        maxLength:35,
        id:'Payer-Name'
      };
  
      return (
          <>
          <label className="autoSuggestionLabel" for='Payer-Name'>{required && <span className="iox-required">*</span>}Payer Name</label>
        <Autosuggest 
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          inputProps={inputProps} />
          {error && <p className="MuiFormHelperText-root Mui-error Mui-required">{errorText }</p>}
          </>
      );
    }
  }
  
 
  export default App;