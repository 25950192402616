import React,{useEffect} from 'react';
import CustomCarousel from './CustomCarousel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button } from 'react-bootstrap';
import Spinner from '../../../SharedModule/Spinner/Spinner';

import { redirectToAzureAD ,redirectToAdminAzureAD} from './authUtils';
import { generateRandomString, generateCodeChallenge } from './pkceUtils';


export default function LoginForm(props) {
    const [spinnerLoader, setSpinnerLoader] = React.useState(false);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogType, setDialogType] = React.useState('');
    const [targetAction, setTargetAction] = React.useState('');
    const [memberConsentLoginUrl] = React.useState(process.env.REACT_APP_CONSENT_MANAGEMENT_MEMBER_LOGIN);
    const [existingMemberLoginUrl] = React.useState(process.env.REACT_APP_TP_MEMBER_LOGIN);
    const [adminLoginUrl] = React.useState(process.env.REACT_APP_ADMIN);
    const codeVerifier = generateRandomString(128);
    const codeChallenge =  generateCodeChallenge(codeVerifier);
    const state = generateRandomString(64);


    const showPrivacyPolicyDialog = (actionUrl) => {
        setDialogOpen(true);
        setDialogType('showPrivacyPolicy')
        setTargetAction(actionUrl)
        return true;
    };
    const acceptPrivacyPolicy = () => {
        setDialogOpen(false);
        window.open(targetAction, "_self")
    };

    /* useEffect(()=>{
        localStorage.setItem("isLoggedIn","ioxadmin");
    },[]) */
    const redirectToLogin = () => {
        setSpinnerLoader(true);
        setTimeout(function () {
          setSpinnerLoader(false);
          window.location.href = process.env.REACT_APP_ADMIN_MEMBER
        }, 3000);
      }
      const openDocument=(name)=>{
            localStorage.setItem("IoxDocName",name);
            window.open("/api-docs");
      }
      const openStaticPage=(name)=>{
                  localStorage.setItem("IoxDocName",name);
                  window.open("/ThirdPartyAppRegistration");
            }


    return (
        <div className="container custom-container py-3">
            <Dialog
                open={dialogOpen}
                className="custom-alert-box">
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <span style={{ fontWeight: 'bold' }}>PRIVACY AND SECURITY NOTICE</span>
                        <br></br><br></br>
                        Anyone using this system must abide by Conduent policies and is hereby notified that all Conduent systems and activities are monitored. If you have any questions or concerns, contact DataPrivacy@conduent.com.

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setDialogOpen(false); }} color="primary" autoFocus>
                        Cancel
                    </Button>
                    {dialogType === 'showPrivacyPolicy' && (
                        <Button onClick={() => { acceptPrivacyPolicy() }} color="primary" className="btn btn-success">
                            I Agree
                        </Button>
                    )
                    }
                </DialogActions>
            </Dialog>

            <div className="login-tab">
                <div className="row">
                    <div className="col-sm-12 col-md-7">
                        <CustomCarousel />
                    </div>
                    <>
                        <div className="col-sm-12 col-md-5">

                            <div className="login_block">
                                {/* New Design start */}
                                <div className="mem-link-right">
                                    <div className="pt-1">
                                        <div className="admin-login-wrapper float-right">
                                            <div className="link-icon icon-admin-login"></div>
                                            <div className="link-text">
                                                {/* <a href="/appPayerManagement" title="Admin Login">Admin Login</a>  */} 
                                                <a href="javascript:void(0)" onClick={()=>showPrivacyPolicyDialog("/SDLogin/Admin")} title="Admin Login">Admin Login</a>
                                                
                                            </div>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="mem-login-link-wrapper">
                                        <div className="mem-login-link-inner my-3">
                                            {/* <div className="link-block p-3">
                                                <div className="link-text icon-pos icon-exist-mem">
                                                    <div className="link-lbl">Existing Members</div>
                                                    <a href="javascript:void(0)" onClick={() => showPrivacyPolicyDialog(existingMemberLoginUrl)} title="Login here">Login here</a><br />
                                                </div>
                                            </div> */}

                                            <div className="link-block p-3">
                                                <div className="link-text icon-pos icon-new-mem">
                                                    <div className="link-lbl">New Member?</div>
                                                    <a href="javascript:void(0)" onClick={() => showPrivacyPolicyDialog("/SDLogin")} title="Register here">Register here</a><br />
                                                    {/* <a onClick={()=>openDocument("Education_Materials")} className="cursor_pointer" title="Review educational materials here">Review educational materials here</a> */}
        
                                                    {/*Added new link*/}
                                                    {/*<a onClick={()=>openStaticPage("Education")} className="cursor_pointer" title="click here to view">click here to view</a>*/}
                                                </div>
                                            </div>

                                            <div className="link-block p-3">
                                                <div className="link-text icon-pos icon-app-dev">
                                                    <div className="link-lbl">App Developer</div>
                                                    <a href="javascript:void(0)" onClick={() => showPrivacyPolicyDialog("/appRegistration")} title="Register your app here">Register your app here</a><br />
                                                    {/* <a  onClick={()=>openDocument("API")} className="cursor_pointer" title="API Documentation">API documentation</a>
                                                    <br/>
                                                    {/*Added new link*/}
                                                   {/* <a onClick={()=>openStaticPage("API")} className="cursor_pointer" title="API Reference">API Reference</a> } */}
                                                </div>
                                            </div>
                                            {/* <div className="link-block p-3">
                                                <div className="link-text icon-pos icon-exist-mem">
                                                    <div className="link-lbl">Payer</div>
                                                    <a href="/payerRegistration" title="Register here">Register here</a>
                                                </div>
                                            </div> */}
                                            <div className="link-block p-3">
                                                <div className="link-text icon-pos icon-exist-mem">
                                                    <div className="link-lbl">Member Consent Management</div>
                                                    <a title="Login here" href="javascript:void(0)" onClick={() => showPrivacyPolicyDialog("SDLogin/MemberConsent")}>Login here</a><br />
                                                </div>
                                            </div>
                                            <div className="link-block p-3">
                                                <div className="link-text icon-pos icon-exist-mem">
                                                    <div className="link-lbl">Educational Material</div>
                                                    {<a onClick={()=>openStaticPage("Education")} className="cursor_pointer" title="IOX Reference">IOX Reference</a>}
                                                    <br/>
                                                    {<a onClick={()=>openDocument("Education_Materials")} className="cursor_pointer" title="Swagger Reference">Swagger Reference</a> }
                                                    
                                                    {/*Added new link*/}
                                                   
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* New Design End */}
                            </div>
                        </div>
                    </>
                </div>

            </div>
        </div>
    )
}
