/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import { Button } from 'react-bootstrap';
import * as ResetProfileConstants from './ResetProfileConstants';
import * as AppConstants from '../../../SharedModule/Utils/AppConstants';
import ErrorMessages from '../../../SharedModule/MessageHandlers/ErrorMessages';
import Spinner from '../../../SharedModule/Spinner/Spinner';
import { EventsLogActions } from '../../../SharedModule/Store/Actions/AppActions';
import { GetSecurityQsActions, PasswordRecoveryActions, resetData } from '../Store/Actions/ResetProfileActions';
import AlertDialog from '../../../SharedModule/Layout/AlertDialog';

export default function PasswordRecoveryForm(props) {
    // Page Constants
    const dispatch = useDispatch();
    const passwordRecoveryRef = useRef(null);
    let errorMessagesArray = [];
    const [openPasswordForm, setOpenPasswordForm] = React.useState(false);
    const [errorMessages, setErrorMessages] = React.useState([]);
    const [spinnerLoader, setSpinnerLoader] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [{
        userIDError,
        answer1Error,
        answer2Error,
        answer3Error
    }, setShowError] = React.useState(false);

    const [{
        userIDErrorText,
        answer1ErrorText,
        answer2ErrorText,
        answer3ErrorText
    }, setShowErrorText] = React.useState('');

    // Field bindings
    const [values, setValues] = React.useState({
        userID: '',
        secQs1: '',
        secQs2: '',
        secQs3: '',
        answer1: '',
        answer2: '',
        answer3: ''
    });

    // Dispatch Actions
    const getSecurityQsDispatch = (getSecurityQsCriteria) => dispatch(GetSecurityQsActions(getSecurityQsCriteria));
    const passwordRecoveryDispatch = (passwordRecoveryCriteria) => dispatch(PasswordRecoveryActions(passwordRecoveryCriteria));

    const getSecurityQsData = useSelector(state => state.resetProfileState.getSecurityQs);
    const passwordRecoveryData = useSelector(state => state.resetProfileState.passwordRecovery);

        // Method to handle Logout
        const handleCancel = () => {
            dispatch(resetData('getSecurityQs'));
            dispatch(resetData('passwordRecovery'));
            AppConstants.backToLogin(props);
        }
    useEffect(() => {
        if (getSecurityQsData) {
            setSpinnerLoader(false);
            errorMessagesArray = [];
            errorMessagesArray = AppConstants.handleAPIErrors(getSecurityQsData, errorMessagesArray, ResetProfileConstants.PASSWORD_RECOVERY_TECHNICAL_ERROR);
            if (errorMessagesArray && errorMessagesArray.length > 0) {
                if (errorMessagesArray[0].indexOf(ResetProfileConstants.PASSWORD_RECOVERY_INVALID_CRED_ERROR) > -1) {
                    errorMessagesArray[0] = ResetProfileConstants.PASSWORD_RECOVERY_INVALID_USER_ERROR;
                }
                setErrorMessages(errorMessagesArray);
            } else {
                if (getSecurityQsData.challenge && getSecurityQsData.challenge[0]) {
                    setValues({
                        userID: values.userID,
                        secQs1: getSecurityQsData.challenge[0],
                        secQs2: getSecurityQsData.challenge[1],
                        secQs3: getSecurityQsData.challenge[2],
                        answer1: '',
                        answer2: '',
                        answer3: ''
                    });
                    setOpenPasswordForm(true);
                } else {
                    errorMessagesArray.push(ResetProfileConstants.PASSWORD_RECOVERY_TECHNICAL_ERROR);
                    setErrorMessages(errorMessagesArray);
                }
            }
        }
    }, [getSecurityQsData]);

    useEffect(() => {
        if (passwordRecoveryData) {
            setSpinnerLoader(false);
            errorMessagesArray = [];
            errorMessagesArray = AppConstants.handleAPIErrors(passwordRecoveryData, errorMessagesArray, ResetProfileConstants.PASSWORD_RECOVERY_TECHNICAL_ERROR);
            if (errorMessagesArray && errorMessagesArray.length > 0) {
                if (errorMessagesArray[0].indexOf(ResetProfileConstants.PASSWORD_RECOVERY_TECHNICAL_ERROR) > -1) {
                    let statusCode = '';
                    if (passwordRecoveryData.systemFailure) {
                        statusCode = 500;
                    }
                    else {
                        statusCode = passwordRecoveryData.status ? passwordRecoveryData.status : 500;
                    }
                    const eventLog = {
                        memberId: "",
                        eventSource: "Password Recovery",
                        eventType: "Failed",
                        eventDescription: `The system failed to unlock member ${values.userID} account. Reason: Status Code ${statusCode}.`
                    }
                    dispatch(EventsLogActions(eventLog));
                    // Redirect to Login after 5s
                    setTimeout(handleCancel, 5000);
                }
                setErrorMessages(errorMessagesArray);
                passwordRecoveryRef.current.scrollIntoView({
                    behavior: "smooth"
                });
            } else {
                setOpen(true);
            }
        }
    }, [passwordRecoveryData]);

    // Method to set field values on change
    const handleChanges = name => event => {
        setValues({ ...values, [name]: event.target.value });
    };

    // Method to validate User Id Form
    const validateUserIDForm = () => {
        errorMessagesArray = [];
        setErrorMessages([]);
        var userIDError = false;
        var userIDErrorText = '';

        if (values.userID === '' || (values.userID && values.userID.trim() === '')) {
            userIDError = true;
            userIDErrorText = ResetProfileConstants.USER_ID + ResetProfileConstants.REQUIRED;
            errorMessagesArray.push(userIDErrorText);
        }
        if (!userIDError && !AppConstants.USER_ID_REGEX.test(values.userID)) {
            userIDError = true;
            userIDErrorText = AppConstants.USER_ID_INVALID;
            errorMessagesArray.push(userIDErrorText);
        }

        setErrorMessages(errorMessagesArray);
        setShowError({
            userIDError: userIDError
        });

        setShowErrorText({
            userIDErrorText: userIDErrorText
        });

        if (errorMessagesArray && errorMessagesArray.length > 0) {
            return true
        } else {
            return false;
        }
    };

    // Method to submit User ID Form
    const submitUserIdForm = () => {
        if (!validateUserIDForm()) {
            const getSecurityQsRequest = {
                userId: values.userID
            };
            setSpinnerLoader(true);
            getSecurityQsDispatch(getSecurityQsRequest);
        }
    };

    // Method to validate Password Recovery Form
    const validatePasswordRecoveryForm = () => {
        errorMessagesArray = [];
        setErrorMessages([]);
        var answer1Error; var answer2Error; var answer3Error = false;
        var answer1ErrorText; var answer2ErrorText; var answer3ErrorText = '';

        if (values.answer1 === '' || (values.answer1 && values.answer1.trim() === '')) {
            answer1Error = true;
            answer1ErrorText = ResetProfileConstants.ANSWER + ResetProfileConstants.REQUIRED;
            errorMessagesArray.push(ResetProfileConstants.ANSWER + ' for Security Question 1' + ResetProfileConstants.REQUIRED);
        }
        if (values.answer2 === '' || (values.answer2 && values.answer1.trim() === '')) {
            answer2Error = true;
            answer2ErrorText = ResetProfileConstants.ANSWER + ResetProfileConstants.REQUIRED;
            errorMessagesArray.push(ResetProfileConstants.ANSWER + ' for Security Question 2' + ResetProfileConstants.REQUIRED);
        }
        if (values.answer3 === '' || (values.answer3 && values.answer1.trim() === '')) {
            answer3Error = true;
            answer3ErrorText = ResetProfileConstants.ANSWER + ResetProfileConstants.REQUIRED;
            errorMessagesArray.push(ResetProfileConstants.ANSWER + ' for Security Question 3' + ResetProfileConstants.REQUIRED);
        }

        setErrorMessages(errorMessagesArray);
        setShowError({
            answer1Error: answer1Error,
            answer2Error: answer2Error,
            answer3Error: answer3Error
        });

        setShowErrorText({
            answer1ErrorText: answer1ErrorText,
            answer2ErrorText: answer2ErrorText,
            answer3ErrorText: answer3ErrorText
        });

        if (errorMessagesArray && errorMessagesArray.length > 0) {
            passwordRecoveryRef.current.scrollIntoView({
                behavior: "smooth"
            });
            return true
        } else {
            return false;
        }
    };

    // Method to submit Password Recovery Form
    const submitPasswordRecoveryForm = () => {
        if (!validatePasswordRecoveryForm()) {
            const passwordRecoveryRequest = {
                userId: values.userID,
                challengeResponses: [
                    { challenge: values.secQs1, response: values.answer1 },
                    { challenge: values.secQs2, response: values.answer2 },
                    { challenge: values.secQs3, response: values.answer3 }
                ],
            }
            setSpinnerLoader(true);
            passwordRecoveryDispatch(passwordRecoveryRequest);
        }
    };
    return (
        <div className="password-recovery-container">
            <div className="text-float pb-1" ref={passwordRecoveryRef}></div>
            {spinnerLoader ? <Spinner /> : null}
            <div className="forgot-username-container">
                {errorMessages && errorMessages.length > 0 ? <div className="form-wrapper col-sm-12 px-0">
                    <ErrorMessages errorMessages={errorMessages} />
                </div> : null}
                <div className="text-float pb-3 font-weight-600">
                    <a href="/contactUs" target="_blank">Contact Us</a>
                    <span className="blue-font px-1">|</span>
                    <a href="/Help2" onClick={()=>{localStorage.setItem("ioxHelpDoc","member")}} target="_blank">Help</a>
                </div>
                <div className="form-wrapper col forgot-password-container d-inline-block">
                    <h5 className="mb-4">PASSWORD RECOVERY</h5>
                    <form autoComplete="off" onSubmit={e => { e.preventDefault(); }}>
                        <div className="row px-3 py-0">
                            <div className="form-wrapper col px-0">
                                <div className="mui-custom-form col p-0 m-0">
                                    <TextField
                                        required
                                        className="field-padding field-family-font"
                                        id="standard-user-id"
                                        disabled={openPasswordForm}
                                        value={values.userID}
                                        label={ResetProfileConstants.USER_ID}
                                        onChange={handleChanges('userID')}
                                        inputProps={{ maxLength: 30 }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        error={userIDError}
                                        helperText={userIDError ? userIDErrorText : null}
                                    />
                                </div>
                                <div className={openPasswordForm ? "d-none" : (!userIDError ? "col-3 form-wrapper p-0 ml-4 d-flex align-items-end" : "col-3 form-wrapper p-0 ml-4 d-flex align-items-end dynamic-btn-margin")}>
                                    <Button id="next" className='btn-primary m-0' style={{ height: '2.5rem' }}
                                        onClick={submitUserIdForm}>Next</Button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                {openPasswordForm ?
                    <div className="col d-inline-block forgot-password-container">
                        <div className="sub-header p-0">Please Answer the below questions</div>
                        <form autoComplete="off" className="form-styles field-family-font">
                            <div className="col p-0">
                                <div className="mui-custom-form question-field-margin ">
                                    <TextField
                                        required
                                        id="standard-sq1"
                                        label={values.secQs1}
                                        value={values.answer1}
                                        onChange={handleChanges('answer1')}
                                        inputProps={{ maxLength: 50 }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        error={answer1Error}
                                        helperText={answer1Error ? answer1ErrorText : null}
                                    />
                                </div>
                                <div className="mui-custom-form question-field-margin">
                                    <TextField
                                        required
                                        id="standard-sq2"
                                        label={values.secQs2}
                                        value={values.answer2}
                                        onChange={handleChanges('answer2')}
                                        inputProps={{ maxLength: 50 }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        error={answer2Error}
                                        helperText={answer2Error ? answer2ErrorText : null}
                                    />
                                </div>
                                <div className="mui-custom-form question-field-margin">
                                    <TextField
                                        required
                                        id="standard-sq3"
                                        label={values.secQs3}
                                        value={values.answer3}
                                        onChange={handleChanges('answer3')}
                                        inputProps={{ maxLength: 50 }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        error={answer3Error}
                                        helperText={answer3Error ? answer3ErrorText : null}
                                    />
                                </div>
                            </div>
                            <div className="form-wrapper mb-0">
                                <Button id="submit" className='col-sm btn-primary mr-sm-3 w-100' onClick={submitPasswordRecoveryForm}>SUBMIT</Button>
                                <Button className='col-sm btn-transparent w-100' onClick={handleCancel}>CANCEL</Button>
                            </div>
                        </form>
                    </div> : null}
                <AlertDialog
                    open={open}
                    setOpen={setOpen}
                    page="passwordRecovery"
                    props={props}
                />
            </div>
        </div>
    )
}
