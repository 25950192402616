import React, { Component } from 'react';
import WindowDimensions from './hooks/WindowDimensions';

export default function ApiDocs() {
    const { height, width } = WindowDimensions();
    var __url = process.env.REACT_APP_SWAGGER_BUNDLE_JSON;
    var __html = "<iframe id='SwaggerDocs' src=/swagger-ui/?" + __url + " style='width:100%;height:" + (height-160) + "px'/>";
    var template = { __html: __html };

    return (
        <div className="Swagger-docs">
            <span dangerouslySetInnerHTML={template} />
        </div>
    )
}
