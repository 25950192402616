import React from 'react';
import Card from 'react-bootstrap/Card';

import Table from 'react-bootstrap/Table';
import styles from './ClinicalOrganization.css';


export default function ClinicalOrganization(){
let ClinicalOrganizationresponse={ "resourceType": "Organization", "id": "590099003332", "meta": { "lastUpdated": "2020-12-14T14:16:15.500+00:00", "profile": [ "http://hl7.org/fhir/us/carin-bb/StructureDefinition/C4BB-Organization" ] }, "identifier": [ { "type": { "coding": [ { "system": "http://terminology.hl7.org/CodeSystem/v2-0203", "code": "RI", "display": "Organization Id" } ] }, "value": "590099003332" }, { "type": { "coding": [ { "system": "http://hl7.org/fhir/us/carin-bb/CodeSystem/C4BBIdentifierType", "code": "npi" } ] }, "system": "http://hl7.org/fhir/sid/us-npi", "value": "Organization_NPI" }, { "type": { "coding": [ { "system": "http://terminology.hl7.org/CodeSystem/v2-0203", "code": "TAX" } ] }, "system": "urn:oid:2.16.840.1.113883.4.4", "value": "TAX_ID_NUMBER" }, { "type": { "coding": [ { "system": "http://hl7.org/fhir/us/carin-bb/CodeSystem/C4BBIdentifierType", "code": "naiccode" } ] }, "system": "urn:oid:2.16.840.1.113883.6.300", "value": "NAIC_CODE_PAYOR" } ], "active": true, "type": [ { "coding": [ { "system": "http://terminology.hl7.org/CodeSystem/organization-type", "code": "pay" } ], "text": "Payer" } ], "name": "Star Club", "telecom": [ { "system": "phone", "value": "123456799" } ], "address": [ { "type": "both", "line": [ "Main Street", "string", "Main Streetstring" ], "city": "Nixa", "state": "Missourie", "postalCode": "54896", "country": "USA" } ] }
const codestyle={backgroundColor: 'lightgray'};
return (
<div className="container">
<Card className="card-background"> <Card.Body>
<blockquote className="blockquote mb-0">
<h1 className="text-header">Organization</h1>
<p>This profile builds upon the US Core Organization profile. It is used to convey a payer, provider, payee or service facility organization information.</p>
</blockquote> </Card.Body>

</Card>
<br/>
<hr className="horizontal-rule"></hr>
<br/>

<Card className="card-background">
<Card.Body>
<h4 className="ClinicalOrganization-heading">URL</h4>
<div>
<codeblock className="accesstoken-code"> URL</codeblock>
<span>&nbsp;</span><span>&nbsp;</span>
<codeblock className="accesstoken-code">{process.env.REACT_APP_EDUCATIONAL_MATERIAL_URL}clinicalData/api/R4/Organization</codeblock>
</div>
</Card.Body>
<Card.Body>
<h4 className="ClinicalOrganization-heading">Headers</h4>
<Table striped bordered hover>
<tbody>

<tr>
<td>Accept</td>
<td>application/json</td>
</tr>
<tr> <td>Authorization</td>
<td>Bearer 'ACCESS_TOKEN'</td>
</tr>
</tbody>
</Table>
</Card.Body>
<p>Note:Third party app will have access token after member has given consent to app (Refer Member consent section)
</p>
<Card.Body>
<h4 className="ClinicalOrganization-heading">Sample Request</h4>

<div style={codestyle}>'curl --location 'https://iox.dev.sddss.apps.conduent.com/clinicalData/api/R4/Organization?name=Hyder%20Ali''\<br/>
                                    '--header 'Authorization: Bearer7Sp6WPj9AcmSLJMD_6u-bVZHp-4' '\<br/>
                                     '--header 'Cookie: JSESSIONID=bp4uCPJH3YbKA9eGuXDDh380zbq1oFPOuE9NsWNi' '\
</div>

<h4 className="Organization-heading">Sample Response</h4>

<pre style={codestyle}>
<code class="prettyprint">
{JSON.stringify(ClinicalOrganizationresponse,null,'  ')}
</code>
</pre>

</Card.Body>
</Card>
</div>
);
}