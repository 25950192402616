import React from 'react';
import TextField from '@material-ui/core/TextField';
import * as AppConstants from '../../../SharedModule/Utils/AppConstants';
//import * as RegistrationConstants from './RegistrationConstants';

export default function EmailVerificationForm(props) {
    const [values, setValues] = React.useState({
        verificationCode: props.verCode !== '' ? props.verCode : ''
    });

    // Method to set field values on change
    const handleChanges = name => event => {
        props.handleVerificationCodeChange(event.target.value);
        setValues({ ...values, [name]: event.target.value.replace(AppConstants.NUMBER_ONLY_REGEX, '') });
    };

    return (
        <div className="">
            <form autoComplete="off" onSubmit={e => { e.preventDefault(); }}>
            <div className="mui-custom-form dialog-textbox col-sm-12 m-0 pt-0 px-0">
                <TextField
                    required
                    id="standard-verficationCode"
                    placeholder="Enter Here"
                    label="Please enter the Verification Code sent to your Email Address."
                    value={values.verificationCode}
                    inputProps={{ maxLength: 6 }}
                    onChange={handleChanges('verificationCode')}
                    InputLabelProps={{
                        shrink: true
                    }}
                    error={props.verificationCodeError}
                    helperText={props.verificationCodeError ? props.verificationCodeErrorText : null}
                />
            </div>
            </form>
        </div>
    )
}
