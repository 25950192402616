import * as actionTypes from '../Actions/AppActionTypes';

const axiosPayLoad = {
  payload: null
};
const reducer = (state = axiosPayLoad, action) => {
  switch (action.type) {
    case actionTypes.APP_CONFIG_DROPDOWNS:
      return { ...state, appConfigDropdown: action.appConfigDropdown };
    case actionTypes.EVENTS_LOG:
      return { ...state, eventsLog: action.eventsLog };
    case actionTypes.SYSTEM_EVENTS_LOG:
        return { ...state, systemEventsLog: action.systemEventsLog };
    case actionTypes.LOGOUT:
        return { ...state, logout: action.logout };
    case actionTypes.SET_PRINT_LAYOUT:
      return { ...state, printLayout: action.printLayout };
    case actionTypes.RESET_LOGOUT_DATA:
      return { ...state, [action.key]: '' };
    default: return state;
  }
};

export default reducer;
