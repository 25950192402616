import React from 'react';
import CancelIcon from '@material-ui/icons/Cancel';

export default function NotFoundForm(props) {
    return (
    <div className="container">
        <div className="success-container">
            <div className="text-danger">
                <CancelIcon className="mx-auto d-block h1" />
                <h6 className="text-center font-weight-bold my-3">Page Not Found</h6>
            </div>
        </div>
    </div>
    )
}
