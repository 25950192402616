/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';

export default function DisplayErrorMessages (props) {
  return <div className="w-100">{props.errorMessages.length > 0 ? <ul className="alert alert-danger custom-alert" role="alert" >
    {props.errorMessages.map((message, index) => <li key ={index}>{message}</li>)
    }
  </ul> : null
  }</div>;
}

DisplayErrorMessages.propTypes = {
  errorMessages: PropTypes.array.isRequired
};
