import * as actionTypes from './AppActionTypes';
import * as serviceEndPoint from '../../Service/service';
import axios from 'axios';

export const AppConfigDropdown = (appConfigDropdown) => {
  return ({
    type: actionTypes.APP_CONFIG_DROPDOWNS,
    appConfigDropdown: appConfigDropdown
  });
};
export const AppConfigDropdownActions = (value) => {
  return dispatch => {
   return axios.post(`${serviceEndPoint.REACT_APP_DROPDOWN_ENV}`, value)
    //return axios.post('http://10.142.119.208/esb/appconfig/configKeyList', value)
      .then(response => {
        dispatch(AppConfigDropdown(response.data));
      })
      .catch(error => {
        dispatch(AppConfigDropdown({ systemFailure: true }));
      });
  };
};
export const EventsLog = (eventsLog) => {
  return ({
    type: actionTypes.EVENTS_LOG,
    eventsLog: eventsLog
  });
};
export const EventsLogActions = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.EVENTS_LOG}`, value)
      .then(response => {
        dispatch(EventsLog(response.data));
      })
      .catch(error => {
        dispatch(EventsLog({ systemFailure: true }));
      });
  };
};

export const SystemEventsLog = (systemEventsLog) => {
  return ({
    type: actionTypes.SYSTEM_EVENTS_LOG,
    systemEventsLog: systemEventsLog
  });
};

export const SystemEventsLogActions = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.SYSTEM_EVENTS_LOG}`, value)
      .then(response => {
        dispatch(SystemEventsLog(response.data));
      })
      .catch(error => {
        dispatch(SystemEventsLog({ systemFailure: true }));
      });
  };
};
export const Logout = (logout) => {
  return ({
    type: actionTypes.LOGOUT,
    logout: logout
  });
};
export const LogoutActions = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.LOGOUT}`, value)
      .then(response => {
        dispatch(Logout(response.data));
      })
      .catch(error => {
        dispatch(Logout({ systemFailure: true }));
      });
  };
};


export const setPrintLayout = (data) => {
  return {
    type: actionTypes.SET_PRINT_LAYOUT,
    printLayout: data
  };
};

export const resetLogoutData = (key) => {
  return {
    type: actionTypes.RESET_LOGOUT_DATA,
    key: key
  };
};