import * as actionTypes from './FeedBackActionTypes';
import * as serviceEndPoint from '../../../../SharedModule/Service/service';
import axios from 'axios';

export const GetFeedbackMonthsActions = () => {
  return dispatch => {
    return axios.get(`${serviceEndPoint.FEEDBACK_MONTHS}`)
      .then(response => {
        dispatch(GetFeedbackMonth(response.data));
      })
      .catch(error => {
        dispatch(GetFeedbackMonth({ systemFailure: true }));
      });
  };
};
export const GetFeedbackMonth = (feedbackMonths) => {
  return ({
    type: actionTypes.FEEDBACK_MONTHS,
    feedbackMonths: feedbackMonths
  });
};

export const FeedbackReportActions = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.FEEDBACK_REPORT}`, value)
      .then(response => {
        dispatch(FeedbackReport(response.data));
      })
      .catch(error => {
        dispatch(FeedbackReport({ systemFailure: true }));
      });
  };
};
export const FeedbackReport = (feedbackReport) => {
  return ({
    type: actionTypes.FEEDBACK_REPORT,
    feedbackReport: feedbackReport
  });
};

export const resetFeedbackData = (key) => {
  return {
    type: actionTypes.RESET_FEEDBACK_DATA,
    key: key
  };
};