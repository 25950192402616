/* eslint-disable no-unused-vars */
import React from "react";
import { Redirect } from 'react-router';
import { Route, Switch } from "react-router-dom";
import Home from './Modules/Login/Components/Home';
// import Login from './Modules/Login/Components/Login';
import Login from './Modules/Login/Components/adminLogin'; // changed for admin login through ForgeRock
import Authorize from './Modules/Login/Components/Authorize';
import SDLogin  from './Modules/Login/Components/SDLogin';
// import SDMember  from './Modules/Login/Components/SDMemberconsent';
// import ThirdPartySd  from './Modules/Login/Components/Thirdparty';

import AdminLogin from './Modules/Login/Components/adminLogin';
import TermsOfUse from './Modules/Login/Components/TermsOfUse';
import PrivacyPolicy from './Modules/Login/Components/PrivacyPolicy';
import BrowserRequirements from './Modules/Login/Components/BrowserRequirement';
import AccessibilityCompliance from './Modules/Login/Components/AccessibilityCompliance';
import Registration from './Modules/Registration/Components/Registration';
import MemberConsent from './Modules/Registration/Components/MemberConsent';
import AppRegistration from './Modules/AppRegistration/Components/AppRegistration';
import PayerRegistration from './Modules/PayerRegistration/Components/PayerRegistration';
import AppSearch from './Modules/AppRegistration/Components/AppSearch';
import AppPayerManagement from './Modules/AppRegistration/Components/AppPayerManagement';
import MemberManagement from './Modules/MemberManagement/Components/MemberManagement';
import PayerSearch from './Modules/PayerRegistration/Components/PayerSearch';
import AppApproveReject from './Modules/AppRegistration/Components/AppApproveReject';
import PayerApproveReject from './Modules/PayerRegistration/Components/PayerApproveReject';
// import AppRevoke from './Modules/AppRegistration/Components/AppRevoke';
import AppPrivacyPolicy from './Modules/AppRegistration/Components/AppPrivacyPolicy';
import RegistrationOptions from './Modules/Registration/Components/RegistrationOptions';
import OldPayerDataExchangeConsent from './Modules/Registration/Components/OldPayerDataExchangeConsent';
import OldPayerDataExchangeDetails from './Modules/Registration/Components/OldPayerDataExchangeDetails';
import ForgotUserID from './Modules/ResetProfile/Components/ForgotUserID';
import PasswordRecovery from './Modules/ResetProfile/Components/PasswordRecovery';
import RegistrationSuccess from './Modules/Registration/Components/RegistrationSuccess';
import Claims from './Modules/Claims/Components/Claims';
import ChangePassword from './Modules/ResetProfile/Components/ChangePassword';
import FeedBack from './Modules/FeedBackReport/Components/Feedback';
import AdminView from './Modules/Login/Components/AdminView';
import ContactUs from './Modules/ContactUs/Components/ContactUs';
import GenericNotFound from './Modules/GenericNotFound/Components/404NotFound';
import PDFViewer from './SharedModule/PDF/PDFViewer';
import Wip from './SharedModule/WIP/Wip';
import Help from './Modules/Help/Help';
import DocViewer from './SharedModule/PDF/docViewer';
import Wrapper from './SharedModule/Layout/wrapper';
import NonWrapper from './SharedModule/Layout/nonWrapper';
import Reports from './Modules/Reports/Components/Reports';
import ConsentsManagement from './Modules/ConsentsManagement/Components/ConsentsManagement';
import PDF_View from './Modules/Reports/Components/PDF_Report';
import PayerConsent from './Modules/PayerRegistration/Components/PayerConsent';
import PayerConsentCpy from './Modules/PayerRegistration/Components/payeconsent_cpy';
import axios from 'axios';

// Importing Review Educational Material Component
import Review from './Modules/ReviewEducationalMaterial/Components/Review';
import ReviewWrapper from './Modules/ReviewEducationalMaterial/Components/ReviewWrapper';
import ThirdPartyAppRegistration from './Modules/ReviewEducationalMaterial/Components/ThirdPartyAppRegistration';
import OAuth from './Modules/ReviewEducationalMaterial/Components/OAuth';
import MemberRegistration from './Modules/ReviewEducationalMaterial/Components/MemberRegistration';
import EOB from './Modules/ReviewEducationalMaterial/Components/EOB';
import Patient from './Modules/ReviewEducationalMaterial/Components/Patient';
import USCORE from './Modules/ReviewEducationalMaterial/Components/USCORE';
import Coverage from './Modules/ReviewEducationalMaterial/Components/Coverage';
import Practitioner from './Modules/ReviewEducationalMaterial/Components/Practitioner';
import Organization from './Modules/ReviewEducationalMaterial/Components/Organization';
import List from './Modules/ReviewEducationalMaterial/Components/List';
import MedicationKnowledge from './Modules/ReviewEducationalMaterial/Components/MedicationKnowledge';
import ProviderPractitioner from './Modules/ReviewEducationalMaterial/Components/ProviderPractitioner';
import ClinicalCoverage from './Modules/ReviewEducationalMaterial/Components/ClinicalCoverage';
import ClinicalPractitioner from './Modules/ReviewEducationalMaterial/Components/ClinicalPractitioner';
import AllergyTolerance from './Modules/ReviewEducationalMaterial/Components/AllergyTolerance';
import CareTeam from './Modules/ReviewEducationalMaterial/Components/CareTeam';
import CarePlan from './Modules/ReviewEducationalMaterial/Components/CarePlan';
import ClinicalOrganization from './Modules/ReviewEducationalMaterial/Components/ClinicalOrganization';
import ApiDocs from './ApiDocs';
import ApiDocsPub from './ApiDocsPub';
import MemberInfo from "./Modules/ReviewEducationalMaterial/Components/MemberInfo";
import SwaggerReference from "./Modules/ReviewEducationalMaterial/Components/SwaggerReference";

export default function RoutesApp(props) {
    const isLoggedIn = sessionStorage.getItem('isLoggedIn');
    const cnRole = localStorage.getItem('cnRole');
    const token = sessionStorage.getItem('token');
    const idToken = sessionStorage.getItem('idToken');
    axios.defaults.headers.common['x-auditUser'] = isLoggedIn;

    
    return (
        <React.Fragment>
            <Switch>
                <PublicRoute path="/SDLogin/:type?" component={SDLogin} />
               <PublicRoute  path="/authorize" component={Authorize} />


                <PublicRoute exact path="/" component={Home} />
                <PublicRoute path="/home" component={Home} />
                <PublicRoute path="/login" component={Login} />
                <PublicRoute path="/adminLogin" component={AdminView} />
                <PublicRoute path="/registration" component={Registration} />
                <PublicRoute path="/registrationOptions" component={RegistrationOptions} />
                <PublicRoute path="/oldpayerDataExchangeConsent" component={OldPayerDataExchangeConsent} />
                <PublicRoute path="/oldpayerDataExchangeDetails" component={OldPayerDataExchangeDetails} />
                <PublicRoute path="/forgotUserID" component={ForgotUserID} />
                <PublicRoute path="/passwordRecovery" component={PasswordRecovery} />
                <PublicRoute path="/changePassword" component={ChangePassword} />
                <PublicRoute path="/success" component={RegistrationSuccess} />
                <PublicRoute path='/claims' component={Claims} />
                <PublicRoute path='/feedback' component={FeedBack} />
                <PublicRoute path='/contactUs' component={ContactUs} />
                <PublicRoute path='/termsOfUse' component={TermsOfUse} />
                <PublicRoute path='/privacyPolicy' component={PrivacyPolicy} />
                <PublicRoute path='/BrowserRequirements' component={BrowserRequirements} />
                <PublicRoute path='/AccessibilityCompliance' component={AccessibilityCompliance} />
                <PublicRoute path='/Help' component={PDFViewer} />
                <PublicRoute path="/appRegistration" component={AppRegistration} />
                <PublicRoute path='/Help2' component={Help} />
                <PublicRoute path='/doc' component={DocViewer} />
                <PublicRoute path='/test' component={PDF_View} />
                <PublicRoute path="/payerRegistration" component={PayerRegistration} />
                <PublicRoute path="/payerConsent" component={PayerConsent} />
                <PublicRoute path="/payerConsentcpy" component={PayerConsentCpy} />
                <PublicRoute  path='/appPrivacyPolicy' component={AppPrivacyPolicy}/>

                <PrivateRoute userId={token && cnRole == "High" ? isLoggedIn : null} path='/appPayerManagement' component={AppPayerManagement} redirectPath='/login' />

                <PrivateRoute userId={token ? isLoggedIn : null} path='/memberManagement' component={MemberManagement} redirectPath='/login' />

                <PrivateRoute userId={token ? isLoggedIn : null} path='/consentsManagement'component={ConsentsManagement} redirectPath='/login'/>

                <PrivateRoute userId={token && cnRole == "High" ? isLoggedIn : null} path='/reports' component={Reports} redirectPath='/login' />

                <PublicRoute  path='/memberconsent' component={MemberConsent}/>
                <PrivateRoute userId={isLoggedIn} path='/appSearch' component={AppSearch} redirectPath='/login' />
                {/*disabled payer management routes for MO*/}
                {/* <PrivateRoute userId={isLoggedIn} path='/payerSearch' component={PayerSearch} redirectPath='/login' /> */}
                <PrivateRoute userId={token ? isLoggedIn : null} path='/appApproveReject' component={AppApproveReject} redirectPath='/login' />
                <PrivateRoute userId={token ? isLoggedIn : null} path='/payerApproveReject' component={PayerApproveReject} redirectPath='/login'/>
                <PublicRoute path='/static' component={GenericNotFound} />
                <PublicRoute path="/wip" component={Wip} />

                //Creating Public route for ReviewEducationalMaterial
                <PublicRoute path="/reviewEducation" component={Review} />

                <ReviewRoute exact path="/ThirdPartyAppRegistration" component={ThirdPartyAppRegistration}/>
                <ReviewRoute exact path="/OAuth" component={OAuth}/>
                <ReviewRoute exact path="/MemberRegistration" component={MemberRegistration}/>

                <ReviewRoute exact path="/EOB" component={EOB}/>
                <ReviewRoute exact path="/Patient" component={Patient}/>
                <ReviewRoute exact path="/USCORE" component={USCORE}/>
                <ReviewRoute exact path="/Coverage" component={Coverage}/>
                <ReviewRoute exact path="/Practitioner" component={Practitioner}/>
                <ReviewRoute exact path="/Organization" component={Organization}/>
                <ReviewRoute exact path="/List" component={List}/>
                <ReviewRoute exact path="/MedicationKnowledge" component={MedicationKnowledge}/>
                <ReviewRoute exact path="/ProviderPractitioner" component={ProviderPractitioner}/>
                <ReviewRoute exact path="/ClinicalCoverage" component={ClinicalCoverage}/>
                <ReviewRoute exact path="/ClinicalPractitioner" component={ClinicalPractitioner}/>
                <ReviewRoute exact path="/AllergyTolerance" component={AllergyTolerance}/>
                <ReviewRoute exact path="/CareTeam" component={CareTeam}/>
                <ReviewRoute exact path="/CarePlan" component={CarePlan}/>
                <ReviewRoute exact path="/ClinicalOrganization" component={ClinicalOrganization}/>
                <ReviewRoute exact path="/MemberInfo" component={MemberInfo}/>
                <ReviewRoute exact path="/SwaggerReference" component={SwaggerReference}/>

                <PublicRoute path='/fhir-api-docs' component={ApiDocs} />
                <PublicRoute path='/api-docs' component={ApiDocsPub} />
                <PublicRoute path='*' exact={true} component={GenericNotFound} />

            </Switch>
        </React.Fragment>
    );
}


function PrivateRoute({ component: Component, userId, redirectPath, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) => userId
                ? <Wrapper><Component {...props} /></Wrapper>
                : <Redirect to={{ pathname: redirectPath }} />}
        />
    )
}

function PublicRoute({ component: Component, ...rest }) {
    return (
        <Route {...rest} render={props => (
            <NonWrapper><Component {...props} /></NonWrapper>
        )} />
    )
}
//Review Wrapper
function ReviewRoute ({component: Component, ...rest}) {
  return (
    <Route {...rest} render={props => (
      <ReviewWrapper><Component {...props} /></ReviewWrapper>
    )} />
  )
}