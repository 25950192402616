import React from 'react';
import Card from 'react-bootstrap/Card';

import Table from 'react-bootstrap/Table';
import styles from './ProviderPractitioner.css';


export default function ProviderPractitioner(){
let ProviderPractitionerresponse={ "resourceType": "Practitioner", "id": "1123800032432", "meta": { "lastUpdated": "2021-02-08T12:22:32.642+00:00", "profile": [ "http://hl7.org/fhir/us/davinci-pdex-plan-net/StructureDefinition/plannet-Practitioner" ] }, "extension": [ { "url": "http://hl7.org/fhir/us/davinci-pdex-plan-net/StructureDefinition/communication-proficiency", "valueCodeableConcept": { "coding": [ { "system": "http://hl7.org/fhir/us/davinci-pdex-plan-net/CodeSystem/LanguageProficiencyCS", "code": "10" }, { "system": "urn:ietf:bcp:47", "code": "ru", "display": "Russian" } ] } } ], "identifier": [ { "type": { "coding": [ { "system": "http://terminology.hl7.org/CodeSystem/v2-0203", "code": "RI", "display": "Pract Id" } ] }, "system": "http://terminology.hl7.org/CodeSystem/v2-0203", "value": "1123800032432" }, { "system": "http://hl7.org/fhir/sid/us-npi", "value": "NPI" } ], "active": true, "name": [ { "text": "Heather Lee", "family": "Lee", "given": [ "Heather", "test" ], "prefix": [ "Ms" ] } ], "telecom": [ { "system": "phone", "value": "(111)-222-3333", "use": "work", "rank": 1 } ], "address": [ { "extension": [ { "url": "http://hl7.org/fhir/StructureDefinition/geolocation", "extension": [ { "url": "latitude", "valueDecimal": 14.58 }, { "url": "longitude", "valueDecimal": 12.95 } ] } ], "use": "work", "type": "postal", "line": [ "main road", "cloverfield" ], "city": "New York", "state": "New York", "postalCode": "896352" } ], "gender": "female", "communication": [ { "coding": [ { "system": "http://hl7.org/fhir/us/davinci-pdex-plan-net/CodeSystem/LanguageProficiencyCS", "code": "10" }, { "system": "urn:ietf:bcp:47", "code": "ru", "display": "Russian" } ] } ] };
const codestyle={backgroundColor: 'lightgray'};
return (
<div className="container">
<Card className="card-background"> <Card.Body>
<blockquote className="blockquote mb-0">
<h1 className="text-header">Practitioner</h1>
<p>Practitioner covers all individuals who are engaged in the healthcare process and healthcare-related services as part of their formal responsibilities and this Resource is used for attribution of activities and responsibilities to these individuals.</p>
</blockquote> </Card.Body>
</Card>
<br/>
<hr className="horizontal-rule"></hr>
<br/>

<Card className="card-background">
<Card.Body>
<h4 className="ProviderPractitioner-heading">URL</h4>
<div>
<codeblock className="accesstoken-code"> URL</codeblock>
<span>&nbsp;</span><span>&nbsp;</span>
<codeblock className="accesstoken-code">{process.env.REACT_APP_EDUCATIONAL_MATERIAL_URL}providerDirectory/api/R4/Practitioner</codeblock>
</div>
</Card.Body>
<Card.Body>
<h4 className="ProviderPractitioner-heading">Headers</h4>
<Table striped bordered hover>
<tbody>

<tr>
<td>Accept</td>
<td>application/json</td>
</tr>
<tr> <td>Authorization</td>
<td>Bearer 'ACCESS_TOKEN'</td>
</tr>
</tbody>
</Table>
</Card.Body>
<p>Note:Third party app will have access token after member has given consent to app (Refer Member consent section)
</p>
<Card.Body>
<h4 className="ProviderPractitioner-heading">Sample Request</h4>

<div style={codestyle}>'curl --location 'https://iox.dev.sddss.apps.conduent.com/providerDirectory/api/R4/Practitioner/1123800032432''\<br/>
                                    '--header 'Authorization: Bearer zz62VWb5BCBlTmAgNk85wrTvy2c' '\
</div>

<h4 className="ProviderPractitioner-heading">Sample Response</h4>

<pre style={codestyle}>
<code class="prettyprint">
{JSON.stringify(ProviderPractitionerresponse,null,'  ')}
</code>
</pre>
</Card.Body>
</Card>
</div>
);
}