/* eslint-disable array-callback-return */
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

export default function GridView(props) {

  const { feedbackData } = props;

    function getTotal(data, flag) {
        var total = 0;
        data.map((row) => {
            if (flag === 'Yes') {
                total = total + row.yesCount;
            } else if (flag === 'No') {
                total = total + row.noCount;
            } else {
                total = total + row.otherCount;
            }
        });
        return total;
    }
  
  return (
    <TableContainer component={Paper} className="feedback-table-container overflow-table">
      <Table className="col-12 p-0" aria-label="Feedback Grid View">
        <TableHead>
          <TableRow>
            <TableCell className="feedback-table-header"><span hidden aria-hidden="true">Feedback</span></TableCell>
            <TableCell className="feedback-table-header"><b>YES</b></TableCell>
            <TableCell className="feedback-table-header"><b>NO</b></TableCell>
            <TableCell className="feedback-table-header"><b>OTHER</b></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {feedbackData.map((row, index) => (
            <TableRow key={row.feedback} className="feedback-data">
              <TableCell className={index % 2 === 1 ? "font-weight-bolder desc" : "font-weight-bolder"}>{row.feedback}</TableCell>
              <TableCell>{row.yesCount}</TableCell>
              <TableCell>{row.noCount}</TableCell>
              <TableCell>{row.otherCount}</TableCell>
            </TableRow>
          ))}
          <TableRow className="total">
            <TableCell className="font-weight-bolder">Total Feedback Received in the Month</TableCell>
            <TableCell className="font-weight-bolder">{getTotal(feedbackData, 'Yes')}</TableCell>
            <TableCell className="font-weight-bolder">{getTotal(feedbackData, 'No')}</TableCell>
            <TableCell className="font-weight-bolder">{getTotal(feedbackData, 'Other')}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
