import React from 'react';
import Card from 'react-bootstrap/Card';

import Table from 'react-bootstrap/Table';
import styles from './Coverage.css';


export default function Coverage(){
let CoverageResponse={ "resourceType": "Coverage", "id": "1001", "meta": { "lastUpdated": "2020-06-16T14:41:26.320+00:00", "profile": [ "http://hl7.org/fhir/us/carin-bb/StructureDefinition/C4BB-Coverage" ] }, "identifier": [ { "type": { "coding": [ { "system": "http://terminology.hl7.org/CodeSystem/v2-0203", "code": "RI", "display": "Coverage Id" } ] }, "value": "1001" }, { "type": { "coding": [ { "system": "http://terminology.hl7.org/CodeSystem/v2-0203", "code": "MB" } ] }, "value": "1001", "assigner": { "reference": "Organization/", "display": "Payer Name" } } ], "status": "active", "subscriberId": "888COVG001A", "beneficiary": { "reference": "Patient/202307071" }, "relationship": { "coding": [ { "system": "http://terminology.hl7.org/CodeSystem/subscriber-relationship", "code": "self" } ] }, "period": { "start": "2021-01-01T00:00:00+00:00", "end": "2021-12-31T00:00:00+00:00" }, "payor": [ { "reference": "Organization/12345", "display": "Payer Name" } ], "class": [ { "type": { "coding": [ { "system": "http://terminology.hl7.org/CodeSystem/coverage-class", "code": "group" } ] }, "value": "MEDICAID" }, { "type": { "coding": [ { "system": "http://terminology.hl7.org/CodeSystem/coverage-class", "code": "plan" } ] }, "value": "9993498-Healthy Families Health Plan" } ] }
const codestyle={backgroundColor: 'lightgray'};
return (
<div className="container">
<Card className="card-background"> <Card.Body>
<blockquote className="blockquote mb-0">
<h1 className="text-header">Coverage</h1>
<p>The Coverage resource is intended to provide the high-level identifiers and descriptors of a specific insurance plan for a specific individual - essentially the insurance card information. This may alternately provide the individual or organization, selfpay, which will pay for products and services rendered.</p>
</blockquote> </Card.Body>
</Card>
<br/>
<hr className="horizontal-rule"></hr>
<br/>

<Card className="card-background">
<Card.Body>
<h4 className="Coverage-heading">URL</h4>
<div>
<codeblock className="accesstoken-code"> URL</codeblock>
<span>&nbsp;</span><span>&nbsp;</span>
<codeblock className="accesstoken-code">{process.env.REACT_APP_EDUCATIONAL_MATERIAL_URL}patientAccess/api/R4/Coverage</codeblock>
</div>
</Card.Body>
<Card.Body>
<h4 className="Coverage-heading">Headers</h4>
<Table striped bordered hover>
<tbody>

<tr>
<td>Accept</td>
<td>application/json</td>
</tr>
<tr> <td>Authorization</td>
<td>Bearer 'ACCESS_TOKEN'</td>
</tr>
</tbody>
</Table>
</Card.Body>
<p>Note:Third party app will have access token after member has given consent to app (Refer Member consent section)
</p>
<Card.Body>
<h4 className="Coverage-heading">Sample Request</h4>

<div style={codestyle}>'curl --location 'https://iox.dev.sddss.apps.conduent.com/patientAccess/api/R4/Coverage/1001''\<br/>
                                    '--header 'Authorization: Bearer 7Sp6WPj9AcmSLJMD_6u-bVZHp-4' '\<br/>
                                     '--header 'Cookie: JSESSIONID=bp4uCPJH3YbKA9eGuXDDh380zbq1oFPOuE9NsWNi' '\
</div>

<h4 className="Coverage-heading">Sample Response</h4>
<pre style={codestyle}>
<code class="prettyprint">
{JSON.stringify(CoverageResponse,null,'  ')}

</code>
</pre>
</Card.Body>
</Card>
</div>
);
}