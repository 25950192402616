// import React from "react";
// import dateFnsFormat from "date-fns/format";
import * as moment from "moment";

const formatDate = (dt) => {
  if(dt){
  let ndt = new Date(dt);
  if (ndt.toString() === "Invalid Date") {
    return false;
  } else {
    return true;
  }
}
else{
  return true;
}
};

export function desc(a, b, orderBy) {

  if (a[orderBy] !== undefined && b[orderBy] !== undefined) {
    if (!isNaN(a[orderBy]) && !isNaN(b[orderBy]) && (''+b[orderBy]).length<17 && (''+a[orderBy]).length<17) {
      if((b[orderBy]==="" || b[orderBy]===null)  && a[orderBy]===0 ) {
        return 1;
      }
      else if ((a[orderBy]==="" || a[orderBy]===null)  && b[orderBy]===0 ) {
        return -1;
      }
      else if((a[orderBy]==="" || a[orderBy]===null) && (b[orderBy]==="" || b[orderBy]===null) ){
        return 0;
      }
      if (Number(b[orderBy]) < Number(a[orderBy])) {
        return 1;
      }
      else if (Number(b[orderBy]) > Number(a[orderBy])) {
        return -1;
      }

      
      return 0;
    } else if (formatDate(a[orderBy]) && formatDate(b[orderBy])) {
     
      if (a[orderBy] && b[orderBy] &&  moment(a[orderBy]).isBefore(b[orderBy])) {
        return -1;
      }
      if (a[orderBy] && b[orderBy] && moment(a[orderBy]).isAfter(b[orderBy])) {
        return 1;
      }
      if(!b[orderBy] && a[orderBy]!==''){
        return 1;
      }
     
      return 0;
    } else {
      
      if (a[orderBy] !== null && b[orderBy] !== null) {
        return typeof a[orderBy] == "string" && typeof b[orderBy] == "string" && a[orderBy].toLowerCase().localeCompare(b[orderBy].toLowerCase());
      } else if (a[orderBy] === null) {
        return 1;
      } else {
        return -1;
      }
    }
  }
}

export function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((firstElement, secondElement) => {
    const order = cmp(firstElement[0], secondElement[0]);
    if (order !== 0) return order;
    return firstElement[1] - secondElement[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function getSorting(order, orderBy) {
  let actual_orders =
    orderBy && orderBy.includes("-") ? orderBy.split("-")[0] : orderBy;

  return order === "desc"
    ? (firstElm, secondElm) => desc(firstElm, secondElm, actual_orders)
    : (firstElme, secondElme) => -desc(firstElme, secondElme, actual_orders);
}
