import React from "react";

export default function BrowserRequirement(props) {
  return (
    <div className="tabs-container container">
      <div className="contactUs-container pl-0 row">
        <div className="contactUs-subContainer">
          <div className="terms-of-use-head p-4">
            <b className="p-2 dib"> BROWSER REQUIREMENTS </b>
          </div>
          <div className="container-body p-4">
            <section className="block-elm px-2 mb-3">
              <h6 className="fw-600"> Browser Compatibility Statement </h6>
              <p className="mt-3">
                Conduent recognizes that our visitors may have various operating
                systems and Internet browsers. While we want every visitor to
                have the best possible experience on our website, we recognize
                that it is impossible to develop applications that work
                identically with all browsers. Therefore, we have designed,
                developed, and tested our applications to work correctly with the
                following browsers. If you would like to download the most
                current version, please click on a link below.
              </p>
            </section>
            <section>
              <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-6">
                  <table className="mytable center mt-3" cellPadding="6">
                    <thead className="browser-head">
                      <tr>
                        <th
                          scope="col"
                          className="browser-table-headers border-head"
                        >
                          Browser
                        </th>
                        <th scope="col" className="browser-table-headers">
                          Version
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td data-th="Department" className="browser-table-data">
                          <a
                            href="https://www.google.com/chrome/"
                            title="Update here"
                            target="_blank"
                          >
                            Chrome
                          </a>
                        </td>
                        <td data-th="Department" className="browser-table-data">
                          120.X and higher
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a
                            href="https://www.microsoft.com/en-us/edge/download"
                            title="Update here"
                            target="_blank"
                          >
                            Edge
                          </a>
                        </td>
                        <td>120.X and higher</td>
                      </tr>
                      <tr>
                        <td data-th="Toll-Free" className="browser-table-data">
                          <a
                            href="https://www.mozilla.org/en-US/firefox/"
                            title="Update here"
                            target="_blank"
                          >
                            Firefox
                          </a>
                        </td>
                        <td data-th="Department" className="browser-table-data">
                          120.X and higher
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-3"></div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
