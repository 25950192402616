import React from 'react';
import ReviewWrapper from './ReviewWrapper';

export default function Review(props) {

return(
<div>
<ReviewWrapper/>
</div>
)
};