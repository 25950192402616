import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Button } from 'react-bootstrap';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import * as AppConstants from '../Utils/AppConstants';

export default function AlertDialog(props) {
    return (
        <Dialog className='alert-dialog' open={props.open}>
            {
                (props.page === 'forgotUserId' ?
                    <div><div className="row">
                        <ErrorOutlineIcon className="alert-font" />
                        <p className="col-md-11 col-sm-11 col-10 px-1 py-3 font-weight-bold">An e-mail containing your User ID has been sent to your registered e-mail address. Please click OK to continue.</p>
                    </div>
                        <div className="flex-row">
                            <Button className="btn btn-primary btn-alert mr-2" onClick={() => AppConstants.backToLogin(props.props)}>OK</Button>
                        </div></div> :
                    (props.page === 'passwordRecovery' ?
                        <div><div className="row">
                            <ErrorOutlineIcon className="alert-font" />
                            <p className="col-md-11 col-sm-11 col-10 px-1 py-3 font-weight-bold">An e-mail has been sent to the e-mail address on file containing your new one-time password. Please click OK to continue.</p>
                        </div>
                            <div className="flex-row">
                                <Button className="btn btn-primary btn-alert mr-2" onClick={() => AppConstants.backToLogin(props.props)}>OK</Button>
                            </div></div> :
                        <div><div className="row">
                            <ErrorOutlineIcon className="alert-font" />
                            <p className="col-md-11 col-sm-11 col-10 px-1 py-3 font-weight-bold">Your password has been changed successfully. Please click OK to continue.</p>
                        </div>
                            <div className="flex-row">
                                <Button className="btn btn-primary btn-alert mr-2" onClick={() => AppConstants.backToClaims(props.props)}>OK</Button>
                            </div></div>)
                )
            }
        </Dialog>
    );
}