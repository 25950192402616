import React from 'react';
import Card from 'react-bootstrap/Card';

import Table from 'react-bootstrap/Table';
import styles from './MedicationKnowledge.css';


export default function MedicationKnowledge(){
let MedicationKnowledgeresponse={ "resourceType": "MedicationKnowledge", "id": "00002120001", "meta": { "lastUpdated": "2021-10-22T03:20:33.960+00:00", "profile": [ "http://hl7.org/fhir/us/davinci-drug-formulary/StructureDefinition/usdf-FormularyDrug" ] }, "extension": [ { "url": "http://hl7.org/fhir/us/davinci-drug-formulary/StructureDefinition/usdf-DrugTierID-extension", "valueCodeableConcept": { "coding": [ { "system": "http://hl7.org/fhir/us/davinci-drug-formulary/CodeSystem/usdf-DrugTierCS", "code": "brand", "display": "brand" } ] } }, { "url": "http://hl7.org/fhir/us/davinci-drug-formulary/StructureDefinition/usdf-PriorAuthorization-extension", "valueBoolean": false }, { "url": "http://hl7.org/fhir/us/davinci-drug-formulary/StructureDefinition/usdf-StepTherapyLimit-extension", "valueBoolean": false }, { "url": "http://hl7.org/fhir/us/davinci-drug-formulary/StructureDefinition/usdf-QuantityLimit-extension", "valueBoolean": true }, { "url": "http://hl7.org/fhir/us/davinci-drug-formulary/StructureDefinition/usdf-PlanID-extension", "valueString": "1102" } ], "code": { "coding": [ { "system": "http://www.nlm.nih.gov/research/umls/rxnorm", "code": "00002120001", "display": "AMYVID VIAL" } ] }, "status": "active", "medicineClassification": [ { "type": { "text": "DIAGNOSTIC RADIOPHARM - AMYLOID/TAU IMAGING" } } ] };
const codestyle={backgroundColor: 'lightgray'};
return (
<div className="container">
<Card className="card-background"> <Card.Body>
<blockquote className="blockquote mb-0">
<h1 className="text-header">Medication Knowledge</h1>
<p>This resource supports use cases for creation of and querying for medication information including attributes such as medication classifications, images of medications, costs and/or coverages, etc. This resource can be used to return medication information as part of a formulary or a catalogue.
Where the Medication resource is intended for the simple identification of a medication for prescribing, dispensing, or administering, the MedicationKnowledge resource is intended to provide more detailed information about the medication. Unlike the MedicinalProductDefinition resource, the MedicationKnowledge resource is not a complete definition of the medication but provides some definitional information along with formulary/catalogue-specific information such as costs, monitoring programs, etc.
</p>
</blockquote> </Card.Body>
</Card>
<br/>
<hr className="horizontal-rule"></hr>
<br/>

<Card className="card-background">
<Card.Body>
<h4 className="MedicationKnowledge-heading">URL</h4>
<div>
<codeblock className="accesstoken-code"> URL</codeblock>
<span>&nbsp;</span><span>&nbsp;</span>
<codeblock className="accesstoken-code">{process.env.REACT_APP_EDUCATIONAL_MATERIAL_URL}drugFormulary/api/R4/MedicationKnowledge</codeblock>
</div>
</Card.Body>
<Card.Body>
<h4 className="MedicationKnowledge-heading">Headers</h4>
<Table striped bordered hover>
<tbody>

<tr>
<td>Accept</td>
<td>application/json</td>
</tr>
<tr> <td>Authorization</td>
<td>Bearer 'ACCESS_TOKEN'</td>
</tr>
</tbody>
</Table>
</Card.Body>
<Card.Body>
<h4 className="MedicationKnowledge-heading">Sample Request</h4>

<div style={codestyle}>'curl --location 'https://iox.dev.sddss.apps.conduent.com/drugFormulary/api/R4/MedicationKnowledge/00002120001''\<br/>
</div>

<h4 className="MedicationKnowledge-heading">Sample Response</h4>

<pre style={codestyle}>
<code class="prettyprint">
{JSON.stringify(MedicationKnowledgeresponse,null,'  ')}
</code>
</pre>

</Card.Body>
</Card>
</div>
);
}