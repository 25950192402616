/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import * as RegistrationConstants from './RegistrationConstants';
import * as AppConstants from '../../../SharedModule/Utils/AppConstants';
import ErrorMessages from '../../../SharedModule/MessageHandlers/ErrorMessages';
import SuccessMessage from '../../../SharedModule/MessageHandlers/SuccessMessage';
import EmailVerification from './EmailVerification';
import Spinner from '../../../SharedModule/Spinner/Spinner';
import { VerifyVerificationCodeActions, ResendVerificationCodeActions } from '../Store/Actions/RegistrationActions';
import moment from 'moment';

export default function RegistrationDialog(props) {
    // Dialogue Starts
    const DialogContent = withStyles(theme => ({
        root: {
            padding: theme.spacing(2)
        }
    }))(MuiDialogContent);

    const DialogActions = withStyles(theme => ({
        root: {
            margin: 0,
            padding: theme.spacing(1)
        }
    }))(MuiDialogActions);

    // Dialogue Ends

    // Page Constants
    const dispatch = useDispatch();
    let successMessagesArray = [];
    let errorMessagesArray = [];
    let poUpErrorMessagesArray = [];
    let verificationCode = '';
    const [popUpErrorMessages, setPopUpErrorMessages] = React.useState([]);
    const [popUpSuccessMessages, setPopUpSuccessMessages] = React.useState([]);
    //const [validationId, setValidationId] = React.useState(props.registrationValue && props.registrationValue.id ? props.registrationValue.id : '');
    const [validationId, setValidationId] = React.useState(props.id ? props.id : '');
    const [verCode, setVerCode] = React.useState('');
    const [spinnerLoader, setSpinnerLoader] = React.useState(false);
    const [{
        verificationCodeError,
    }, setShowError] = React.useState(false);

    const [{
        verificationCodeErrorText,
    }, setShowErrorText] = React.useState('');

    // Dispatch Actions
    const verifyVerificationCodeDispatch = (verificationCriteria) => dispatch(VerifyVerificationCodeActions(verificationCriteria));
    const resendVerificationCodeDispatch = (resendVerificationCriteria) => dispatch(ResendVerificationCodeActions(resendVerificationCriteria));

    const verificationCodeData = useSelector(state => state.registrationState.verifyVerificationCode);
    const resendVerificationCodeData = useSelector(state => state.registrationState.resendVerificationCode);

    useEffect(() => {
        if (verificationCodeData) {
            setSpinnerLoader(false);
            errorMessagesArray = [];
            errorMessagesArray = AppConstants.handleAPIErrors(verificationCodeData, errorMessagesArray, RegistrationConstants.REGISTRATION_TECHNICAL_ERROR);
            if (errorMessagesArray && errorMessagesArray.length > 0) {
                setPopUpErrorMessages(errorMessagesArray);
            } else if(verificationCodeData?.errorMessage == "Data not found"){
                errorMessagesArray.push(RegistrationConstants.VERIFICATION_CODE_ERROR);
                setPopUpErrorMessages(errorMessagesArray);
            }else {
                props.setOpen(false);
                 props.history.push({
                    pathname: '/success',
                    state: { registrationValue: props.registrationValue }
                }); 
                // props.history.push({
                //     pathname: '/oldpayerDataExchangeConsent'
                // }); 
            }
        }
    }, [verificationCodeData]);

    useEffect(() => {
        if (resendVerificationCodeData) {
            setSpinnerLoader(false);
            errorMessagesArray = [];
            errorMessagesArray = AppConstants.handleAPIErrors(resendVerificationCodeData, errorMessagesArray, RegistrationConstants.REGISTRATION_TECHNICAL_ERROR);
            if (errorMessagesArray && errorMessagesArray.length > 0) {
                setPopUpErrorMessages(errorMessagesArray);
            }else if(resendVerificationCodeData?.errorMessage == "Data not found"){
                errorMessagesArray.push(RegistrationConstants.VERIFICATION_CODE_ERROR);
                setPopUpErrorMessages(errorMessagesArray);
            } else {
                setValidationId(resendVerificationCodeData.id);
                successMessagesArray.push(RegistrationConstants.VERIFICATION_EMAIL_SUCCESS);
                setPopUpSuccessMessages(successMessagesArray);
            }
        }
    }, [resendVerificationCodeData]);


    // Method to handle Verification Code change
    const handleVerificationCodeChange = (value) => {
        verificationCode = value;
    };

    // Method to validate Email Verification Form
    const validateEmailVerificationForm = () => {
        var verificationCodeError = false;
        var verificationCodeErrorText = '';
        verificationCode = verificationCode ? verificationCode : verCode;

        if (verificationCode === '' || (verificationCode && verificationCode.trim() === '')) {
            verificationCodeError = true;
            verificationCodeErrorText = RegistrationConstants.VERIFICATION_CODE + RegistrationConstants.REQUIRED;
            poUpErrorMessagesArray.push(verificationCodeErrorText);
        }

        setPopUpErrorMessages(poUpErrorMessagesArray);
        setShowError({
            verificationCodeError: verificationCodeError
        });
        setShowErrorText({
            verificationCodeErrorText: verificationCodeErrorText
        });

        if (poUpErrorMessagesArray && poUpErrorMessagesArray.length > 0) {
            return true
        } else {
            return false;
        }
    };
    
    // Method to clear error/success messages
    const clearMessages = () => {
        errorMessagesArray = [];
        poUpErrorMessagesArray = [];
        successMessagesArray = [];
        setPopUpErrorMessages([]);
        setPopUpSuccessMessages([]);
    }

    // Method to redirect to Success Page
    const submitEmailVerificationForm = () => {
        clearMessages();
        setVerCode(verificationCode ? verificationCode : verCode);
        if (!validateEmailVerificationForm()) {
            const verificationRequest = {
                id: validationId,
                userId: props.registrationValue.userID,
                firstName: props.registrationValue.firstName,
                lastName: props.registrationValue.lastName,
                email: props.registrationValue.emailAddress,
                phoneNumber: parseInt(props.registrationValue.phoneNumber),
                dob: moment(props.registrationValue.dob).format('YYYY-MM-DD'),
                ssn: props.registrationValue.memberSSN,
                memberSystemId: props.registrationValue.memberSystemId,
                verificationCode: verificationCode ? verificationCode : verCode,
                mailingAddress: {
                    address: null,
                    addressLine1: props.registrationValue.addressLine1,
                    addressLine2: props.registrationValue.addressLine2,
                    city: props.registrationValue.city,
                    state: props.registrationValue.state !== AppConstants.SELECT ? props.registrationValue.state : '',
                    postalCode: props.registrationValue.postalCode
                },
                kbarequest:{
                    input: {
                            kba: [
                                {
                                    answer: props.registrationValue.answer1,
                                    questionId: props.registrationValue.secQs1
                                },
                                {
                                    answer: props.registrationValue.answer2,
                                    questionId: props.registrationValue.secQs2
                                },
                                {
                                    answer: props.registrationValue.answer3,
                                    questionId: props.registrationValue.secQs3
                                }
                            ]
                        }
                },
                // challengeResponses: [
                //     { challenge: props.registrationValue.secQs1, response: props.registrationValue.answer1 },
                //     { challenge: props.registrationValue.secQs2, response: props.registrationValue.answer2 },
                //     { challenge: props.registrationValue.secQs3, response: props.registrationValue.answer3 }
                // ],
                memberId: props.registrationValue.memberID
            };
            setSpinnerLoader(true);
            verifyVerificationCodeDispatch(verificationRequest);
        }
    }

    // Method to resend Verification Code
    const resendVerificationCode = () => {
        clearMessages();
        setVerCode(verificationCode ? verificationCode : verCode);
        const resendVerificationRequest = {
            userId: props.registrationValue.userID,
            firstName: props.registrationValue.firstName,
            lastName: props.registrationValue.lastName,
            email: props.registrationValue.emailAddress,
            dob: moment(props.registrationValue.dob).format('YYYY-MM-DD'),
            memberSystemId: props.registrationValue.memberSystemId,
            memberId: props.registrationValue.memberID
        };
        setSpinnerLoader(true);
        resendVerificationCodeDispatch(resendVerificationRequest);
    }

    const handleClose = () => {
        props.setOpen(false);
    };

    return (
        <div>
            <Dialog className='custom-dialog custom-dialog-new-theme dialog-520' open={props.open} disableBackdropClick>
                {spinnerLoader ? <Spinner /> : null}
                <div className="custom-panel">
                    <div className="panel-header">Email Verification</div>
                    <div className="close-modal-bx" onClick={handleClose}><i class="fa fa-times"></i></div>
                </div>
                <div className="form-wrapper mt-3 px-0 dialog-err-msg">
                    {popUpErrorMessages && popUpErrorMessages.length > 0 ?
                        <ErrorMessages errorMessages={popUpErrorMessages} /> : null}
                    {popUpErrorMessages && popUpSuccessMessages && popUpErrorMessages.length === 0 && popUpSuccessMessages.length > 0 ?
                        <SuccessMessage successMessages={popUpSuccessMessages} /> : null}
                </div>
                {/* <img src="Email_verification_icon.svg" className="mx-auto d-block" alt="Email verification"></img>
                <h6 className="text-center font-weight-bold my-3">Email Verification</h6>
                <p className="text-center p-2 mb-0">Please enter the Verification Code sent to your Email Address.</p> */}
                <div className="dialog-inner-cont">
                <DialogContent>
                    <EmailVerification handleVerificationCodeChange={handleVerificationCodeChange} verCode={verCode}
                        verificationCodeError={verificationCodeError} verificationCodeErrorText={verificationCodeErrorText}
                    />
                </DialogContent>
                <DialogActions>
                <div className="tab-header mr-2">
                    <div className="float-right th-btnGroup">
                        <Button variant='outlined' color='primary' className='btn btn-ic btn-verify'
                            onClick={submitEmailVerificationForm}>
                            Verify
                            </Button>
                        <Button variant='outlined' color='primary' className='btn btn-ic btn-send-new-code'
                            onClick={resendVerificationCode}>
                            Send New Code
                            </Button>
                    </div>
                    <div className="clearfix" />
                    </div>
                </DialogActions>
                </div>
            </Dialog>
        </div>
    )
}
