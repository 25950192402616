import React, { Component } from 'react';
import {Document, Page, pdfjs} from 'react-pdf';
import './pdf.scss';
// import PDF from './Help.pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import Spinner from '../../SharedModule/Spinner/Spinner';

class PDFViewer extends Component {
  constructor(props){
    super(props);
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    this.desktop = false;
    this.tab = false;
    this.mobile = false;

    this.state = {
      numPages: null,
      pageNumber: 1,
      spinnerLoader:false
    }
    if (window.innerWidth >= 992) {
      this.desktop = true;
      this.tab = false;
      this.mobile = false;
    } else if (window.innerWidth >= 480 && window.innerWidth < 992) {
      this.desktop = false;
      this.tab = true;
      this.mobile = false;
    } else if (window.innerWidth < 480) {
      this.desktop = false;
      this.tab = false;
      this.mobile = true;
    }
  }

  componentDidMount(){
    this.setState({spinnerLoader:true});
    setTimeout(() => {
      this.setState({spinnerLoader:false});
      Array.from(document.getElementsByClassName("pdf_link")).forEach(e=>{
        Array.from(e.getElementsByTagName("a")).forEach(c=>c.innerText="test");
      }) 
    }, 4000);

  }

  onDocumentLoad = ( {numPages}) => {
    this.setState({numPages});
  }

  render() {
    const {numPages,spinnerLoader} = this.state;
    

    return (
      <div className="pdf_link">
         {spinnerLoader ? <Spinner /> : null}
        <Document
          file = {this.props.PDF}
          onLoadSuccess = {this.onDocumentLoad}
          >
        {Array.from(new Array(numPages), (el, index) => (
          <>
            {this.desktop ? <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={1.7} loading="" className="show-desktop"/> : null }
            {this.tab ? <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={1.2} loading="" className="show-tab"/> : null }
            {this.mobile ? <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={0.55} loading="" className="show-mobile"/> : null }
          </>
      ))}
    </Document>
      </div>

    );
  }
}

export default PDFViewer;