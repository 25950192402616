import React from 'react';
const modalStyle = {
    position: 'fixed',
    zIndex: 1,
    left: 0,
    top: '6%',
    width: '100%',
    height: '100%',
    overflow: 'auto',
    backgroundColor: 'rgba(0,0,0,0.4)'
};
const modalContentStyle = {
    backgroundColor: '#fefefe',
    marginTop: '12px',
    margin: '5% auto',
    padding: '30px',
    border: '1px solid #888',
    width: '80%',
    maxWidth: '500px'
};

const buttonStyle = {
    backgroundColor: 'Blue',
    color: 'white',
    padding: '10px 20px',
    margin: '10px 0',
    border: 'none',
    cursor: 'pointer',
    width: '100%'
};

const PopupModal = ({ showModal, message, onConfirm, buttonText }) => {
    if (!showModal) return null;

    return (
        <div style={modalStyle}>
            <div style={modalContentStyle}>
                <p>{message}</p>
                <button style={buttonStyle} onClick={onConfirm}>{buttonText}</button>
            </div>
        </div>
    );
};

export default PopupModal;