import React, { useEffect, useRef, useState } from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Spinner from "../../../SharedModule/Spinner/Spinner";
import Radio from "@material-ui/core/Radio";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker,
} from "@material-ui/pickers";
import GridView from "./GridView";
import GraphView from "./GraphView";
import LineChartView from "./TrenChartView";
import { getReportsDataActions,resetReportsDataActions,getTrendReportsDataActions,getProviderReportsDataActions} from "../Store/Actions/ReportActions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import ReactToPrint from "react-to-print";
import { setPrintLayout } from "../../../SharedModule/Store/Actions/AppActions";
import ErrorMessages from "../../../SharedModule/MessageHandlers/ErrorMessages";
import SuccessMessage from "../../../SharedModule/MessageHandlers/SuccessMessage";
import { generateRequestObject, getFirstAndLastDateOfMonth ,getFirstAndLastDateOfQuarter,generateProviderRequestObject} from "./utility";
import * as AppConstants from "../../../SharedModule/Utils/AppConstants";
import Header from "../../../SharedModule/Layout/header";
import QuarterDatePicker from './QuarterDatePicker';
import PDFReport from './PDF_Report';
import IdleSessionLogout from "../../../SharedModule/SessionManagement/IdleSessionLogout";

const Reports = (props) => {
  const dispatch = useDispatch();
  const toPrintRef = useRef();
  const toBottomRef = useRef(); 
  const reportRef = useRef(null);
  const [spinnerLoader, setSpinnerLoader] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [successMessages, setSuccessMessages] = useState([]);
  let errorMessagesArray = [];
  const [reportList, setReportList] = useState([
    { key: "App", value: "Third Party Apps Registration" },
    { key: "Member", value: "Member Registration" },
    { key: "API", value: "Third Party API Access" },
    { key: "MemberData", value: "Member API Access" },
    { key: "ProviderData", value: "Provider Directory API" },
    { key:"AppAPI", value:"App API Access"},
  ]);
  const [reportType, setReportType] = useState("-1");
  const [customFilter, setCustomFilter] = useState({
    startDate: null,
    endDate: null,
  });
  const [monthlyFilter, setMonthlyFilter] = useState(null);
  const [quartelyFilter, setQuartelyFilter] = useState(null);
  const [filterType, setFilterType] = useState("");
  const [viewType, setViewType] = useState("");
  const [reportData, setReportData] = useState(null);
  const [providerReportsData, setProviderReportData] = useState(null);
  const [trendReportData, setTrendReportData] = useState(null);
  const [showTrendGraph, setShowTrendGraph] = useState(false);
  const [printView, setPrintView] = useState(false);
  const [trenGraphTitle, SetTrenGraphTitle] = useState("");
  const [reportsRequestObject,setReportsRequestObject]=useState(null);
  let reportNameList = {
    App: "Third Party App Registration",
    Member: "Member Registration",
    API: "Third Party API Access",
    MemberData: "Member API Access",
    ProviderData: "Provider Directory API",
    AppAPI : "APP API Access",
  };
  let reportIDList = {
    App: "RPT-IOX-TPA-001",
    Member: "RPT-IOX-MEM-001",
    API: "RPT-IOX-TPA-002",
    MemberData: "RPT-IOX-MEM-002",
    ProviderData: "RPT-IOX-PRV-001",
    AppAPI :"RPT-IOX-API-001",
    // once other two reports will be implemented, here we need to add name for those two 
    // reports as well
    // 5. RPT-IOX-APP-002
    // 6. RPT-IOX-PRV-001
  };

  let printclasses = ['main-toggle-button', 'm-0', 'p-0', 'float-right', 'print-toggle-width'];
  let classes1 = ['main-toggle-button', 'm-0', 'p-0', 'float-right', 'toggle-width'];
  let classes2 = ['sec-toggle-button', 'm-0', 'p-0', 'float-right', 'toggle-width'];

  const reportsDataActions = (val) => dispatch(getReportsDataActions(val));
  const providerReportsDataAction = (val) => dispatch(getProviderReportsDataActions(val));
  const trendReportsDataActions = (val) => dispatch(getTrendReportsDataActions(val));
  // const resetReportsActions = (val) => dispatch(resetReportsDataActions(val));
  const filteredReportsData = useSelector(
    (state) => state.reportState.reportsData
  );
  const filteredTrendReportsData = useSelector(
    (state) => state.reportState.trendReportsData
  );
  const filteredProviderReportsData = useSelector(
    (state) => state.reportState.providerReportsData
  );
  const clearValues = () => {
    setCustomFilter({ startDate: null, endDate: null });
    setMonthlyFilter(null);
    setQuartelyFilter(null);
    dispatch(resetReportsDataActions("reportsData"));
    setFilterType("");
    setViewType("")
  };

  const onClickGenerateReport = () => {
    let reqObj = {};
      setSpinnerLoader(true);
    if (filterType == "Custom") {
      reqObj = generateRequestObject({ ...customFilter, reportType });
    } else if (filterType == "Monthly") {
      let { startDate, endDate } = getFirstAndLastDateOfMonth(monthlyFilter);
      reqObj = generateRequestObject({ startDate, endDate, reportType });
    }else if (filterType == "Quarterly") {
      let { startDate, endDate } = getFirstAndLastDateOfQuarter(quartelyFilter);
      reqObj = generateRequestObject({ startDate, endDate, reportType });
    }else if(reportType == "ProviderData")
    {
      reqObj = generateProviderRequestObject({ reportType });      
    }    
      setReportsRequestObject(reqObj);
    if(reportType == "ProviderData"){
      providerReportsDataAction(reqObj);
    }
    else{
      reportsDataActions(reqObj);
    }   
  };

  useEffect(()=>{
        setSpinnerLoader(true);
    setTimeout(() => {
        reportRef.current.scrollIntoView({
        behavior: "smooth"
    });
       document.getElementById("root").scrollTop=0;  
       setSpinnerLoader(false);
    }, 1000);
    
    return ()=>{
      clearValues();
    }
  },[])

  useEffect(()=>{
    if(viewType){
      scrollBottomOfPage();
    }
  },[viewType])

  const scrollBottomOfPage=()=>{
    toBottomRef.current.scrollIntoView({
       behavior: "smooth"
    });
  }

  useEffect(() => {
        setSuccessMessages([]);
        setErrorMessages([]);
        setProviderReportData(null);
        setViewType("");
        setShowTrendGraph(false);
    if (filteredProviderReportsData) {
        setSpinnerLoader(false);
        errorMessagesArray = [];
      // successMessagesArray = [];
      errorMessagesArray = AppConstants.handleAPIErrors(
        filteredProviderReportsData,
        errorMessagesArray,
        ""
      );
      if (errorMessagesArray && errorMessagesArray.length > 0) {
          setErrorMessages(errorMessagesArray);
      } else {
        if (filteredProviderReportsData && (filteredProviderReportsData?.data?.length > 0 || filteredProviderReportsData?.data)) {
          setProviderReportData(filteredProviderReportsData);
          setViewType("Grid");
          //props.history.push("/test");
        }else{
          setErrorMessages(["No records found for the search criteria entered."])
        }
      }
    }
  }, [filteredProviderReportsData]);


  useEffect(() => {
      setSuccessMessages([]);
      setErrorMessages([]);
      setReportData(null);
      setViewType("");
      setShowTrendGraph(false);
    if (filteredReportsData) {
      setSpinnerLoader(false);
      errorMessagesArray = [];
      // successMessagesArray = [];
      errorMessagesArray = AppConstants.handleAPIErrors(
        filteredReportsData,
        errorMessagesArray,
        ""
      );
      if (errorMessagesArray && errorMessagesArray.length > 0) {
        setErrorMessages(errorMessagesArray);
      } else {
        if(reportType==="AppAPI"){
          if(filteredReportsData?.data?.patientAppsInfo?.length > 0 || filteredReportsData?.data?.providerAppsInfo?.length > 0 ||
            filteredReportsData?.data?.drugListAppsInfo?.length > 0 )
            {
            setReportData(filteredReportsData);
            setViewType("Grid");
          }else{
            setErrorMessages(["No records found for the search criteria entered."])
          }
        }
        else{
          if (filteredReportsData && (filteredReportsData?.data?.length > 0 || filteredReportsData?.data)) {
            setReportData(filteredReportsData);
            setViewType("Grid");
            //props.history.push("/test");
          }else{
            setErrorMessages(["No records found for the search criteria entered."])
          }
        }
      }
    }
  }, [filteredReportsData]);

  useEffect(() => {
      setSuccessMessages([]);
      setErrorMessages([]);
      setTrendReportData(null);
      setShowTrendGraph(false);
    if (filteredTrendReportsData) {
      setSpinnerLoader(false);
      errorMessagesArray = [];
      // successMessagesArray = [];
      errorMessagesArray = AppConstants.handleAPIErrors(
        filteredTrendReportsData,
        errorMessagesArray,
        ""
      );
      if (errorMessagesArray && errorMessagesArray.length > 0) {
        setErrorMessages(errorMessagesArray);
      } else {
        if (filteredTrendReportsData && (filteredTrendReportsData?.data?.length > 0 || filteredTrendReportsData?.data)) {
          setTrendReportData(filteredTrendReportsData);
          setShowTrendGraph(true);
        }else{
          // setSuccessMessages(["No Data Available"])
        }
      }
    }
  }, [filteredTrendReportsData]);

  const toGetTrenGraphChartData=(val)=>{
    SetTrenGraphTitle(val);
    let reqObj=null;
    switch (reportType) {
      case "App":
        reqObj={...reportsRequestObject,trendType:"appReg",subTrendType:val?.toUpperCase()}
        break;
      case "Member":
      //reqObj={...reportsRequestObject,trendType:"memberReg",subTrendType:"memberReg"}
        reqObj=(val=="Registrations")?{...reportsRequestObject,trendType:"memberReg",subTrendType:"memberReg"}:null
        break;
      case "MemberData":
        let memobj={"Unique member access data once":"distincMember","Unique member access data more than once":"memberMtOnce"};
        reqObj={...reportsRequestObject,trendType:memobj[val],subTrendType:"memberReg"};
        break;
      case "API":
        let apiobj={"Covered Drug List API":"drug","Patient Access API":"patient","Provider Directory API":"provider"};
        reqObj={...reportsRequestObject,trendType:"appAccess",subTrendType:apiobj[val]}
        break;
      default:
        break;
    }
    if(reqObj!= null){trendReportsDataActions(reqObj);}
    else{setShowTrendGraph(false)}
  }
  return (
    <>
      <div className="content-wrapper block-elm" ref={reportRef} >
        {spinnerLoader ? <Spinner /> : null}
        <IdleSessionLogout/>
        {/* <!-- main content start --> */}
        <div className="frm-app-registratio" >
          <div className="tabs-container">
            <div className="page-header">
              <h1 className="page-heading float-left"> Reports </h1>
              <div className="clearfix" />
            </div>
            <div className="form-wrapper col-sm-12 px-0">
              {errorMessages && errorMessages.length > 0 ? (
                <ErrorMessages errorMessages={errorMessages} />
              ) : null}
              {errorMessages &&
              successMessages &&
              errorMessages.length === 0 &&
              successMessages.length > 0 ? (
                <SuccessMessage successMessages={successMessages} />
              ) : null}
            </div>

            <div className="tab-body mt-2 pb-3">
              {/* <!-- section start --> */}
              <div className="form-wrapper-iox pb-0">
                <div className="mui-custom-form with-select input-md">
                  <TextField
                    select
                    label="Reports"
                    placeholder="Please Select One"
                    value={reportType}
                    onChange={(e) =>{ clearValues();setReportType(e.target.value)}}
                    InputLabelProps={{
                      shrink: true,
                      for: 'Reports'
                    }}
                    InputProps={{
                      id: 'Reports'
                    }}
                  >
                    <MenuItem selected key="-1" value="-1">
                      Selection
                    </MenuItem>
                    {reportList &&
                      reportList.map((e) => (
                        <MenuItem selected key={e.key} value={e.key}>
                          {e.value}
                        </MenuItem>
                      ))}
                  </TextField>
                </div>
              </div>
                         
    <div className="tab-holder">
    {reportType !== "ProviderData" &&   <div className="form-wrapper-iox py-0">
                      <div className="mui-custom-form input-md field-xl">
                        <div className="set-sub-radio">
                        <div className="sub-radio">
                          <Radio
                            type="radio"
                            id="Custom-Date"
                            name="filterType"
                            onClick={() => {
                              clearValues();
                              setFilterType("Custom");
                            }}
                            checked={filterType == "Custom"}
                          />
                          <label for="Custom-Date" className="radio-label">
                            Custom
                          </label>
                        </div>
                        </div>
                      </div>
                      <div className="mui-custom-form  custom-date-picker">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Grid container justify="space-around">
                            <KeyboardDatePicker
                              autoOk={true}
                              className="m-0"
                              variant="inline"
                              margin="normal"
                              label="Start Date"
                              maxDate={new Date()}
                              disabled={filterType !== "Custom"}
                              value={customFilter?.startDate}
                              placeholder="MM/DD/YYYY"
                              format="MM/dd/yyyy"
                              id="Start_Date_01"
                              InputLabelProps={{
                                shrink: true,
                                "htmlFor": "Start_Date_01"
                              }}
                              //emptyLabel="MM/DD/YYYY"
                              onChange={(e) =>
                                setCustomFilter({
                                  ...customFilter,
                                  startDate: e,
                                })
                              }
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                            />
                          </Grid>
                        </MuiPickersUtilsProvider>
                      </div>

                      <div className="mui-custom-form input-md custom-date-picker">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Grid container justify="space-around">
                            <KeyboardDatePicker
                              placeholder="MM/DD/YYYY"
                              InputLabelProps={{
                                shrink: true,
                                "htmlFor": "End_Date_01"
                               }}
                               id="End_Date_01"
                              //emptyLabel="MM/DD/YYYY"
                              className="m-0"
                              variant="inline"
                              autoOk={true}
                              format="MM/dd/yyyy"
                              margin="normal"
                              label="End Date"
                              minDate={customFilter?.startDate}
                              maxDate={new Date()}
                              disabled={filterType !== "Custom"}
                              value={customFilter?.endDate}
                              onChange={(e) =>
                                setCustomFilter({ ...customFilter, endDate: e })
                              }
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                            />
                          </Grid>
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>}
      {reportType !== "ProviderData" &&   <div className="form-wrapper-iox">
                      <div className="mui-custom-form input-md">
                        <div className="set-sub-radio">
                            <div className="sub-radio m-0">
                              <Radio
                                type="radio"
                                id="Monthly-check"
                                name="filterType"
                                onClick={() => {
                                  clearValues();
                                  setFilterType("Monthly");
                                }}
                                checked={filterType == "Monthly"}
                              />
                            
                          <label className="radio-label" for="Monthly-check">
                              {" "}
                              Monthly{" "}
                          </label>
                          </div>
                          </div>
                        <div className="MuiFormControl-root MuiTextField-root no-label">
                          <label for="monthlyID" className="MuiFormLabel-root" id="monthlyID-label"> monthlyID
                          </label>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                              views={["month", "year"]}
                              //label="Year and Month"
                              // helperText="With min and max"
                              // minDate={new Date("2018-03-01")}
                              variant="inline"
                              autoOk={true}
                              maxDate={new Date()}
                              disabled={filterType !== "Monthly"}
                              value={monthlyFilter}
                              placeholder="Duration"
                              onChange={(e) => setMonthlyFilter(e)}
                              id="monthlyID"
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      </div>
                      <div className="mui-custom-form input-md">
                        <div className="MuiFormControl-root MuiTextField-root">
                          <div className="set-sub-radio">
                            <div className="sub-radio m-0">
                              <Radio
                                type="radio"
                                id="Quarterly-check"
                                name="filterType"
                                onClick={() => {
                                  clearValues();
                                  setFilterType("Quarterly");
                                }}
                                checked={filterType == "Quarterly"}
                              />

                            <label className="radio-label" for="Quarterly-check">
                              {" "}
                              Quarterly{" "}
                            </label>
                            </div>
                          </div>
                          {/* <label for="example1" className="MuiFormLabel-root hide-elm">
                             Quartely </label> */}

                          <QuarterDatePicker value={quartelyFilter} id="quarterID"
                              setQuartelyFilter={setQuartelyFilter} disabled={filterType !== "Quarterly"}/>
                        </div>
                      </div>
                    </div>}       
              <div className="tab-footer px-3 block-elm">
                  <div className="float-right th-btnGroup">
                    <button
                      className="btn btn-primary"
                      disabled={
                         reportType === "ProviderData"?false:reportType == -1 ||(filterType?.length <=0 ||
                          (filterType == "Custom"
                            ? (customFilter?.startDate == null ||
                              customFilter?.endDate == null || customFilter?.startDate.toString() == "Invalid Date" || customFilter?.endDate.toString() == "Invalid Date" || customFilter?.startDate > customFilter?.endDate)
                            : filterType == "Monthly"
                            ? monthlyFilter == null
                            : filterType == "Quarterly"
                            ? quartelyFilter == null
                            : false))
                      }
                      onClick={onClickGenerateReport}
                    >
                      <i className="fa fa-clipboard-list" /> Generate Report
                    </button>
                    {viewType && (
                      <>
                        <button
                          className={ viewType == "Grid" ? "btn btn-primary" : "btn btn-default"}
                          onClick={() =>{setViewType("Grid");setShowTrendGraph(false);}}
                        >
                          Grid View
                        </button>
                        <button
                           className={ viewType == "Graph" ? "btn btn-primary" : "btn btn-default"}
                          onClick={() =>{setViewType("Graph");setShowTrendGraph(false);}}
                        >
                          Graph View
                        </button>
                        {/* <button className="btn btn-default">
                                        <i className="fa fa-download" /> PDF
                                </button> */}
                        <ReactToPrint
                          onBeforeGetContent={() => {
                            dispatch(setPrintLayout(true));
                            setSpinnerLoader(true);
                            return new Promise((resolve) =>
                              setTimeout(() => resolve(), 100)
                            );
                          }}
                          onAfterPrint={() => {
                            setSpinnerLoader(false);
                            dispatch(setPrintLayout(false));
                          }}
                          trigger={() => (
                            /* <img src="PDF_Normal.svg" alt="pdf icon" className="align-image hide-on-print" /> */
                            <button className="btn btn-default" onClick={()=>setPrintView(true)}>
                              <i className="fa fa-download" /> PDF
                            </button>
                          )}
                          content={() => toPrintRef.current}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
              {viewType && (
                  <>
                <div className="tab-holder container-space table-center-text">
                  <div className="tab-header mb-2">
                    <h2 className="tab-heading float-left">
                      {" "}
                      {reportNameList[reportType]}{" "}
                    </h2>
                    <div className="clearfix" />
                  </div>
                  {viewType == "Grid" && (
                    <GridView reportType={reportType} reportData={reportData||providerReportsData} reportsRequestObject={reportsRequestObject} toGetTrenGraphChartData={toGetTrenGraphChartData} trenGraphTitle={trenGraphTitle} setShowTrendGraph={setShowTrendGraph}/>
                  )}
                  {viewType == "Graph" && (
                    <GraphView
                      reportType={reportType}
                      reportData={reportData||providerReportsData}
                      scrollBottomOfPage={scrollBottomOfPage}
                      toGetTrenGraphChartData={toGetTrenGraphChartData}
                    />
                  )}
                  {showTrendGraph && (                   
                    <LineChartView
                      reportType={reportType}
                      reportData={trendReportData}
                      scrollBottomOfPage={scrollBottomOfPage}
                      trenGraphTitle={trenGraphTitle}
                    />
                  )}
                  <div className="block-elm mt-3">
                    <div className="float-left">
                      Run Date: <span> {moment().format("L")} </span>
                    </div>
                    <div className="float-right">
                      Run Time: <span> {moment().format("HH:mm:ss")} </span>
                    </div>
                    <div className="clearfix" />
                  </div>
                </div>
                
                </>
              )}

              {/* <!-- section end --> */}
            </div>
          </div>
        </div>
        {/* <!-- main content end --> */}
        <div className="hide-on-screen" ref={toPrintRef}>
         <PDFReport 
          reportType={reportType} 
          reportData={reportData||providerReportsData} 
          trendReportData={trendReportData} 
          showTrendGraph={showTrendGraph} 
          filterType={filterType} 
          reportsRequestObject={reportsRequestObject}  
          trenGraphTitle={trenGraphTitle}
          reportID={reportIDList[reportType]}
          reportTitle={reportNameList[reportType]}
          />
      </div> 
      <div ref={toBottomRef}></div>  
      </div>
      
    </>
  );
};
export default Reports;
