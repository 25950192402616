import React, { useEffect, useState } from "react";
//import Routes from "../../Routes";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Dropdown } from "react-bootstrap";
import Header from "../../../SharedModule/Layout/header";
//import Footer from "./footer";
import $ from "jquery";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { ListItemIcon, ListSubheader, colors } from "@material-ui/core";
import Footer from "../../../SharedModule/Layout/footer";
//import * as serviceEndPoint from '../../SharedModule/Service/service';
const drawerWidth = 285;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    paddingTop: "0px",
  },
  hide: {
    display: "none",
  },
  containerFluid: {
    padding: 0,
  },
  active: {
    background: "lightgrey",
  },
}));
export default function Layout(props) {
  const isLoggedIn = sessionStorage.getItem("isLoggedIn");
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [open, setOpen] = React.useState(true);
  const [activeClass, setActiveClass] = useState("app");

  const registrationMenuItems = [
    {
      name: "Third Party App Registration",
      text: "Third Party App Registration",
      path: "/ThirdPartyAppRegistration",
      icon: <i className="fa fa-mobile" />,
    },
    {
      name: "OAuth",
      text: "OAuth",
      path: "/OAuth",
      icon: <i class="fa fa-cog" />,
    },
    {
      name: "Member Registration",
      text: "Member Registration",
      path: "/MemberRegistration",
      icon: <i className="fa fa-user-plus" />,
    },
  ];

  const memberInfo = {
    name: "Member Additional Info",
    text: "Member Additional Info",
    path: "/MemberInfo",
    icon: <i class="fa fa-clipboard-list" aria-hidden="true"></i>,
  };
  const developmentTools = {
    name: "Swagger Reference",
    text: "Swagger Reference",
    path: "/SwaggerReference",
    icon: <i className="fa fa-code" />,
  };
  const apiReference = [
    {
      name: "Explanation Of Benefits",
      text: "Explanation Of Benefits",
      path: "/EOB",
      icon: <i className="fa fa-clipboard-list" />,
    },

    {
      name: "Patient",
      text: "Patient",
      path: "/Patient",
      icon: <i className="fa fa-clipboard-list" />,
    },

    {
      name: "Coverage",
      text: "Coverage ",
      path: "/Coverage",
      icon: <i className="fa fa-clipboard-list" />,
    },

    {
      name: "Practitioner",
      text: "Practitioner",
      path: "/Practitioner",
      icon: <i className="fa fa-clipboard-list" />,
    },

    {
      name: "Organization",
      text: "Organization",
      path: "/Organization",
      icon: <i className="fa fa-clipboard-list" />,
    },
  ];
  const usDrugFormulary = [
    {
      name: "List",
      text: "List",
      path: "/List",
      icon: <i className="fa fa-clipboard-list" />,
    },

    {
      name: "Medication Knowledge",
      text: "Medication Knowledge",
      path: "/MedicationKnowledge",
      icon: <i className="fa fa-clipboard-list" />,
    },
  ];
  const apiReferencesPdex = {
    name: "Practitioner",
    text: "Practitioner",
    path: "/ProviderPractitioner",
    icon: <i className="fa fa-clipboard-list" />,
  };
  const apiReferencesUSCORE = [
    {
      name: "Clinical Coverage",
      text: "Clinical Coverage",
      path: "/ClinicalCoverage",
      icon: <i className="fa fa-clipboard-list" />,
    },

    {
      name: "Practitioner",
      text: "Practitioner",
      path: "/ClinicalPractitioner",
      icon: <i className="fa fa-clipboard-list" />,
    },

    {
      name: "Allergy Intolerance",
      text: "Allergy Intolerance",
      path: "/AllergyTolerance",
      icon: <i className="fa fa-clipboard-list" />,
    },
    ,
    {
      name: "Care Team",
      text: "Care Team",
      path: "/CareTeam",
      icon: <i className="fa fa-clipboard-list" />,
    },

    {
      name: "Care Plan",
      text: "Care Plan",
      path: "/CarePlan",
      icon: <i className="fa fa-clipboard-list" />,
    },

    {
      name: "Organization",
      text: "Organization",
      path: "/ClinicalOrganization",
      icon: <i className="fa fa-clipboard-list" />,
    },
  ];

  useEffect(() => {
    setActiveClass(location.pathname);
  }, [location.pathname]);

  const onSideMenuClick = (pathname) => {
    history.push(pathname);
  };
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 1024) {
        $("body").addClass("leftNavOff");
      }else{
        $("body").removeClass("leftNavOff");
      }
    }
    window.addEventListener("resize", handleResize);
    handleResize();

  },[]);

  const toggleSideBar = () => {
    if ($("body").hasClass("leftNavOff")) {
      $("body").removeClass("leftNavOff");
    } else {
      $("body").addClass("leftNavOff");
    }
  };

  const logout = () => {
    sessionStorage.clear();
    localStorage.clear();
    window.open(process.env.REACT_APP_ADMIN_OAUTH_LOGOUT_URI, "_self");
  };
  return (
    <>
      <div className="main-container">
        <Drawer
          className={clsx(open ? classes.drawer : "", "side-drawer")}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className="nav-list-container" id="sideNav">
            <div className="top-brand-border" />
            <h5>Registration Guide</h5>
            <List className="side-menu-list muiMainNav">
              {registrationMenuItems.map((item) => (
                <ListItem
                  title={item.name}
                  button
                  key={item.title}
                  className={
                    location.pathname == item.path ? classes.active : null
                  }
                  onClick={() => onSideMenuClick(item.path)}
                >
                  <div className="side-menu-icon">{item.icon}</div>
                  <ListItemText>{item.text}</ListItemText>
                </ListItem>
              ))}
            </List>
            <h5>Member Info</h5>
            <List className="side-menu-list muiMainNav">
              <ListItem
                title={memberInfo.name}
                button
                key={memberInfo.title}
                className={
                  location.pathname == memberInfo.path ? classes.active : null
                }
                onClick={() => onSideMenuClick(memberInfo.path)}
              >
                <div className="side-menu-icon">{memberInfo.icon}</div>
                <ListItemText>{memberInfo.text}</ListItemText>
              </ListItem>
            </List>

            <List className="side-menu-list muiMainNav">
              <h5>Development Tools</h5>
              <ListItem
                title={developmentTools.name}
                button
                key={developmentTools.title}
                className={
                  location.pathname == developmentTools.path
                    ? classes.active
                    : null
                }
                onClick={() => onSideMenuClick(developmentTools.path)}
              >
                <div className="side-menu-icon">{developmentTools.icon}</div>
                <ListItemText>{developmentTools.text}</ListItemText>
              </ListItem>
            </List>
            <h5>API References CarinBB</h5>
            <List className="side-menu-list muiMainNav">
              {apiReference.map((item) => (
                <ListItem
                  title={item.name}
                  button
                  key={item.title}
                  className={
                    location.pathname == item.path ? classes.active : null
                  }
                  onClick={() => onSideMenuClick(item.path)}
                >
                  <div className="side-menu-icon">{item.icon}</div>
                  <ListItemText>{item.text}</ListItemText>
                </ListItem>
              ))}
            </List>
            <h5>US Drug Formulary</h5>
            <List className="side-menu-list muiMainNav">
              {usDrugFormulary.map((item) => (
                <ListItem
                  title={item.name}
                  button
                  key={item.title}
                  className={
                    location.pathname == item.path ? classes.active : null
                  }
                  onClick={() => onSideMenuClick(item.path)}
                >
                  <div className="side-menu-icon">{item.icon}</div>
                  <ListItemText>{item.text}</ListItemText>
                </ListItem>
              ))}
            </List>
            <h5>API References Pdex</h5>
            <List className="side-menu-list muiMainNav">
              <ListItem
                title={apiReferencesPdex.name}
                button
                key={apiReferencesPdex.title}
                className={
                  location.pathname == apiReferencesPdex.path ? classes.active : null
                }
                onClick={() => onSideMenuClick(apiReferencesPdex.path)}
              >
                <div className="side-menu-icon">{apiReferencesPdex.icon}</div>
                <ListItemText>{apiReferencesPdex.text}</ListItemText>
              </ListItem>
            </List>
            <h5>API References USCORE</h5>
            <List className="side-menu-list muiMainNav">
              {apiReferencesUSCORE.map((item) => (
                <ListItem
                  title={item.name}
                  button
                  key={item.title}
                  className={
                    location.pathname == item.path ? classes.active : null
                  }
                  onClick={() => onSideMenuClick(item.path)}
                >
                  <div className="side-menu-icon">{item.icon}</div>
                  <ListItemText>{item.text}</ListItemText>
                </ListItem>
              ))}
            </List>

          </div>
        </Drawer>
        <div className="main-content block-elm">
          <div className="page-container">
            <Header print={false} />
            
            {props.children}
          </div>
          <Footer/>
        </div>
      </div>
    </>
  );
}
