import React from 'react';
import Card from 'react-bootstrap/Card';

import Table from 'react-bootstrap/Table';
import styles from './ClinicalPractitioner.css';


export default function ClinicalPractitioner(){
let Practitionerresponse={ "resourceType": "Practitioner", "id": "1123800032432", "meta": { "lastUpdated": "2021-02-08T12:22:32.642+00:00", "profile": [ "http://hl7.org/fhir/us/carin-bb/StructureDefinition/C4BB-Practitioner" ] }, "identifier": [ { "type": { "coding": [ { "system": "http://hl7.org/fhir/sid/us-npi", "code": "RI", "display": "1356588727" } ] }, "value": "1123800032432" }, { "type": { "coding": [ { "system": "http://hl7.org/fhir/us/carin-bb/CodeSystem/C4BBIdentifierType", "code": "npi" } ] }, "system": "http://hl7.org/fhir/sid/us-npi", "value": "npi" } ], "name": [ { "family": "Lee", "given": [ "Heather", "test" ], "prefix": [ "Ms" ] } ] };
const codestyle={backgroundColor: 'lightgray'};
return (
<div className="container">
<Card className="card-background"> <Card.Body>
<blockquote className="blockquote mb-0">
<h1 className="text-header">Practitioner</h1>
<p>This profile builds upon the US Core Practitioner profile. It is used to convey information about the practitioner who provided to the patient services described on the claim.</p>
</blockquote> </Card.Body>
</Card>
<br/>
<hr className="horizontal-rule"></hr>
<br/>

<Card className="card-background">
<Card.Body>
<h4 className="ClinicalPractitioner-heading">URL</h4>
<div>
<codeblock className="accesstoken-code"> URL</codeblock>
<span>&nbsp;</span><span>&nbsp;</span>
<codeblock className="accesstoken-code">{process.env.REACT_APP_EDUCATIONAL_MATERIAL_URL}clinicalData/api/R4/PractitionerRole</codeblock>
</div>
</Card.Body>
<Card.Body>
<h4 className="ClinicalPractitioner-heading">Headers</h4>
<Table striped bordered hover>
<tbody>

<tr>
<td>Accept</td>
<td>application/json</td>
</tr>
<tr> <td>Authorization</td>
<td>Bearer 'ACCESS_TOKEN'</td>
</tr>
</tbody>
</Table>
</Card.Body>
<p>Note:Third party app will have access token after member has given consent to app (Refer Member consent section)
</p>
<Card.Body>
<h4 className="ClinicalPractitioner-heading">Sample Request</h4>

<div style={codestyle}>'curl --location 'https://iox.dev.sddss.apps.conduent.com/clinicalData/api/R4/PractitionerRole/12345''\<br/>
                                    '--header 'Authorization: Bearer XYPDpdP2QTyZW3kpqLqSaYclmlU' '\
</div>
<br/>
<h4 className="Practitioner-heading">Sample Response</h4>

<pre style={codestyle}>
<code class="prettyprint">
{JSON.stringify(Practitionerresponse,null,'  ')}
</code>
</pre>
</Card.Body>
</Card>
</div>
);
}