import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import CustomCarousel from './CustomCarousel';
import { redirectToAzureAD, redirectToAdminAzureAD } from './authUtils';

const SDLogin = () => {
    const location = useLocation();
    const { type } = useParams();
    const isMemberConsent = type === 'MemberConsent';
    const isThirdParty = type === 'ThirdParty';
    const isAdminLogin = type === 'Admin';

    const handleLogin = async () => {
        sessionStorage.setItem('loggedInViaAuthorize', 'true');
        await redirectToAzureAD();
    };
    
    const initiateAzureADLogin = async () => {
        sessionStorage.setItem("isRegistration", "true");
        await redirectToAzureAD();
    };

    const handleImageClick = () => {
        if (isMemberConsent) {
            redirectToAzureAD();
        } else if (isThirdParty) {
            handleLogin();
        } else if (isAdminLogin) {
            redirectToAdminAzureAD();
        } else {
            initiateAzureADLogin();
        }
    };

    const imageStyle = {
        width: '100%', 
        height: 'auto', 
        display: 'block',
        margin: 'auto',
        marginTop: '50px',
        // marginLeft: '-12px', 
        marginRight: '30px', 
        cursor: 'pointer', 
    };

    // const containerStyle = {
    //     backgroundColor: '#ADD8E6', 
    // };

    
    const imageUrl = isAdminLogin
    ? "https://cdn.sd.gov/agency/BIT/img/SD-B2Blogin-button_dark.svg"
    : "https://cdn.sd.gov/img/mySD-loginbutton.svg";

    return (
        <div>
            <div className="container custom-container py-3" >
                <div className="login-tab">
                    <div className="row">
                        <div className="col-sm-12 col-md-7">
                            <CustomCarousel /> 
                        </div>
                        <div className="col-sm-12 col-md-5" >
                            <div>
                                <img src={imageUrl} alt={isAdminLogin ? "Admin Login" : "Login/Register using mySD"} style={imageStyle} onClick={handleImageClick} />
                                
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SDLogin;
