import React,{useState,useEffect,useRef} from 'react'
import TextField from '@material-ui/core/TextField';
import Radio from "@material-ui/core/Radio";
import ErrorMessages from '../../../SharedModule/MessageHandlers/ErrorMessages';
import SuccessMessage from '../../../SharedModule/MessageHandlers/SuccessMessage';
import * as regConstants from './RegistrationConstants';
import {useDispatch,useSelector} from 'react-redux';
import RegistrationDialog from './RegistrationDialog';
import {savePayerConsentExchangeDataActions} from '../Store/Actions/RegistrationActions';
import Spinner from '../../../SharedModule/Spinner/Spinner';

const OldPayerDataExchangeConsent=(props)=>{

	const dispatch=useDispatch();
	const payerDetailsRef=useRef();

    const [dataConsent,setDataConsent]=useState(null);
    const [errorMessages, setErrorMessages] = React.useState([]);
	const [successMessages, setSuccessMessages] = React.useState([]);
	const [open, setOpen] = React.useState(false);
	const [spinnerLoader, setSpinnerLoader] = useState(false);
	
	const savePayerExchangeData=(data)=>dispatch(savePayerConsentExchangeDataActions(data));
	
	const registrationValues = useSelector(state=>state.registrationState.registrationValues);
	const PayerConsentData=useSelector(state=>state.registrationState.PayerConsentData);

	useEffect(()=>{
		setSuccessMessages([regConstants?.MEMBER_REGISTRATION_SUCCESS]);
	},[])

	useEffect(() => {
        if (PayerConsentData) {
            setSpinnerLoader(false);
            if (PayerConsentData.success) {   
                payerDetailsRef.current.scrollIntoView({
                    behavior: "smooth"
                });
                setSuccessMessages([PayerConsentData?.message]);
                // setOpen(true);
                props.history.push({
                    pathname: '/success',
                    state: { registrationValue: registrationValues }
                });

            } else {
                setErrorMessages(['Unable to process your request. Please try later.']);
                payerDetailsRef.current.scrollIntoView({
                    behavior: "smooth"
                });
            }
        }
    }, [PayerConsentData]);

    const onNextClick=()=>{
        setErrorMessages([]);
        if(dataConsent){
            dataConsent === "Yes" ? props.history.push("/oldpayerDataExchangeDetails") : savePayerDetails()
        }else{
            setErrorMessages([regConstants.OLD_PAYER_DATA_CONSENT]);
        }
        
    }

	const savePayerDetails=()=>{
			setSpinnerLoader(true);
            let obj={
                "addedAuditTimeStamp": "",
                "addedAuditUserID": "",
                "auditTimeStamp": "",
                "auditUserID": "",
                "currentStatus": "",
                "id": "",
                "isUpdateStatus": true,
                "memberReferenceId": registrationValues ? registrationValues?.id : null,
                "payers": [
                  {
					payerName: '',
					coverageId: '',
					startDate: null,
					endDate: null,
					groupName: '',
					planName: '',
					payerReferenceId:'',
					accessToken: "",
					refreshToken: "",
					memberMedicaidId: "",
					memberUniqueId: "",
					memberWorkFlowStatus: "No Member consent for Pdex"
				  }
                ]
              }
            savePayerExchangeData(obj);
    }

    return (
        <div className="content-wrapper block-elm"  ref={payerDetailsRef}>
			{spinnerLoader ? <Spinner /> : null}
<div className="page-header" >
	<div className="float-right"><a href="/Help2" onClick={()=>{localStorage.setItem("ioxHelpDoc","member")}} target="_blank" className="dib fw-600 py-2 mt-1"> Help </a></div> 
	<div className="clearfix" />
</div>
<div className="col-md-8 offset-md-2 form-wrapper col-sm-12 px-0">
    {errorMessages && errorMessages.length > 0 ?
        <ErrorMessages errorMessages={errorMessages} /> : null}
	{errorMessages && successMessages && errorMessages.length === 0 && successMessages.length > 0 ?
            <SuccessMessage successMessages={successMessages} /> : null}
</div>
<div className="tab-holder container-space">
	<div className="row">
	<div className="col-md-8 offset-md-2">
	<h2 className="page-heading fw-600 h1-md">
	   <i className="fa fa-user primary-color float-left mr-2" />
	   <span> Would you like us to obtain your health data from your previous health plans?</span>
	</h2>

	<div className="tab-holder">
		<p className="fw-600 py-3 font-md">
			CMS Interoperability Final Rule requires a covered plan, at the member's request, to make their health information available to any other plan as directed by the member. If you would like to make this request, you will be asked to enter the details of the previous coverages in the next screen. Please have your previous insurance card ready.
		</p>
		<p className="font-md"><i>If <b>Yes</b>, you would be required to provide payer additional details for us to exchange details. </i></p>
	</div>

	<div className="tab-holder row pl-1">
		<div className="sub-radio col-12 p-0">
			<Radio
				type="radio"
				value={'true'}
				id="dataExchange-Yes"
				className="float-left set-top-m2"
                value={dataConsent}
                checked={ dataConsent === "Yes"}
                onChange={() =>setDataConsent("Yes")}
			/>
			<label for="dataExchange-Yes" className="radio-label h5 fw-600 pt-2">
				Yes
			</label>
		</div>
		<div className="sub-radio col-12 p-0 m-0">
			<Radio
				type="radio"
				value={'false'}
				id="dataExchange-No"
				className="float-left set-top-m2"
                value={dataConsent}
                checked={ dataConsent === "No"}
                onChange={() =>setDataConsent("No")}
			/>
			<label for="dataExchange-No" className="radio-label h5 fw-600 pt-2">
				No
			</label>
		</div>
	</div>
	<div className="tab-footer mt-3">
		<button className="btn btn-primary" title="Cancel" onClick={onNextClick} disabled={!dataConsent}> NEXT </button>
	</div>
	</div>
	</div>

</div>
{open ? <RegistrationDialog open={open} setOpen={setOpen} history={props.history}
                      registrationValue={registrationValues} id={registrationValues?.id} /> : null}
</div>
    )

}

export default OldPayerDataExchangeConsent;