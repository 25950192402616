import * as actionTypes from '../Actions/ClaimsActionTypes';

const axiosPayLoad = {
  payload: null
};
const reducer = (state = axiosPayLoad, action) => {
  switch (action.type) {
    case actionTypes.CLAIMS_ROLLING_MONTHS:
      return { ...state, claimsRollingMonths: action.claimsRollingMonths };
    case actionTypes.CLAIMS_MONTHLY_DATA:
      return { ...state, claimsMonthlyData: action.claimsMonthlyData };
    case actionTypes.CREATE_FEEDBACK:
      return { ...state, createFeedback: action.createFeedback };
    case actionTypes.UPDATE_FEEDBACK:
      return { ...state, updateFeedback: action.updateFeedback };
    case actionTypes.RESET_CLAIMS_DATA:
      return { ...state, [action.key]: '' };
    default: return state;
  }
};

export default reducer;
