import React from 'react';
import Card from 'react-bootstrap/Card';

import Table from 'react-bootstrap/Table';
import styles from './CarePlan.css';


export default function CarePlan(){
let CarePlanResponse={
    "resourceType": "CarePlan",
    "meta": {
        "lastUpdated": "2019-05-23T00:06:03.000+05:30",
        "profile": [
            "http://hl7.org/fhir/us/core/StructureDefinition/us-core-careplan"
        ]
    },
    "text": {
        "status": "additional",
        "div": "<div xml:lang=\"en-US\" xmlns=\"http://www.w3.org/1999/xhtml\" lang=\"en-US\"><p><b>Generated Narrative</b></p></div>"
    },
    "identifier": [
        {
            "value": "11111111"
        }
    ],
    "status": "active",
    "intent": "order",
    "category": [
        {
            "coding": [
                {
                    "system": "http://hl7.org/fhir/us/core/CodeSystem/careplan-category",
                    "code": "assess-plan"
                }
            ]
        }
    ],
    "title": "Title for the Care Plan",
    "description": "Description for the Care Plan",
    "subject": {
        "reference": "Patient/200000400",
        "display": "Bruce Wayne Clark"
    },
    "period": {
        "start": "2020-06-26T05:30:00+05:30",
        "end": "2020-06-27T05:30:00+05:30"
    },
    "note": [
        {
            "text": "Any notes which is not captured here"
        }
    ]
}
const codestyle={backgroundColor: 'lightgray'};
return (
<div className="container">
<Card className="card-background"> <Card.Body>
<blockquote className="blockquote mb-0">
<h1 className="text-header">Care Plan</h1>
<p>Care Plans are used in many areas of healthcare with a variety of scopes. They can be as simple as a general practitioner keeping track of when their patient is next due for a tetanus immunization through to a detailed plan for an oncology patient covering diet, chemotherapy, radiation, lab work and counseling with detailed timing relationships, pre-conditions and goals.</p>
</blockquote> </Card.Body>
</Card>
<br/>
<hr className="horizontal-rule"></hr>
<br/>

<Card className="card-background">
<Card.Body>
<h4 className="CarePlan-heading">URL</h4>
<div>
<codeblock className="accesstoken-code"> URL</codeblock>
<span>&nbsp;</span><span>&nbsp;</span>
<codeblock className="accesstoken-code">{process.env.REACT_APP_EDUCATIONAL_MATERIAL_URL}clinicalData/api/R4/CarePlan</codeblock>
</div>
</Card.Body>
<Card.Body>
<h4 className="CarePlan-heading">Headers</h4>
<Table striped bordered hover>
<tbody>

<tr>
<td>Accept</td>
<td>application/json</td>
</tr>
<tr> <td>Authorization</td>
<td>Bearer 'ACCESS_TOKEN'</td>
</tr>
</tbody>
</Table>
</Card.Body>
<p>Note:Third party app will have access token after member has given consent to app (Refer Member consent section)
</p>
<Card.Body>
<h4 className="CarePlan-heading">Sample Request</h4>

<div style={codestyle}>'curl --location 'https://iox.dev.sddss.apps.conduent.com/clincalData/api/R4/CarePlan/1000000011''\<br/>
                                    '--header 'Authorization: Bearer XYPDpdP2QTyZW3kpqLqSaYclmlU' '\
</div>

<h4 className="Coverage-heading">Sample Response</h4>
<pre style={codestyle}>
<code class="prettyprint">
{JSON.stringify(CarePlanResponse,null,'  ')}

</code>
</pre>
</Card.Body>
</Card>
</div>
);
}