import { generateRandomString, generateCodeChallenge } from './pkceUtils';

export const redirectToAzureAD = async () => {
    const codeVerifier = generateRandomString(128);
    const codeChallenge = await generateCodeChallenge(codeVerifier);
    const state = generateRandomString(64);

    sessionStorage.setItem('pkce_code_verifier', codeVerifier);
    sessionStorage.setItem('pkce_state', state);
    sessionStorage.setItem('userType', 'member');


     const azureADLoginUrl = `${process.env.REACT_APP_AZURE_AD_LOGIN_URL}&client_id=${process.env.REACT_APP_AZURE_AD_CLIENT_ID}&response_type=code&redirect_uri=${encodeURIComponent(process.env.REACT_APP_AZURE_AD_REDIRECT_URI)}&scope=openid%20profile&state=${state}&code_challenge_method=S256&code_challenge=${codeChallenge}`;

    window.location.href = azureADLoginUrl;
};

export const redirectToAdminAzureAD = async () => {
    const codeVerifier = generateRandomString(128);
    const codeChallenge = await generateCodeChallenge(codeVerifier);
    const state = generateRandomString(64);

    sessionStorage.setItem('pkce_code_verifier', codeVerifier);
    sessionStorage.setItem('pkce_state', state);
    sessionStorage.setItem('userType', 'admin');


    //   const azureADAdminLoginUrl = `${process.env.REACT_APP_AZURE_AD_Admin_LOGIN_URL}&client_id=${process.env.REACT_APP_AZURE_AD_Admin_CLIENT_ID}&response_type=code&redirect_uri=${encodeURIComponent(process.env.REACT_APP_AZURE_AD_REDIRECT_URI)}&scope=openid%20profile&state=${state}&code_challenge_method=S256&code_challenge=${codeChallenge}`;
     const azureADAdminLoginUrl = `${process.env.REACT_APP_AZURE_AD_Admin_LOGIN_URL}?client_id=${process.env.REACT_APP_AZURE_AD_Admin_CLIENT_ID}&response_type=code&redirect_uri=${encodeURIComponent(process.env.REACT_APP_AZURE_AD_REDIRECT_URI)}&scope=openid%20profile&state=${state}&code_challenge_method=S256&code_challenge=${codeChallenge}`;

    window.location.href = azureADAdminLoginUrl;
};