/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Spinner from '../../../SharedModule/Spinner/Spinner';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ConsentsView from './ConsentsView';
import IdleSessionLogout from '../../../SharedModule/SessionManagement/IdleSessionLogout';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function ConsentsManagement(props) {
    const [spinnerLoader, setSpinnerLoader] = React.useState(false);
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const dispatch = useDispatch();
    useEffect(() => {
        setSpinnerLoader(true);
    }, [])

    useEffect(() => {
        setTimeout(() => {
            setSpinnerLoader(false);
        }, 1000);
    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <div className="content-wrapper block-elm" >
            {spinnerLoader ? <Spinner /> : null}
            <IdleSessionLogout />
            {/* <!-- main content start --> */}
            <div className="tabs-container mt-4">
                {/* <div className="block-elm text-right">
                    <a href="/Help2" onClick={() => { localStorage.setItem("ioxHelpDoc", "memberConsent") }} target="_blank" className="dib fw-600 pr-1"> Help </a>
                </div> */}
                <div className="tab-body">
                    <div className='tab-holder custom-tabber mt-3 pb-3'>
                        <AppBar position='static' color="default">
                            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                                <Tab label={`Member Consent Management`} {...a11yProps(0)} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={value} index={0}>
                            <ConsentsView />
                        </TabPanel>
                    </div>
                </div>
            </div>
            {/* <!-- main content end --> */}
        </div>
    )
}