import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import LoginForm from './Home';
import { redirectToAzureAD } from './authUtils';
// import Login from './Modules/Login/Components/adminLogin';





const Authorize = (req ,res) => {
   const history = useHistory();
  sessionStorage.setItem('res', req);

useEffect(() => {

  const urlParams = new URLSearchParams(window.location.search);
  const clientId = urlParams.get('client_id');
  const redirect_uri = urlParams.get('redirect_uri'); 
  const Scope = urlParams.get('scope'); 
  const state = urlParams.get('state'); 

  sessionStorage.setItem('clientId', clientId);
  sessionStorage.setItem('redirect_uri', redirect_uri);
  sessionStorage.setItem('scope', Scope);
  sessionStorage.setItem('state', state);

 history.push('/SDLogin/ThirdParty');

 
 },[]);

  
 };

export default Authorize;
