import * as actionTypes from '../Actions/RegistrationActionTypes';

const axiosPayLoad = {  
  payload: null,
  appDetails:null
};
const reducer = (state = axiosPayLoad, action) => {
  switch (action.type) {
    case actionTypes.VALIDATE_REGISTRATION:
      return { ...state, validateRegistration: action.validateRegistration };
    case actionTypes.APP_SEARCH:
      return { ...state, searchResult: action.payload };
    case actionTypes.UNAPPROVED_APPS:
      return { ...state, unApprovedApps: action.payload };
    case actionTypes.APP_SEARCH_VIEW:
      return { ...state, appDetails: action.appSearchData, appDetailsTime: new Date(), type: 'Edit'};
    case actionTypes.DOWNLOAD_FILE_ACTION:
      return { ...state, downloadeFile: action.downloadeFile };
      case actionTypes.APP_STATUS_UPDATE:
        return { ...state, appStatusDetails: action.appDetailsData };
      case actionTypes.RESET_APP_STATUS:
        return { ...state, appStatusDetails: action.appDetailsData };
      case actionTypes.DOWNLOAD_RESET:
        return { ...state, downloadeFile: action.downloadeFile };
    // case actionTypes.VALIDATE_REGISTRATION_OPTIONS:
    //   return { ...state, validateRegistrationOptions: action.validateRegistrationOptions };
    // case actionTypes.VERIFY_VERIFICATION_CODE:
    //   return { ...state, verifyVerificationCode: action.verifyVerificationCode };
    // case actionTypes.RESEND_VERIFICATION_CODE:
    //   return { ...state, resendVerificationCode: action.resendVerificationCode };
    // case actionTypes.RESEND_EMAIL:
    //   return { ...state, resendEmail: action.resendEmail };
    // case actionTypes.RESET_REGISTRATION_DATA:
    //   return { ...state, [action.key]: '' };
    default: return state;
  }
};

export default reducer;
