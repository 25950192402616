/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import * as RegistrationConstants from './RegistrationConstants';
import * as AppConstants from '../../../SharedModule/Utils/AppConstants';
import ErrorMessages from '../../../SharedModule/MessageHandlers/ErrorMessages';
import SuccessMessage from '../../../SharedModule/MessageHandlers/SuccessMessage';
import { ResendEmailActions } from '../Store/Actions/RegistrationActions';
import moment from 'moment';
import Spinner from '../../../SharedModule/Spinner/Spinner';
import ResentOTPDialog from './resendOTPDialog';
// import SDLogin from '../../Login/Components/SDLogin';
import { useHistory,useLocation  } from 'react-router-dom';



export default function RegistrationSuccessForm(props) {
    const history = useHistory();
    const { state } = useLocation();
    const location = useLocation();
    const fromThirdParty = sessionStorage.getItem('fromThirdParty') === 'true';

    const dispatch = useDispatch();
    let successMessagesArray = [];
    let errorMessagesArray = [];
    // eslint-disable-next-line no-unused-vars
    const [registrationData, setRegistrationData] = React.useState(
        props.location.state && props.location.state.registrationValue
            ? props.location.state.registrationValue
            : []
    );
    const [errorMessages, setErrorMessages] = React.useState([]);
    const [successMessages, setSuccessMessages] = React.useState([]);
    const [spinnerLoader, setSpinnerLoader] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    // Dispatch Actions
    const resendEmailDispatch = (resendEmailCriteria) => dispatch(ResendEmailActions(resendEmailCriteria));

    const resendEmailData = useSelector(state => state.registrationState.resendEmail);

    useEffect(() => {
        if (resendEmailData) {
            setSpinnerLoader(false);
            errorMessagesArray = [];
            errorMessagesArray = AppConstants.handleAPIErrors(resendEmailData, errorMessagesArray, RegistrationConstants.REGISTRATION_TECHNICAL_ERROR);
            if (errorMessagesArray && errorMessagesArray.length > 0) {
                setErrorMessages(errorMessagesArray);
            } else {
                successMessagesArray.push(RegistrationConstants.RESEND_EMAIL_SUCCESS);
                setSuccessMessages(successMessagesArray); 
                // setOpen(true);
            }
        }
    }, [resendEmailData]);

    // Method to resend Email
    const handleResendEmail = () => {
        setOpen(false);
        const resendEmailRequest = {
            userId: registrationData.userID ? registrationData.userID : '',
            firstName: registrationData.firstName ? registrationData.firstName : '',
            lastName: registrationData.lastName ? registrationData.lastName : '',
            email: registrationData.emailAddress ? registrationData.emailAddress : '',
            dob: registrationData.dob ? moment(registrationData.dob).format('YYYY-MM-DD') : ''
        };
        setSpinnerLoader(true);
        resendEmailDispatch(resendEmailRequest);
    }

    //Method toredirect to Member Login
    const goToMemberLogin = () => {
        setOpen(false);
        window.location.href = process.env.REACT_APP_TP_MEMBER_LOGIN;
    }
    const continueProcess = () => {
        const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        history.push('/MemberConsent', { userInfo: userInfo });
    }
    

    return (
        <div className="container">
            {spinnerLoader ? <Spinner /> : null}
            <div className="success-alert-container">
                {errorMessages && errorMessages.length > 0 ?
                    <ErrorMessages errorMessages={errorMessages} /> : null}
                {errorMessages && successMessages && errorMessages.length === 0 && successMessages.length > 0 ?
                    <SuccessMessage successMessages={successMessages} /> : null}
            </div>
                {/* <div className="float-right"><a href="/Help2" onClick={()=>{localStorage.setItem("ioxHelpDoc","member")}} target="_blank" className="dib fw-600 py-2 mt-1"> Help </a></div> */}
                {/* <a href="https://www.conduent.com/contact-us/frequently-asked-questions/" target="_blank">Help</a>           */}
            {/* </div> */}
            <div className="success-container custom-success-container">
                <div className="">
                    <img src="Successfully_submitted.svg" className="mx-auto d-block" alt="Successfully Submitted" />
                    <h6 className="text-center font-weight-bold my-3">Congratulations!</h6>
                    <div className="alert alert-success custom-alert">

                    {fromThirdParty ? 
                        <p>You have successfully registered for IOX access.<br/> Please click 'CONTINUE PROCESS' to provide Consent and give data access to third party app</p> :
                        <p>You have successfully registered for IOX access. <br/>Please click 'CLOSE' to return to IOX landing page</p>
                    }
                   
                    </div>
                    {/* <p className="text-center px-lg-5 m-0 mb-5">Please login to continue.</p> */}
                    <div className="tab-header mr-2">
                    <div className="float-right th-btnGroup">

                  {!fromThirdParty && (
                        <Button variant='outlined' color='primary' className='btn btn-ic btn-login-new' onClick={() => history.push('/Home')}>
                         Close
                        </Button>
                    )}
                  {fromThirdParty && (
                        <Button variant='outlined' color='primary' className='btn btn-ic btn-login-new' onClick={continueProcess}>
                          Continue Process
                        </Button>
                     )}
                        
                    </div>
                    </div>
                </div>
            </div>
            {open ? <ResentOTPDialog open={open} setOpen={setOpen} goToMemberLogin={goToMemberLogin} handleResendEmail={handleResendEmail}/> : null}
        </div>
    )
}


