import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { validateMember, validateAdmin, getMemberProfileDetails } from './ValidateMember';
import PopupModal from './PopupModal';
import ErrorMessages from '../../../SharedModule/MessageHandlers/ErrorMessages';
import { CONFIDENTIALITY_STATUS } from '../../Registration/Components/RegistrationConstants';

// import Alert from '@material-ui/lab/Alert';
// import IconButton from '@material-ui/core/IconButton';



export default function Home(props) {
   
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [modalNavigationPath, setModalNavigationPath] = useState('');
    const history = useHistory();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userName, setUserName] = useState('');
    const [errorMessages, setErrorMessages] = useState([]);
  

    const decodeJWT = (token) => {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => 
            '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''));
        return JSON.parse(jsonPayload);
    };

    const setSessionData = (key, value) => {
        sessionStorage.setItem(key, JSON.stringify(value));
    };

    const clearSessionData = (key) => {
        sessionStorage.removeItem(key);
    };

    const handleMemberRegistrationResponse = (validationResponse) => {
        if (validationResponse && validationResponse.status === "MEMBER_FOUND") {
            setModalMessage("You have already registered. Click Ok to return to the IOX Landing page. If you continue to have issues, Please Contact HelpDesk at 1-877-350-2171.");
            setModalNavigationPath('/Home');
            setShowErrorModal(true);
        } else if (!validationResponse || validationResponse.status === "Technical Error") {
            setErrorMessages(["An Error Occurred During Member Validation."]);
        } else {
             history.push('/registration');
            // history.push('/home');

        }
    };
 
    const navigateBasedOnModal = () => {
        setShowErrorModal(false);
        history.push(modalNavigationPath);
    };
    

    const handleMemberValidationResponse = async (validationResponse, userInfo) => {
        if (validationResponse && validationResponse.status === "MEMBER_FOUND") {
            const profileDetailsResponse = await getMemberProfileDetails(userInfo.oid);
            if (profileDetailsResponse && profileDetailsResponse.status === "Active") {
                if (userInfo.roles && userInfo.roles.includes('Admin')) {
                    localStorage.setItem('isAdmin', 'true');
                   history.push('/appPayerManagement');
               } else {
                    localStorage.setItem('isAdmin', 'false');
                   history.push('/ConsentsManagement');
               }
            } else {
                 setErrorMessages([CONFIDENTIALITY_STATUS]);
            }
        } else if (!validationResponse || validationResponse.status === "Technical Error") {
            setErrorMessages(["An error occurred during Member validation."]);
        } else {
        setModalMessage("Member is not registered. Please Register the Member First.");
        setModalNavigationPath('/registration');
        setShowErrorModal(true);
            
        }
    };

    const handleAdminValidationResponse = (validationResponse) => {
        if (validationResponse && Object.keys(validationResponse).length !== 0) {
            const securityRole = validationResponse.securityRole;
             localStorage.setItem('cnRole', validationResponse.securityRole);
            localStorage.setItem('isAdmin', 'true');
            if (securityRole === "High") {
                  history.push('/appPayerManagement');
            } else if (securityRole === "Low") {
                history.push('/memberManagement');
            } else {
                localStorage.removeItem('isAdmin');
                setErrorMessages(["You are recognized but your access level does not allow management features."]);
            }
        } else if (validationResponse && Object.keys(validationResponse).length === 0) {
            localStorage.setItem('isAdmin', 'false');
            setErrorMessages(["You are not an Admin.Please contact Helpdesk."]);
            history.push('/login');
        } else {
            setErrorMessages(["An error occurred during admin validation. Please try again later."]);
            history.push('/login');  
        }
    };
    
    const getUserProfile = async (userInfo) => {
        setIsAuthenticated(true);
        setSessionData("userInfo", userInfo);

        const userType = sessionStorage.getItem('userType');
        const isRegistration = JSON.parse(sessionStorage.getItem("isRegistration"));
        const loggedInViaAuthorize = sessionStorage.getItem("loggedInViaAuthorize") === "true";

        try {
            let validationResponse;
            if (isRegistration) {
                clearSessionData("isRegistration");
                validationResponse = await validateMember(userInfo);
                handleMemberRegistrationResponse(validationResponse);
            } else if (loggedInViaAuthorize) {
                clearSessionData("loggedInViaAuthorize");
                history.push('/MemberConsent');
            } else if (userType === 'admin') {
                    validationResponse = await validateAdmin(userInfo);
                    handleAdminValidationResponse(validationResponse);
            } else {
                validationResponse = await validateMember(userInfo);
                handleMemberValidationResponse(validationResponse, userInfo);
                }
        } catch (error) {
            console.error('Error During User Profile Validation:', error);
            setErrorMessages([error.response?.data?.message || "An unexpected error occurred."]);
        }
    };

    useEffect(() => {
        const exchangeCodeForToken = async (code, codeVerifier) => {
            const userType = sessionStorage.getItem('userType');
            const clientId = userType === 'admin' ? process.env.REACT_APP_AZURE_AD_Admin_CLIENT_ID : process.env.REACT_APP_AZURE_AD_CLIENT_ID;
            const tokenUrl = userType === 'admin' ? process.env.REACT_APP_AZURE_AD_Admin_T0KEN_URL : process.env.REACT_APP_AZURE_AD_T0KEN_URL;
           

            const bodyParams = new URLSearchParams({
                client_id: clientId,
                grant_type: "authorization_code",
                code,
                redirect_uri: process.env.REACT_APP_AZURE_AD_REDIRECT_URI,
                code_verifier: codeVerifier 
            });

              try {
                const response = await axios.post(tokenUrl, bodyParams, {
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                });

                if (response.data && response.data.id_token) {
                   const token = response.data.id_token;
                    sessionStorage.setItem("token", token);
                    sessionStorage.setItem("accessToken",  response.data.id_token);
                    const userInfo = decodeJWT(token);
                    sessionStorage.setItem('userIdentifier', userInfo.login_hint);
                   getUserProfile(userInfo);
                    var userId = userInfo.oid;
                   sessionStorage.setItem('isLoggedIn', userId);

                } else {
                    setIsAuthenticated(false); 
                    history.push('/');
                }
            } catch (err) {
                console.error("Error during token exchange:", err);
                history.push('/');
            }
        };

        const query = window.location.search;
        const urlParams = new URLSearchParams(query);
        const code = urlParams.get("code");

        if (code) {
            const codeVerifier = sessionStorage.getItem("pkce_code_verifier");
            exchangeCodeForToken(code, codeVerifier);
        } else {
            history.push('/');
        }
    }, [history]);


    

    return (
        <div>
            {errorMessages.length > 0 && (
                <ErrorMessages errorMessages={errorMessages} />
            )}
         {showErrorModal ? (
      <PopupModal 
            showModal={showErrorModal} 
            message={modalMessage} 
            onConfirm={navigateBasedOnModal} 
            buttonText="Ok"
            
         />
         ) : errorMessages.length === 0 && (
        <div>Loading...</div>
        )}
        </div>
    );

   
    
}
