/* eslint-disable array-callback-return */
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { TextField, Radio } from '@material-ui/core';
import { Button } from 'react-bootstrap';
import ClaimsDialog from './ClaimsDialog';
import * as ClaimsConstants from './ClaimsConstants';
import ErrorMessages from '../../../SharedModule/MessageHandlers/ErrorMessages';
import moment from 'moment';
import numeral from 'numeral';
import ClaimsPDF from './ClaimsPDF';
import ReactToPrint from 'react-to-print';
import { setPrintLayout } from '../../../SharedModule/Store/Actions/AppActions';

function Row(props) {
  // Page Constants
  const { row, index, rowIndex, onClickRow, cIndex, setOpen, feedbackQsList, values,
    setValues, showError, setShowError, showErrorText, setShowErrorText, errorMessages,
    selectedMenu, setIsClaimsOpen, setRowData, disableSaveFeedback } = props;

  // Method to handle radio button changes
  const handleChange = name => event => {
    setRowData(row);
    setIsClaimsOpen(true);
    setValues({ ...values, [name]: event.target.value });
    if (name === 'feedbackQs0' && event.target.value !== 'O') {
      setValues({ ...values, [name]: event.target.value, feedbackText0: '' });
    }
    if (name === 'feedbackQs1' && event.target.value !== 'O') {
      setValues({ ...values, [name]: event.target.value, feedbackText1: '' });
    }
    if (name === 'feedbackQs2' && event.target.value !== 'O') {
      setValues({ ...values, [name]: event.target.value, feedbackText2: '' });
    }
    if (name === 'feedbackQs3' && event.target.value !== 'O') {
      setValues({ ...values, [name]: event.target.value, feedbackText3: '' });
    }
  };

  // Method to submit Feedback Form
  const submitFeedbackForm = () => {
    var i = 0;
    let errorObj = showError;
    let errorTextObj = showErrorText;
    var feedbackError = 'feedbackError';
    var feedbackErrorText = 'feedbackErrorText';
    for (var x = 0; x < feedbackQsList.length; x++) {
      setShowError(false);
      setShowErrorText('');
      errorObj = { ...errorObj, [feedbackError + x]: false };
      errorTextObj = { ...errorTextObj, [feedbackErrorText + x]: '' };
      if (values['feedbackQs' + x] === 'O' &&
        (values['feedbackText' + x] === '' || (values['feedbackText' + x] && values['feedbackText' + x].trim() === ''))) {
        i++;
        errorObj = { ...errorObj, [feedbackError + x]: true };
        errorTextObj = { ...errorTextObj, [feedbackErrorText + x]: ClaimsConstants.OTHER_TEXT_ERROR };
      }
    }
    if (i === 0) {
      setRowData(row);
      setOpen(true);
    } else {
      setShowError(errorObj);
      setShowErrorText(errorTextObj);
    }
  }

  return (
    <React.Fragment>
      <TableRow key={row.tcn} onClick={() => onClickRow(row, index)}>
        <TableCell className="toggle-arrow">
          <IconButton aria-label="expand row" size="small">
            {index === cIndex ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <div className="gray-font">TCN</div>
          <p className="font-weight-bold my-2 p-0">{row.tcn}</p>
        </TableCell>
        <TableCell className="hide-on-mob"><div className="gray-font">DATE OF SERVICE</div><p className="p-0 my-2">{`${moment(row.fdos).format('DD MMM YYYY')} - ${moment(row.ldos).format('DD MMM YYYY')}`}</p></TableCell>
        <TableCell className="hide-on-mob"><div className="gray-font">BILLING PROVIDER</div><p className="p-0 my-2">{row.billingProvider}</p></TableCell>
        <TableCell>
          <div className="gray-font">AMOUNT PAID</div>
          <p className="font-weight-bold my-2 p-0">{row.totalPaidAmount ? `$${numeral(Number(row.totalPaidAmount.toFixed(2))).format('0,0.00')}` : '$0.00'}</p>
        </TableCell>
        <TableCell hidden={row.isHeaderPriced === 'N'}>
          <div className="gray-font">COPAY</div>
          <p className="font-weight-bold my-2 p-0">{row.totalCoPayAmount ? `$${numeral(Number(row.totalCoPayAmount.toFixed(2))).format('0,0.00')}` : '$0.00'}</p>
        </TableCell>
      </TableRow>
      <TableRow className="no-hover-color">
        {index === cIndex ? <Row row={row} indexVal={index === cIndex ? true : false} /> : null}
      </TableRow>
      <TableRow className="no-hover-color">
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={index === rowIndex} timeout="auto" unmountOnExit>
            <Box margin={1} padding={2}>
              <div className="show-on-mob"><div className=""><div className="gray-font">DATE OF SERVICE</div><p className="p-0 my-2">{`${moment(row.fdos).format('DD MMM YYYY')} - ${moment(row.ldos).format('DD MMM YYYY')}`}</p></div></div>
              <div className="show-on-mob"><div className=""><div className="gray-font">BILLING PROVIDER</div><p className="p-0 my-2">{row.billingProvider}</p></div></div>
              <div className="row">
                {row.renderingProvider ? <div hidden={row.isHeaderPriced === 'N'} className="col-lg-3 mb-3"><div className="gray-font">RENDERING PROVIDER</div><p className="p-0 my-2">{row.renderingProvider}</p></div> : null}
                <div hidden={row.isHeaderPriced === 'N'} className="col"><div className="gray-font">DESCRIPTION OF SERVICE</div><p className="p-0 my-2">{row.descOfService}</p></div>
              </div>
              {(row.surgicalProcedureDesc && row.surgicalProcedureDesc.length > 0) ? <div hidden={row.isHeaderPriced === 'N'} className="mb-4"><div className="gray-font">SURGICAL PROCEDURE</div>
                {row.surgicalProcedureDesc.slice(0, 7).map((spRow, index) => (<p className="p-0 my-2">{spRow}</p>))}</div> : null}
              <div>
                <div className="gray-font">PRIMARY DIAGNOSIS</div>
                <p className="p-0 my-2">{row.diagnosisDesc[0]}</p>
              </div>
              {row.isHeaderPriced === 'N' && row.lineItems && row.lineItems.length > 0 ?
                <Table size="small" aria-label="purchases" className="table border-0 inner-table mt-3">
                  <TableBody>
                    {row.lineItems[0].lineDescOfService && row.claimIdCode === '62' ?
                      <React.Fragment>
                        <TableRow key={row.lineItems[0].lineNumber}>
                          <TableCell className="dos"><div className="gray-font">DATE OF SERVICE</div><p className="p-0 my-2">{`${moment(row.lineItems[0].lineFdos).format('DD MMM YYYY')} - ${moment(row.lineItems[0].lineLdos).format('DD MMM YYYY')}`}</p></TableCell>
                          <TableCell><div className="gray-font">RENDERING PROVIDER</div><p className="p-0 my-2">{row.lineItems[0].lineRenderingProvider ? row.lineItems[0].lineRenderingProvider : ''}</p></TableCell>
                          <TableCell><div className="gray-font">DESCRIPTION OF SERVICE</div><p className="p-0 my-2">{row.lineItems[0].lineDescOfService ? row.lineItems[0].lineDescOfService : ''}</p></TableCell>
                          <TableCell>
                            <div className="gray-font">AMOUNT PAID</div>
                            <p className="font-weight-bold my-2 p-0">{row.lineItems[0].linePaidAmount ? `$${numeral(Number(row.lineItems[0].linePaidAmount.toFixed(2))).format('0,0.00')}` : (row.lineItems[0].linebaseAmountSourceCode === 'ZP' ? 'Included in the above price' : '$0.00')}</p>
                          </TableCell>
                          <TableCell>
                            <div className="gray-font">COPAY</div>
                            <p className="font-weight-bold my-2 p-0">{row.lineItems[0].lineCoPayAmount ? `$${numeral(Number(row.lineItems[0].lineCoPayAmount.toFixed(2))).format('0,0.00')}` : '$0.00'}</p>
                          </TableCell>
                        </TableRow>
                      </React.Fragment> :
                      row.lineItems.map((lineItem) => (
                        <React.Fragment>
                          <TableRow key={lineItem.lineNumber}>
                            <TableCell className="dos"><div className="gray-font">DATE OF SERVICE</div><p className="p-0 my-2">{`${moment(lineItem.lineFdos).format('DD MMM YYYY')} - ${moment(lineItem.lineLdos).format('DD MMM YYYY')}`}</p></TableCell>
                            <TableCell><div className="gray-font">RENDERING PROVIDER</div><p className="p-0 my-2">{lineItem.lineRenderingProvider ? lineItem.lineRenderingProvider : ''}</p></TableCell>
                            <TableCell><div className="gray-font">DESCRIPTION OF SERVICE</div><p className="p-0 my-2">{lineItem.lineDescOfService ? lineItem.lineDescOfService : ''}</p></TableCell>
                            <TableCell>
                              <div className="gray-font">AMOUNT PAID</div>
                              <p className="font-weight-bold my-2 p-0">{lineItem.linePaidAmount ? `$${numeral(Number(lineItem.linePaidAmount.toFixed(2))).format('0,0.00')}` : (lineItem.linebaseAmountSourceCode === 'ZP' ? 'Included in the above price' : '$0.00')}</p>
                            </TableCell>
                            <TableCell>
                              <div className="gray-font">COPAY</div>
                              <p className="font-weight-bold my-2 p-0">{lineItem.lineCoPayAmount ? `$${numeral(Number(lineItem.lineCoPayAmount.toFixed(2))).format('0,0.00')}` : '$0.00'}</p>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      ))}
                  </TableBody>
                </Table> : null}
              <div className="col px-0 pt-3">
                <div className="form-wrapper col-sm-12 px-0">
                  {errorMessages && errorMessages.length > 0 ?
                    <ErrorMessages errorMessages={errorMessages} /> : null}
                </div>
                {feedbackQsList && feedbackQsList.map((data, index) => (
                  <div className="col form-group col-sm-12 mb-2 px-0">
                    <p className="p-0 my-0 font-weight-bold">{data.value}</p>
                    <div className="row ml-0 mt-1">
                      <div className="col col-sm-2 col-xs-4 px-0 py-2">
                        <Radio
                          value="Y"
                          id={`check-yes${index}`}
                          defaultChecked
                          disabled={row.tcn === disableSaveFeedback.tcn && selectedMenu === disableSaveFeedback.key && disableSaveFeedback.disableFeedback}
                          checked={values['feedbackQs' + index] === 'Y'}
                          onChange={handleChange(`feedbackQs${index}`)}
                        />
                        <label className="ml-2" htmlFor={`check-yes${index}`}>
                          Yes
                      </label>
                      </div>
                      <div className="col col-sm-2 col-xs-4 px-0 py-2">
                        <Radio
                          value="N"
                          id={`check-no${index}`}
                          disabled={row.tcn === disableSaveFeedback.tcn && selectedMenu === disableSaveFeedback.key && disableSaveFeedback.disableFeedback}
                          checked={values['feedbackQs' + index] === 'N'}
                          onChange={handleChange(`feedbackQs${index}`)}
                        />
                        <label className="ml-2" htmlFor={`check-no${index}`}>
                          No
                      </label>
                      </div>
                      <div className="col col-md-2 col-lg-1 col-xs-4 px-0 py-2">
                        <Radio
                          value="O"
                          id={`check-others${index}`}
                          disabled={row.tcn === disableSaveFeedback.tcn && selectedMenu === disableSaveFeedback.key && disableSaveFeedback.disableFeedback}
                          checked={values['feedbackQs' + index] === 'O'}
                          onChange={handleChange(`feedbackQs${index}`)}
                        />
                        <label className="ml-2" htmlFor={`check-others${index}`}>
                          Others
                    </label>
                      </div>
                      <div className="mui-custom-form col-sm-5 col-md-4 other-textbox">
                        <label htmlFor={`text-others${index}`} hidden>Others</label>
                        <TextField
                          autoComplete="off"
                          id={`text-others${index}`}
                          required
                          fullWidth
                          disabled={values['feedbackQs' + index] !== 'O' || (row.tcn === disableSaveFeedback.tcn && selectedMenu === disableSaveFeedback.key && disableSaveFeedback.disableFeedback)}
                          value={values['feedbackText' + index]}
                          onChange={handleChange(`feedbackText${index}`)}
                          inputProps={{ maxLength: 200 }}
                          InputLabelProps={{
                            shrink: true
                          }}
                          error={showError['feedbackError' + index]}
                          helperText={showError['feedbackError' + index] ? showErrorText['feedbackErrorText' + index] : null}
                        />
                      </div>
                    </div>
                  </div>))}
                <div className="mt-3">
                  <Button id="submitFeedback" className='btn-primary btn-xs mx-0' onClick={() => submitFeedbackForm()}
                    hidden={row.tcn === disableSaveFeedback.tcn && selectedMenu === disableSaveFeedback.key && disableSaveFeedback.disableFeedback}
                    aria-hidden={row.tcn === disableSaveFeedback.tcn && selectedMenu === disableSaveFeedback.key && disableSaveFeedback.disableFeedback}
                  > Save Feedback </Button>
                  <Button id="submitFeedback" className='btn-primary btn-xs mx-0' disabled
                    hidden={!(row.tcn === disableSaveFeedback.tcn && selectedMenu === disableSaveFeedback.key && disableSaveFeedback.disableFeedback)}
                    aria-hidden={!(row.tcn === disableSaveFeedback.tcn && selectedMenu === disableSaveFeedback.key && disableSaveFeedback.disableFeedback)}
                  > Save Feedback </Button>
                </div>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function ClaimsTable(props) {
  // Page Constants
  const dispatch = useDispatch();
  const toPrintRef = useRef();
  const printLayout = useSelector(state => state.sharedState.printLayout);
  const { feedbackQsList, claimsDataList, selectedMenu, open, setOpen, isClaimsOpen, setIsClaimsOpen,
    userID, userName, setSpinnerLoader, loginTime, onSelect, selectedKey, setSelectedKey } = props;
  const [rowIndex, setRowIndex] = React.useState(-1);
  const [tempRowIndex, setTempRowIndex] = React.useState(-1);
  const [cIndex, setCIndex] = React.useState(-1);
  const [rowData, setRowData] = React.useState([]);
  const [tempRowData, setTempRowData] = React.useState([]);
  const [values, setValues] = React.useState([]);
  const [showError, setShowError] = React.useState(false);
  const [showErrorText, setShowErrorText] = React.useState('');
  const [disableSaveFeedback, setDisableSaveFeedback] = React.useState({});
  const [errorMessages, setErrorMessages] = React.useState([]);

  useEffect(() => {
    setRowIndex(-1);
  }, [selectedMenu]);

  // Method called on each row click
  const onClickRow = (row, i) => {
    setTempRowIndex(i);
    setTempRowData(row);
    if (!isClaimsOpen) {
      // Logic - if claim's feedback is already saved - Start
      if (row.claimsFeedbackDetails && row.claimsFeedbackDetails.length > 0) {
        var isafter = moment(loginTime).isAfter(moment(row.claimsFeedbackDetails[0].updatedOn));
        savedFeedbackObj(row.claimsFeedbackDetails);
        const disableFeedback = {
          tcn: row.tcn,
          key: selectedMenu,
          disableFeedback: isafter
        };
        setDisableSaveFeedback(disableFeedback);
        if (isafter) {
          setIsClaimsOpen(false);
        }
        else {
          setIsClaimsOpen(rowIndex !== i && isafter ? true : false);
        }
      } else {
        createFeedbackObj();
        setIsClaimsOpen(rowIndex !== i ? true : false);
      }

      setRowData(row);
      // Logic - if claim's feedback is already saved - End

      setCIndex(-1);
      setRowIndex(rowIndex === i ? -1 : i);
    } else {
      setOpen(true);
    }
  }

  // Method to dynamically create Feedback Section on load
  const createFeedbackObj = () => {
    var feedbackQsId = 'feedbackQsId';
    var feedbackQs = 'feedbackQs';
    var feedbackText = 'feedbackText';
    var feedbackError = 'feedbackError';
    var feedbackErrorText = 'feedbackErrorText';

    let obj = {};
    let errorObj = {};
    let errorTextObj = {};
    for (var x = 0; x < feedbackQsList.length; x++) {
      obj = { ...obj, [feedbackQsId + x]: '', [feedbackQs + x]: 'Y', [feedbackText + x]: '' };
      errorObj = { ...errorObj, [feedbackError + x]: false };
      errorTextObj = { ...errorTextObj, [feedbackErrorText + x]: '' };
      obj[`feedbackQsId${x}`] = feedbackQsList[x].key;
    }
    setValues(obj);
    setShowError(errorObj);
    setShowErrorText(errorTextObj);
  }

  // Method to dynamically create Feedback Section on load
  const savedFeedbackObj = (claimsFeedbackDetails) => {
    var feedbackQsId = 'feedbackQsId';
    var feedbackQs = 'feedbackQs';
    var feedbackText = 'feedbackText';
    var feedbackError = 'feedbackError';
    var feedbackErrorText = 'feedbackErrorText';

    let obj = {};
    let errorObj = {};
    let errorTextObj = {};
    claimsFeedbackDetails = JSON.parse(JSON.stringify(claimsFeedbackDetails.sort((a, b) => a.feedbackQuestionId.toLocaleUpperCase() < b.feedbackQuestionId.toLocaleUpperCase() ? -1 : 1)));
    for (var x = 0; x < feedbackQsList.length; x++) {
      obj = { ...obj, [feedbackQsId + x]: '', [feedbackQs + x]: 'Y', [feedbackText + x]: '' };
      errorObj = { ...errorObj, [feedbackError + x]: false };
      errorTextObj = { ...errorTextObj, [feedbackErrorText + x]: '' };
      obj[`feedbackQsId${x}`] = feedbackQsList[x].key;
    }

    claimsFeedbackDetails.map((data, index) => {
      obj[`feedbackQs${index}`] = data.responseOption;
      obj[`feedbackText${index}`] = data.responseText;
    });
    setValues(obj);
    setShowError(errorObj);
    setShowErrorText(errorTextObj);
  }

  return (
    <div className="px-sm-4">
      {claimsDataList && claimsDataList.claims ?
        <div className="p-3">
          <ReactToPrint
            onBeforeGetContent={() => {
              dispatch(setPrintLayout(true));
              setSpinnerLoader(true);
              return new Promise(resolve => setTimeout(() => resolve(), 100));
            }}
            onAfterPrint={() => {
              setSpinnerLoader(false);
              dispatch(setPrintLayout(false));
            }}
            trigger={() => (
              <img src="PDF_Normal.svg" alt="pdf icon" />
            )}
            content={() => toPrintRef.current}
          />

          <span className="float-right">
            <div className="gray-font">TOTAL AMOUNT PAID FOR THIS MONTH</div>
            <h3 className="text-right">{claimsDataList.totalPaidAmount ? `$${numeral(Number(claimsDataList.totalPaidAmount.toFixed(2))).format('0,0.00')}` : '$0.00'}</h3>
          </span>
        </div> : null}
      <TableContainer className="mb-4" component={Paper}>
        <Table aria-label="collapsible table" className="table my-0">
          <TableBody>
            {claimsDataList && claimsDataList.claims && claimsDataList.claims.map((row, index) => (
              <Row key={row.name} onClickRow={onClickRow} rowIndex={rowIndex} cIndex={cIndex} index={index}
                row={row} setOpen={setOpen} feedbackQsList={feedbackQsList} selectedMenu={selectedMenu}
                values={values} setValues={setValues} showError={showError} setShowError={setShowError}
                showErrorText={showErrorText} setShowErrorText={setShowErrorText} errorMessages={errorMessages}
                setIsClaimsOpen={setIsClaimsOpen} setRowData={setRowData} disableSaveFeedback={disableSaveFeedback} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ClaimsDialog
        open={open}
        setOpen={setOpen}
        setIsClaimsOpen={setIsClaimsOpen}
        row={rowData}
        values={values}
        setValues={setValues}
        userID={userID}
        feedbackQsList={feedbackQsList}
        setSpinnerLoader={setSpinnerLoader}
        setErrorMessages={setErrorMessages}
        showError={showError}
        setShowError={setShowError}
        showErrorText={showErrorText}
        setShowErrorText={setShowErrorText}
        selectedMenu={selectedMenu}
        selectedKey={selectedKey}
        setSelectedKey={setSelectedKey}
        onSelect={onSelect}
        tempRowIndex={tempRowIndex}
        tempRowData={tempRowData}
        setTempRowIndex={setTempRowIndex}
        setTempRowData={setTempRowData}
        onClickRow={onClickRow}
      />
      <div ref={toPrintRef} className="hide-on-screen">
        {printLayout && claimsDataList && claimsDataList.claims && claimsDataList.claims.length > 0 ?
          <ClaimsPDF claimsPDFdata={claimsDataList} feedbackQsList={feedbackQsList}
            userID={userID} userName={userName} selectedMenu={selectedMenu} />
          : null}
      </div>
    </div>
  );
}

