import React from 'react';
import Card from 'react-bootstrap/Card';

import Table from 'react-bootstrap/Table';
import styles from './AllergyTolerance.css';


export default function AllergyTolerance(){
let AllergyIntoleranceResponse={ 
    "resourceType": "AllergyIntolerance",
    "id": "12345678",
    "meta": {
        "profile": [
            "http://hl7.org/fhir/us/core/StructureDefinition/us-core-allergyintolerance"
        ]
    },
    "text": {
        "status": "generated",
        "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>Generated Narrative with Details</p></div>"
    },
    "identifier": [
        {
            "value": "12345678"
        }
    ],
    "clinicalStatus": {
        "coding": [
            {
                "system": "http://terminology.hl7.org/CodeSystem/allergyintolerance-clinical",
                "code": "active"
            }
        ]
    },
    "verificationStatus": {
        "coding": [
            {
                "system": "http://terminology.hl7.org/CodeSystem/allergyintolerance-verification",
                "code": "confirmed"
            }
        ]
    },
    "type": "allergy",
    "category": [
        "medication"
    ],
    "criticality": "high",
    "code": {
        "coding": [
            {
                "system": "http://snomed.info/sct",
                "code": "387406002",
                "display": "Sulfonamide (substance)"
            }
        ],
        "text": "sulfonamide antibacterial"
    },
    "patient": {
        "reference": "Patient/200000400",
        "display": "Mark Wayne Clark"
    },
    "note": [
        {
            "text": "Notes on allegies which is not captured in any of the above fields"
        }
    ],
    "reaction": [
        {
            "manifestation": [
                {
                    "coding": [
                        {
                            "system": "http://snomed.info/sct",
                            "code": "271807003",
                            "display": "skin rash"
                        }
                    ],
                    "text": "skin rash"
                }
            ],
            "severity": "mild"
        }
    ]
}
const codestyle={backgroundColor: 'lightgray'};
return (
<div className="container">
<Card className="card-background"> <Card.Body>
<blockquote className="blockquote mb-0">
<h1 className="text-header">Allergy Intolerance</h1>
<p>A record of a clinical assessment of an allergy or intolerance; a propensity, or a potential risk to an individual, to have an adverse reaction on future exposure to the specified substance, or class of substance.</p>
</blockquote> </Card.Body>
</Card>
<br/>
<hr className="horizontal-rule"></hr>
<br/>

<Card className="card-background">
<Card.Body>
<h4 className="AllergyTolerance-heading">URL</h4>
<div>
<codeblock className="accesstoken-code"> URL</codeblock>
<span>&nbsp;</span><span>&nbsp;</span>
<codeblock className="accesstoken-code">{process.env.REACT_APP_EDUCATIONAL_MATERIAL_URL}clinicalData/api/R4/AllergyIntolerance</codeblock>
</div>
</Card.Body>
<Card.Body>
<h4 className="AllergyTolerance-heading">Headers</h4>
<Table striped bordered hover>
<tbody>

<tr>
<td>Accept</td>
<td>application/json</td>
</tr>
<tr> <td>Authorization</td>
<td>Bearer 'ACCESS_TOKEN'</td>
</tr>
</tbody>
</Table>
</Card.Body>
<p>Note:Third party app will have access token after member has given consent to app (Refer Member consent section)
</p>
<Card.Body>
<h4 className="AllergyTolerance-heading">Sample Request</h4>

<div style={codestyle}>'curl --location 'https://iox.dev.sddss.apps.conduent.com/clincalData/api/R4/AllergyIntolerance/1011''\<br/>
                                    '--header 'Authorization: Bearer XYPDpdP2QTyZW3kpqLqSaYclmlU' '\
</div>

<h4 className="AllergyTolerance-heading">Sample Response</h4>
<pre style={codestyle}>
<code class="prettyprint">
{JSON.stringify(AllergyIntoleranceResponse,null,'  ')}

</code>
</pre>
</Card.Body>
</Card>
</div>
);
}