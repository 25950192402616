import React,{useState,useEffect} from 'react';
import TableComponent from '../../../SharedModule/Table/Table';
import {getAppstatusCount} from './utility' ;

const appRegistrationHeadCells = [
    {
        id: 'reportName', numeric: false, disablePadding: true, label: 'Reports', enableHyperLink: false, fontSize: 12, width: '32%'
    },
    {
        id: 'submitted', numeric: false, disablePadding: false, label: 'Submitted', enableHyperLink: true, fontSize: 12, width: '17%'
    },
    {
        id: 'approved', numeric: false, disablePadding: false, label: 'Approved', enableHyperLink: true, fontSize: 12, width: '17%'
    },
    {
        id: 'rejected', numeric: false, disablePadding: false, label: 'Rejected', enableHyperLink: true, fontSize: 12, width: '17%'
    },
    {
        id: 'revoked', numeric: false, disablePadding: false, label: 'Revoked', enableHyperLink: true, fontSize: 12, width: '17%'
    }
];
const memberRegistrationHeadCells = [
    {
        id: 'reportName', numeric: false, disablePadding: true, label: 'Reports', enableHyperLink: false, fontSize: 12, width: '32%'
    },
	{
        id: 'registrationCount', numeric: false, disablePadding: false, label: 'Registrations', enableHyperLink: true, fontSize: 12, width: '17%'
    },
    {
        id: 'age0toBelow18Count', numeric: false, disablePadding: false, label: '0 to <18', enableHyperLink: false, fontSize: 12, width: '17%'
    },
    {
        id: 'age18toBelow30Count', numeric: false, disablePadding: false, label: '18 to <30', enableHyperLink: false, fontSize: 12, width: '17%'
    },
    {
        id: 'age30toBelow50Count', numeric: false, disablePadding: false, label: '30 to <50', enableHyperLink: false, fontSize: 12, width: '17%'
    },
    {
        id: 'age50toBelow70Count', numeric: false, disablePadding: false, label: '50 to <70', enableHyperLink: false, fontSize: 12, width: '17%'
    },
    {
        id: 'ageAbove70Count', numeric: false, disablePadding: false, label: 'Above >70', enableHyperLink: false, fontSize: 12, width: '17%'
    },
];
const apiAccessHeadCells = [
    {
        id: 'reportName', numeric: false, disablePadding: true, label: 'Reports', enableHyperLink: false, fontSize: 12, width: '32%'
    },
    {
        id: 'patientCount', numeric: false, disablePadding: false, label: 'Patient Access API', enableHyperLink: true, fontSize: 12, width: '17%'
    },
    {
        id: 'providerCount', numeric: false, disablePadding: false, label: 'Provider Directory API', enableHyperLink: true, fontSize: 12, width: '17%'
    },
    {
        id: 'drugCount', numeric: false, disablePadding: false, label: 'Covered Drug List API', enableHyperLink: true, fontSize: 12, width: '17%'
    },
];
const appApiAccessHeadCells = [
    {
       id: 'appName', numeric: false, disablePadding: true, label: 'App Name - Patient Access API', enableHyperLink: false, fontSize: 12, width: '32%'
   },
   {
       id: 'count', numeric: false, disablePadding: false, label:'Count', enableHyperLink: false, fontSize: 12, width: '17%'
   },
];

const appApiProviderHeadCells = [
   {
      id: 'appName', numeric: false, disablePadding: true, label: 'App Name - Provider API', enableHyperLink: false, fontSize: 12, width: '32%'
  },
  {
      id: 'count', numeric: false, disablePadding: false, label:'Count', enableHyperLink: false, fontSize: 12, width: '17%'
  },
];

const appApiDrugHeadCells = [
   {
      id: 'appName', numeric: false, disablePadding: true, label: 'App Name - Drug List API', enableHyperLink: false, fontSize: 12, width: '32%'
  },
  {
      id: 'count', numeric: false, disablePadding: false, label:'Count', enableHyperLink: false, fontSize: 12, width: '17%'
  },
];

const memberDataAccessHeadCells = [
    {
        id: 'reportName', numeric: false, disablePadding: true, label: 'Reports', enableHyperLink: false, fontSize: 12, width: '32%'
    },
    {
        id: 'accessDistinct', numeric: false, disablePadding: false, label: 'Unique member access data once', enableHyperLink: true, fontSize: 12, width: '17%'
    },
    {
        id: 'accessMTOnce', numeric: false, disablePadding: false, label: 'Unique member access data more than once', enableHyperLink: true, fontSize: 12, width: '17%'
    },
];
const providerDirectoryHeadCells = [
    {
        id: 'reportName', numeric: false, disablePadding: true, label: 'Reports', enableHyperLink: false, fontSize: 12, width: '32%'
    },
    {
        id: 'practitionerCount', numeric: false, disablePadding: false, label: 'Practitioner', enableHyperLink: false, fontSize: 12, width: '17%'
    },
    {
        id: 'organizationCount', numeric: false, disablePadding: false, label: 'Organization', enableHyperLink: false, fontSize: 12, width: '17%'
    },
	];

const GridView=props=>{
    const {reportType,reportData,toGetTrenGraphChartData,setShowTrendGraph} = props;

    const [gridData,setGridData]=useState([]);
    const [patientgridData,setPatientGridData]=useState([]);
    const [provgridData,setProvGridData]=useState([]);
    const [druggridData,setDrugGridData]=useState([]);
    
    
    useEffect(()=>{
        async function getFormattedData() {
            if(reportType == "AppAPI"){
                 let response = await getStatusCount(reportData.data);
                 setPatientGridData(response.patientAppsInfo);
                 setProvGridData(response.providerAppsInfo);
                 setDrugGridData(response.drugListAppsInfo);
            }else{
                 let response = await getStatusCount(reportData.data);
                 setGridData([response]);
            }
        }
           getFormattedData();  
    },[reportData])


    const getTableData = (val) => {
        if (val && val?.length) {
            Object.keys(val[0]).map(e=>val[0][e] == null || val[0][e] == "" ? val[0][e] ="0" : val[0][e]);
            return val;
        } else {
            return [];
        }
    };

    const getStatusCount=(data)=>{
        let reportNameList={"App":"Third Party Apps Registration","Member":"Member Registration","API":"Third Party API Access","MemberData":"Member API Access","ProviderData": "Provider Directory API"};
        if(reportType === "App"){
              return {...data,reportName:reportNameList[reportType]};
        }else if(reportType == "Member"){
              return {...data,reportName:reportNameList[reportType]};
        }else if(reportType == "MemberData"){
              return {...data,reportName:reportNameList[reportType]};
        }else if(reportType == "API"){
            return {...data,reportName:reportNameList[reportType]};
        }else if(reportType == "AppAPI"){
            return {...data,reportName:reportNameList[reportType]};
        } else if(reportType == "ProviderData"){
            return {...data,reportName:reportNameList[reportType]};
        }else{
            return {}
        }
        
    }

    const editRow =(id)=>(event) => {  
        if(event.target.innerText &&  event.target.innerText != "0" && event.target.innerText != 0){  
            let headCells = reportType== "App" ? appRegistrationHeadCells : reportType== "Member" ? memberRegistrationHeadCells : reportType== "API" ? apiAccessHeadCells : reportType== "MemberData" ? memberDataAccessHeadCells : [];         
            headCells.map(e=>{
                if(e.id == id){
                    toGetTrenGraphChartData(e.label);
                }
            })
        }else{
            setShowTrendGraph(false);
        }
    }

    
    if(reportType != "AppAPI"){
    return (
    <TableComponent headCells={reportType== "App" ? appRegistrationHeadCells : reportType== "Member" ? memberRegistrationHeadCells : reportType== "API" ? apiAccessHeadCells : reportType== "MemberData" ? memberDataAccessHeadCells : reportType== "ProviderData" ? providerDirectoryHeadCells : []} tableData={getTableData(gridData)}  onTableCellClick={editRow} />
    )
    }else{
        return (
            <jsx><TableComponent headCells={reportType == "App" ? appRegistrationHeadCells : reportType == "Member" ? memberRegistrationHeadCells : reportType == "API" ? apiAccessHeadCells : reportType == "MemberData" ? memberDataAccessHeadCells : reportType == "AppAPI" ? appApiAccessHeadCells : []} tableData={getTableData(patientgridData)} onTableCellClick={editRow} />
            <TableComponent headCells={reportType == "App" ? appRegistrationHeadCells : reportType == "Member" ? memberRegistrationHeadCells : reportType == "API" ? apiAccessHeadCells : reportType == "MemberData" ? memberDataAccessHeadCells : reportType == "AppAPI" ? appApiProviderHeadCells : []} tableData={getTableData(provgridData)} onTableCellClick={editRow} />
            <TableComponent headCells={reportType == "App" ? appRegistrationHeadCells : reportType == "Member" ? memberRegistrationHeadCells : reportType == "API" ? apiAccessHeadCells : reportType == "MemberData" ? memberDataAccessHeadCells : reportType == "AppAPI" ? appApiDrugHeadCells : []} tableData={getTableData(druggridData)} onTableCellClick={editRow} />
            </jsx>
        )
        }
}
export default GridView;