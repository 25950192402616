import React from 'react';

export default function TermsOfUseForm(props) {
    return (
        <div className="tabs-container container">
        <div className="contactUs-container pl-0 row">
            <div className="contactUs-subContainer">                   
                <div className="terms-of-use-head p-4">
                    <b className="p-2 dib"> TERMS OF USE </b>
                </div>

                <div className="container-body p-4 mt-2">

                <section className="block-elm mb-3 px-2">
                    <p>
                    Information Submitted to Conduent. Other than personally identifiable health information as provided in the Conduent Online Privacy Statement, any other information sent to Conduent through this Site will be treated as non-confidential. You grant to Conduent an unrestricted, royalty-free, irrevocable license to use, reproduce, display, perform, modify, transmit and distribute the non-confidential information. You agree that Conduent is entitled to use for any purpose whatsoever any ideas, concepts, know-how or techniques that you send, without any obligation of compensation to you or any other person. Your personally identifiable information that you submit will be treated in accordance with the Conduent Online Privacy Statement that accompanies this Site or as more specifically described in another Conduent web site you may access. </p>

                    <p>                        
                    You are prohibited from posting on this Site or transmitting to or from this Site any material that is false or inaccurate, solicitous, advertising, unlawful, threatening, libelous, defamatory, inflammatory, obscene, profane, pornographic, invasive of a person's privacy or could give rise to any civil or criminal liability under the law. You are prohibited from posting or transmitting to this Site any copyrighted material or material that is confidential to a third party. </p>

                    <p>                       
                    Conduent has the right but not the obligation to monitor and review transmissions, bulletin boards, postings and chat rooms on this Site. Conduent assumes no liability with respect to such activities or the content of any such locations. </p>
                </section>


                <section className="block-elm my-3 px-2">                        
                    <p>
                    <b>Third Party Web Sites.</b> References on this Site to any names, marks, products or services of any third parties or links to third party sites are provided solely as a convenience to you and do not constitute or imply an endorsement or sponsorship by Conduent or an affiliation with the third party. You agree that Conduent will not be responsible for any damage or loss incurred in connection with the third party web sites or any products or services available from the third party. </p>

                    <p>                        
                    <b>Use of Site Content.</b> &copy;Copyright 2021 Conduent, Inc. All rights reserved. Conduent and Conduent Agile Star are trademarks of Conduent, Inc. and/or its subsidiaries in the United States and/or other countries. </p>

                    <p>                        
                    All information, text, software, audio, video, photographs, images and other data contained on this Site ("Site Content") are the property of Conduent or licensed to Conduent. Site Content is protected by copyright laws and other proprietary rights laws. You are authorized to access this Site solely for informational, non-commercial purposes to view and browse Site Content. Use of Site Content for any purpose except as provided in these Terms or as stated in the text on the Site is prohibited. </p>

                    <p>                        
                    <b>Disclaimer of Warranties.</b> Conduent is not responsible for any errors or omissions contained on this Site. All Conduent and third party information is provided "AS IS". CONDUENT DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT OF INTELLECTUAL PROPERTY, OR ARISING FROM A COURSE OF DEALING, USAGE, OR PRACTICE. Some jurisdictions do not allow the exclusion of implied warranties, so the above exclusion may not apply to you. </p>

                    <p>                        
                    <b>Limitation of Liability.</b> IN NO EVENT WILL CONDUENT BE LIABLE FOR ANY DAMAGES WHATSOEVER, INCLUDING BUT NOT LIMITED TO ANY DIRECT, SPECIAL, INDIRECT, INCIDENTAL OR CONSEQUENTIAL DAMAGES OR ANY DAMAGES FOR LOST PROFITS, LOST REVENUE OR LOSS OF USE, ARISING OUT OF OR RELATED TO THE USE OR INABILITY TO USE THIS SITE, ITS CONTENT OR LINKS, INCLUDING DAMAGES CAUSED BY OR RELATED TO INTERRUPTIONS, DEFECTS, DELAY IN OPERATION OR TRANSMISSION, OR COMPUTER VIRUS, EVEN IF CONDUENT HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. </p>

                    <p>                        
                    <b>Trademarks.</b> The trademarks, service marks, logos and slogans appearing on this Site ("Marks") are the registered or common law marks of Conduent or designated third parties. No right or license is granted, by implication, estoppel or otherwise, to use the Marks or any other intellectual property rights of Conduent or any third party. </p>
                        
                    <p>
                    <b>Termination.</b> Conduent reserves the right in its sole discretion to terminate your access to or use of this Site for any reason, including a failure by you to comply with these Terms. Conduent may choose to take legal action against you for your failure to comply with these Terms. </p>
                        
                    <p>
                    <b>Revisions.</b> Conduent may revise or withdraw all or any portion of Site Content and discontinue any products or services at any time without notice. By accessing this Site, you agree to be bound by any revisions made by Conduent and you agree that you will visit these Terms regularly to view the then-current Terms. </p>

                    <p>                        
                    <b>Applicable Law.</b> You agree that the laws and statutes of the United States will apply to all matters relating to the use of this Site, and that all litigation in connection with the use of this Site shall be subject to the exclusive jurisdiction of the state or federal courts. </p>
                        
                                            
                </section>

                </div>
             </div>
        </div>
    </div>
    )
}
