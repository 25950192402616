/* eslint-disable no-unused-vars */
import React from 'react';
import classes from './Spinner.scss';

export default function spinner({ load }) {
  return (
    <div className={"hide-on-print" + (load ? " mt-2" : "")}>
      {
        load
          ?
          (<div className="loading-spinner-redirect">
            <div className="loader"></div>
          </div>)
          :
          (<div className="loading-spinner hide-on-print ">
            <div className="loader"></div>
          </div>)
      }</div>
  );
}
