import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";

const LineChartView = (props) => {
  const { reportType, reportData,scrollBottomOfPage,trenGraphTitle } = props;

  const [graphData, setGraphData] = useState([]);
  let reportNameList = {
    App: "Third Party Apps Registration",
    Member: "Member Registration",
    API: "Third Party API Access",
    MemberData: "Member API Access",
  };

  useEffect(() => {
    setTimeout(() => {
      if(document.querySelector('div[aria-label="A tabular representation of the data in the chart."]'))
    document.querySelector('div[aria-label="A tabular representation of the data in the chart."]').remove();
    }, 5000);
    
  },[]);
  
  useEffect(() => {
    async function getFormattedData() {
      let response = await getStatusCount(reportData.data);
      setGraphData(response);
    }
    getFormattedData();
  },[reportData]);

  const getStatusCount = (data) => {
      let tempArr=[];
      data.map(e=>{
        tempArr.push([e.xvalue,Number(e.yvalue)]);
      })
      return tempArr;
    };
  
  return (
    <>
    <div  className="tab-holder">
      <div className="tab-header mb-2">
                    <h2 className="tab-heading float-left">
                      {" "}
                      {`Trend Graph for ${trenGraphTitle}`}{" "}
                    </h2>
                    <div className="clearfix" />
                  </div>
                  </div>
    <div className="row" >
    <div className="col-3"></div>
    <div className="col-6">
      <Chart
        height="400px"
        chartType="LineChart"
        loader={<div>Loading Chart</div>}
        data={[["", ""], ...graphData]}
        options={{
          /* title: reportNameList[reportType], */
          hAxis: { title: "" },
          vAxis: { title: "" ,minValue: 0,format:'0'},
          legend: "none",
          bar: {groupWidth: "40%"},
          trendlines: { 0: {} },
        }}
        rootProps={{ "data-testid": "0" }}
      />
    </div>
    <div className="col-3"></div>
    </div>
    </>
  );
};

export default LineChartView;
