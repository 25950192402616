const ROUTE_HOST_ENV = process.env.REACT_APP_ROUTE_ENV;

/** App Registration **/
export const APP_REGISTRATTION = ROUTE_HOST_ENV + 'app/registerApp';
export const APP_SEARCH = ROUTE_HOST_ENV + 'app/search';
export const APP_SEARCH_VIEW = ROUTE_HOST_ENV + 'app/getDetails';
export const APP_PRIVACY_VIEW = ROUTE_HOST_ENV + 'app/getPrivacyDetails';
export const REACT_APP_DROPDOWN_ENV = ROUTE_HOST_ENV + 'configuration/getApplicationConfiguration/configKeyList';
export const DOWNLOAD_ACTION_LINK = ROUTE_HOST_ENV +'app/attachment/downloadAttachment';
export const APP_STATUS_UPDATE = ROUTE_HOST_ENV +'api/appregistration';

export const APP_CONFIG_DROPDOWN = ROUTE_HOST_ENV + 'esb/appconfig/configKeyList';
export const EVENTS_LOG = ROUTE_HOST_ENV + 'esb/event';
export const SYSTEM_EVENTS_LOG = ROUTE_HOST_ENV + 'esb/event/log';
export const LOGOUT = ROUTE_HOST_ENV + 'esb/member/logout';

/** Registration Endpoints start **/ 
export const VALIDATE_REGISTRATION = ROUTE_HOST_ENV + 'esb/memberregistration/validation';
export const REGISTRATION_OPTIONS = ROUTE_HOST_ENV + 'esb/claims/getValidOptions';
// export const VALIDATE_REGISTRATION_OPTIONS = ROUTE_HOST_ENV + 'esb/memberregistration/emailcode';
export const VALIDATE_REGISTRATION_OPTIONS = ROUTE_HOST_ENV + 'esb/memberrecentvisited/answers/validation';
export const VERIFY_VERIFICATION_CODE = ROUTE_HOST_ENV + 'esb/memberregistration/codeverify';
//export const RESEND_VERIFICATION_CODE = ROUTE_HOST_ENV + 'identity/generateEmailVerificationCodeService';
export const RESEND_VERIFICATION_CODE = ROUTE_HOST_ENV + 'esb/memberregistration/resendcode';
export const RESEND_EMAIL = ROUTE_HOST_ENV + 'esb/memberregistration/resendmail';


/** Registration Endpoints end **/ 

/** Member Profile Endpoints start **/
export const LOGIN_USER = ROUTE_HOST_ENV + 'esb/member/login';
export const FORGOT_USERID = ROUTE_HOST_ENV + 'esb/member/forgotuserid';
export const GET_SECURITY_QS = ROUTE_HOST_ENV + 'esb/member/getSecQuestions';
export const PASSWORD_RECOVERY = ROUTE_HOST_ENV + 'esb/member/forgotPassword';
export const CHANGE_PASSWORD = ROUTE_HOST_ENV + 'esb/member/changePassword';
/** Member Profile Endpoints end **/

/** Claims Endpoints start **/
export const CLAIMS_ROLLING_MONTHS = ROUTE_HOST_ENV + 'esb/appconfig/listofmonths';
export const CLAIMS_MONTHLY_DATA = ROUTE_HOST_ENV + 'esb/claims/getMonthlyData';
export const CREATE_FEEDBACK = ROUTE_HOST_ENV + 'esb/claims/createFeedback';
export const UPDATE_FEEDBACK = ROUTE_HOST_ENV + 'esb/claims/updateFeedback';
/** Claims Endpoints end **/

/** Feedback Report Endpoints start **/
export const FEEDBACK_MONTHS = ROUTE_HOST_ENV + 'esb/claims/feedbackReport/listofmonths';
export const FEEDBACK_REPORT = ROUTE_HOST_ENV + 'esb/claims/feedbackReport';
/** Feedback Report Endpoints end **/

/*** Payer Registration */
export const PAYER_STATUS_UPDATE = ROUTE_HOST_ENV + 'api/esb/updateStatus';
export const PAYER_SEARCH = ROUTE_HOST_ENV + 'payer/search';
export const EXISTING_PAYER_LIST = ROUTE_HOST_ENV + 'payer/existingPayerList';
export const PAYER_SEARCH_VIEW = ROUTE_HOST_ENV + 'payer/getDetailsByPayerId';
export const PAYER_REGISTRATION = ROUTE_HOST_ENV + 'api/esb/payerregistration';

/** Reports */
export const GET_APP_REPORTS_DATA = ROUTE_HOST_ENV + 'app/generateAppRegReport';
export const GET_MEMBER_REPORTS_DATA = ROUTE_HOST_ENV + 'member/generateMemRegReport';
export const GET_MEMBER_ACCESS_REPORTS_DATA = ROUTE_HOST_ENV + 'member/generateMemAccessReport';
export const GET_API_ACCESS_REPORTS_DATA = ROUTE_HOST_ENV + 'app/generateAppAccessReport';
export const GET_APP_API_ACCESS_REPORTS_DATA = ROUTE_HOST_ENV + 'app/generateAppApiAccessReport';

export const GET_MEMBER_TREND_REPORTS_DATA = ROUTE_HOST_ENV + 'member/generateMemTrendGraph';
export const GET_APP_TREND_REPORTS_DATA = ROUTE_HOST_ENV + 'app/generateAppTrendGraph';
export const GET_PROVIDER_REPORTS_DATA = ROUTE_HOST_ENV + 'generateProviderApiReport';

/** Old Payer data exchange */
export const PAYER_TO_PAYER_SEARCH = ROUTE_HOST_ENV + 'payer/p2p/search';
export const MEMBER_SEARCH = ROUTE_HOST_ENV + 'member/searchMember';
export const SAVE_PAYER_CONSENT_DATA_EXCHANGE = ROUTE_HOST_ENV + 'api/esb/p2p/savememberDataExchange';
export const SAVE_NEW_PAYER_DETAILS = ROUTE_HOST_ENV + 'api/esb/p2p/savepayerDataExchange';

/**** For admin */
export const GET_USER_INFO_FOR_ADMIN = ROUTE_HOST_ENV + 'adminUserInfo/getUserInfo';
export const GET_ACCESS_TOKEN_FOR_ADMIN = ROUTE_HOST_ENV + 'adminUserInfo/getAccessToken';
export const INVALIDATE_TOKEN_FOR_ADMIN = ROUTE_HOST_ENV + 'adminUserInfo/invalidateToken';

export const GET_USER_INFO = ROUTE_HOST_ENV + 'userInfo/getUserInfo';
export const GET_ACCESS_TOKEN = ROUTE_HOST_ENV + 'userInfo/getAccessToken';
export const INVALIDATE_TOKEN_FOR_MEMBER = ROUTE_HOST_ENV + 'userInfo/invalidateToken';

export const MEMBER_CONSENT_DETAILS = ROUTE_HOST_ENV + 'esb/member/consentDetails';
export const REVOKE_MEMBER_CONSENT = ROUTE_HOST_ENV + 'esb/member/revokeConsent';

/** For rediret URL payer consent */

export const GET_ACCESS_TOKEN_FOR_P2P = ROUTE_HOST_ENV + 'payer/p2pexchange/token/getAccessToken';

/**Member management by admin */
export const GET_PATIENT_FHIRPROFILE = ROUTE_HOST_ENV + 'fhirProfile/searchPatient';
export const UPDATE_PATIENT_FHIRPROFILE_CONF_CODE = ROUTE_HOST_ENV + 'api/esb/memberConfCodeUpdateByAdmin';

/****For Remote Member Consent */
export const MEMBER_CONSENT_POST = ROUTE_HOST_ENV + 'esb/memberremoteconsent/saveconsent';
export const SAVE_MEMBER_CONSENT_DATA_EXCHANGE = ROUTE_HOST_ENV + 'esb/memberremoteconsent/updateconsent';
export const FETCH_CONSENT = ROUTE_HOST_ENV + 'esb/memberremoteconsent/fetchconsent';

// export const GET_TOKEN = ROUTE_HOST_ENV + 'userinfo/gettoken';
 export const validateMember = ROUTE_HOST_ENV + 'member/validateMemberExists';
 export const validateAdmin = ROUTE_HOST_ENV + 'member/validateAdminExists';

export const memberProfileDetails = ROUTE_HOST_ENV + 'member/memberProfileDetails';