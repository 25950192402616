import React from 'react';
import Card from 'react-bootstrap/Card';
export default function MemberInfo(){
return (
<div className="container">
<Card className="card-background"> <Card.Body>
<blockquote className="blockquote mb-0">
<h1 className="text-header">Member Privacy and Security Resources</h1>
<p> The CMS (Centers for Medicare and Medicaid Services) released the Interoperability and Patient Access final rule on March 9, 2020. This final rule requires most CMS-regulated payers to implement and maintain a secure, standards-based Patient Access API (Application Programming Interface) that allows members to easily access their claims and encounter information through third-party applications of their choice.  This includes access to cost information – specifically, provider remittances and enrollee cost-sharing – as well as a defined sub-set of their clinical information.  These CMS-regulated payers must implement and maintain the API using HL7 (Health Level 7®) FHIR (Fast Healthcare Interoperability Resources®) Release 4.0.1.  The CMS-regulated payers required to follow these CMS final rule requirements specifically include the following:</p>
<ul className="registration-list">
<li>Medicare Advantage organizations,</li>
<li>Medicaid FFS (Fee-For-Service) programs,</li>
<li>CHIP (Children’s Health Insurance Program) FFS programs,</li>
<li>Medicaid managed care plans,</li>
<li>CHIP managed care entities, and </li>
<li>QHP (Qualified Health Plan) issuers on the Federally-Facilitated Exchanges, not including issuers offering only Stand-Alone Dental plans and QHP issuers offering coverage in the Federally-Facilitated Small Business Health Options Program. </li>
</ul>
<p>
This rule also requires these payers to make resources regarding privacy and security available to all members.  Note: In this document, the word “patient” also means SDDSS members. SDDSS means the Department of Health and Human Services.
</p>
<h1 className="text-header"> Factors to consider before authorizing a Third-Party App</h1>
<p>What are the important factors members should consider before authorizing a third-party app to retrieve their health care data?  It is important for members to take an active role in protecting their health information. Members should know what to look for when choosing an app to make more informed decisions.  Members should look for an easy-to-read privacy policy that clearly explains how the app will use their data. If an app does not have a privacy policy, we advise you not to use the app.  Members should consider :</p>
<ul className="registration-list">
<li>What health data will this app collect? Will this app collect non-health data from my device, such as my location?  </li>
<li>Will my data be stored in a de-identified or anonymized form?  </li>
<li>Will this app disclose my data to third parties? Will this app sell my data for any reason, such as advertising or research? Will this app share my data for any reason? If so, with whom? For what purpose?    </li>
<li>How can I limit this app’s use and disclosure of my data?   </li>
<li>What security measures does this app use to protect my data?  </li>
<li>QWhat impact could sharing my data with this app have on others, such as my family members?   </li>
<li>How can I access my data and correct inaccuracies in data retrieved by this app?   </li>
<li>Does this app have a process for collecting and responding to user complaints?  </li>
<li>If I no longer want to use this app, or if I no longer want this app to have access to my health information, how do I terminate the app’s access to my data?  </li>
<li>What is the app’s policy for deleting my data once I terminate access? Do I have to do more than just delete the app from my device?</li>
<li>How does this app inform users of changes that could affect its privacy practices?  If the app’s privacy policy does not clearly answer these questions, members should reconsider using the app to access their health information. Health information is very sensitive information, and members should be careful to choose apps with strong privacy and security standards to protect it.  </li>
</ul>

<h1 className="text-header"> App Registration and Attestation </h1>
<p>What the SDDSS requires every app developer to provide before approving an app. SDDSS requires every app developer to provide details of their apps, including but not limited to:</p>
<ol className="registration-list">
<li>App Name </li>
<li>App Organization </li>
<li>App Type </li>
<li>App Developer Name and address</li>
<li>App Developer phone and email address</li>
<li>App’s privacy policy and terms of services URLs (Uniform Resource Locators)</li>
<li>Attestation to having certain provisions in the app’s privacy policy such as:
<ol type="a">
<li>Does the app have a publicly available privacy policy, written in plain language, that has been affirmatively shared with the member prior to the member authorizing app access to their health information? </li>
<li>Does the app’s privacy policy include important information, such as, at a minimum:
<ul>
<li>How a member’s health information may be accessed, exchanged, or used by any person or other entity including whether the member’s health information may be shared or sold at any time (including in the future) </li>
<li>A requirement for express consent from a member before the member’s health information is accessed, exchanged, or used, including receiving express consent before a member’s health information is shared or sold (other than disclosures required by law or disclosures necessary in connection with the sale of the application or a similar transaction) </li>
<li>If the app will access any other information from a member’s device; or </li>
<li>How a member can discontinue app access to their data, and what the app’s policy and process is for disposing of a member’s data once the member has withdrawn consent?</li>
{/* <li> Does the app follow industry best practices when developing a privacy policy and consult relevant resources, such as: </li> */}
{/* <li>CARIN Alliance Code of Conduct: <a href="https://www.carinalliance.com/code-of-conduct" target='new'>Code of Conduct</a> If the app developer responds negatively to these attestations, the member will have an opportunity to change their mind about sharing their data. But if the member doesn’t want to stop the app’s access to their data, the member’s data will be shared as they originally requested.</li> */}
{/* <li>CARIN Alliance Code of Conduct: <a href="https://www.carinalliance.com/code-of-conduct" target='new'>Code of Conduct</a> </li> */}
{/* If the app developer responds negatively to these attestations, the member will have an opportunity to change their mind about sharing their data. But if the member doesn’t want to stop the app’s access to their data, the member’s data will be shared as they originally requested. */}

</ul>
<li> Does the app follow industry best practices when developing a privacy policy and consult relevant resources, such as: </li>
<ul>
<li>CARIN Alliance Code of Conduct: <a href="https://www.carinalliance.com/code-of-conduct" target='new'>Code of Conduct</a> </li>


</ul>




</li>

</ol>
</li><br/>
If the app developer responds negatively to these attestations, the member will have an opportunity to change their mind about sharing their data. But if the member doesn’t want to stop the app’s access to their data, the member’s data will be shared as they originally requested.


</ol>
<h1 className="text-header"> Authorized Representative </h1>
<p>A health insurance authorized representative is a trusted individual who you select to act on your behalf regarding your health coverage and related matters. This person will then have consent to make decisions on your behalf and ask questions regarding your health insurance. An authorized representative may have access to review your medical information for the purpose of making the right decision for your health coverage, especially when it comes to filling out claims and appeals. To protect member’s data privacy and security, SDDSS will not allow the member to register and create an account if they have a valid authorized representative.  </p>
<h1 className="text-header"> Member Rights (HIPAA) </h1>
<p>The U.S. Department of Health and Human Services OCR (Office for Civil Rights) enforces the HIPAA (Health Insurance Portability and Accountability Act) Privacy, Security, and Breach Notification Rules, and the Patient Safety Act and Rule. You can find more information about member rights under HIPAA and who is obligated to follow : <a href="https://www.hhs.gov/hipaa/for-individuals/guidance-materials-for-consumers/index.html" target='new'>HIPAA here</a> . <a href="https://www.hhs.gov/hipaa/for-individuals/faq/index.html" target='new'>HIPAA FAQs for Individuals</a> </p>
<h1 className="text-header"> Are third-party apps covered by HIPAA? </h1>
<p>Most third-party apps will not be covered by HIPAA. Most third-party apps will instead fall under the jurisdiction of the FTC (Federal Trade Commission) and the protections provided by the FTC Act. The FTC Act, among other things, protects against deceptive acts (for example, if an app shares personal data without permission, despite having a privacy policy that says it will not do so). The FTC provides information about mobile app privacy and security for consumers here:
<a href="https://www.consumer.ftc.gov/articles/0018-understanding-mobile-apps" target='new'>Click here to learn more</a> </p>
<h1 className="text-header"> Member Data Breach </h1>
<p>Member Data Breach How to File a Complaint If you believe your privacy rights have been violated by SDDSS or one of its contractors, you may file a complaint addressed to the Privacy Officer of State of South Dakota Department of Social Services, Division of Medical Services, 700 Governors Drive, Pierre, SD 57501-2291. Your complaint must be in writing. You may also file a complaint with the federal government by contacting the Secretary of the Department of Health and Human Services at 200 Independence Ave., SW, Washington, DC 20201. You will not be penalized or retaliated against for filing a complaint. To learn more about filing a complaint with OCR under HIPAA, visit:<a href="https://www.hhs.gov/hipaa/filing-a-complaint/index.html" target='new'>here</a> </p>
<p> Individuals can file a complaint with the FTC using the FTC complaint assistant:<a href="https://ocrportal.hhs.gov/ocr/smartscreen/main.jsf " target='new'>visit here.</a></p>

</blockquote>
</Card.Body>
</Card>
<hr className="horizontal-rule"></hr>
<br/>

</div>
);
}