import React,{useState,useEffect,useRef } from 'react'
import TextField from '@material-ui/core/TextField';
import Radio from "@material-ui/core/Radio";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ErrorMessages from '../../../SharedModule/MessageHandlers/ErrorMessages';
import SuccessMessage from '../../../SharedModule/MessageHandlers/SuccessMessage';
import * as regConstants from './RegistrationConstants';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
// import Autosuggest from './AutoSuggestions';
import Autosuggest from '../../../SharedModule/AutoSuggestion/AutoSuggestions';
import {getPayerToPayerSearchActions,savePayerConsentExchangeDataActions,memberSearchDetailsActions,saveNewPayerDetailsActions} from '../Store/Actions/RegistrationActions';
import {useDispatch,useSelector} from 'react-redux';
import RegistrationDialog from './RegistrationDialog';
import Spinner from '../../../SharedModule/Spinner/Spinner';
import moment from 'moment';
import { AppConfigDropdownActions } from '../../../SharedModule/Store/Actions/AppActions';
import * as AppConstants from '../../../SharedModule/Utils/AppConstants';

let READY_FOR_MEMBER_CONSENT="Ready for Member Consent";
let PAYER_REGISTRATION_PENDING="Payer Registration Pending";
let PAYER_REGISTRATION_VERIFIED = "Payer Registration Verified";
let CLIENT_REGISTRATION_COMPLETED = "Client Registration Completed";

const OldPayerDataExchangeDetails=(props)=>{
    const dispatch=useDispatch();
    let defaultValues={
        payerName: '',
        coverageId: '',
        startDate: null,
        endDate: null,
        groupName: '',
        planName: '',
        payerReferenceId:'',
        accessToken: "",
        refreshToken: "",
        memberMedicaidId: "",
        memberUniqueId: "",
        memberWorkFlowStatus: READY_FOR_MEMBER_CONSENT,
        savedFlag:false
    }
    const payerDetailsRef=useRef();
    const [values, setValues] = useState([defaultValues]);
    const [termsCheckBox,setTermsCheckBox] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [spinnerLoader, setSpinnerLoader] = useState(false);
    const [oldPayerDetailsCount, setOldPayerDetailsCount] = useState(1);
    const [CollapsePayerDetails, setCollapsePayerDetails] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState();
    const [atleastSaveOneOldPayerCount,setAtleastSaveOneOldPayerCount]=useState(0);
    const [memberData,setMemberData]=useState(null);
    const [newPayerDetail,setNewPayerDetail]=useState(null);

    const registrationValues = useSelector(state=>state.registrationState.registrationValues);


    let errorMessagesArray = [];
    const [{
       planNameError,
       startDateError,
       endDateError,
       payerNameError,
       coverageIdError,
       termsCheckBoxError
    }, setShowError] = React.useState(false);
    const [errorMessages, setErrorMessages] = React.useState([]);
    const [successMessages, setSuccessMessages] = React.useState([]);

    const [{
        planNameErrorText,
        startDateErrorText,
        endDateErrorText,
        payerNameErrorText,
        coverageIdErrorText,
        termsCheckBoxErrorText
    }, setShowErrorText] = React.useState('');

    const getPayerList=()=>dispatch(getPayerToPayerSearchActions());
    const savePayerExchangeData=(data)=>dispatch(savePayerConsentExchangeDataActions(data));
    const saveNewPayerDetailsData=(data)=>dispatch(saveNewPayerDetailsActions(data));
    const memberSearchDetailsData=(data)=>dispatch(memberSearchDetailsActions(data));
    const dropDownDispatch = (configKeyList) => dispatch(AppConfigDropdownActions(configKeyList));

    const payerList=useSelector(state=>state.registrationState.payerToPayerList);
    const PayerConsentData=useSelector(state=>state.registrationState.PayerConsentData);
    const newPayerData=useSelector(state=>state.registrationState.newPayerDetails);
    const memberSearchData=useSelector(state=>state.registrationState.memberSearchDetails);
    const dropdownData = useSelector(state => state.sharedState.appConfigDropdown);

    useEffect(()=>{
        getPayerList();
        const configKeyList = ["NEW_PAYER"];
        dropDownDispatch(configKeyList);
    },[])

    useEffect(() => {
        if (PayerConsentData) {
            setSpinnerLoader(false);
            if (PayerConsentData.success) { 
                setSuccessMessages([PayerConsentData?.message]);
                document.getElementById("root").scrollTop=0;   
                /* payerDetailsRef.current.scrollIntoView({
                    behavior: "smooth"
                }); */
                // setOpen(true);
                props.history.push({
                    pathname: '/success',
                    state: { registrationValue: registrationValues }
                });

            } else {
                setErrorMessages(['Unable to process your request. Please try later.']);
                document.getElementById("root").scrollTop=0; 
                /* payerDetailsRef.current.scrollIntoView({
                    behavior: "smooth"
                }); */
            }
        }
    }, [PayerConsentData]);

    useEffect(()=>{
        if (newPayerData) {
            setSpinnerLoader(false);
            if (newPayerData.success) {   
                setAtleastSaveOneOldPayerCount(1)   
                document.getElementById("root").scrollTop=0;           ;
                /* payerDetailsRef.current.scrollIntoView({
                    behavior: "smooth"
                }); */
                // setSuccessMessages([newPayerData?.message]);
                
                let tempValues=[...values];
                setSuccessMessages([`${tempValues[selectedIndex]?.payerName} details have been saved successfully.`]);
                tempValues[selectedIndex]["payerReferenceId"] = newPayerData?.data?.id;
                tempValues[selectedIndex]["memberWorkFlowStatus"] = PAYER_REGISTRATION_PENDING;
                tempValues[selectedIndex].savedFlag=true;
                setValues([...tempValues]);

            } else {
                // setErrorMessages(['Unable to process your request. Please try later.']);
                setErrorMessages(['Payer details are not registered in the system']);
                document.getElementById("root").scrollTop=0; 
                /* payerDetailsRef.current.scrollIntoView({
                    behavior: "smooth"
                }); */
            }
        }
    },[newPayerData])

    useEffect(() => {
        if (dropdownData) {
            errorMessagesArray = [];
            errorMessagesArray = AppConstants.handleAPIErrors(dropdownData, errorMessagesArray, regConstants.REGISTRATION_TECHNICAL_ERROR);
            if (errorMessagesArray && errorMessagesArray.length > 0) {
                setErrorMessages(errorMessagesArray);
            } else {
                if (dropdownData.NEW_PAYER && dropdownData.NEW_PAYER.configKey === AppConstants.NEW_PAYER) {
                    setNewPayerDetail(dropdownData.NEW_PAYER.configDetail);
                }
            }
        }
    }, [dropdownData]);

    useEffect(()=>{
        if (memberSearchData && registrationValues) {
            setMemberData({...registrationValues,gender:memberSearchData?.entry[0]?.resource?.gender});
        } 
    },[memberSearchData])

    useEffect(()=>{
        if(registrationValues){
            memberSearchDetailsData({lastName:registrationValues?.lastName,memberID:registrationValues?.memberID,memberSSN:registrationValues?.memberSSN,dob:moment(registrationValues?.dob).format('YYYY-MM-DD')});
        }
        
       //  memberSearchDetailsData({lastName:"HyderAli",memberID:"22210719",memberSSN:"123456789",dob:"1989-01-01"});

    },[registrationValues])

    const handleChanges = (name,index) => event => {
        let tempValues=[...values];
        if(name === "startDate" || name === "endDate" || name === "payerName"){
            // setValues({ ...values, [name]: event });
            // setValues([...values, {...values[index],[name]: event }]);
            tempValues[index][name]=event;
        }else{
           //  setValues({ ...values, [name]: event.target.value });
            // setValues([...values, {...values[index],[name]: event.target.value }]);
            if(name == "coverageId"){
                tempValues[index]["memberMedicaidId"]=event.target.value;
            }
            tempValues[index][name]=event.target.value;
        }
        setValues([...tempValues]);
    }

    const setPayerName=(val,index)=>{
        // setValues({ ...values, payerName: val });
        let tempValues=[...values];
        tempValues[index]["payerName"]=val;
        setValues([...tempValues]);
        // setValues([...values, {...values[index],payerName:val }]);
    }
    const setPayerId=(val,index)=>{
        let tempValues=[...values];
        tempValues[index]["payerReferenceId"]=val;
        setValues([...tempValues]);
        // setValues([...values, {...values[index],payerReferenceId:val }]);
    }

    const checkPayerStatus=(val,index)=>{
        let tempValues=[...values];
        if(val === CLIENT_REGISTRATION_COMPLETED ){
            tempValues[index]["memberWorkFlowStatus"] = READY_FOR_MEMBER_CONSENT;
        }else{
            tempValues[index]["memberWorkFlowStatus"] = PAYER_REGISTRATION_PENDING;
        }
        setValues([...tempValues]);
    }

    const collapsePayerDeatilsFun=(index)=>{
        let temp = CollapsePayerDetails;
        if(temp.indexOf(index) > -1){
            temp.splice(temp.indexOf(index),1);
            setCollapsePayerDetails([...temp]);
        }else{
            setCollapsePayerDetails([...CollapsePayerDetails,index]);
        }
        
    }

    const validations=(index)=>{
       let planNameError,
       startDateError,
       endDateError,
       payerNameError,
       coverageIdError,
       planNameErrorText,
       startDateErrorText,
       endDateErrorText,
       payerNameErrorText,
       coverageIdErrorText,
       termsCheckBoxError,
       termsCheckBoxErrorText,
       stdtGTEnddtErr,
       stdtGTEnddtErrText;
        if (values[index].payerName === '' || (values[index].payerName && values[index].payerName.trim() === '')) {
            payerNameError=true;
            payerNameErrorText=regConstants.PAYER_NAME
            errorMessagesArray.push(payerNameErrorText);
        }
        if (values[index].startDate === '' || values[index].startDate === null ) {
            startDateError=true;
            startDateErrorText=regConstants.START_DATE
            errorMessagesArray.push(startDateErrorText);
        }
        if (values[index].endDate === '' || values[index].endDate === null ) {
            endDateError=true;
            endDateErrorText=regConstants.END_DATE
            errorMessagesArray.push(endDateErrorText);
        }
        if (values[index].startDate && values[index].startDate.toString() === 'Invalid Date'){
            startDateError=true;
            startDateErrorText=regConstants.START_DATE_INVALID
            errorMessagesArray.push(startDateErrorText);
        }
        if (values[index].endDate && values[index].endDate.toString() === 'Invalid Date'){
            endDateError=true;
            endDateErrorText=regConstants.END_DATE_INVALID
            errorMessagesArray.push(endDateErrorText);
        }
        if(values[index].startDate &&  values[index].endDate && new Date(values[index].startDate) >= new Date(values[index].endDate) ){
            startDateError=true;
            startDateErrorText=regConstants.STARTDT_GREATER_THAN_ENDDT;
            errorMessagesArray.push(startDateErrorText);
        }

        if (values[index].planName === '' ) {
            planNameError=true;
            planNameErrorText=regConstants.PLAN_NAME
            errorMessagesArray.push(planNameErrorText);
        }
        if (values[index].coverageId === '' ) {
            coverageIdError=true;
            coverageIdErrorText=regConstants.COVERAGE_ID
            errorMessagesArray.push(coverageIdErrorText);
        }
        /* if(!termsCheckBox){
            termsCheckBoxError=true;
            termsCheckBoxErrorText=regConstants.TERMS_CONDITION_REQUIRED
            errorMessagesArray.push(termsCheckBoxErrorText);
        } */
        setErrorMessages(errorMessagesArray);
        setShowError({
            planNameError,
       startDateError,
       endDateError,
       payerNameError,
       coverageIdError,
       termsCheckBoxError,
       stdtGTEnddtErr
        });

        setShowErrorText({
            planNameErrorText,
            startDateErrorText,
            endDateErrorText,
            payerNameErrorText,
            coverageIdErrorText,
            termsCheckBoxErrorText,
            stdtGTEnddtErrText
        });

        if (errorMessagesArray && errorMessagesArray.length === 0) {
            setAtleastSaveOneOldPayerCount(1);
            createNewPayer(index);
            return true;
        } else {
            document.getElementById("root").scrollTop=0; 
            /* payerDetailsRef.current.scrollIntoView({
                behavior: "smooth"
            }); */
            return false;
        }
    }

    // Method to set Terms Check Box
    const handleChangeTermCheckBox = () => {
        setTermsCheckBox(!termsCheckBox);
    };
 
    const ResetValues=(index)=>{
        // setValues([...values,{...values[index],...defaultValues}])
        let tempValues=[...values];
        tempValues[index]=defaultValues;
        tempValues.splice(index,1);
        setOldPayerDetailsCount(oldPayerDetailsCount-1);
        setValues([...tempValues]);
    }


    const addPayerDetails=(index)=>{
        setSelectedIndex(index);
        validations(index);
    }

    const savePayerDetails=(index)=>{
        setSpinnerLoader(true);
            let tempValues=[...values];
            tempValues = tempValues.filter(e=>e.savedFlag === true);
            let obj={
                "addedAuditTimeStamp": "",
                "addedAuditUserID": "",
                "auditTimeStamp": "",
                "auditUserID": "",
                "currentStatus": "",
                "id": "",
                "isUpdateStatus": true,
                "memberReferenceId": registrationValues ? registrationValues?.id : null,
                "payers": [
                  ...tempValues
                ]
              }
            savePayerExchangeData(obj);
    }
 
    const  createNewPayer=(index)=>{
        if(values){
            
                if(!values[index]?.payerReferenceId){
                    let req={
                        "payerName": values[index]?.payerName,
                        // "payerId": "",
                        "payerStatus": "New Payer Registered",
                        "clientTokenDetails": {
                            "payerAuthURL": "",
                            "payerTokenURL": "",
                            "clientId": "",
                            "clientSecret": ""
                        }
                    }
                    saveNewPayerDetailsData(req);
                }else{
                    values[index].savedFlag=true;
                    setSuccessMessages([`${values[index]?.payerName} details have been saved successfully.`]);
                    document.getElementById("root").scrollTop=0; 
                    /* payerDetailsRef.current.scrollIntoView({
                        behavior: "smooth"
                    }); */
                }
        
        }
    }

    const addOldPayerDetails=()=>{
        let tempValues=[...values];
        tempValues.push(defaultValues);
        setValues(tempValues);
        setOldPayerDetailsCount(oldPayerDetailsCount+1);
    }

  return (
    <div className="content-wrapper block-elm" ref={payerDetailsRef}>
        {spinnerLoader ? <Spinner /> : null}
    <div className="page-header" >
        <h1 className="page-heading float-left">
            {/* <i>Member's consent is provided to share the details of the previous payer</i> */}
            <i>Submit request to receive your health data from previous Payers</i>
        </h1>
        <div className="float-right"><a href="/Help2" onClick={()=>{localStorage.setItem("ioxHelpDoc","member")}} target="_blank" className="dib fw-600 py-2 mt-1"> Help </a></div> 
        <div className="clearfix" />
    </div>
    <div className="tab-holder">
    <div className="form-wrapper col-sm-12 px-0">
        {errorMessages && errorMessages.length > 0 ?
            <ErrorMessages errorMessages={errorMessages} /> : null}
        {errorMessages && successMessages && errorMessages.length === 0 && successMessages.length > 0 ?
            <SuccessMessage successMessages={successMessages} /> : null}
    </div>
    <div className="tab-body container-space">
        {/* <!-- Payer Details --> */}
        <div className="tab-header">
            <h2 className="tab-heading float-left">
                Member Demographics  </h2>
            <div className="clearfix" />
        </div>
        <div className="tab-body-bordered mb-3">
            <div className="form-wrapper-iox">
                    <div className="mui-custom-form input-md">
                        <TextField
                            required
                            disabled
                            label="First Name"
                            value={memberData?.firstName}
                            inputProps={{  maxLength: 50 }}
                            InputLabelProps={{
                                shrink: true,
                                for: 'firstName-lbl'
                            }}
                        />
                    </div>
                    <div className="mui-custom-form input-md">
                        <TextField
                            required
                            disabled
                            label="Last Name"
                            value={memberData?.lastName}
                            inputProps={{  maxLength: 50 }}
                            InputLabelProps={{
                                shrink: true,
                                for: 'lastName-lbl'
                            }}
                        />
                    </div>
                    <div className="mui-custom-form input-md">
                        <TextField
                            required
                            disabled
                            label="Date of Birth"
                            value={memberData?.dob ? moment(memberData?.dob).format('YYYY-MM-DD'): null}
                            inputProps={{  maxLength: 10 }}
                            InputLabelProps={{
                                shrink: true,
                                for: 'DOB-lbl'
                            }}
                        />
                    </div>
                    {/* <div className="mui-custom-form input-md">
                        <TextField
                            required
                            disabled
                            label="Gender"
                            value={memberData?.gender}
                            inputProps={{  maxLength: 5 }}
                            InputLabelProps={{
                                shrink: true,
                                for: 'Gender-lbl'
                            }}
                        />
                    </div> */}
            </div>
        </div>
        {/* <!-- ** --> */}
        <div className="tab-body-bordered mb-3">
            <div className="custom-panel">
            <div className="panel-header">
                 Previous Payer Details
            </div>
            <p className="fw-600 py-3 font-md px-3">
                    Please provide the details of the previous health plan. If you would like your data from multiple health plans be sent to us, please use the + button to add additional health plan details. We will not be able to process this request if you provide incomplete details.
        		</p>
            {oldPayerDetailsCount > 0 ?  [...Array(oldPayerDetailsCount).keys()].map(index=><div className="panel-body">
                <div className="tab-header px-3">
                    <h2 className="tab-heading float-left" >
                    {CollapsePayerDetails.indexOf(index) > -1 ? <i class="fas fa-plus-circle cursor_pointer" onClick={()=>collapsePayerDeatilsFun(index)}></i> : <i class="fas fa-minus-circle cursor_pointer" onClick={()=>collapsePayerDeatilsFun(index)}></i>} <i>Please provide the details of your previous Payer {index+1}</i>
                    </h2>
                    <div className="float-right">
                        <button className="btn btn-default ml-3 float-right" title="Delete details" onClick={()=>ResetValues(index)}> Delete </button>
                        <button className="btn btn-success float-right" title="Save Details" onClick={()=>addPayerDetails(index)}> Save </button>
                    </div>
                    <div className="clearfix" />
                </div>
                <div hidden={CollapsePayerDetails.indexOf(index) > -1}>
                <div className="form-wrapper-iox pb-0">
                    <div className="mui-custom-form input-md">
                        {/* <TextField
                            required
                            label="Name of the Payer"
                            inputProps={{  maxLength: 50 }}
                            InputLabelProps={{
                                shrink: true,
                                for: 'PayerName-lbl'
                            }}
                            value={values.payerName}
                            onChange={handleChanges('payerName')}
                            error={payerNameError}
                            helperText={payerNameError ? payerNameErrorText : null}
                        /> */}
                        <Autosuggest inpValue={values[index]?.payerName} change={setPayerName} error={payerNameError && selectedIndex == index}
                            helperText={payerNameError && selectedIndex == index ? payerNameErrorText : null} setPayerId={setPayerId} checkPayerStatus={checkPayerStatus} payerList={payerList} index={index} required={true}/>
                    </div>
                    <div className="mui-custom-form input-md">
                        <TextField
                            required
                            label="Member ID/Coverage ID"
                            inputProps={{  maxLength: 35 }}
                            InputLabelProps={{
                                shrink: true,
                                for: 'CoverageID-lbl'
                            }}
                            value={values[index].coverageId}
                            onChange={handleChanges('coverageId',index)}
                            error={coverageIdError && selectedIndex == index}
                            helperText={coverageIdError && selectedIndex == index ? coverageIdErrorText : null}
                        />
                    </div>
                </div> 
    
                <div className="tab-header px-3 pb-0">
                    <h2 className="tab-heading float-left p-0">
                    <span className="dib iox-required mr-1">*</span>
                     Period   </h2>
                    <div className="clearfix" />
                </div>
    
                <div className="form-wrapper-iox pb-0">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div className="custom-date-picker mui-custom-form ">
                            <KeyboardDatePicker
                                fullWidth
                                required
                                id="date-picker-inline"
                                name='date'
                                maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
                                label="Start Date"
                                value={values[index].startDate}
                                onChange={handleChanges('startDate',index)}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                placeholder="MM/DD/YYYY"
                                format="MM/dd/yyyy"
                                KeyboardButtonProps={{
                                    'aria-label': 'change date'
                                }}
                                error={startDateError && selectedIndex == index }
                                helperText={startDateError && selectedIndex == index ? startDateErrorText : null}
                            />
                        </div>
                    </MuiPickersUtilsProvider>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div className="custom-date-picker mui-custom-form ">
                            <KeyboardDatePicker
                                fullWidth
                                required
                                id="date-picker-inline"
                                name='date'
                                minDate={values[index].startDate}
                                maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
                                label="End Date"
                                value={values[index].endDate}
                                onChange={handleChanges('endDate',index)}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                placeholder="MM/DD/YYYY"
                                format="MM/dd/yyyy"
                                KeyboardButtonProps={{
                                    'aria-label': 'change date'
                                }}
                                error={endDateError && selectedIndex == index}
                                helperText={endDateError && selectedIndex == index ? endDateErrorText : null}
                            />
                        </div>
                    </MuiPickersUtilsProvider>
                </div> 
                <div className="form-wrapper-iox">
                    <div className="mui-custom-form input-md">
                        <TextField
                            label="Group Name"
                            inputProps={{  maxLength: 35 }}
                            InputLabelProps={{
                                shrink: true,
                                for: 'GroupName-lbl'
                            }}
                            value={values[index].groupName}
                            onChange={handleChanges('groupName',index)}
                        />
                    </div>
                    <div className="mui-custom-form input-md">
                        <TextField
                            required
                            label="Plan Name"
                            inputProps={{  maxLength: 35 }}
                            InputLabelProps={{
                                shrink: true,
                                for: 'PlanName-lbl'
                            }}
                            value={values[index].planName}
                            onChange={handleChanges('planName',index)}
                            error={planNameError && selectedIndex == index}
                            helperText={planNameError && selectedIndex == index ? planNameErrorText : null}
                        />
                    </div>
                </div>  
                </div>
            </div>):null}
            <div className="panel-body">
            <div className="tab-header px-3">
                    <h2 className="tab-heading float-left" >
                    <i class="fas fa-plus-circle cursor_pointer" onClick={addOldPayerDetails}></i> <i>Please provide the details of your previous Payer {oldPayerDetailsCount+1}</i>
                    </h2>
                </div>
                <div className="clearfix" />
            </div>
            </div>
    
        </div>
        <div className="block-elm py-2">
        <div className="sub-radio float-left mt-0">
                    <FormControlLabel for="termsConditionscheckbox"
                        className="sub-radio hide-spn"
                        control={
                            <Checkbox
                                id="termsConditionscheckbox"
                                color="primary"
                                 error={termsCheckBoxError}
                                onChange={handleChangeTermCheckBox}
                                checked={termsCheckBox}
                                value={termsCheckBox} 
                            />
                        }
                        label="TandC"

                    />
                </div>
            <span class="fw-600 py-3 font-md px-2">I attest that the above details are correct and I want {newPayerDetail && newPayerDetail[0].value} to receive my health data from my previous health plans.</span>
            {termsCheckBoxError ? <p className="attest-error mx-3">{termsCheckBoxError ? termsCheckBoxErrorText : null}</p> : null}
        </div>
    
        <div className="tab-footer text-center my-3">
            <button className="btn btn-default" title="Previous" onClick={()=>props.history.push("/oldpayerDataExchangeConsent")}> Previous </button>
            <button className="btn btn-primary ml-3" disabled={!(atleastSaveOneOldPayerCount > 0 && termsCheckBox)} title="Next" onClick={savePayerDetails}> Next </button>
        </div>
    </div>
    </div>
    {open ? <RegistrationDialog open={open} setOpen={setOpen} history={props.history}
                      registrationValue={registrationValues} id={registrationValues?.id} /> : null}
    </div>
  )
}

export default OldPayerDataExchangeDetails;