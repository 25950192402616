/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import { TextField, Radio } from '@material-ui/core';
import moment from 'moment';
import numeral from 'numeral';
import Header from '../../../SharedModule/Layout/header';

export default function ClaimsPDF(props) {
    // Page Constants
    const { claimsPDFdata, feedbackQsList, userName, selectedMenu } = props;
    const [startDate, setStartDate] = React.useState('');
    const [endDate, setEndDate] = React.useState('');
    const [feedBackData, setFeedbackData] = React.useState([]);

    useEffect(() => {
        setDateSpan();
        createFeedbackData();
    }, []);

    //Method to set Date Span
    const setDateSpan = () => {
        var key = selectedMenu.split(" ");
        var year = key[1];
        var month = moment().month(key[0]).format("M");
        var sDate = moment([year, month - 1]);
        var eDate = moment(sDate).endOf('month');

        setStartDate(moment(sDate).format('L'));
        setEndDate(moment(eDate).format('L'));
    }

    // Method to dynamically create Feedback Section
    const createFeedbackData = () => {
        let feedbackObj = [];
        if (claimsPDFdata && claimsPDFdata.claims && claimsPDFdata.claims.length > 0) {
            claimsPDFdata.claims.map((data, index) => {
                var feedbackQsId = 'feedbackQsId';
                var feedbackQs = 'feedbackQs';
                var feedbackText = 'feedbackText';
                var feedbackError = 'feedbackError';
                var feedbackErrorText = 'feedbackErrorText';

                let obj = {};
                let errorObj = {};
                let errorTextObj = {};
                if (data.claimsFeedbackDetails && data.claimsFeedbackDetails.length > 0) {
                    data.claimsFeedbackDetails.map((claims, x) => {
                        obj = { ...obj, [feedbackQsId + x]: '', [feedbackQs + x]: claims.responseOption, [feedbackText + x]: claims.responseText };
                        errorObj = { ...errorObj, [feedbackError + x]: false };
                        errorTextObj = { ...errorTextObj, [feedbackErrorText + x]: '' };
                        // obj[`feedbackQsId${x}`] = feedbackQsList[x].key;
                    })
                } else {
                    for (var x = 0; x < feedbackQsList.length; x++) {
                        obj = { ...obj, [feedbackQsId + x]: '', [feedbackQs + x]: 'Y', [feedbackText + x]: '' };
                        errorObj = { ...errorObj, [feedbackError + x]: false };
                        errorTextObj = { ...errorTextObj, [feedbackErrorText + x]: '' };
                        obj[`feedbackQsId${x}`] = feedbackQsList[x].key;
                    }
                }
                let feedBackObj = {
                    [data.tcn]: obj
                }
                feedbackObj.push(feedBackObj);
            })
        }
        setFeedbackData(feedbackObj);
    }

    return (
        <>
            {/* <table>
            <thead><tr><td>
            </td></tr></thead>
            <tbody><tr><td> */}
            <div className="header-space pb-2"><Header print /></div>
            <div className='pdf-container'>
                <div className='pdf-header container-fluid'>
                    <div className='text-center font-weight-bold'>Department of Health and Social Services</div>
                    <div className='text-center'>EEOMB On Demand PDF Report</div>
                    <div className='text-center'><span className='font-weight-bold'>Date Span: </span>{startDate} - {endDate} </div>
                    <div className='text-center'><span className='font-weight-bold'>Member Name: </span>{userName}</div>
                    <div className='text-center'><span className='font-weight-bold'>Member ID: </span>{claimsPDFdata.claims[0].memberId}</div>
                </div>
                <div className='pdf-body'>
                    {claimsPDFdata && claimsPDFdata.claims && claimsPDFdata.claims.map((row, index) => (
                        <div>
                            <div className='pdf-section'>
                                <div className='pdf-section-head'>
                                    <p className='text-center'>Claim Details for
                                        {row.claimIdCode ? (row.claimIdCode === '60' ? ' Professional' : (row.claimIdCode === '61' ? ' Institutional' : (row.claimIdCode === '62' ? ' Pharmacy' : (row.claimIdCode === '63' ? ' Dental' : '')))) : ''}
                                    </p>
                                </div>
                                <div className='pdf-section-body px-4'>
                                    <TableContainer className="mb-4" component={Paper}>
                                        <Table aria-label="collapsible table" className="table my-0">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell><span className='font-weight-bold'>TCN</span></TableCell>
                                                    <TableCell><span className='font-weight-bold' style={{ width: '22%' }}>Date of Service</span></TableCell>
                                                    <TableCell><span className='font-weight-bold'>Billing Provider</span></TableCell>
                                                    {row.renderingProvider ? <TableCell hidden={row.isHeaderPriced === 'N'}><span className='font-weight-bold'>Rendering Provider</span></TableCell> : null}
                                                    <TableCell hidden={row.isHeaderPriced === 'N'}><span className='font-weight-bold'>Description of Service</span></TableCell>
                                                    {(row.surgicalProcedureDesc && row.surgicalProcedureDesc.length > 0) ? <TableCell hidden={row.isHeaderPriced === 'N'}><span className='font-weight-bold'>Surgical Procedure</span></TableCell> : null}
                                                    <TableCell><span className='font-weight-bold'>Primary Diagnosis</span></TableCell>
                                                    <TableCell><span className='font-weight-bold'>Amount Paid</span></TableCell>
                                                    <TableCell hidden={row.isHeaderPriced === 'N'}><span className='font-weight-bold'>Copay</span></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>{row.tcn}</TableCell>
                                                    <TableCell style={{ width: '22%' }}>{`${moment(row.fdos).format('DD MMM YYYY')} - ${moment(row.ldos).format('DD MMM YYYY')}`}</TableCell>
                                                    <TableCell>{row.billingProvider}</TableCell>
                                                    {row.renderingProvider ? <TableCell hidden={row.isHeaderPriced === 'N'}>{row.renderingProvider}</TableCell> : null}
                                                    <TableCell hidden={row.isHeaderPriced === 'N'}>{row.descOfService}</TableCell>
                                                    {(row.surgicalProcedureDesc && row.surgicalProcedureDesc.length > 0) ? <TableCell hidden={row.isHeaderPriced === 'N'}><div>{row.surgicalProcedureDesc.slice(0, 5).map((spRow, index) => (<p className="p-0">{spRow}</p>))}</div></TableCell> : null}
                                                    <TableCell>{row.diagnosisDesc[0]}</TableCell>
                                                    <TableCell>{row.totalPaidAmount ? `$${numeral(Number(row.totalPaidAmount.toFixed(2))).format('0,0.00')}` : '$0.00'}</TableCell>
                                                    <TableCell hidden={row.isHeaderPriced === 'N'}>{row.totalCoPayAmount ? `$${numeral(Number(row.totalCoPayAmount.toFixed(2))).format('0,0.00')}` : '$0.00'}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>
                            {row.isHeaderPriced === 'N' && row.lineItems && row.lineItems.length > 0 ?
                                <div className='pdf-section'>
                                    <div className='pdf-section-head'>
                                        <p className='text-center'>Claim Line Details for
                                        {row.claimIdCode ? (row.claimIdCode === '60' ? ' Professional' : (row.claimIdCode === '61' ? ' Institutional' : (row.claimIdCode === '62' ? ' Pharmacy' : (row.claimIdCode === '63' ? ' Dental' : '')))) : ''}
                                        </p>
                                    </div>
                                    <div className='pdf-section-body px-4'>
                                        <TableContainer className="mb-4" component={Paper}>
                                            <Table aria-label="collapsible table" className="table my-0">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell><span className='font-weight-bold' style={{ width: '22%' }}>Date of Service</span></TableCell>
                                                        <TableCell><span className='font-weight-bold'>Rendering Provider</span></TableCell>
                                                        <TableCell><span className='font-weight-bold'>Description of Service</span></TableCell>
                                                        <TableCell><span className='font-weight-bold'>Amount Paid</span></TableCell>
                                                        <TableCell><span className='font-weight-bold'>Copay</span></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {row.lineItems[0].lineDescOfService && row.claimIdCode === '62' ?
                                                        <TableRow>
                                                            <TableCell style={{ width: '22%' }}>{`${moment(row.lineItems[0].lineFdos).format('DD MMM YYYY')} - ${moment(row.lineItems[0].lineLdos).format('DD MMM YYYY')}`}</TableCell>
                                                            <TableCell>{row.lineItems[0].lineRenderingProvider ? row.lineItems[0].lineRenderingProvider : ''}</TableCell>
                                                            <TableCell>{row.lineItems[0].lineDescOfService ? row.lineItems[0].lineDescOfService : ''}</TableCell>
                                                            <TableCell>{row.lineItems[0].linePaidAmount ? `$${numeral(Number(row.lineItems[0].linePaidAmount.toFixed(2))).format('0,0.00')}` : (row.lineItems[0].linebaseAmountSourceCode === 'ZP' ? 'Included in the above price' : '$0.00')}</TableCell>
                                                            <TableCell>{row.lineItems[0].lineCoPayAmount ? `$${numeral(Number(row.lineItems[0].lineCoPayAmount.toFixed(2))).format('0,0.00')}` : '$0.00'}</TableCell>
                                                        </TableRow> :
                                                        row.lineItems.map((lineItem) => (
                                                            <TableRow>
                                                                <TableCell style={{ width: '22%' }}>{`${moment(lineItem.lineFdos).format('DD MMM YYYY')} - ${moment(lineItem.lineLdos).format('DD MMM YYYY')}`}</TableCell>
                                                                <TableCell>{lineItem.lineRenderingProvider ? lineItem.lineRenderingProvider : ''}</TableCell>
                                                                <TableCell>{lineItem.lineDescOfService ? lineItem.lineDescOfService : ''}</TableCell>
                                                                <TableCell>{lineItem.linePaidAmount ? `$${numeral(Number(lineItem.linePaidAmount.toFixed(2))).format('0,0.00')}` : (lineItem.linebaseAmountSourceCode === 'ZP' ? 'Included in the above price' : '$0.00')}</TableCell>
                                                                <TableCell>{lineItem.lineCoPayAmount ? `$${numeral(Number(lineItem.lineCoPayAmount.toFixed(2))).format('0,0.00')}` : '$0.00'}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div> : null}
                            <div className='pdf-section mt-1'>
                                <div className='pdf-section-head'>
                                    <p className='text-center'>Claim Feedback Response</p>
                                </div>
                                {feedBackData && feedBackData.length > 0 && feedBackData.map((feedback, i) => (
                                    Object.keys(feedback)[0] === row.tcn && feedbackQsList && feedbackQsList.map((data, index) => (
                                        <div className='pdf-section-body px-4'>
                                            <div className="col px-0 pt-3">
                                                <div className="col form-group col-sm-12 mb-2 px-0">
                                                    <div className="form-wrapper col-sm-12 px-0">
                                                    </div>
                                                    <p className="p-0 my-0 font-weight-bold">{data.value}</p>
                                                    <div className="row ml-0 mt-1">
                                                        <div className="col px-0 py-2">
                                                            <Radio
                                                                value="Y"
                                                                id={`check-yes${index}`}
                                                                defaultChecked
                                                                checked={feedback[row.tcn] && feedback[row.tcn]['feedbackQs' + index] === 'Y'}
                                                            />
                                                            <label className="ml-2" htmlFor={`check-yes${index}`}>
                                                                Yes
                                            </label>
                                                        </div>
                                                        <div className="col px-0 py-2">
                                                            <Radio
                                                                value="N"
                                                                id={`check-no${index}`}
                                                                checked={feedback[row.tcn] && feedback[row.tcn]['feedbackQs' + index] === 'N'}
                                                            />
                                                            <label className="ml-2" htmlFor={`check-no${index}`}>
                                                                No
                                            </label>
                                                        </div>
                                                        <div className="col px-0 py-2">
                                                            <Radio
                                                                value="O"
                                                                id={`check-others${index}`}
                                                                checked={feedback[row.tcn] && feedback[row.tcn]['feedbackQs' + index] === 'O'}
                                                            />
                                                            <label className="ml-2" htmlFor={`check-others${index}`}>
                                                                Others
                                            </label>
                                                        </div>
                                                        <div className="mui-custom-form col other-textbox">
                                                            <label htmlFor={`text-others${index}`} hidden>Others</label>
                                                            <TextField
                                                                id={`text-others${index}`}
                                                                required
                                                                fullWidth
                                                                disabled={feedback[row.tcn] && feedback[row.tcn]['feedbackQs' + index] !== 'O'}
                                                                value={feedback[row.tcn] && feedback[row.tcn]['feedbackText' + index]}
                                                                inputProps={{ maxLength: 200 }}
                                                                InputLabelProps={{
                                                                    shrink: true
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ))}
                            </div>
                            <hr className='my-5' />
                        </div>
                    ))}
                </div>
            </div>
            <div className='container-fluid p-0'>
                <div className="px-0 pt-3 row mx-0">
                    <div className="col-6 px-0 small-size-font font-weight-bold">
                        Run Date: {moment().format('L')}
                    </div>
                    <div className="col-6 px-0 text-right small-size-font font-weight-bold">
                        Run Time: {moment().format("HH:mm:ss")}
                    </div>
                </div>
            </div>
            {/* </td></tr></tbody>
            <tfoot><tr><td>
                
            </td></tr></tfoot>
        </table> */}
            {/* <div className="header"><Header print/></div>
        <div className="footer">
            <span style={{marginLeft: '1.25rem'}}>Run Date: {moment().format('L')}</span>
             <span style={{marginLeft: '19.5rem'}} id="page-counter">Page: </span> 
            <span style={{marginLeft: '42rem'}}>Run Time: {moment().format("HH:mm:ss")}</span>
        </div> */}

        </>

    )
}