export const PAYER_ID_REQUIRED="Payer ID is required";
export const PAYER_NAME_REQUIRED="Payer Name is required";
export const EMAIL_ID_REQUIRED="Email ID is required";
export const CONTACT_NUMBER_REQUIRED="Phone Number is required";
export const ADDRESS_REQUIRED="At least one Address Line is required";
export const CITY_REQUIRED="City is required";
export const STATE_REQUIRED="State is required";
export const POSTAL_REQUIRED="Postal Code is required";
export const PRIVACY_URL_REQUIRED="Privacy Policy URL is required";
export const TERMS_URL_REQUIRED="Terms of Service URL is required";
export const REDIRECT_URL_REQUIRED="Redirect URL is required";
export const CONDITION_REQUIRED="Terms & Conditions,Privacy Policy is required";
export const EMAIL_INVALID = 'Email Address must be in xxx@yyy.zzz format and only allowed characters are (A-Z)(a-z)(0-9)(@._-).';
export const ZIP_INVALID = 'Zip is invalid.';
export const PHONE_INVALID = 'Phone Number is invalid.';
export const TERMS_CONDITION_REQUIRED='Terms & Conditions,Privacy Policy is required';
export const PHONE_NUMBER_FORMAT = 'Phone Number must be 10 digits.';
export const REJECT_NOTE_REQUIRED = 'Reject reason is required.';
export const REVOKE_NOTE_REQUIRED = 'Revoke reason is required.';
export const APPROVED_SUCCESS = 'Status of the application is updated to Approved. Approved By & Approved Date is captured.';
export const REJECTED_SUCCESS = 'Status of the application is updated to Rejected. Rejected By, Rejected Date & Rejection Reason is captured.';
export const REVOKED_SUCCESS = 'Status of the application is updated to Revoked. Revoked By, Revoked Date’ & Revocation Reason is captured.';
export const REGISTRATION_TECHNICAL_ERROR = 'Due to technical problems, the system was unable to complete the request. Please contact your Service Representative at 1-844-ONE-CNDT.';