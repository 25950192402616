import * as serviceEndPoint from '../../../../SharedModule/Service/service';
import axios from 'axios';
import * as actionTypes from './PayerRegistraionActionType';

const dispatchRegisterPayerData=(data)=>{
    return {
        type:actionTypes.PAYER_REGISTRATION,
        payload:data
    }
}
export const registerPayer=(data)=>{
  return dispatch=>{
      axios.post(serviceEndPoint.PAYER_REGISTRATION ,data).then(res=>{
        dispatch(dispatchRegisterPayerData(res.data))
      }).catch(error=>{
        dispatch(dispatchRegisterPayerData(error?.data))
      })
  }
}

export const dispatchPayerStatusUpdateAction = (response) => ({
  type: actionTypes.PAYER_STATUS_UPDATE,
  payerDetailsData: response
});

export const payerStatusUpdateAction = values => dispatch => {   
  return axios.post(serviceEndPoint.PAYER_STATUS_UPDATE,values)
      .then(response => {
          dispatch(dispatchPayerStatusUpdateAction(response.data));
      })
      .catch(error => {
          dispatch(dispatchPayerStatusUpdateAction(error.response.data));
      })    
}

export const payerSearchDispatch = (payload) => {
  return ({
    type: actionTypes.PAYER_SEARCH,
    payload: payload
  });
};



export const payerSearch = (value) => {  
  return dispatch => {
    return axios.post(`${serviceEndPoint.PAYER_SEARCH + '?status='+value}`)
      .then(response => {
        dispatch(payerSearchDispatch(response.data));
      })
      .catch(error => {
        dispatch(payerSearchDispatch({ systemFailure: true }));
      });
  };
};

export const payerExistingSearchDispatch = (payload) => {
  return ({
    type: actionTypes.EXISTING_PAYER_LIST,
    payload: payload
  });
};



export const payerExistingSearch = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.EXISTING_PAYER_LIST}`)
      .then(response => {
        dispatch(payerExistingSearchDispatch(response.data));
      })
      .catch(error => {
        dispatch(payerExistingSearchDispatch({ systemFailure: true }));
      });
  };
};

export const unapprovedPayerDispatch = (payload) => {
  return ({
    type: actionTypes.UNAPPROVED_PAYERS,
    payload: payload
  });
};



export const getUnapprovedPayers = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.PAYER_SEARCH + '?status='+value}`)
      .then(response => {
        dispatch(unapprovedPayerDispatch(response.data));
      })
      .catch(error => {
        dispatch(unapprovedPayerDispatch({ systemFailure: true }));
      });
  };
};

export const dispatchPayerDetailsAction = (response) => ({
  type: actionTypes.PAYER_SEARCH_VIEW,
  appSearchData: response
});

export const payerDetailsAction = values => dispatch => {   
  return axios.get(serviceEndPoint.PAYER_SEARCH_VIEW + '?id='+values)
      .then(response => {
          dispatch(dispatchPayerDetailsAction(response.data));
      })
      .catch(error => {
          dispatch(dispatchPayerDetailsAction(error.response.data));
      })    
}

export const resetAppStatus = () => ({
  type: actionTypes.RESET_PAYER_STATUS,
  appDetailsData: null
});