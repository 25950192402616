import * as actionTypes from './ClaimsActionTypes';
import * as serviceEndPoint from '../../../../SharedModule/Service/service';
import axios from 'axios';

export const GetClaimsRollingMonthsActions = () => {
  return dispatch => {
    return axios.get(`${serviceEndPoint.CLAIMS_ROLLING_MONTHS}?listOfMonths=CLAIM_ROLLING_MONTHS`)
      .then(response => {
        dispatch(GetClaimsRollingMonths(response.data));
      })
      .catch(error => {
        dispatch(GetClaimsRollingMonths({ systemFailure: true }));
      });
  };
};
export const GetClaimsRollingMonths = (claimsRollingMonths) => {
  return ({
    type: actionTypes.CLAIMS_ROLLING_MONTHS,
    claimsRollingMonths: claimsRollingMonths
  });
};

export const GetClaimsMonthlyDataActions = (value) => {
  const token = sessionStorage.getItem('authToken');
  return dispatch => {
    return axios.post(`${serviceEndPoint.CLAIMS_MONTHLY_DATA}`, value, {
      headers: {
        'Authorization': `bearer ${token}`
      },
    })
      .then(response => {
        dispatch(GetClaimsMonthlyData(response.data));
      })
      .catch(error => {
        dispatch(GetClaimsMonthlyData({ systemFailure: true }));
      });
  };
};
export const GetClaimsMonthlyData = (claimsMonthlyData) => {
  return ({
    type: actionTypes.CLAIMS_MONTHLY_DATA,
    claimsMonthlyData: claimsMonthlyData
  });
};

export const CreateFeedbackActions = (value) => {
  const token = sessionStorage.getItem('authToken');
  return dispatch => {
    return axios.post(`${serviceEndPoint.CREATE_FEEDBACK}`, value, {
      headers: {
        'Authorization': `bearer ${token}`
      },
    })
      .then(response => {
        dispatch(CreateFeedback(response.data));
      })
      .catch(error => {
        dispatch(CreateFeedback({ systemFailure: true }));
      });
  };
};
export const CreateFeedback = (createFeedback) => {
  return ({
    type: actionTypes.CREATE_FEEDBACK,
    createFeedback: createFeedback
  });
};

export const UpdateFeedbackActions = (value) => {
  const token = sessionStorage.getItem('authToken');
  return dispatch => {
    return axios.post(`${serviceEndPoint.UPDATE_FEEDBACK}`, value, {
      headers: {
        'Authorization': `bearer ${token}`
      },
    })
      .then(response => {
        dispatch(UpdateFeedback(response.data));
      })
      .catch(error => {
        dispatch(UpdateFeedback({ systemFailure: true }));
      });
  };
};
export const UpdateFeedback = (updateFeedback) => {
  return ({
    type: actionTypes.UPDATE_FEEDBACK,
    updateFeedback: updateFeedback
  });
};

export const resetClaimsData = (key) => {
  return {
    type: actionTypes.RESET_CLAIMS_DATA,
    key: key
  };
};