import React from "react";

export default function AccessibilityCompliance(props) {
  return (
    <div className="tabs-container container">
      <div className="contactUs-container pl-0 row">
        <div className="contactUs-subContainer">

          <div className="terms-of-use-head p-4">
            <b className="p-2 dib"> ACCESSIBILITY COMPLIANCE </b>
          </div>
          <div className="container-body p-4 mt-2">
            <section className="block-elm px-2 mb-3">
              <p>
                Thank you for visiting the Interoperability Exchange product of the Conduent, Inc. Conduent is dedicated to providing users with various types of information from the South Dakota Department of Social Services. It is our intent that all users receive easy and efficient access to our web site. This site complies with Section 508 Guidelines outlined by the Federal government. Section 508 compliance is completely satisfied by WCAG (2.0) Priorities 1 and 2. This site has a conformance level of "AA" meaning that all Priority 1 and 2 checkpoints are satisfied.
              </p>
            </section>
            <div className="block-elm px-2 mb-3">
              <ul className="p-0 ml-3">
              <li className="pb-3">
              If you are unable to view PDFs, <a href="https://get.adobe.com/reader/" target="_blank">please download Adobe Reader</a>
              </li>
              </ul>
              <p className="mt-3"> If you have difficulty accessing the information on our site, please contact the South Dakota Interoperability Exchange Help Desk at 1-877-350-2171 from Monday to Friday, 8 am - 6 pm Central Time on business days. </p>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}
