import { VIEW_CONSENT } from "../Actions/ConsentsManagementActionTypes";

const initialState = {
    consents: [],
};

export default function consentsManagementReducer(state = initialState, action) {
    switch (action.type) {
        case VIEW_CONSENT: {
            return { ...state, consents: action.payload };
        }
        default:
            return state;
    };
}