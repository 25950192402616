import React, { useEffect, useState } from 'react';
import axios from 'axios';
import * as serviceEndPoint from '../Service/service';
import Dialog from '@material-ui/core/Dialog';
import { Button } from 'react-bootstrap';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

    const IdleSessionLogout = () => {
        const [showPopup, setshowPopup] = useState(false);
        const [signoutTime, setSignoutTime] = useState(1000 * 60 * process.env.REACT_APP_SIGNOUT_TIME);
        const [warningTime, setWarningTime] = useState(1000 * 60 * process.env.REACT_APP_WARNING_TIME);
        let warnTimeout;
        let logoutTimeout;

        const warn = () => {
            console.log('Warning');
            setshowPopup(true);
            document.title = 'Session About To Expire!';
        };
       
        const logout = () => {
            const isAdmin = localStorage.getItem('isAdmin') === 'true'
            const userIdentifier = sessionStorage.getItem('userIdentifier');
            let logoutUrl;
             sessionStorage.clear();
             localStorage.clear();
             if (isAdmin) {
                logoutUrl = `${process.env.REACT_APP_ADMIN_OAUTH_LOGOUT_URI}&logout_hint=${userIdentifier}`;
           } else {
            logoutUrl = process.env.REACT_APP_MEMBER_OAUTH_LOGOUT_URI;
           }
           window.open(logoutUrl, "_self");
          };

        const redirectToLogin = (logoutUrl, redirectUrl) => {
            sessionStorage.clear();
            localStorage.clear();
            const iframe = document.createElement("iframe");
            //iframe.style.display = "none";
            iframe.src = logoutUrl;
            document.body.appendChild(iframe);
            setTimeout(() => {
                document.body.removeChild(iframe);
                window.open(redirectUrl, "_self");
            }, 2000);
            //window.open(logoutUrl, "_self");
        }

        const destroy = () => {
            console.log('Session destroyed');
            document.title = 'CMdS Interoperability Portal';
            sessionStorage.clear();
            localStorage.clear();
            let isAdmin = localStorage.getItem('isAdmin') === 'true';
            window.open(process.env.REACT_APP_ADMIN_OAUTH_LOGOUT_URI, "_self");
        }
        const setTimeouts = () => {
            warnTimeout = setTimeout(warn, warningTime);
            logoutTimeout = setTimeout(logout, signoutTime);
        };

        const clearTimeouts = () => {
            if (warnTimeout) clearTimeout(warnTimeout);
            if (logoutTimeout) clearTimeout(logoutTimeout);
        };

        const extendSession= () => {
            setshowPopup(false);
            if (warnTimeout) clearTimeout(warnTimeout);
            if (logoutTimeout) clearTimeout(logoutTimeout);
            document.title = 'CMdS Interoperability Portal';
            localStorage.setItem('sessionContinue', Date.now().toString());
        };

        useEffect(() => {

            const events = [
                'load',
                'mousemove',
                'mousedown',
                'click',
                'scroll',
                'keypress'
            ];

            const resetTimeout = () => {
                clearTimeouts();
                setTimeouts();
            };

            for (let i in events) {
                window.addEventListener(events[i], resetTimeout);
            }

            setTimeouts();
            window.addEventListener('storage', (event) => {
                if (event.key === 'sessionContinue') {
                  window.location.reload();
                }
              });
            return () => {
                for(let i in events){
                    window.removeEventListener(events[i], resetTimeout);
                    clearTimeouts();
                }
            }
        },[]);


        return (
        <div>
            <Dialog className='alert-dialog' open={showPopup}>
            <div className="row">
                <ErrorOutlineIcon className="alert-font" />
                <p className="col-md-11 col-sm-11 col-10 px-1 py-3 font-weight-bold">Your session is about to expire. Do you want to continue with this session?</p>
            </div>
            <div className="flex-row">
                <Button className="btn btn-primary btn-alert mr-2" onClick={extendSession}>YES</Button>
                <Button className="btn btn-primary btn-alert mr-2" onClick={logout}>NO</Button>
            </div>
            </Dialog>
      </div>
      )
    }
    export default IdleSessionLogout;