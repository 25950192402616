import React from 'react';
import Card from 'react-bootstrap/Card';

import Table from 'react-bootstrap/Table';
import styles from './Practitioner.css';


export default function Practitioner(){
let Practitionerresponse={ "resourceType": "Practitioner", "id": "1123800032432", "meta": { "lastUpdated": "2021-02-08T12:22:32.642+00:00", "profile": [ "http://hl7.org/fhir/us/carin-bb/StructureDefinition/C4BB-Practitioner" ] }, "identifier": [ { "type": { "coding": [ { "system": "http://hl7.org/fhir/sid/us-npi", "code": "RI", "display": "1356588727" } ] }, "value": "1123800032432" }, { "type": { "coding": [ { "system": "http://hl7.org/fhir/us/carin-bb/CodeSystem/C4BBIdentifierType", "code": "npi" } ] }, "system": "http://hl7.org/fhir/sid/us-npi", "value": "npi" } ], "name": [ { "family": "Lee", "given": [ "Heather", "test" ], "prefix": [ "Ms" ] } ] };
const codestyle={backgroundColor: 'lightgray'};
return (
<div className="container">
<Card className="card-background"> <Card.Body>
<blockquote className="blockquote mb-0">
<h1 className="text-header">Practitioner</h1>
<p>Practitioner covers all individuals who are engaged in the healthcare process and healthcare-related services as part of their formal responsibilities and this Resource is used for attribution of activities and responsibilities to these individuals.</p>
</blockquote> </Card.Body>
</Card>
<br/>
<hr className="horizontal-rule"></hr>
<br/>

<Card className="card-background">
<Card.Body>
<h4 className="Practitioner-heading">URL</h4>
<div>
<codeblock className="accesstoken-code"> URL</codeblock>
<span>&nbsp;</span><span>&nbsp;</span>
<codeblock className="accesstoken-code">{process.env.REACT_APP_EDUCATIONAL_MATERIAL_URL}providerDirectory/api/R4/Practitioner</codeblock>
</div>
</Card.Body>
<Card.Body>
<h4 className="Practitioner-heading">Headers</h4>
<Table striped bordered hover>
<tbody>

<tr>
<td>Accept</td>
<td>application/json</td>
</tr>
<tr> <td>Authorization</td>
<td>Bearer 'ACCESS_TOKEN'</td>
</tr>
</tbody>
</Table>
</Card.Body>
<p>Note:Third party app will have access token after member has given consent to app (Refer Member consent section)
</p>
<Card.Body>
<h4 className="Practitioner-heading">Sample Request</h4>

<div style={codestyle}>'curl --location 'https://iox.dev.sddss.apps.conduent.com/providerDirectory/api/R4/Practitioner/1123800032432''\<br/>
                                    '--header 'Authorization: Bearer7Sp6WPj9AcmSLJMD_6u-bVZHp-4' '\<br/>
                                     '--header 'Cookie: JSESSIONID=bp4uCPJH3YbKA9eGuXDDh380zbq1oFPOuE9NsWNi' '\
</div>
<br/>
<h4 className="Practitioner-heading">Sample Response</h4>

<pre style={codestyle}>
<code class="prettyprint">
{JSON.stringify(Practitionerresponse,null,'  ')}
</code>
</pre>
</Card.Body>
</Card>
</div>
);
}