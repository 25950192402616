import React from 'react';
import Card from 'react-bootstrap/Card';
import styles from './MemberRegistration.css';
export default function MemberRegistration(){
return (
<div className="container">
<Card className="card-background"> <Card.Body>
<blockquote className="blockquote mb-0">
<h1 className="text-header">Member Registration</h1>
<p> The purpose of the South Dakota Interoperability Exchange (IOX) is to allow you to access your South Dakota Medicaid data securely through a Third Party Personal Health Application (TPPHA). You would typically download the TPPHA from an App Store. The App must previously have registered with South Dakota’s IOX and been approved.</p>
<p> In order to use a registered TPPHA, you must have an active account with South Dakota MySD, and you must also register with SD IOX in order to authenticate your access to your health data through a TPPHA.</p>
<p> Members can access the IOX Registration Process in two ways:</p>
<ul className="registration-list">
<li>They can use a web browser to go to the IOX Landing Page and then to the IOX registration screen. They can choose one or more TPPHAs at a later time. </li>
<li>A Member can install a TPPHA that has previously registered with IOX. Within the TPPHA, the Member notices that SD Medicaid is offered as one available source for their medical data and chooses to add data from South Dakota Medicaid. The TPPHA transfers the Member to IOX to validate the Member’s IOX credentials.</li>
</ul>
</blockquote>
</Card.Body>
</Card>
<br/>
<hr className="horizontal-rule"></hr>
<br/>
<Card className="card-background"><Card.Body>

<b>STEP 1: Sign in to MySD</b><br/><br/>
As described in the section above, the Member initiates validation of their credentials from the IOX 
Registration page. After they accept the Security Banner, they are taken to the IOX Sign-in page, where they can click MySD to continue their sign-in process or register with MySD.<br/><br/>
On the MySD screens, the Member must either sign into an existing account or create a new account. 
<br/><br/>
<img src="login.JPG" alt="Sign in or Registration Screen" style={{
        display: 'block',        
        margin: '0 auto',        
        maxWidth: '80%',         
        height: 'auto'           
    }}/>
<br/><br/>

<p><b>STEP 2: Supply information to IOX to validate your identity</b></p>
<p>After you successfully sign in to MySD, you will be returned to IOX. Unless you have previously registered with IOX, you will see a screen that asks you to supply 
    information to validate your identity. Items whose source is listed as “MySD” are fed from your MySD log-in, and some of them cannot be changed. 
</p>
<table border="1" class="custom_table" >
<thead class="center">
<tr><th>Field</th><th>Source</th><th>Description</th></tr>
</thead>
<tbody>
<hr className="horizontal-rule"></hr>
<tr><td>First Name</td><td>MySD – can’t be overridden</td><td>Member's First Name</td></tr>
<tr><td>Last Name</td><td>MySD – can’t be overridden</td><td>Member's Last Name</td></tr>
<tr><td>Date of Birth</td><td>Member</td><td>Member's Date of Birth</td></tr>
<tr><td>Phone Number</td><td>Member</td><td>Member's Phone Number</td></tr>
<tr><td>Email Address</td><td>MySD – can’t be overridden</td><td>Member’s Email Address - IOX may send communications related to your account, including an Email Verification Code.</td></tr>
<tr><td>Member ID </td><td>Member</td><td>The member identification number provided to you when you applied for Medicaid benefits.</td></tr>
<tr><td>Member SSN</td><td>Member</td><td>Member’s Social Security Number (SSN)</td></tr>
<tr><td>Address</td><td>MySD – can be overridden</td><td>Member’s Email Address</td></tr>
<tr><td>City</td><td>MySD – can be overridden</td><td>Member’s City</td></tr>
<tr><td>State</td><td>MySD – can be overridden</td><td>Member’s State</td></tr>
<tr><td>Postal Code</td><td>MySD – can be overridden</td><td>Member’s Postal Code</td></tr>
</tbody>
</table>

<br/>
<p>	After you complete the registration form, click <b>Next</b>.<br/><br/>
<p>IOX uses the information that you supply to make sure that you are authorized to use the IOX application</p>
<ul className="registration-list">
<li>If you are not authorized, you will see an informational message. </li>
<li>If you are currently authorized to access your information through SD IOX, you will go to the next step.</li>
</ul>

<b>STEP 3: </b>A new page will appear with a questionnaire listing several medical visits which might be yours, plus an option indicating that none of the visits belongs to you. You need to choose the correct recent visit option. You will only have one chance to choose.<br/><br/>
<ul className="registration-list">
<li>If you are not authorized, you will see an informational message. </li>
<li>If you provide an incorrect response, you will have to wait 24 hours to try again.</li>
</ul>
<br/><br/>

<b>STEP 4: </b>Enter the email verification code that IOX has sent to your email address, and click the <b>VERIFY</b> button.You can ask to have a new code sent if you don’t receive the first one. <br/><br/>
 
After you successfully verify your email address, IOX displays the Congratulations message.
<ul className="registration-list">
<li>If you came to the registration page from the IOX landing page, you are done with registration. </li>
<li>If you came to the registration page from a TPPHA, you will be asked to consent to having the TPPHA access your data.</li>
</ul>

<b>STEP 5: This step only applies if you came to IOX from a TPPHA:</b>  You will be shown a page that asks you to consent to having the TPPHA access your information on your behalf. 
Please read the page before accepting it, because it provides you with valuable information about protecting your data. If you do accept the consent information, you will be returned to your TPPHA, and you
 can then use it to view the information from South Dakota Medicaid. <br/><br/>
 
 For additional information on the IOX application and on protecting your data, you can click on other help links within IOX. 


</p>
</Card.Body></Card>

</div>
);
}