
import axios from 'axios';
import * as serviceEndPoint from '../../../SharedModule/Service/service';

export const validateMember =  (userInfo) => {
    return axios.post(serviceEndPoint.validateMember, {
          userId: userInfo.oid,
        //   userId: "abcde",

        firstName: userInfo.given_name,
        lastName: userInfo.family_name,
        memberId: userInfo.memberId,
        dob: userInfo.dob,
        ssn: userInfo.ssn,
    }, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(response => {
        return response.data;
    })
    .catch(error => {
        console.error('Error validating member:', error);
        return null;
    });
};
export const validateAdmin =  (userInfo) => {
    return axios.post(serviceEndPoint.validateAdmin, {
        email: userInfo.preferred_username,
    }, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(response => {
        return response.data;
    })
    .catch(error => {
        console.error('Error validating Admin', error);
        return null;
    });
};


export const getMemberProfileDetails = async (userId) => {
    try {
        const response = await axios.get(`${serviceEndPoint.memberProfileDetails}/${userId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching member profile details:', error);
        throw error; // Rethrow the error for the caller to handle
    }
};
