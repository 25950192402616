import React, { useState } from 'react';
import { Carousel } from 'react-bootstrap';

function CustomCarousel() {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    return (
        <Carousel id="carousal" activeIndex={index} onSelect={handleSelect}>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    // src="Login_img_1.png"
                    src={`${window.location.origin}/Login_img_1.png`}

                    alt="First slide"
                />
                <Carousel.Caption>
                    <h5 className="text-left mb-0">Welcome to</h5>
                    <h3 className="text-left">South Dakota DSS Interoperability Portal</h3>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    // src="Login_img_2.png"
                    src={`${window.location.origin}/Login_img_2.png`}

                    alt="Second slide"
                />
                <Carousel.Caption>
                    <h5 className="text-left mb-0">Welcome to</h5>
                    <h3 className="text-left">South Dakota DSS Interoperability Portal</h3>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    // src="Login_img_3.png"
                    src={`${window.location.origin}/Login_img_3.png`}

                    alt="Third slide"
                />
                <Carousel.Caption>
                    <h5 className="text-left mb-0">Welcome to</h5>
                    <h3 className="text-left">South Dakota DSS Interoperability Portal</h3>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    );
}
export default CustomCarousel;